import React, { useEffect, useState } from 'react';
import Layout from '../../../layout/Layout';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';
import { billService, clientService } from '../../../../services';
import CrudEmpty from '../../../crud/CrudEmpty';
import Filter from '../../../filter/Filter';
import styled from 'styled-components';
import { red } from '@mui/material/colors';
import { InfoOutlined } from '@mui/icons-material';
import CustomPaginationActionsTable from '../../../table/Table';
import PrintIcon from '@mui/icons-material/Print';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilePdf, faFileWord } from '@fortawesome/free-solid-svg-icons';
const moment = require('moment');

export default function Model347() {
      const { t, i18n } = useTranslation();
      const [anchorEl, setAnchorEl] = React.useState(null);

      const [open, setOpen] = React.useState(true);
      const [data, setData] = React.useState([]);
      const [filter, setFilter] = React.useState({ ejercicio: moment().year() - 1 });

      const createEstructureFilter = [
            {
                  type: 'autocomplete',
                  name: 'ejercicio',
                  options: [
                        { key: moment().year() - 1, name: moment().year() - 1 + '' },
                        { key: moment().year() - 2, name: moment().year() - 2 + '' },
                        { key: moment().year() - 3, name: moment().year() - 3 + '' },
                        { key: moment().year() - 4, name: moment().year() - 4 + '' },
                        { key: moment().year() - 5, name: moment().year() - 5 + '' },
                        { key: moment().year() - 6, name: moment().year() - 6 + '' },
                        { key: moment().year() - 7, name: moment().year() - 7 + '' },
                        { key: moment().year() - 8, name: moment().year() - 8 + '' },
                  ],
                  col: 3,
            },
            {
                  type: 'autocomplete',
                  name: 'client',
                  service: clientService.comboAll,
                  searchcode: true,
                  col: 3,
            },
      ];

      const search = (filter) => {
            if (filter.ejercicio) {
                  if (filter.client && filter.client > 0) {
                        billService.model347Client(filter.ejercicio, filter.client, (data, error) => {
                              if (data.data) {
                                    setData(data.data);
                              }
                        });
                  } else {
                        billService.model347(filter.ejercicio, (data, error) => {
                              if (data.data) {
                                    setData(data.data);
                              }
                        });
                  }
            }
      };

      useEffect(() => {
            setFilter({ ejercicio: moment().year() - 1 });
            search({ ejercicio: moment().year() - 1 });
      }, []);

      const searchFilter = (filter) => {
            console.log(filter);
            setFilter(filter);
            search(filter);
      };

      const paintCell = {
            total: (value, object) => {
                  if (value) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
            trimestre1: (value, object) => {
                  if (value) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
            trimestre2: (value, object) => {
                  if (value) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
            trimestre3: (value, object) => {
                  if (value) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
            trimestre4: (value, object) => {
                  if (value) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
      };

      const handlePrint = (bill) => {
            let fileName = 'Modelo347-' + bill.codeClient + '-' + filter.ejercicio + '.pdf';
            billService.model347ClientPrint(filter.ejercicio, bill.idClient, fileName, (data, erro) => {
                  //toast.success("Se ha descargado la factura", {  position: toast.POSITION.TOP_CENTER });
            });
      };

      const clickExportExcel = () => {
            billService.model347Excel(filter.ejercicio, (data, error) => {});
      };

      const clickExportWord = () => {
            billService.model347Word(filter.ejercicio, (data, error) => {});
      };

      const clickExportPdf = () => {
            billService.model347Pdf(filter.ejercicio, (data, error) => {});
      };

      return (
            <CrudEmpty i18n={'model347'}>
                  <Grid container style={{ background: 'white', padding: '0.3em 1em 1em', borderRadius: '3px' }}>
                        <Grid item sm={12} xs={12} style={{ textAlign: 'left', marginBottom: '10px' }}>
                              <Filter
                                    t={t}
                                    i18n={'model347'}
                                    cleanFilter={searchFilter}
                                    searchFilter={searchFilter}
                                    filter={filter}
                                    titlebutton={t('model347.titlebutton')}
                                    estructure={createEstructureFilter}
                                    hiddenButton={true}
                                    expanded={true}
                              />
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'right', marginBottom: '10px' }}>
                              <Button variant="text" color="secondary" style={{ marginLeft: '5px', fontSize: '30px' }} onClick={clickExportExcel}>
                                    {' '}
                                    <FontAwesomeIcon icon={faFileExcel} />{' '}
                              </Button>
                              <Button variant="text" color="secondary" style={{ marginLeft: '5px', fontSize: '30px', color: 'red' }} onClick={clickExportPdf}>
                                    {' '}
                                    <FontAwesomeIcon icon={faFilePdf} />{' '}
                              </Button>
                              <Button variant="text" color="secondary" style={{ marginLeft: '5px', fontSize: '30px', color: 'blue' }} onClick={clickExportWord}>
                                    {' '}
                                    <FontAwesomeIcon icon={faFileWord} />{' '}
                              </Button>
                        </Grid>
                        <Grid item sm={12} xs={12} style={{ textAlign: 'left', marginBottom: '3em' }}>
                              <PanelRounded>
                                    <h3>Clientes</h3>
                                    <Grid container>
                                          <Grid item sm={12} xs={12} style={{ textAlign: 'left', marginBottom: '10px', marginTop: '20px' }}>
                                                <CustomPaginationActionsTable
                                                      t={t}
                                                      i18n={'model347'}
                                                      labels={{ 'codeClient|nameClient|nifClient': t('model347.field.client') }}
                                                      rows={data}
                                                      fields={['codeClient|nameClient|nifClient', 'trimestre1', 'trimestre2', 'trimestre3', 'trimestre4', 'total']}
                                                      fieldId={'idClient'}
                                                      hiddenpagination={true}
                                                      deleteaction={false}
                                                      electHandler={null}
                                                      editable={false}
                                                      defaultValues={{}}
                                                      paintCell={paintCell}
                                                      paintvalue={{}}
                                                      selectable={false}
                                                      handlerSelected={null}
                                                      moreActions={[{ handle: handlePrint, icon: <PrintIcon /> }]}
                                                />
                                          </Grid>
                                    </Grid>
                              </PanelRounded>
                        </Grid>
                  </Grid>
            </CrudEmpty>
      );
}

const PanelRounded = styled.div((props) => ({
      display: 'block',
      padding: '1em',
      position: 'relative',
      border: '1px solid #ccc',
      borderRadius: '3px',
      '& h3': {
            color: 'black',
            position: 'absolute',
            top: '-2em',
            left: '2em',
            background: 'white',
            padding: '0.2em 1em',
            display: 'inline-block',
      },
}));

const SpanAmmount = styled.span((props) => ({
      display: 'inline-block',
      fontWeight: 'bold',
      padding: '0.2em 1em',
}));
