import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudFormTabs from "../../crud/CrudFormTabs"
import {supplierService as service, routeService, paymenttypesupplierService, activityService } from "../../../services"
import { useTranslation } from 'react-i18next';
import {initElementStreet, estructureformStreet, getElementStreet} from '../../utils/Data';
import TabDeliverynotesupplier from './TabDeliverynotesupplier';
import TabBillsupplier from './TabBillsupplier';

const initElement = {
     code:"",
     nif:"",
     comercialName:"",
     email:"",
     phone: "",
     street: initElementStreet,
     paymentType:{id:null, name: null},
     nameContact: "",
     note: "",
     numregistro1:"",
     numregistro2:""
  };


type FormsupplierParms = {
  id: string;
};
  

export default function FormSupplier() {
  let { id } = useParams<FormsupplierParms>();
  const [idRow, setIdrow] = useState(id?Number(id):0);
  const { t, i18n } = useTranslation();


  const estructureform =  [
    {
      type:"input",
      name: "code",
      col: 3,
     },
     {
       type:"input",
       name: "nif",
       col: 3
     },
     {
       type:"input",
       name: "comercialName",
       col: 3
     },
     {
      type:"input",
      name: "nameContact",
      col: 3
     },
     {
        type:"input",
        name: "phone",
        col: 3
     },
     {
        type:"input",
        name: "email",
        inputtype:"email",
        col: 3
     },
      {
        type:"autocomplete",
        name: "paymentType.id",
        service:paymenttypesupplierService.combo,
        col: 3
      },
      {
         type:"input",
         name: "numregistro1",
         col: 3
      },
      {
         type:"input",
         name: "numregistro1",
         col: 3
      },
      ...estructureformStreet,

      {
        type:"title",
        name: "titlenotes",
        col: 12
      },
      {
        type:"editor",
        name: "note",
        col: 8
      },
      {
        type:"image",
        name: "picture",
        col: 4
      },

    ];


  const recoveryElement = function(objectedit,element){

    objectedit['code']=element.code?element.code:"";
    objectedit['nif']=element.nif?element.nif:"";
    objectedit['comercialName']=element.comercialName?element.comercialName:"";
    objectedit['email']=element.email?element.email:"";
    objectedit['phone']=element.phone?element.phone:"";
    objectedit['paymentType']=element.paymentType?element.paymentType:{id:null, name: null};
    objectedit['nameContact']=element.nameContact?element.nameContact:"";
    objectedit['picture']=element.picture?element.picture:"";
    objectedit['note']=element.note?element.note:"";

    getElementStreet(element.street,objectedit['street']);
   

    return objectedit;
  }




  const isValid = function(object){
  
    if(!object['comercialName']){
      return t("supplier.error.name");
    }
    if(!object['nif']){
      return t("supplier.error.nif");
    }


      return null;
  }



  const tabDelivery = (id) => {
    return <TabDeliverynotesupplier idSupplier={id}/>;
  }


  const tabBill = (id) => {
    return <TabBillsupplier idSupplier={id}/>;
  }


  return (
    <CrudFormTabs
        objectform = {{...initElement}}
        estructureform = {estructureform}
        i18n={"supplier"}
        urledit={"/supplier/edit/"}
        urlCancel={"/supplier"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
        haveother = {true}
        search={true}
        fieldcode={"code"}
        labelCode={"Buscar proovedor por código"}
        tabs={[{title:"client.tab.deliverytitle", method:tabDelivery},{title:"client.tab.billtitle", method:tabBill}]}
      />

  );
}
