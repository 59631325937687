import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../../crud/CrudForm"
import {movementsService as service, productService, parterutaService, supplierService } from "../../../../services"
import Streets from '../../users/Streets'
import { useTranslation } from 'react-i18next';


const initElement = {
     date:null,
     product:{id:null, name: null},
     lote:"",
     ammount: null,
     supplier:{id:null, name: null},
     client:{id:null, name: null},
     type:null
  };


type FormClientParms = {
  id: string;
};
  

export default function FormMovements() {
  let { id } = useParams<FormClientParms>();
  const [idRow, setIdrow] = useState(id?Number(id):0);
  const { t, i18n } = useTranslation();


  const checkIsExit= function(object){
    return object.type && object.type != 1;
  }

  const checkIsEntry= function(object){
    return object.type && object.type != 2;
  }

  const setValuType  = (setValueField,objectform,value,valueform,setState,id,aux) => {
    
    var name = "type";

      var newobject = {...objectform}
      newobject = setValueField(newobject,name,value?value.key:null);

      var valueFormAux = {...valueform}
      valueFormAux["type"] = value;

      newobject =  setValueField(newobject,"type", value.key);
      if(value.key == 1){
        newobject =  setValueField(newobject,"supplier", {id:null,name:""});
        valueFormAux["supplier.id"] = {id:null,name:""};
      } else if(value.key == 2){
        newobject =  setValueField(newobject,"parteRuta", {id:null,name:""});
        valueFormAux["parteRuta.id"] = {id:null,name:""};
      } 


      var update = {
        objectform: newobject,
        valueform: valueFormAux,
      }

      setState(currentState => ({...currentState, ...update}));

      return newobject;

  }

  const estructureform =  [
    {
      type:"input",
      name: "date",
      inputtype:"date",
      col: 3,
     },
     {
       type:"autocomplete",
       name: "product.id",
       service:productService.combo,
       col: 3
     },
     {
       type:"input",
       name: "lote",
       col: 3
     },
     {
       type:"input",
       name: "ammount",
       inputtype:"number",
       step:0.01,
       col: 3
     },
     {
       type:"autocomplete",
       name: "type",
       options:[{key:1,name:"Salida"},{key:2,name:"Entrada"}],
       setvalue: setValuType,
       col: 3
     },
     {
       type:"autocomplete",
       name: "parteRuta.id",
       service:parterutaService.combo,
       conditionalread:checkIsExit,
       col: 3
     },
     {
       type:"autocomplete",
       name: "supplier.id",
       service:supplierService.combo,
       conditionalread:checkIsEntry,
       col: 3   
     },
     

    ];


  const recoveryElement = function(objectedit,element){

    objectedit['date']=element.date?element.date:null;
    objectedit['lote']=element.lote?element.lote:"";
    objectedit['ammount']=element.ammount?element.ammount:null;
    objectedit['product']=element.product?element.product:{id:null, name: null};
    objectedit['supplier']={id:element.supplier && element.supplier.id?element.supplier.id:null, name: element.supplier && element.supplier.comercialName?element.supplier.comercialName:null};
    objectedit['client']={id:element.client && element.client.id?element.client.id:null, name: element.client && element.client.comercialName?element.client.comercialName:null};
    objectedit['parteRuta']={id:element.parteRuta && element.parteRuta.id?element.parteRuta.id:null, name: element.parteRuta && element.parteRuta.code?element.parteRuta.code:null};
    
    objectedit['type']=element.type?element.type:{id:null, name: null};


    return objectedit;
  }




  const isValid = function(object){
    if(!object['type']){
      return t("movements.error.type");
    }
    if(!object['product'] || !object['product']['id']){
      return t("movements.error.product");
    }
    if(!object['lote'] || object['lote'] == ""){
      return t("movements.error.lote");
    }
    if(!object['ammount']){
      return t("movements.error.ammount");
    }
    if(!object['date'] || object['date'] == ""){
      return t("movements.error.date");
    }
  

      return null;
  }



  return (
    <CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        i18n={"movements"}
        urledit={"/movements/edit/"}
        urlCancel={"/movements"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid} />

  );
}
