import CrudTable from "../../../crud/CrudTable"
import {productService as service, categoryService} from "../../../../services"
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'

const initFilter =  {
    code : null,
    name : null,
    fromPrice: null,
    toPrice: null,
    category: null,
    active:"true",

};


export default function Product() {
  const { t, i18n } = useTranslation();

  const createEstructureFilter =  [    
     {
       type:"input",
       name: "code",
       autoFocus:true,
       col: 4
      },
     {
       type:"input",
       name: "name",
       col: 4
      },
      {
        type:"input",
        name: "fromPrice",
        inputtype:"numer",
        step:0.01,
        col: 4
      },
      {
        type:"input",
        name: "toPrice",
        inputtype:"numer",
        step:0.01,
        col: 4
      },
      {
        type:"autocomplete",
        name: "category",
        service:categoryService.combo,
        relationfield:'subcategory',
        servicerelation:categoryService.comboChildren,
        col: 4,
      },
      {
         type:"autocomplete",
         name: "subcategory",
         options:[],
         col: 4
       },
       {
         type:"autocomplete",
         name: "active",
         options:[{key:"true",name:"SI"},{key:"false",name:"NO"}],
         col: 4
       },
  ];

  const pritnprice = () => {
    service.printPrice("Listadeprecios.pdf", (data,error) => {

    });
  }

  const othersPanel = () => {
    return <Grid container> <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
              <Button variant="contained" color="primary" style={{marginLeft:'30px'}} onClick={pritnprice}> Imprimir lista de precios </Button>
          </Grid>
          </Grid>
  }


  return (
    <CrudTable
          service={service}
          urledit={"/product/edit/"}
          urlnew={"/product/new/"}
          filter={initFilter}
          i18n={"product"}
          expendedFilter={true}
          fieldstable={["imageprin","code","name","lastBuyPrice","percentPvp","price","category.name"]}
          order={true}
          orderColumns={{"code":"code", "name":"name", "lastBuyPrice":"lastBuyPrice", "percentPvp":"percentPvp", "price":"price"}}
          estructureForm={createEstructureFilter}
          haveother={true}
          othersPanel={othersPanel()} />
  );


}