import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTab from "../../crud/CrudTab"
import {deliverynoterutaService as service, clientService, paymenttypeService} from "../../../services"
import 'toasted-notes/src/styles.css';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/system';
import { Button, Grid } from '@mui/material';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const initFilter =  {
  from : '2000-01-01T00:00:00Z',
  to: '2000-01-01T23:00:00Z',
  route:-1,
  client:null,
  paymenttype:null
};


export default function TabDeliverynoteRuta(props) {

  const { t, i18n } = useTranslation();

  const rowsEmpyt:any[] = [];

  const[{filter,rows,forcesearch,openModal,delivery,updateStateParent},setState] = useState({
    filter:{...initFilter},
    rows:rowsEmpyt,
    forcesearch:false,
    openModal:false,
    delivery:{},
    updateStateParent: props.updateStateParent
  })



  const paintCell = {
    base: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    discount: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    iva: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    total: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    equivalencia: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    tax: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    paytotal: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    payLess: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    }

  }

  const createEstructureFilter =  [
    {
      type:"autocomplete",
      name: "client",
      service: clientService.combo,
      searchcode:true,
      col: 3
     },
     {
      type:"autocomplete",
      name: "paymenttype",
      service: paymenttypeService.combo,
      col: 3
     },

     
 ];

  const conditionColor = (row) => {
    
    if(row["payLess"] && +row["payLess"] > 1){
      return "red";
    } else {
      return "black";
    }
  }


  useEffect(() => {
    
    var filterAux = {...filter}
    filterAux["route"] = props.route.id;
    filterAux["from"] = props.dates.from;
    filterAux["to"] = props.dates.to;
  
    if(props.route.id > 0){

      var update = {
        filter:filterAux,
        forcesearch:!forcesearch
      }
      setState(currentState => ({...currentState, ...update}))
    } else {
      var update2 = {
        filter:filterAux,
        rows:[]
      }
      setState(currentState => ({...currentState, ...update2}))
    }
  
  }, [props.route,props.dates]);

  useEffect(() => {
    return () => {
  
  
      
      setState({
        filter:{
          from : '2000-01-01T00:00:00Z',
          to: '2000-01-01T23:00:00Z',
          route:-1,
          client:null,
          paymenttype:null
        },
        rows:[],
        forcesearch:false,
        openModal:false,
        delivery:{},
        updateStateParent:null});
     }
   
  }, []);

  const handleEdit = (selected) => {
    var update = {
      delivery: selected,
      openModal:true
    } ;

    setState(currentState => ({...currentState, ...update}));
  }

  const handleOpenModal = () => {
    setState(currentState => ({...currentState, ["openModal"]:false}));
  }

  const handleSavePay = () => {
    setState(currentState => ({...currentState, ["openModal"]:false}));
    service.modifypay(delivery, (data,error) => {

      if(data && data.data){

        var update = {
          forcesearch: !forcesearch,
          delivery: {}
        }

        setState(currentState => ({...currentState, ...update}));
        updateStateParent(currentState => ({...currentState, ["parteRutaForm"]:data.data.parteRuta}));
      }
    });
  }


  return (
      <><CrudTab
        filter={filter}
        editable={false}
        deleteaction={false}
        estructureForm={createEstructureFilter}
        blockedinitsearch={true}
        rows={rows}
        service={service}
        hiddenpagination={true}
        paintCell={paintCell}
        conditionColor={conditionColor}
        i18n={"deliverynote"}
        moreActions={[{"handle":handleEdit, icon:<EditIcon />}]}
        fieldstable={["serieAndNumber","date","client.comercialName|client.email|client.phone","paymentType.name","paytotal","payLess","total"]}
        labels={{"client.comercialName|client.email|client.phone":t('bill.field.client.name')}}
        defaultValues={{serie:"",number:"Borrador"}}
        rowsPerPage={400}
        forcesearch={forcesearch}
       />
        <Modal
            open={openModal}
            onClose={handleOpenModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
            <Box sx={style}>
              <div style={{marginBottom:'30px'}}>
                  Modificar el pago del albarán {delivery['serieAndNumber']}
              </div>
              <div style={{marginBottom:'30px'}}>
                <TextField variant="standard" sx={{width: "100%"}} label={t("bill.field.payTotal")} value={delivery['paytotal']} onFocus={(evt) => {evt.target.select()}} onChange={(evt) => {var s = {...delivery}; s["paytotal"] = evt.target.value; setState(currenstate => ({...currenstate, ["delivery"]:s}))}} type={"number"}  inputProps={{step:0.01,  autoComplete: 'off'}}  InputLabelProps={{shrink:true}} autoComplete="off"/>
              </div>
              <div>
                <Button variant="outlined" color="primary" style={{marginRight:'30px'}} onClick={handleOpenModal}> Cancelar</Button>
                <Button variant="contained" color="primary" onClick={handleSavePay}> Guardar </Button>
              </div>
          </Box>
        </Modal>
       </>

  );

}
