import React, {useEffect,useState} from 'react';
import Layout from '../../layout/Layout';
import { Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, ResponsiveContainer } from 'recharts';
import { deliverynoteService } from '../../../services';
import CrudEmpty from "../../crud/CrudEmpty"
import Filter from '../../filter/Filter';
const moment = require("moment");



export default function Dashboard() {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [{data,selected,idselected,name,filter,total,totalold},setState] = useState({
        data: {},
        selected:[],
        idselected: -1,
        name:"en total",
        filter:{year:moment().year()},
        total:0,
        totalold:0
  });




  const searchDashboard = (filter) => {
    deliverynoteService.dashboard(filter.year,(data,error) => {
        if(data.data) {

            var taux = 0;
            var toldaux = 0;

            (data.data.all['months']).forEach(element => {
                taux += +element['year'];
                toldaux += +element['yearold'];
            });


           var update = {
            data : data.data,
            selected: data.data.all['months'],
            total: taux,
            totalold: toldaux
           }

           
           setState(currentState => ({...currentState, ...update}));
        }
    });
  }


  useEffect(() => {
   
    return () => {
        setState(
          {
            data: {},
            selected:[],
            idselected: -1,
            name:"en total",
            filter:{year:moment().year()},
            total:0,
            totalold:0
          }
        )
      }

  }, []);

  useEffect(() => {
     if (data['all']) {
         var selectedAux = [];
         var nameAux = "en total"
        if (idselected == -1) {
            selectedAux = data['all']['months'];
            nameAux = "en total"
        } else if (idselected == 1) {
            selectedAux = data['route1']['months'];
            nameAux = "en OUTES-MUROS"
        } else  if (idselected == 2) {
            selectedAux = data['route2']['months'];
            nameAux = "en NOIA-SON"
        } else  if (idselected == 3) {
            selectedAux = data['route3']['months'];
            nameAux = "en RIBEIRA-BOIRO"
        } else  if (idselected == 4) {
            selectedAux = data['route4']['months'];
            nameAux = "en SANTIAGO-NEGREIRA"
        } else  if (idselected == 5) {
            selectedAux = data['route5']['months'];
            nameAux = "en CASA DA AVOA"
        } 

   
        var taux = 0;
        var toldaux = 0;

        (selectedAux).forEach(element => {
            taux += +element['year'];
            toldaux += +element['yearold'];
        });
            var update = {
            name : nameAux,
            selected: selectedAux,
            total: taux,
            totalold: toldaux
           }

           setState(currentState => ({...currentState, ...update}));
     }
  }, [idselected,data]);


  useEffect(() => {
    searchDashboard(filter);
 }, [filter]);

  const selectedRoute = (id) => {
      var update = {
        idselected : id
       }

       setState(currentState => ({...currentState, ...update}));
  }

  const createEstructureFilter =  [
    {
      type:"autocomplete",
      name: "year",
      options:[{key:moment().year(), name:moment().year()+""}, {key:moment().year()-1, name:moment().year()-1+""},{key:moment().year()-2, name:moment().year()-2+""},{key:moment().year()-3, name:moment().year()-3+""},{key:moment().year()-4, name:moment().year()-4+""},{key:moment().year()-5, name:moment().year()-5+""},{key:moment().year()-6, name:moment().year()-6+""},{key:moment().year()-7, name:moment().year()-7+""},{key:moment().year()-8, name:moment().year()-8+""}],
      col: 3
      },
 ];


 const searchFilter = (filteraux) => {
       debugger;
       var update = {
        filter : {...filteraux}
       }

      setState(currentState => ({...currentState, ...update}));
  }


  return (
    <CrudEmpty i18n={"dashboard"} goback={true}>

        <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                <Filter t={t} i18n={"dashboard"} cleanFilter={searchFilter} searchFilter={searchFilter} filter={filter} titlebutton={t("trazabilidad.titlebutton")} estructure={createEstructureFilter}  hiddenButton={true} expanded={true} inline={true}/>
        </Grid>
        <Grid container style={{background:"white", padding: "0.3em 1em 1em", borderRadius: "3px"}}>
         <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px',padding: "1em 0em 0em"}}>
                 <Button variant={idselected == -1 ? "contained":"outlined"} onClick={() => selectedRoute(-1)} style={{marginRight: "10px"}}> Todas las rutas </Button>
                 <Button variant={idselected == 1 ? "contained":"outlined"} onClick={() => selectedRoute(1)}  style={{marginRight: "10px"}}> OUTES-MUROS </Button>
                 <Button variant={idselected == 2 ? "contained":"outlined"} onClick={() => selectedRoute(2)}  style={{marginRight: "10px"}}> NOIA-SON </Button>
                 <Button variant={idselected == 3 ? "contained":"outlined"} onClick={() => selectedRoute(3)}  style={{marginRight: "10px"}}> RIBEIRA-BOIRO </Button>
                 <Button variant={idselected == 4 ? "contained":"outlined"} onClick={() => selectedRoute(4)}  style={{marginRight: "10px"}}> SANTIAGO-NEGREIRA </Button>
                 <Button variant={idselected == 5 ? "contained":"outlined"} onClick={() => selectedRoute(5)}  style={{marginRight: "10px"}}> CASA DA AVOA </Button>
         </Grid>
          <Grid item sm={6} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                        <h3>Ventas en {name} sin IVA</h3>
                    </Grid>
                    {selected.length  > 0 && <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                        <ResponsiveContainer aspect={2.5}>
                            <BarChart data={selected}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="nameMonth" />
                                <YAxis tickFormatter={value => value.toLocaleString(undefined, {maximumFractionDigits:2})}/>
                                <Tooltip formatter={value => value.toLocaleString(undefined, {maximumFractionDigits:2}) + "€"}/>
                                <Legend />
                                <Bar dataKey="year" fill="#64ac5b" name={"Año " + (filter.year)}/>
                                <Bar dataKey="yearold" fill="#8884d8" name={"Año " + (filter.year-1)}/>
                            </BarChart>
                        </ResponsiveContainer>
                    </Grid> }
                </Grid>
            </Grid>
            <Grid item sm={1} xs={12} style={{textAlign:'left', marginBottom: '10px'}}></Grid>
            <Grid item sm={5} xs={12}>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12} style={{textAlign:'left'}}>
                        <h3></h3>
                    </Grid> 
                    <Grid item sm={6} xs={6} style={{textAlign:'left', borderBottom: "1px solid black"}}>
                        <b>Més</b>
                    </Grid> 
                    <Grid item sm={3} xs={3} style={{textAlign:'right', borderBottom: "1px solid black"}}>
                        <b>Año actual</b>
                    </Grid> 
                    <Grid item sm={3} xs={3} style={{textAlign:'right', borderBottom: "1px solid black"}}>
                        <b>Año pasado</b>
                    </Grid> 
                    {selected.length > 0  && selected.map((ma) => (
                        <>
                            <Grid item sm={6} xs={6} style={{textAlign:'left', borderBottom: "1px solid lightgray"}}>
                                 {ma['nameMonth']}
                            </Grid> 
                            <Grid item sm={3} xs={3} style={{textAlign:'right', borderBottom: "1px solid lightgray"}}>
                                    {(+ma['year']).toLocaleString(undefined, {maximumFractionDigits:2})}€
                            </Grid> 
                            <Grid item sm={3} xs={3} style={{textAlign:'right', borderBottom: "1px solid lightgray"}}>
                                    {(+ma['yearold']).toLocaleString(undefined, {maximumFractionDigits:2})}€
                            </Grid> 
                        </>
                    ))}
                    <Grid item sm={6} xs={6} style={{textAlign:'left', borderBottom: "1px solid lightgray"}}>
                               <b>Total</b>
                    </Grid> 
                    <Grid item sm={3} xs={3} style={{textAlign:'right', borderBottom: "1px solid lightgray"}}>
                               <b>{(total).toLocaleString(undefined, {maximumFractionDigits:2})}€</b>
                    </Grid> 
                    <Grid item sm={3} xs={3} style={{textAlign:'right', borderBottom: "1px solid lightgray"}}>
                               <b>{(totalold).toLocaleString(undefined, {maximumFractionDigits:2})}€</b>
                    </Grid> 
                </Grid>
            </Grid>
           


        </Grid>

    </CrudEmpty>
  );
}
