import CrudTable from "../../../crud/CrudTable"
import {statedeliverysupplierService as service} from "../../../../services"

const initFilter =  {
    name : null,
    code: null,
};


export default function Statedeliverysupplier() {

  const createEstructureFilter =  [
     {
       type:"input",
       name: "name",
       col: 4
      },
  ];


  return (
    <CrudTable
          service={service}
          urledit={"/statedeliverysupplier/edit/"}
          urlnew={"/statedeliverysupplier/new/"}
          filter={initFilter}
          i18n={"statedeliverysupplier"}
          fieldstable={["id","name"]}
          estructureForm={createEstructureFilter} />
  );
}
