import CrudTable from "../../../crud/CrudTable"
import {taxtypeService as service} from "../../../../services"

const initFilter =  {
    name : null,
    code: null,
};


export default function Tax() {

  const createEstructureFilter =  [
     {
       type:"input",
       name: "name",
       col: 4
      },
  ];


  return (
    <CrudTable
          service={service}
          urledit={"/taxtype/edit/"}
          urlnew={"/taxtype/new/"}
          filter={initFilter}
          i18n={"taxtype"}
          fieldstable={["name","percent"]}
          estructureForm={createEstructureFilter} />
  );
}
