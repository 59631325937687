import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTable from "../../../crud/CrudTable"
import {billService as service} from "../../../../services"
import PrintIcon from '@mui/icons-material/Print'
import RefreshIcon from '@mui/icons-material/Refresh'
import { ToastContainer, toast } from 'react-toastify';
import 'toasted-notes/src/styles.css';
import { useTranslation } from 'react-i18next';
import{estructureformStreet, pad} from '../../../utils/Data'; 
import { Box } from '@mui/system';
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CustomPaginationActionsTable from "../../../table/Table"
import Filter from "../../../filter/Filter"
import Layout from '../../../layout/Layout';
import styled from "styled-components";

const moment = require("moment");

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const initFilter =  {
    month:moment().month(),
    year: moment().year()
};


export default function RemesaGenerate() {

  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [{ids,rows,clickconfirm},setState] = useState({
    ids:[],
    rows:[],
    clickconfirm:false
  });

  
  const month = [{key:0, name:"Enero"},{key:1, name:"Febrero"},{key:2, name:"Marzo"},{key:3, name:"Abril"},{key:4, name:"Mayo"},{key:5, name:"Junio"},{key:6, name:"Julio"},{key:7, name:"Agosto"},{key:8, name:"Septimbre"},{key:9, name:"Octubre"},{key:10, name:"Noviembre"},{key:11, name:"Diciembre"}];
  const year = [{key:moment().year(), name:moment().year()+""},{key:moment().year()-1, name:(moment().year()-1)+""}];

  const createEstructureFilter =  [
     {
       type:"autocomplete",
       name: "month",
       options:month,
       col: 3
      },
      {
        type:"autocomplete",
        name: "year",
        options:year,
        col: 3
       },
      
  ];

  const searchFilter = () => {
    console.log();
    var update = {rows: []};
    setState(currentState => ({...currentState, ...update}));
    service.toremesa((data, error) => {
      if(data && data.data){
        var update = {rows: data.data};
        setState(currentState => ({...currentState, ...update}));
      }
    });
    

  }

  const confirm = () => {
    /*let nameFile = "remesa"+moment().year()+(moment().month()+1)+moment().date()+".xls";
    service.toremesaconfirm(nameFile,(data, error) => {
      debugger;
      toast.success("Se ha generado correctamente la remsesa y se ha descragado el excel a importar", {position: toast.POSITION.TOP_CENTER });
      searchFilter();
    });*/


    var update = {clickconfirm: true};
    setState(currentState => ({...currentState, ...update}));
    
  }

  useEffect(() => {
     if (clickconfirm) {
        var update = {clickconfirm: false};
        setState(currentState => ({...currentState, ...update}));
        if(ids && ids.length > 0 ){
          let nameFile = "remesa"+moment().year()+(moment().month()+1)+moment().date()+".xls";
          service.toremesaconfirmPost(ids,nameFile,(data, error) => {
            debugger;
            toast.success("Se ha generado correctamente la remsesa y se ha descragado el excel a importar", {position: toast.POSITION.TOP_CENTER });
            searchFilter();
          });
        }
     }
  }, [clickconfirm,ids]);


  const paintCell = {
    base: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    discount: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    iva: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    total: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    equivalencia: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    tax: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },

  }


  useEffect(() => {
    searchFilter();
  }, []);



  const handlerSelected = (ids) => {
    debugger;
    var update = {ids: ids};
    setState(currentState => ({...currentState, ...update}));
  }


  return (
    <Layout t={t} title={t("remesa.titleformnew")}>
      <DivPanel>
       <DivTable>
          <CustomPaginationActionsTable 
          t={t} i18n={"bill"} 
          labels={{"client.comercialName|client.email|client.phone":t('bill.field.client.name')}} 
          rows={rows} 
          fields={["serie","number","date","client.comercialName|client.email|client.phone","base","tax","equivalencia","iva","total"]} 
          fieldId={"id"} 
          rowsPerPage={10} 
          hiddenpagination={true} 
          deleteaction={false} 
          editable={false} 
          defaultValues={{serie:"",number:"Borrador"}} 
          paintCell={paintCell} 
          paintvalue={{}} 
          moreActions={[]}  
          conditionDelete={null} 
          columOrder={null} 
          columOrderDirection={"ASC"} 
          clickHeader={null} 
          selectable={true}
          handlerSelected={handlerSelected} />
        </DivTable>
        <Grid item xs={12} style={{textAlign:'right', marginBottom: '10px',marginTop: '30px'}}>
            <Button  variant="outlined" color="secondary" style={{marginLeft:'30px'}} onClick={() => history.goBack()}> Volver </Button>
            {rows.length > 0 &&  <Button  variant="contained" color="secondary" style={{marginLeft:'30px'}} onClick={confirm}> Exportar remesa </Button> }
        </Grid>
      </DivPanel>
      <ToastContainer />
    </Layout>

  );
}

const DivPanel =  styled.div`
    padding: 2em;
    @media (max-width: 767px) {
      padding: 0.5em;
    }
`;

const DivTable =  styled.div`
`;