import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../../crud/CrudForm"
import {hollydayService as service, comercialService } from "../../../../services"
import { useTranslation } from 'react-i18next';


  const initElement =  {
    comercial : {id:null, name:null},
    from:null,
    to:null
};


export default function FormHollyday() {
  const { t, i18n } = useTranslation();


  const estructureform =  [
      {
        type:"autocomplete",
        name: "comercial.id",
        service: comercialService.combo,
        col: 4
       },
       {
        type:"input",
        name: "from",
        inputtype:"date",
        col: 4
      },
      {
        type:"input",
        name: "to",
        inputtype:"date",
        col: 4
        },
   ];


  const recoveryElement = function(objectedit,element){


    objectedit['comercial']= element.comercial && element.comercial.id? {id:element.comercial.id, name:element.comercial.name}:{id:null, name:null};
    objectedit['from']= element.from ? element.from:null;
    objectedit['to']= element.to ? element.to:null;

    return objectedit;
  }



  const isValid = function(object){
      if(!object['comercial']['id'] || object['comercial']['id'] < 1){
        return t("hollyday.error.comercial");
      }
      if(!object['from']){
        return t("hollyday.error.from");
      }
      if(!object['to']){
        return t("hollyday.error.to");
      }

      return null;
  }



  return (
    <CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        i18n={"hollyday"}
        urledit={"/hollyday/edit/"}
        urlCancel={"/hollyday"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />

  );
}
