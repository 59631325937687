import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import CrudTable from "../../../crud/CrudTable"
import {deliverynotetpvService as service, clienttpvService, routetpvService} from "../../../../services/tpv"
import PrintIcon from '@mui/icons-material/Print'
import 'toasted-notes/src/styles.css';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import{pad} from '../../../utils/Data'; 
import Grid from '@mui/material/Grid'
import DownloadIcon from '@mui/icons-material/Download';


const moment = require("moment");


type Filter = {
    number:any,
    serie : any,
    client:any,
    from:any,
    to:any,
    state:any,
    route:any,
    paymenttype:any,
    product:any,
    lote:any
}

const initFilter:Filter =  {
    number:null,
    serie : null,
    client:null,
    from:moment().format("YYYY")+"-01-01T00:00:00Z",
    to:null,
    state:null,
    route:13,
    paymenttype:null,
    product:null,
    lote:null
};


export default function DeliverynoteTPV() {

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const idBillInit:any=null;
  const [{openBill,idBill,ids,eventClick},setState] = useState({
    openBill: false,
    idBill:idBillInit,
    ids:[],
    eventClick:0
  });

  const queryParams = new URLSearchParams(window.location.search)
  const product = queryParams.get("product");
  const lote = queryParams.get("lote");
  initFilter['product'] = product?product:null;
  initFilter['lote'] = lote?lote:null;

  const createEstructureFilter =  [

    {
      type:"autocomplete",
      name: "route",
      service:routetpvService.combo,
      col: 4
    },
    {
      type:"input",
      name: "serie",
      col: 3
     },
    {
      type:"input",
      name: "number",
      inputtype:"number",
      autoFocus:true,
      col: 3
     },
     {
       type:"autocomplete",
       name: "client",
       service: clienttpvService.combo,
       searchcode:true,
       col: 3
      },
       {
        type:"input",
        name: "from",
        inputtype:"date",
        col: 3
       },
      {
        type:"input",
        name: "to",
        inputtype:"date",
        col: 3
      }
  ];

  const handlePrint = (bill) => {

    var isGenerate = bill && bill.number && bill.number != '' && bill.state && bill.state.id && bill.state.id != 1;
    if (isGenerate){
      let fileName = "Albarán"+(bill.serie?bill.serie:"")+pad(+bill.number,5)+".pdf";
      service.print(bill.id,fileName,  (data,erro) =>{
        toast.success("Se ha descargado la albarán", {  position: toast.POSITION.TOP_CENTER });  
      });
    } else {
      toast.error("Solo se puede imprimir albaranes ya generados", {  position: toast.POSITION.TOP_CENTER });
    }
    
  }


  const handlePrintDirect = (bill) => {

    var isGenerate = bill && bill.number && bill.number != '' && bill.state && bill.state.id && bill.state.id != 1;
    if (isGenerate){
      let fileName = "Albarán"+(bill.serie?bill.serie:"")+pad(+bill.number,5)+".pdf";
      service.printDirect(bill.id,fileName,  (data,erro) =>{
        toast.success("Se ha descargado la albarán", {  position: toast.POSITION.TOP_CENTER });  
      });
    } else {
      toast.error("Solo se puede imprimir albaranes ya generados", {  position: toast.POSITION.TOP_CENTER });
    }
    
  }


  
  
  const paintCell = {
    base: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    discount: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    iva: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    total: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    equivalencia: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    tax: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    paytotal: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    payLess: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    

  }





  useEffect(()=>{
    return () => {
      setState({
        openBill: false,
        idBill:idBillInit,
        ids:[],
        eventClick:0
      });
     }
  },[]);





  const othersPanel = () => {
    return <Grid container>
          </Grid>
  }


  const conditionIsGenerate = (bill) => {
    var isGenerate = bill && bill.number && bill.number != '' && bill.state && bill.state.id && bill.state.id != 1;
    return isGenerate
  }

  const conditionColor = (row) => {
    
    if(row["number"]){
      if(!row["paymentType"]["id"] || row["paymentType"]["id"] == 1){ //CONTADO
        return "purple";
      } else if(!row["paymentType"]["id"] || row["paymentType"]["id"] == 3){ //CONTADO MENSUAL
        return "orange";
      } else if(!row["paymentType"]["id"] || row["paymentType"]["id"] == 7){ //TRANSFERENCIA BANCARIA
        return "blue";
      } else if(!row["paymentType"]["id"] || row["paymentType"]["id"] == 5){ //GIRO REMESA BANCARIA MENSUAL
        return "green";
      } else if(!row["paymentType"]["id"] || row["paymentType"]["id"] == 8){ //TPV
        return "lightseagreen";
      } else if(!row["paymentType"]["id"] || row["paymentType"]["id"] == 1001){ //GIRO REMESA BANCARIA SEMANAL
        return "fuchsia";
      } else {
        return "purple";
      }
    } else {
      return "black";
    }
    
  }
  
  return (
      <><CrudTable
        service={service}
        urledit={"/tpv/deliverynote/edit/"}
        urlnew={"/tpv/deliverynote/new/"}
        filter={initFilter}
        i18n={"deliverynote"}
        fieldstable={["serie","number","date","client.comercialName|client.email|client.phone","total","paytotal","payLess","state.name","paymentType.name","isPay"]}
        exportfields={["number","date","client.code","client.comercialName","base","iva","equivalencia","total"]}
        defaultValues={{serie:"",number:"Borrador"}}
        moreActions={[{"handle":handlePrintDirect, icon:<PrintIcon />, condition: conditionIsGenerate},{"handle":handlePrint, icon:<DownloadIcon />, condition: conditionIsGenerate}]}
    
        paintCell={paintCell}
        labels={{"client.comercialName|client.email|client.phone":t('bill.field.client.name')}}
        estructureForm={createEstructureFilter}
        haveother={true}
        othersPanel={othersPanel()}
        selectable={false}
        order={true}
        conditionColor={conditionColor}
        expendedFilter={true}
        notexport={true}
        deleteaction={false}
        orderColumns={{"serie":"serie", "number":"number", "date":"date"}}   /><ToastContainer/>
        </>

  );
}
