import React, { useState, useEffect }  from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import {supplierService as service, paymenttypeService} from "../../services"
import Form from "../form/Form"
import {initElementStreet, estructureformStreet, getElementStreet} from '../utils/Data';
import { Box } from '@mui/system';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  maxWidth:"80%",
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const initClient = {
    code:"",
    nif:"",
    comercialName:"",
    email:"",
    phone: "",
    street: initElementStreet,
    paymentType:{id:null, name: null},
    nameContact: "",
    note: "",
 };


export default function ModalSupplier(props) {
 const [clientform,setClientform] = useState({...initClient});
 const [openClient, setOpenClient] = useState(false);
 const [editable,setEditable] = useState(props.editable != null?props.editable:true)
 
 
 const estructureformClient =  [
  {
    type:"input",
    name: "code",
    col: 3,
    readonly:true
   },
   {
     type:"input",
     name: "nif",
     col: 3
   },
   {
     type:"input",
     name: "comercialName",
     col: 3
   },
   {
    type:"input",
    name: "nameContact",
    col: 3
   },
   {
      type:"input",
      name: "phone",
      col: 3
   },
   {
      type:"input",
      name: "email",
      inputtype:"email",
      col: 3
   },
    {
      type:"autocomplete",
      name: "paymentType.id",
      service:paymenttypeService.combo,
      col: 3
    },
    ...estructureformStreet,

  ];

const handleCloseClient = () => {
    setOpenClient(false);
    setClientform({...initClient});
  }

const saveFormClient = (object) => {
    handleCloseClient();
    service.create(object, (data, error) => {
    if(data){
        props.saveClient(data.data);
    }
});

}


return (
    <Grid item sm={12} xs={12} style={{textAlign:'right'}}>
        {editable && <Button variant="contained" color="secondary" style={{marginLeft:'10px'}} onClick={() => setOpenClient(true)}> Nuevo proovedor </Button>}

      <Modal
          open={openClient}
          onClose={handleCloseClient}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <Box sx={style}>
            <div>
              <h2>Nuevo prooevdor</h2>
            </div>
            <div>
                <Form t={props.t} i18n={'client'}  objectform={clientform} fields={estructureformClient}  searchFilter={saveFormClient} cleanFilter={handleCloseClient} labelsave={props.t('general.save')} labelcancel={props.t('general.cancel')}  />
            </div>
        </Box>
        </Modal>
    </Grid>
  );
}

