import BaseService from './_base.service';

const URL = "/admin/deliverynote";

export const deliverynoteloteService = {
    pageFilter,
    allFilterPdf,
    allFilterExcel,
    allFilterWord
};

function pageFilter(page,size,filter,callback) {
    return BaseService.post(URL+"/product/page/"+page+"/"+size, filter, callback, true)
}
function allFilterPdf(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/pdf", filter,"albaranes.pdf", callback,)
}


function allFilterExcel(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/excel", filter,"albaranes.xlsx", callback,)
}


function allFilterWord(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/word", filter,"albaranes.doc", callback,)
}