import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import {billService as service} from "../../../../services"
import 'toasted-notes/src/styles.css';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CustomPaginationActionsTable from "../../../table/Table"
import Filter from "../../../filter/Filter"
import Layout from '../../../layout/Layout';
import styled from "styled-components";
import { ToastContainer, toast } from 'react-toastify';
import 'toasted-notes/src/styles.css';

const moment = require("moment");


const initFilter =  {
    from:moment().startOf('month').format('YYYY-MM-DD')+'T00:00:00Z',
    to:moment().endOf('month').format('YYYY-MM-DD')+'T00:00:00Z',
    serie: null,
};


export default function ExportContable() {

  const { t, i18n } = useTranslation();
  const [rows,setRows] = useState([]);
  const [filterConfirm,setFilterConfirm] = useState({...initFilter});
  
  const createEstructureFilter =  [
     {
       type:"input",
       name: "from",
       inputtype:"date",
       col: 3
      },
      {
        type:"input",
        name: "to",
        inputtype:"date",
        col: 3
       },
      {
        type:"autocomplete",
        name: "serie",
        options:[{key:"",name:"TODAS"},{key:"S1",name:"S1"},{key:"S2",name:"S2"},{key:"R",name:"R"}],
        col: 3
      },
      
  ];

  const cleanFilter = (filter) => {
    console.log(filter);
    setRows([]);

  }

  const searchFilter = (filter) => {
    console.log(filter);
    setFilterConfirm(filter);
    setRows([]);
    if(filter.from && filter.to){
      service.allFilter(filter, (data, error) => {
        if(data && data.data){
          setRows(data.data)
        }
      });
    }
    

  }

  const confirm = () => {
    if(filterConfirm.from && filterConfirm.to && filterConfirm.serie){
      let from = moment(filterConfirm.from).format("YYYYMMDD");
      let to = moment(filterConfirm.to).format("YYYYMMDD");
      let name = "VENTAS SERIE " + filterConfirm.serie + " " + moment(filterConfirm.from).lang("es").format('MMMM')+".dat";
      service.contable(from, to,filterConfirm.serie,name, (data, error) => {
          if(error){
            toast.error("Error al generar el fichero vuelva a intentarlo mas tarde", {  position: toast.POSITION.TOP_CENTER });
          } else {
            toast.success("Se ha descargado el documento correctamente", {  position: toast.POSITION.TOP_CENTER });
          }
      });
    } else {

      toast.error("Debe indicar el rango de fechas y la serie", {  position: toast.POSITION.TOP_CENTER });
    }
    

  }


  const paintCell = {
    base: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    discount: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    iva: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    total: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    equivalencia: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    tax: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },

  }



  return (
    <Layout t={t} title={t("exportcontable.title")}>
      <DivPanel>
        <Filter t={t} i18n={"exportcontable"} cleanFilter={cleanFilter} searchFilter={searchFilter} filter={initFilter} estructure={createEstructureFilter}  hiddenButton={true} forceExpand={true}/>
        {rows.length > 0 && <Grid item xs={12} style={{textAlign:'right', marginBottom: '10px',marginTop: '30px'}}>
            <Button  variant="contained" color="secondary" style={{marginLeft:'30px'}} onClick={confirm}> {t("general.export")} </Button>
        </Grid>}
        <DivTable>
          <CustomPaginationActionsTable t={t} i18n={"bill"} labels={{"client.comercialName|client.email|client.phone":t('bill.field.client.name')}} rows={rows} fields={["serie","number","date","client.comercialName|client.email|client.phone","base","equivalencia","iva","total"]} fieldId={"id"} rowsPerPage={10} hiddenpagination={true} deleteaction={false} editable={false} defaultValues={{serie:"",number:"Borrador"}} paintCell={paintCell} paintvalue={{}} moreActions={[]}  selectable={false} handlerSelected={null} conditionDelete={null} columOrder={null} columOrderDirection={"ASC"} clickHeader={null} />
        </DivTable>
        
      </DivPanel>
      <ToastContainer />
    </Layout>

  );
}

const DivPanel =  styled.div`
    padding: 2em;
    @media (max-width: 767px) {
      padding: 0.5em;
    }
`;

const DivTable =  styled.div`
`;