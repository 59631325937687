import React, { useState, useEffect }  from 'react';
import { useTranslation } from 'react-i18next';
import { t } from "i18next";
import { Autocomplete, TextField,Grid } from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const moment = require("moment");



export default function Moth(props) {

  const t = props.t;
  const month = props.month;
  const [events, setEvents] = useState<any>(props.events); 
  const [startOfMonth, setStartOfMonth] = useState<any>(moment().set('month', month-1).startOf('month').format("YYYY-MM-DD")); 
  const [endOfMonthAux, setEndOfMonthAux] = useState<any>(moment().set('month', month-1).endOf('month').add(1,'day').format("YYYY-MM-DD")); 
  const calendarRef  = React.createRef<FullCalendar>();

  useEffect(() => {
      setEvents(props.events);
      calendarRef?.current?.render();
 }, [props.events]);

 useEffect(() => {
  setStartOfMonth(moment().set('year',props.year).set('month', month-1).startOf('month').format("YYYY-MM-DD"));
  setEndOfMonthAux(moment().set('year',props.year).set('month', month-1).endOf('month').add(1,'day').format("YYYY-MM-DD"))
  calendarRef?.current?.render();

}, [props.year]);


  return (
    <Grid item md={3} sm={4} xs={12} style={{padding: "1em"}}>  
        <FullCalendar
                ref={calendarRef}
                plugins={[ interactionPlugin, dayGridPlugin ]}
                initialView="dayGridMonth"
                locale="es"
                navLinks={false}
                headerToolbar={{start: 'title',   center: '', end: ''}}
                validRange={{start: startOfMonth, end: endOfMonthAux}}
                height={450}
                contentHeight={450}
                events={events}
                firstDay={1}
                eventClick={(info) => {
                  props.eventClick(info);
                }}
                selectable={true}
                editable={true}
                dateClick={(info) => {
                  props.dateClick(info);
                }}
            />
    </Grid>
  );


}
