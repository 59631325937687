import React, {useEffect,useState} from 'react';
import Layout from '../../../layout/Layout';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { deliverynoteService, categoryService } from '../../../../services';
import CrudEmpty from "../../../crud/CrudEmpty"
import Filter from "../../../filter/Filter";
import styled from "styled-components";
import { red } from '@mui/material/colors';
import { InfoOutlined } from '@mui/icons-material';
import CustomPaginationActionsTable from "../../../table/Table"
import { Autocomplete, TextField } from '@mui/material';
import { textAlign } from '@mui/system';
const moment = require("moment");


const initFilter =  {
  from:  moment(Date()).format('YYYY')+"-01-01T00:00:00Z",
  to:  moment(Date()).format('YYYY-MM-DD')+"T00:00:00Z",
};



export default function PaymentEffective() {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [open, setOpen] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [filter, setFilter] = React.useState({...initFilter});
  const [exportAction, setExportAction] = React.useState(0);



  const createEstructureFilter =  [    
    {
      type:"input",
      name: "from",
      inputtype: "date",
      col: 4
     },  
     {
       type:"input",
       name: "to",
       inputtype: "date",
       col: 4
      }
 ];

  const search = (filter) => {
    var from = moment(Date.parse(filter.from)).format('YYYY-MM-DD');
    var to = moment(Date.parse(filter.to)).format('YYYY-MM-DD');
      deliverynoteService.reportpayment(from,to, (data,error) => {
          if(data && data.data) {
              setData(data.data);
          } else {
            setData([]);
          
          }
      });
  }


  useEffect(() => {
        search(filter)
  }, [filter]);


  const searchFilter = (filter) => {
    console.log(filter);
    setFilter(filter);
  }


  const paintCell = {
    d: (value,object) => {
      if (value) {
        return <p style={{textAlign: "right"}}>{moment(Date.parse(value)).format('DD/MM/YYYY')}</p>
      } else {
        return <></>
      }
    },
    ammount: (value,object) => {
      if (value) {
        return <p style={{textAlign: "right"}}>{value.toLocaleString()} €</p>
      } else {
        return <></>
      }
    }
  }

  const exportToExcel = () => {
    setExportAction(currentState => (currentState + 1));
  }

  useEffect(() => {
    if(exportAction > 0){
       setExportAction(0);
       var from = moment(Date.parse(filter.from)).format('YYYY-MM-DD');
       var to = moment(Date.parse(filter.to)).format('YYYY-MM-DD');
         deliverynoteService.reportpaymentexcel(from,to, (data,error) => {
            
         });
    }
}, [exportAction,filter,data]);




  return (
    <CrudEmpty i18n={"paymenteffective"}>
        <Grid container style={{background:"white", padding: "0.3em 1em 1em", borderRadius: "3px"}}>
            <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '3em', paddingTop: "3em"}}>
                 <Filter t={t} i18n={"product"} cleanFilter={()=>{}} searchFilter={searchFilter} filter={filter} estructure={createEstructureFilter}  hiddenButton={true} expanded={true} isHiddenCancel={true}/>
           
            </Grid>
            <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '3em'}}>
                  <Button variant="contained" color="primary" onClick={exportToExcel} style={{display:"inline-block", width:"200px"}}> Exportar a excel </Button><p>Se exportaran segun el filtro indicado</p>
            </Grid>

            <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '3em'}}>
                <PanelRounded>
                        <h3>Pagos en efectivo</h3>
                        <Grid container>
                                <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', marginTop: '20px'}}>
                                        <CustomPaginationActionsTable
                                           t={t} i18n={"product"} 
                                           rows={data} 
                                           fields={["serie|num","code|name","ammount","d"]} 
                                           labels={{"serie|num":"Albarán", "code|name": "Cliente","ammount": "Cantidad", "d" :"Fecha"}}
                                           fieldId={"id"} hiddenpagination={true} deleteaction={false}  selectHandler={null} editable={false} defaultValues={{}} paintCell={paintCell} paintvalue={{}}  selectable={false} handlerSelected={null} 
                                           />
                                </Grid>
                        </Grid>
                </PanelRounded>
            </Grid>
        </Grid>




    </CrudEmpty>
  );
}

const PanelRounded = styled.div(props => ({
    display: 'block',
    padding: '1em',
    position: 'relative',
    border: '1px solid #ccc',
    borderRadius: '3px',
    "& h3":{
        color: "black",
        position: "absolute",
        top: "-2em",
        left: "2em",
        background: "white",
        padding: "0.2em 1em",
        display: "inline-block",

    }

  }));

const SpanAmmount = styled.span(props => ({
    display: 'inline-block',
    fontWeight: 'bold',
    padding: "0.2em 1em"

  }));
