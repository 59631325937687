import BaseService from './_base.service';

const URL = "/admin/product";

export const productService = {
    search,
    combo,
    all,
    comboState,
    page,
    pageFilter,
    one,
    create,
    edit,
    remove,
    removeLogic,
    report,
    printPrice,
    controlprice,
    controlpriceSave,
    allFilterPdf,
    allFilterExcel,
    allFilterWord,
    benefice,
    beneficeExcel,
    ammountbuy,
    ammountsale
};

function search(offset,callback) {
    return BaseService.get(URL+"/all/", callback)
}

function combo(callback) {
    return BaseService.get(URL+"/combo/", callback, true)
}
function all(callback) {
    return BaseService.get(URL+"/all/", callback, true)
}
function comboState(id,callback) {
    return BaseService.get(URL+"/combo/state/"+id, callback, true)
}
function page(page,size,callback) {
    return BaseService.get(URL+"/page/"+page+"/"+size, callback, true)
}

function pageFilter(page,size,filter,callback) {
    return BaseService.post(URL+"/page/"+page+"/"+size, filter, callback, true)
}

function one(id,callback) {
    return BaseService.get(URL + "/one/"+id, callback);
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post(URL, body, callback, true)
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put(URL+"/"+id, body, callback, true)
}

function remove(id,callback) {
    return BaseService.delete(URL+"/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete(URL+"/"+id+"/logic", callback);
}

function report(year,callback) {
    return BaseService.get(URL+"/report/"+year, callback);
}


function benefice(filter,callback) {
    return BaseService.post(URL+"/benefice",filter, callback,true);
}


function beneficeExcel(filter,callback) {
    return BaseService.postFile(URL+"/benefice/excel",filter, "beneficio.xls", callback,true);
}

function ammountsale(filter,callback) {
    return BaseService.postFile(URL+"/ammountsale/excel",filter, "cantidad-vendida.xls", callback,true);
}

function ammountbuy(filter,callback) {
    return BaseService.postFile(URL+"/ammountbuy/excel",filter, "cantidad-comprada.xls", callback,true);
}


function printPrice (name,callback){
    return BaseService.getFile(URL + "/print/price",name, callback);
}

function controlprice (date,callback){
    return BaseService.get(URL + "/control/price/"+date, callback);
}

function controlpriceSave (bodyelement,callback){
    let body = JSON.stringify(bodyelement)
    return BaseService.post(URL + "/control/price/",body, callback);
}

function allFilterPdf(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/pdf", filter,"productos.pdf", callback,)
}


function allFilterExcel(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/excel", filter,"productos.xlsx", callback,)
}


function allFilterWord(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/word", filter,"productos.doc", callback,)
}