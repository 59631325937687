import React, {useEffect,useState} from 'react';
import Layout from '../../layout/Layout';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';
import { trazabilidadService, productService } from '../../../services';
import CrudEmpty from "../../crud/CrudEmpty"
import Filter from "../../filter/Filter";
import styled from "styled-components";
import { red } from '@mui/material/colors';
import { InfoOutlined } from '@mui/icons-material';
import CustomPaginationActionsTable from "../../table/Table"
const moment = require("moment");



export default function Trazabilidad() {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [open, setOpen] = React.useState(true);
  const [data, setData] = React.useState({exit:[],entries:[],movements:[],totalExit:0,totalEntries:0});
  const [exits, setExits] = React.useState([]);
  const [entries, setEntries] = React.useState([]);
  const [movements, setMovements] = React.useState([]);
  const [totalExit, setTotalExit] = React.useState(0);
  const [totalEntries, setTotalEntries] = React.useState(0);
  const [filter, setFilter] = React.useState({});


  const createEstructureFilter =  [
        {
          type:"autocomplete",
          name: "ejercicio",
          options:[{key:moment().year(), name:moment().year()+""}, {key:moment().year()-1, name:moment().year()-1+""},{key:moment().year()-2, name:moment().year()-2+""},{key:moment().year()-3, name:moment().year()-3+""},{key:moment().year()-4, name:moment().year()-4+""},{key:moment().year()-5, name:moment().year()-5+""},{key:moment().year()-6, name:moment().year()-6+""},{key:moment().year()-7, name:moment().year()-7+""},{key:moment().year()-8, name:moment().year()-8+""}],
          col: 3
          },
        {
            type:"autocomplete",
            name: "product",
            service: productService.all,
            searchcode:true,
            col: 3
        },
        {
            type:"input",
            name: "lote",
            col: 3
        },
  ];

  const search = (filter) => {
    if(filter.ejercicio && filter.lote && filter.product){
        trazabilidadService.get(filter.ejercicio,filter.product,filter.lote, (data,error) => {
            if(data.data) {
              setEntries(data.data.entries);
              setExits(data.data.exit);
              setMovements(data.data.movements);
              setTotalEntries(data.data.totalEntries);
              setTotalExit(data.data.totalExit);
              //setData(currentValueform => ({...currentValueform, ...data.data}))
                //setData({exit:data.data.exit?data.data.exit:[],entries:data.data.entries?data.data.entries:[],movements:data.data.movements?data.data.movements:[],totalExit:0,totalEntries:0});
            } else {
              setEntries([]);
              setExits([]);
              setMovements([]);
              setTotalEntries(0);
              setTotalExit(0);
            }
        });
    } else if(filter.ejercicio && filter.lote){
      trazabilidadService.getWihoutProduct(filter.ejercicio,filter.lote, (data,error) => {
          if(data.data) {
            setEntries(data.data.entries);
            setExits(data.data.exit);
            setMovements(data.data.movements);
            setTotalEntries(data.data.totalEntries);
            setTotalExit(data.data.totalExit);
            //setData(currentValueform => ({...currentValueform, ...data.data}))
              //setData({exit:data.data.exit?data.data.exit:[],entries:data.data.entries?data.data.entries:[],movements:data.data.movements?data.data.movements:[],totalExit:0,totalEntries:0});
          } else {
            setEntries([]);
            setExits([]);
            setMovements([]);
            setTotalEntries(0);
            setTotalExit(0);
          }
      });
    }
  }


  useEffect(() => {
        setFilter({ejercicio:moment().year(), product:null,lote:null});
  }, []);


  const searchFilter = (filter) => {
    console.log(filter);
    setFilter(filter);
    search(filter);
  }


  const paintCell = {
    ammount: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },

  }


  return (
    <CrudEmpty i18n={"trazabilidad"}>
        <Grid container style={{background:"white", padding: "0.3em 1em 1em", borderRadius: "3px"}}>

            <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                <Filter t={t} i18n={"trazabilidad"} cleanFilter={searchFilter} searchFilter={searchFilter} filter={filter} titlebutton={t("trazabilidad.titlebutton")} estructure={createEstructureFilter}  hiddenButton={true} expanded={true}/>
            </Grid>
            <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '3em'}}>
                <PanelRounded>
                        <h3>Cantidad</h3>
                        <Grid container>
                                <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                                    <label>Elaborada/Comprada</label> <SpanAmmount>{totalEntries ? totalEntries.toFixed(2): 0.00} Kg</SpanAmmount>
                                </Grid>
                                <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                                    <label>Vendido</label> <SpanAmmount>{totalExit ? totalExit.toFixed(2): 0.00} Kg</SpanAmmount>
                                </Grid>
                        </Grid>
                </PanelRounded>
            </Grid>
            {entries && entries.length > 0 && <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '3em'}}>
                <PanelRounded>
                        <h3>Comprado</h3>
                        <Grid container>
                                <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', marginTop: '20px'}}>
                                        <CustomPaginationActionsTable t={t} i18n={"trazabilidad"} labels={{"supplierCif|supplierName":t('trazabilidad.field.entries.supplierName'),"productCode|productName":t('trazabilidad.field.entries.productCode')}} rows={entries} fields={["date","serieAndNumber","supplierCif|supplierName","productCode|productName","lote","ammount"]} fieldId={"productId"} hiddenpagination={true} deleteaction={false}  selectHandler={null} editable={false} defaultValues={{}} paintCell={paintCell} paintvalue={{}}  selectable={false} handlerSelected={null} />
                                </Grid>
                        </Grid>
                </PanelRounded>
            </Grid> }
            {movements && movements.length > 0 && <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '3em'}}>
                <PanelRounded>
                        <h3>Elaborado</h3>
                        <Grid container>
                                <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', marginTop: '20px'}}>
                                        <CustomPaginationActionsTable t={t} i18n={"trazabilidad"} labels={{"productCode|productName":t('trazabilidad.field.movements.productCode')}} rows={movements} fields={["date","productCode|productName","lote","ammount"]} fieldId={"productId"} hiddenpagination={true} deleteaction={false}  selectHandler={null} editable={false} defaultValues={{}} paintCell={paintCell} paintvalue={{}}  selectable={false} handlerSelected={null} />
                                </Grid>
                        </Grid>
                </PanelRounded>
            </Grid> }
            <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '3em'}}>
                <PanelRounded>
                        <h3>Vendido</h3>
                        <Grid container>
                                <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', marginTop: '20px'}}>
                                        <CustomPaginationActionsTable t={t} i18n={"trazabilidad"} labels={{"clientCif|clientName":t('trazabilidad.field.exit.clientName'),"productCode|productName":t('trazabilidad.field.exit.productCode')}} rows={exits} fields={["date","serieAndNumber","clientCif|clientName","productCode|productName","lote","ammount"]} fieldId={"productId"} hiddenpagination={true} deleteaction={false}  selectHandler={null} editable={false} defaultValues={{}} paintCell={paintCell} paintvalue={{}}  selectable={false} handlerSelected={null} />
                                </Grid>
                        </Grid>
                </PanelRounded>
            </Grid>
        </Grid>




    </CrudEmpty>
  );
}

const PanelRounded = styled.div(props => ({
    display: 'block',
    padding: '1em',
    position: 'relative',
    border: '1px solid #ccc',
    borderRadius: '3px',
    "& h3":{
        color: "black",
        position: "absolute",
        top: "-2em",
        left: "2em",
        background: "white",
        padding: "0.2em 1em",
        display: "inline-block",

    }

  }));

const SpanAmmount = styled.span(props => ({
    display: 'inline-block',
    fontWeight: 'bold',
    padding: "0.2em 1em"

  }));
