import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTab from "../../crud/CrudTab"
import {budgetService as service, clientService, routeService, paymenttypeService, statebudgetService, deliverynoteService} from "../../../services"
import PrintIcon from '@mui/icons-material/Print'
import 'toasted-notes/src/styles.css';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import{pad} from '../../utils/Data'; 
import { Box } from '@mui/system';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import PublishIcon from '@mui/icons-material/Publish';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function TabBudget(props) {


  const [filter,setFilter] = useState({
    number:null,
    serie : null,
    client:props.idclient,
    from:null,
    to:null,
    state:null,
    route:null,
    paymenttype:null
})

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [openBill, setOpenBill] = React.useState(false);
  const [idBill, setIdBill] = React.useState<any>(null);

  const createEstructureFilter =  [
    {
      type:"input",
      name: "serie",
      col: 3
     },
    {
      type:"input",
      name: "number",
      inputtype:"number",
      col: 3
     },
       {
        type:"input",
        name: "from",
        inputtype:"date",
        col: 3
       },
      {
        type:"input",
        name: "to",
        inputtype:"date",
        col: 3
      },
      {
        type:"autocomplete",
        name: "state",
        service: statebudgetService.combo,
        col: 3
        },
        {
          type:"autocomplete",
          name: "route",
          service: routeService.combo,
          col: 3
        },
        {
          type:"autocomplete",
          name: "paymenttype",
          service: paymenttypeService.combo,
          col: 3
          },
  ];

  const handlePrint = (bill) => {

    var isGenerate = bill && bill.number && bill.number != '' && bill.state && bill.state.id && bill.state.id != 1;
    if (isGenerate){
      let fileName = "Presupuesto"+(bill.serie?bill.serie:"")+pad(+bill.number,5)+".pdf";
      service.print(bill.id,fileName,  (data,erro) =>{
        toast.success("Se ha descargado el presupuesto", {  position: toast.POSITION.TOP_CENTER });  
      });
    } else {
      toast.error("Solo se puede imprimir presupuestos ya generadas", {  position: toast.POSITION.TOP_CENTER });
    }
    
  }

  const paintCell = {
    base: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    discount: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    iva: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    total: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    equivalencia: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    tax: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },

  }

  const handelBill = (bill) => {
    setIdBill(bill.id);
    setOpenBill(!openBill);    
  }
  

  const handleCloseBill = () => {
    setOpenBill(!openBill);
  };

  const generateBill = () =>{
    deliverynoteService.budget(idBill, (data,error) =>{

      if (data && data.data && data.data.id){
        history.push("/deliverynote/edit/"+data.data.id);
        window.location.reload();
      } else {
        setOpenBill(!openBill);
        toast.error(error.mes, { position: toast.POSITION.TOP_CENTER });
      }
    });
  }


  useEffect(() => {
    setFilter({
      number:null,
      serie : null,
      client: props.idclient,
      from:null,
      to:null,
      state:null,
      route:null,
      paymenttype:null
  });
  },[props.idclient])
  
  return (
      <><CrudTab
        service={service}
        urledit={"/budget/edit/"}
        urlnew={"/budget/new/"}
        filter={filter}
        i18n={"budget"}
        fieldstable={["serie","number","date","client.comercialName|client.email|client.phone","base","tax","equivalencia","iva","total"]}
        defaultValues={{serie:"",number:"Borrador"}}
        moreActions={[{"handle":handlePrint, icon:<PrintIcon />},{"handle":handelBill, icon:<PublishIcon />}]}
        paintCell={paintCell}
        labels={{"client.comercialName|client.email|client.phone":t('budget.field.client.name')}}
        estructureForm={createEstructureFilter} />
        <Modal
              open={openBill}
              onClose={handleCloseBill}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description">
              <Box sx={style}>
                <div style={{marginBottom:'30px'}}>
                    ¿Quieres hacer un albarán de este presupuesto?
                </div>
                <div>
                  <Button variant="outlined" color="primary" style={{marginRight:'30px'}} onClick={handleCloseBill}> No</Button>
                  <Button variant="contained" color="primary" onClick={generateBill}> Sí </Button>
                </div>
            </Box>
          </Modal></>

  );
}
