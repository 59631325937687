import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../../crud/CrudForm"
import {cashConfigureService as service } from "../../../../services"
import { useTranslation } from 'react-i18next';


  const initElement =  {
    name : null,
};


export default function FormCashConfigure() {
  const { t, i18n } = useTranslation();


  const estructureform =  [
      {
        type:"input",
        name: "ejercicio",
        col: 4
       },
       {
         type:"input",
         name: "ammount",
         inputtype:"number",
         step:0.1,
         col: 4
        },
   ];


  const recoveryElement = function(objectedit,element){


    objectedit['name']=element.name?element.name:"";

    return objectedit;
  }



  const isValid = function(object){
      if(!object['ejercicio']){
        return t("cashconfigure.error.ejercicio");
      }
      if(!object['ammount']){
        return t("cashconfigure.error.ammount");
      }

      return null;
  }



  return (
    <CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        i18n={"cashconfigure"}
        urledit={"/cashconfigure/edit/"}
        urlCancel={"/cashconfigure"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />

  );
}
