import React, { useState, useEffect }  from 'react';
import CrudEmpty from "../../../crud/CrudEmpty"
import { useParams,useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import {hollydayService as service, comercialService} from "../../../../services"
import { t } from "i18next";
import { Autocomplete, TextField,Grid } from '@mui/material';
import Moth from './Month';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';

const moment = require("moment");

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function Hollyday(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const [routes, setRoutes] = useState<any>([]);
  const [routeSelected, setRouteSelected] = useState<any>(null);
  const [comercials, setComercials] = useState<any>([]);
  const [yearSelected, setYearSelected] = useState<any>(moment().year());
  const [events, setEvents] = useState<any>([]);
  const [openModalNo, setOpenModalNo] = useState<any>(false); 
  const [eventSelected, setEventSelected] = useState<any>([]);
  const [comercialSelected, setComercialSelected] = useState<any>(null);


  const getLabelOption = function (option){
    if(option && option.name){
      if (typeof option.name === 'string'){
        return option.name
      } else {
        return ""
      }
    } else if (option && option.label && typeof option.label === 'string'){
      return option.label
    } else {
      return "";
    }
 } 

 const searhHollyday = () => {
    let filter = {from: yearSelected+"-01-01T00:00:00Z", to:yearSelected+"-12-31T23:59:59Z", commercial: comercialSelected && comercialSelected.key ? comercialSelected.key: null}
    service.pageFilter(0,200,filter,(data,error) => {
      if (data.data){

        if(data.data.length > 0){
            var eventsAux:any[] = [];
            data.data.forEach(element => {
                eventsAux.push({
                        id: element.id,
                        title: element.comercial.name,
                        start: moment(element.from).format("YYYY-MM-DD")+"T00:00:00",
                        end: moment(element.to).add(1, 'days').format("YYYY-MM-DD")+"T23:59:59",
                        backgroundColor:element.color,
                        allDay:true
                })
            });
            setEvents(eventsAux);
        } else {
          setEvents([]);
        }
    }
    });
 }

 const searchComercial = () => {
    comercialService.combo((data,error) => {
      if (data.data){
          setComercials(data.data);
        } else {
          setComercials([]);
        }
    });
}

 useEffect(() => {
    searhHollyday()
    searchComercial();
  }, []);

  useEffect(() => {
    searhHollyday()
  }, [yearSelected]);

  useEffect(() => {
    searhHollyday()
  }, [comercialSelected]);


  const eventClick = (info) => {
      setEventSelected(info.event);
  }

  const dateClick  = (info) => {

  }

  const handleCloseNo = () => {
    setOpenModalNo(false);
  };


  const handlerEditConfirm = function(){
    history.push("/hollyday/edit/"+eventSelected.id);
  }

  const handlerDelConfirm = function(){
    setOpenModalNo(false);
    service.removeLogic(eventSelected.id,(data,error) => {
      debugger;
      searhHollyday();
      setEventSelected(null);
    })
  }

  useEffect(() => {
    if(eventSelected && eventSelected.id){
      setOpenModalNo(true);
    }
  }, [eventSelected]);


  return (
    <CrudEmpty i18n={"hollyday"}>
        <Grid container style={{background:"white", padding: "0.3em 1em 1em", borderRadius: "3px"}}>
           <Grid item md={2} sm={3} xs={12}>  
              <Autocomplete
                   id={"year"}
                    options={[moment().year(), moment().year()-1,moment().year()-2,moment().year()-3,moment().year()-4,moment().year()-5,moment().year()-6,moment().year()-7,moment().year()-8]}
                    value={yearSelected}
                    onChange={(event,value) => setYearSelected(value)}
                    disableClearable={true }
                    renderInput={(params) => <TextField {...params} variant="standard" label={t("parteruta.field.year")}  inputProps={{ ...params.inputProps,  autoComplete: 'off' }} autoComplete="off"/>}
                  />
           </Grid>
           <Grid item md={2} sm={3} xs={12} style={{paddingLeft: "10px"}}>  
              <Autocomplete
                   id={"route"}
                    options={comercials}
                    getOptionLabel={getLabelOption}
                    value={comercialSelected}
                    onChange={(event,value) => setComercialSelected(value)}
                    disableClearable={true }
                    renderInput={(params) => <TextField {...params} variant="standard" label={"Comercial"}  inputProps={{ ...params.inputProps,  autoComplete: 'off' }} autoComplete="off"/>}
                  />
           </Grid> 
           <Grid item md={6} sm={3} xs={12}></Grid>
           <Grid item md={2} sm={3} xs={12} style={{display: "flex", alignItems:"center"}}>  
               <Button component={Link} to={"/hollyday/new"} variant="contained" color="secondary" style={{marginLeft:'30px'}}> Indicar nuevas vaciones </Button>
           </Grid>
        </Grid>
        <Grid container style={{background:"white", padding: "0.3em 1em 1em", borderRadius: "3px", marginTop:"2em"}}>
            <Moth month={1} t={t}  year={yearSelected} eventClick={eventClick} dateClick={dateClick} events={events}/>
            <Moth month={2} t={t}  year={yearSelected} eventClick={eventClick} dateClick={dateClick} events={events} />
            <Moth month={3} t={t}  year={yearSelected} eventClick={eventClick} dateClick={dateClick} events={events} />
            <Moth month={4} t={t}  year={yearSelected} eventClick={eventClick} dateClick={dateClick} events={events} />
            <Moth month={5} t={t}  year={yearSelected} eventClick={eventClick} dateClick={dateClick} events={events}/>
            <Moth month={6} t={t}  year={yearSelected} eventClick={eventClick} dateClick={dateClick} events={events}/>
            <Moth month={7} t={t}  year={yearSelected} eventClick={eventClick} dateClick={dateClick} events={events}/>
            <Moth month={8} t={t}  year={yearSelected} eventClick={eventClick} dateClick={dateClick} events={events}/>
            <Moth month={9} t={t}  year={yearSelected} eventClick={eventClick} dateClick={dateClick} events={events}/>
            <Moth month={10} t={t}  year={yearSelected} eventClick={eventClick} dateClick={dateClick} events={events}/>
            <Moth month={11} t={t}  year={yearSelected} eventClick={eventClick} dateClick={dateClick} events={events}/>
            <Moth month={12} t={t}  year={yearSelected} eventClick={eventClick} dateClick={dateClick} events={events}/>
        </Grid>

        <Modal
          open={openModalNo}
          onClose={handleCloseNo}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
            <Box sx={style}>
              <div style={{marginBottom:'30px'}}>
                  ¿Que desea hacer con las vacaciones de {eventSelected && eventSelected.title ? eventSelected.title: ''}?
              </div>
              <div>
                <Button variant="outlined" color="primary" style={{marginRight:'30px'}} onClick={handleCloseNo}> Cancelar</Button>
                <Button variant="contained" color="warning" style={{marginRight:'30px'}}  onClick={handlerDelConfirm}> Borrar </Button>
                <Button variant="contained" color="primary" onClick={handlerEditConfirm}> Editar </Button>
              </div>
          </Box>
        </Modal>
    </CrudEmpty>
  );


}
