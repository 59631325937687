import React, {useEffect,useState} from 'react';
import Layout from '../../../layout/Layout';
import { Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';
import { cashService } from '../../../../services';
import CrudEmpty from "../../../crud/CrudEmpty"
import Filter from "../../../filter/Filter";
import styled from "styled-components";
import { Autocomplete, TextField } from '@mui/material';
import 'moment/locale/es';
const moment = require("moment");



export default function Diary() {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [open, setOpen] = React.useState(true);
  const [data, setData] = React.useState<any>({});
  const [filter, setFilter] = React.useState({ejercicio: moment().year()});


  const createEstructureFilter =  [
        {
          type:"autocomplete",
          name: "ejercicio",
          options:[{key:moment().year(), name:moment().year()+""}, {key:moment().year()-1, name:moment().year()-1+""},{key:moment().year()-2, name:moment().year()-2+""},{key:moment().year()-3, name:moment().year()-3+""},{key:moment().year()-4, name:moment().year()-4+""},{key:moment().year()-5, name:moment().year()-5+""},{key:moment().year()-6, name:moment().year()-6+""},{key:moment().year()-7, name:moment().year()-7+""},{key:moment().year()-8, name:moment().year()-8+""}],
          col: 3
        },
  ];

  const search = (filter) => {
      cashService.diary(filter.ejercicio, (data,error) => {
          if(data &&data.data) {
              setData(data.data);
          }
      });
  }


  useEffect(() => {
        setFilter({ejercicio:moment().year()});
        search({ejercicio:moment().year()});
  }, []);


  const searchFilter = (filter) => {
    console.log(filter);
    setFilter(filter);
    search(filter);
  }


  const pritnprice = () => {
        cashService.diaryexport(filter.ejercicio,"DIARIO DE CAJA AÑO "+filter.ejercicio+".xls", (data,error) => {
    
        });
      }


  return (
    <CrudEmpty i18n={"diary"}>
        <Grid container style={{background:"white", padding: "2em 1em 1em", borderRadius: "3px"}}>

            <Grid item sm={5} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
              <PanelRounded>
                        <h3>Ejercicio</h3>
                        <Grid container>
                                <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                                <Autocomplete
                                    id={"year"}
                                      options={[moment().year(), moment().year()-1,moment().year()-2,moment().year()-3,moment().year()-4,moment().year()-5,moment().year()-6,moment().year()-7,moment().year()-8]}
                                      value={filter.ejercicio}
                                      onChange={(event,value) => {filter.ejercicio = value; searchFilter(filter)}}
                                      disableClearable={true }
                                      renderInput={(params) => <TextField {...params} variant="standard" label={t("parteruta.field.year")}  inputProps={{ ...params.inputProps,  autoComplete: 'off' }} autoComplete="off"/>}
                                    />
                                </Grid>
                        </Grid>
                </PanelRounded>
            </Grid>
            <Grid item sm={2} xs={2} style={{textAlign:'left', marginBottom: '3em'}}></Grid>
            <Grid item sm={5} xs={12} style={{textAlign:'left', marginBottom: '3em'}}>
                <PanelRounded>
                        <h3>Dinero Inicial</h3>
                        <Grid container>
                                <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                                   <SpanAmmount>{data.amountInit && data.amountInit.toFixed? data.amountInit.toFixed(2): 0.00} €</SpanAmmount>
                                </Grid>
                        </Grid>
                </PanelRounded>
            </Grid>
            <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                  <Button variant="contained" color="primary" style={{marginLeft:'30px'}} onClick={pritnprice}> Exportar a excel </Button>
            </Grid>
            <Grid item sm={12} xs={12} style={{textAlign:'center', background:"#64ac5b", color: "black", fontWeight: "bold", padding: "1em 0em"}}>
                  <Grid container>
                          <Grid item sm={2} xs={2}>
                                   Fecha
                          </Grid>
                          <Grid item sm={10} xs={10}>
                            <Grid container>
                                <Grid item sm={3} xs={3}>
                                        Concepto
                                </Grid>
                                <Grid item sm={3} xs={3}>
                                        Entrada
                                </Grid>
                                <Grid item sm={3} xs={3}>
                                        Salida
                                </Grid>
                                <Grid item sm={3} xs={3}>
                                        Saldo
                                </Grid>
                            </Grid>
                          </Grid>
                          
                  </Grid>
            </Grid>
            {data && data.cashmap && Object.keys(data.cashmap).sort((n1,n2) => n1 < n2 ? -1 : (n1 > n2? 1:0)).map((values,i) => (
              <Grid item sm={12} xs={12}>
                  <Grid container style={{background: moment(values).day() == 0 ? "lightcoral":"white"}}>
                          <Grid item sm={2} xs={2} style={{borderTop: "1px solid #ccc",borderLeft: "1px solid #ccc",borderRight: "1px solid #ccc",borderBottom: (i == 364?"1px ":"0px ") +"solid #ccc", padding: "0.5em", display: "flex", alignItems: "center"}}>
                                   {moment(values).lang("es").format('dddd').substring(0,3)}., {moment(values).lang("es").format('DD/MM')}  
                          </Grid>
                          <Grid item sm={10} xs={10} style={{borderTop: "1px solid #ccc",borderRight: "1px solid #ccc",borderBottom: (i == 364?"1px ":"0px ") +"solid #ccc"}}>
                                   {data.cashmap[values] && data.cashmap[values].length > 0 && data.cashmap[values].map((v,i) => (
                                      <Grid container style={{borderTop: (i > 0?"1px ":"0px ") +"solid #ccc"}}>
                                        <Grid item sm={3} xs={3} style={{padding: "0.5em"}}>
                                                {v.concept}
                                        </Grid>
                                        <Grid item sm={3} xs={3} style={{borderLeft: "1px solid #ccc", textAlign: "right",padding: "0.5em"}}>
                                                {!v.exit ? v.ammount.toFixed(2) : ''}
                                        </Grid>
                                        <Grid item sm={3} xs={3} style={{borderLeft: "1px solid #ccc", textAlign: "right",padding: "0.5em"}}>
                                                {v.exit ? v.ammount.toFixed(2) : ''}
                                        </Grid>
                                        <Grid item sm={3} xs={3} style={{borderLeft: "1px solid #ccc", textAlign: "right",padding: "0.5em"}}>
                                                {v.totalInCash ? v.totalInCash.toFixed(2) : ''}
                                        </Grid>
                                      </Grid>
                                   ))}

                                  {data.cashmap[values].length == 0 &&
                                      <Grid container style={{minHeight: "100%"}}>
                                        <Grid item sm={3} xs={3} style={{padding: "0.5em"}}>
                                        </Grid>
                                        <Grid item sm={3} xs={3} style={{borderLeft: "1px solid #ccc", textAlign: "right",padding: "0.5em"}}>
                                                
                                        </Grid>
                                        <Grid item sm={3} xs={3} style={{borderLeft: "1px solid #ccc", textAlign: "right",padding: "0.5em"}}>
                                                
                                        </Grid>
                                        <Grid item sm={3} xs={3} style={{borderLeft: "1px solid #ccc", textAlign: "right",padding: "0.5em"}}>
                                                
                                        </Grid>
                                      </Grid>
                                   }
                          </Grid>
                          
                  </Grid>
            </Grid>
            ))} 

        </Grid>







    </CrudEmpty>
  );
}

const PanelRounded = styled.div(props => ({
    display: 'block',
    padding: '1em',
    position: 'relative',
    border: '1px solid #ccc',
    borderRadius: '3px',
    "& h3":{
        color: "black",
        position: "absolute",
        top: "-2em",
        left: "2em",
        background: "white",
        padding: "0.2em 1em",
        display: "inline-block",

    }

  }));

const SpanAmmount = styled.span(props => ({
    display: 'inline-block',
    fontWeight: 'bold',
    padding: "0.2em 1em"

  }));
