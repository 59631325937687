import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTab from "../../../crud/CrudTab"
import {deliverynotelotesupplierService as service} from "../../../../services"
import PrintIcon from '@mui/icons-material/Print'
import 'toasted-notes/src/styles.css';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import{pad} from '../../../utils/Data'; 
import { Box } from '@mui/system';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import PublishIcon from '@mui/icons-material/Publish';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};




export default function TabLotebuy(props) {

  const [filter,setFilter] = useState({
    name:null,
    id:props.idproduct
  });

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [openBill, setOpenBill] = React.useState(false);
  const [idBill, setIdBill] = React.useState<any>(null);
  var deliverynotesSelected = [];



  const createEstructureFilter =  [    
    {
      type:"input",
      name: "name",
      col: 4
     },
 ];


 const goToDeliverynote = (lote) => {
   history.push("/deliverynotesupplier?product="+props.idproduct+"&lote="+lote.lote);  
}

useEffect(() => {
  setFilter({
    name:null,
    id:props.idproduct
  });
},[props.idproduct])

  return (
      <><CrudTab
        filter={filter}
        service={service}
        editable={false}
        estructureForm={createEstructureFilter}
        deleteaction={true}
        i18n={"loteproduct"}
        fieldstable={["lote","minDate","maxDate","numDelivery"]}
        moreActions={[{"handle":goToDeliverynote, icon:<RemoveRedEyeIcon />}]}
       /></>

  );

}
