import React, {useEffect,useState} from 'react';
import Layout from '../../../layout/Layout';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { billsupplierService, paymenttypesupplierService } from '../../../../services';
import CrudEmpty from "../../../crud/CrudEmpty"
import Filter from "../../../filter/Filter";
import styled from "styled-components";
import { red } from '@mui/material/colors';
import { InfoOutlined } from '@mui/icons-material';
import CustomPaginationActionsTable from "../../../table/Table"
import { Autocomplete, TextField, Button } from '@mui/material';
import Form from '../../../form/Form';

const moment = require("moment");



export default function DiaryBillSupplier() {
  const { t, i18n } = useTranslation();
  const [{data,filter}, setState] = React.useState({
    data: [],
    filter: {year: moment().year(),month:null,paymenttype:null }
  });




  const createEstructureFilter =  [
    {
      type:"autocomplete",
      name: "year",
      options:[{key:moment().year(),name:moment().year()+""},{key:moment().year()-1,name:(moment().year()-1)+""},{key:moment().year()-2,name:(moment().year()-2)+""},{key:moment().year()-3,name:(moment().year()-3)+""},{key:moment().year()-4,name:(moment().year()-4)+""},{key:moment().year()-5,name:(moment().year()-5)+""},{key:moment().year()-6,name:(moment().year()-6)+""},{key:moment().year()-7,name:(moment().year()-7)+""},{key:moment().year()-8,name:(moment().year()-8)+""}],
      col: 3
     },
     {
      type:"autocomplete",
      name: "month",
      options:[{key:1,name:"Enero"},{key:2,name:"Febrero"},{key:3,name:"Marzo"},{key:4,name:"Abril"},{key:5,name:"Mayo"},{key:6,name:"Junio"},{key:7,name:"Julio"},{key:8,name:"Agosto"},{key:9,name:"Septiembre"},{key:10,name:"Octubre"},{key:11,name:"Noviembre"},{key:12,name:"Diciembre"}],
      col: 3
     },
     {
      type:"autocomplete",
      name: "paymenttype",
      service: paymenttypesupplierService.combo,
      col: 3
     },
   
  ];



  useEffect(() => {
     billsupplierService.reportpost(filter, (data, error) => {
        if(data && data.data) {
          setState(currentState => ({...currentState, ["data"]:data.data}));
        } else {
          setState(currentState => ({...currentState, ["data"]:[]}));
        }
     });
  }, [filter]);



  const paintCell = {
    ammount: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },

  }

  const pritnprice = () => {
    billsupplierService.reportExcel(filter.year,"Facturas proovedores "+filter.year+".xls", (data,error) => {

    });
  }

  const pritnpricepost = () => {
    billsupplierService.reportExcelpost(filter,"Facturas proovedores "+filter.year+".xls", (data,error) => {

    });
  }

  const cleanFilter = (filter) => {
    filter["year"] = moment().year();
    setState(currentState => ({...currentState, ["filter"]:{...filter}}));

  }

  const searchFilter = (filter) => {
    setState(currentState => ({...currentState, ["filter"]:{...filter}}));
  }



  return (
    <CrudEmpty i18n={"diarybill"}>
        <Grid container style={{background:"white", padding: "0.3em 1em 1em", borderRadius: "3px"}}>
            <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '3em', paddingTop: "3em"}}>
                <PanelRounded>
                        <h3>Ejercicio</h3>
                        <Grid container>
                                <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
                                   <Form t={t} i18n={"diarybill.filter"} objectform={filter} fields={createEstructureFilter}  searchFilter={searchFilter} cleanFilter={cleanFilter} labelsave={"Buscar"} labelcancel={"Limpiar"} cleanInOK={false} isHiddenSave={false} isHiddenCancel={false} notCleanOnCancel={false}/>
                                </Grid>
                        </Grid>
                </PanelRounded>
            </Grid>
            <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
                  <Button variant="contained" color="primary" style={{marginLeft:'30px'}} onClick={pritnprice}> Exportar a excel completo</Button>
                  <Button variant="contained" color="primary" style={{marginLeft:'30px'}} onClick={pritnpricepost}> Exportar a excel mostrado</Button>
            </Grid>
            <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '3em'}}>
                <PanelRounded>
                        <h3>Facturas proovedores</h3>
                        <Grid container>
                                <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', marginTop: '20px'}}>
                                        <CustomPaginationActionsTable t={t} i18n={"billsupplier"} labels={{"supplier.comercialName|supplier.email|supplier.phone":t('billsupplier.field.supplier.name')}} rows={data} fields={["supplier.comercialName|supplier.email|supplier.phone","date","serieAndNumber","total","dateFinish","paymentType.name"]} fieldId={"id"} hiddenpagination={true} deleteaction={false}  selectHandler={null} editable={false} defaultValues={{}} paintCell={paintCell} paintvalue={{}}  selectable={false} handlerSelected={null} />
                                </Grid>
                        </Grid>
                </PanelRounded>
            </Grid>
        </Grid>




    </CrudEmpty>
  );
}

const PanelRounded = styled.div(props => ({
    display: 'block',
    padding: '1em',
    position: 'relative',
    border: '1px solid #ccc',
    borderRadius: '3px',
    "& h3":{
        color: "black",
        position: "absolute",
        top: "-2em",
        left: "2em",
        background: "white",
        padding: "0.2em 1em",
        display: "inline-block",

    }

  }));

const SpanAmmount = styled.span(props => ({
    display: 'inline-block',
    fontWeight: 'bold',
    padding: "0.2em 1em"

  }));
