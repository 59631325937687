import React, { useEffect, useState, useRef } from 'react';
import Layout from '../../../layout/Layout';
import { Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';
import { billService, clientService, paymenttypeService, statebillService, routeService } from '../../../../services';
import CrudEmpty from '../../../crud/CrudEmpty';
import Filter from '../../../filter/Filter';
import styled from 'styled-components';
import { red } from '@mui/material/colors';
import { InfoOutlined } from '@mui/icons-material';
import CustomPaginationActionsTable from '../../../table/Table';
import CheckIcon from '@mui/icons-material/Check';
import RuleIcon from '@mui/icons-material/Rule';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Form from '../../../form/Form';
import DeleteIcon from '@mui/icons-material/Delete';
const moment = require('moment');

const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 1000,
      bgcolor: 'white',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
};

const initFilter = {
      number: null,
      serie: null,
      client: null,
      from: null,
      to: null,
      state: null,
      route: null,
      paymenttype: null,
      notpaymenttype: 1,
      pay: 1,
};

const initBill = {
      serieAndNumber: null,
      date: null,
      client: {
            id: null,
            comercialName: null,
            email: null,
            phone: null,
      },
      total: null,
      payone: null,
      payoneDate: null,
      paytwo: null,
      paytwoDate: null,
      paythree: null,
      paythreeDate: null,
      payTotal: null,
      payLess: null,
};

export default function ControlPayment() {
      const { t, i18n } = useTranslation();
      const [anchorEl, setAnchorEl] = React.useState(null);

      const [open, setOpen] = React.useState(true);
      const [data, setData] = React.useState([]);
      const [filter, setFilter] = React.useState(initFilter);
      const [billselected, setBillselected] = React.useState<any>(null);
      const [bill, setBill] = React.useState<any>(initBill);
      const [openRect, setOpenRect] = React.useState(false);
      const panelEdit = useRef<any>(null);
      const [columOrder, setColumnOrder] = useState<any>(null);
      const [columOrderDirection, setColumnOrderDirection] = useState<any>(null);
      const [totalPending, setTotalPending] = useState<any>(0);
      const [paytotal, setPaytotal] = useState<any>(0);
      const [billPar, setBillPar] = React.useState<any>(null);
      const [paydate, setPayDate] = useState<any>(moment().format('YYYY-MM-DD'));
      const [openParcial, setOpenParcial] = React.useState(false);
      const [ids, setIds] = React.useState([]);
      const [payall, setPayAll] = React.useState(false);

      const createEstructureFilter = [
            {
                  type: 'autocomplete',
                  name: 'serie',
                  options: [
                        { key: '', name: 'TODAS' },
                        { key: 'S1', name: 'S1' },
                        { key: 'S2', name: 'S2' },
                        { key: 'R', name: 'R' },
                  ],
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'number',
                  inputtype: 'number',
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'cif',
                  col: 3,
            },
            {
                  type: 'autocomplete',
                  name: 'client',
                  service: clientService.combo,
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'from',
                  inputtype: 'date',
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'to',
                  inputtype: 'date',
                  col: 3,
            },
            {
                  type: 'autocomplete',
                  name: 'state',
                  service: statebillService.combo,
                  col: 3,
            },
            {
                  type: 'autocomplete',
                  name: 'route',
                  service: routeService.combo,
                  col: 3,
            },
            {
                  type: 'autocomplete',
                  name: 'paymenttype',
                  service: paymenttypeService.combo,
                  col: 3,
            },
      ];

      const search = (filter) => {
            billService.allFilter(filter, (data, error) => {
                  setData([]);
                  if (data.data) {
                        setData(data.data);
                        var total = 0;
                        data.data.forEach((element) => {
                              total = total + element['payLess'];
                        });

                        setTotalPending(total);
                  }
            });
      };

      useEffect(() => {
            search(filter);
      }, []);

      const searchFilter = (filter) => {
            console.log(filter);
            setFilter(filter);
            search(filter);
      };

      const paintCell = {
            total: (value, object) => {
                  if (value && value.toFixed) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
            payTotal: (value, object) => {
                  if (value && value.toFixed) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
            payLess: (value, object) => {
                  if (value && value.toFixed) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
      };

      const handleComplete = (bill) => {
            setBillselected(bill);
            setOpenRect(true);
      };

      const handlePartial = (bill) => {
            billService.one(bill.id, (data, error) => {
                  if (data && data.data) {
                        setPaytotal(data.data?.payLess);
                        setBillPar(data.data);
                  }
            });
      };

      const handleCloseRect = () => {
            setOpenRect(!openRect);
      };

      const completePay = (evt) => {
            setOpenRect(!openRect);
            billService.paycomplete(billselected.id, billselected.paymentType.id, (data, error) => {
                  if (data.data) {
                        toast.success('Se ha completado el pago', { position: toast.POSITION.TOP_CENTER });
                        search(filter);
                  } else {
                        toast.error('No se ha podido procesar el pago', { position: toast.POSITION.TOP_CENTER });
                  }
            });
      };

      const labels = { 'client.comercialName|client.email|client.phone': t('bill.field.client.name') };
      const fieldsOrder = { serieAndNumber: 'serieAndNumber', date: 'date', 'client.comercialName|client.email|client.phone': 'client.comercialName', total: 'total', paytotal: 'paytotal', payLess: 'payLess' };
      const fields = ['serieAndNumber', 'date', 'client.comercialName|client.email|client.phone', 'total', 'payTotal', 'payLess', 'client.dayOfPay'];

      const moreActions = [
            { handle: handleComplete, icon: <CheckIcon /> },
            { handle: handlePartial, icon: <RuleIcon /> },
      ];

      const conditionColor = (row) => {
            if (row['number']) {
                  if (!row['paymentType']['id'] || row['paymentType']['id'] == 1) {
                        //CONTADO
                        return 'purple';
                  } else if (!row['paymentType']['id'] || row['paymentType']['id'] == 3) {
                        //CONTADO MENSUAL
                        return 'orange';
                  } else if (!row['paymentType']['id'] || row['paymentType']['id'] == 7) {
                        //TRANSFERENCIA BANCARIA
                        return 'blue';
                  } else if (!row['paymentType']['id'] || row['paymentType']['id'] == 5) {
                        //GIRO REMESA BANCARIA MENSUAL
                        return 'green';
                  } else if (!row['paymentType']['id'] || row['paymentType']['id'] == 8) {
                        //TPV
                        return 'lightseagreen';
                  } else if (!row['paymentType']['id'] || row['paymentType']['id'] == 1001) {
                        //GIRO REMESA BANCARIA SEMANAL
                        return 'fuchsia';
                  } else {
                        return 'purple';
                  }
            } else {
                  return 'black';
            }
      };

      const getValueField = (object, field) => {
            if (object) {
                  if (field.includes('.')) {
                        const parts = field.split('.');
                        var objfield = object[parts[0]];
                        if (Array.isArray(objfield)) {
                              var result = '';
                              for (let entry of objfield) {
                                    var linr = getValueField(entry, field.substring(parts[0].length + 1));
                                    result = result + linr;
                              }
                              return result;
                        } else {
                              var r = getValueField(objfield, field.substring(parts[0].length + 1));
                              return r;
                        }
                  } else {
                        return object[field];
                  }
            } else {
                  return '';
            }
      };

      const sortRows = (column, order) => {
            var aux = data.sort((n1, n2) => {
                  var field1 = getValueField(n1, column);
                  var field2 = getValueField(n2, column);
                  if (field1 > field2) {
                        return order ? 1 : -1;
                  }

                  if (field1 < field2) {
                        return order ? -1 : 1;
                  }

                  return 0;
            });

            setData(aux);
      };

      const clickHeader = (column) => {
            if (column && column == columOrder) {
                  if (columOrderDirection) {
                        setColumnOrderDirection(null);
                        setColumnOrder(null);
                  } else {
                        setColumnOrderDirection(true);
                        sortRows(column, true);
                  }
            } else {
                  setColumnOrder(column);
                  setColumnOrderDirection(false);
                  sortRows(column, false);
            }
      };

      const exportToExcel = (evt) => {
            billService.allFilterPrint(filter, (data, error) => {});
      };
      const handleCloseParcial = () => {
            setOpenParcial(!openParcial);
      };

      const handlePayParcial = () => {
            payparcial(1);
      };

      const handlePayParcialTpv = () => {
            payparcial(8);
      };

      const handlePayParcialTransferencia = () => {
            payparcial(7);
      };

      const handlePayParcialGiro = () => {
            payparcial(5);
      };

      const payparcial = (type) => {
            var payment = {
                  ammount: paytotal,
                  date: paydate + 'T00:00:00Z',
                  paymentType: {
                        id: type,
                  },
            };

            billService.payparcial(billPar.id, payment, (data, error) => {
                  if (data && data.data) {
                        search(filter);
                        setBillPar(null);
                        setPayDate(moment().format('YYYY-MM-DD'));
                  }
            });
            setOpenParcial(!openParcial);
      };

      const deleteIndividualPay = (id) => {
            setOpenParcial(!openParcial);
            billService.deleteIndividualPay(id, (data, error) => {
                  if (data && data.data) {
                        search(filter);
                        setBillPar(null);
                        setPayDate(moment().format('YYYY-MM-DD'));
                  }
            });
      };

      const handlerSelected = (ids) => {
            setIds(ids);
      };

      const sendPayAll = (ids, filter) => {
            billService.payAll(ids, (data, error) => {
                  if (data && data.data) {
                        search(filter);
                  }
            });
      };

      useEffect(() => {
            if (billPar && billPar.id && !openParcial) {
                  setOpenParcial(true);
            }
      }, [billPar]);

      useEffect(() => {
            if (payall && ids.length > 0) {
                  setPayAll(false);
                  sendPayAll(ids, filter);
            }
      }, [payall, ids, filter]);

      return (
            <CrudEmpty i18n={'controlpaymentbill'}>
                  <Grid container style={{ background: 'white', padding: '0.3em 1em 1em', borderRadius: '3px' }}>
                        <Grid item sm={12} xs={12} style={{ textAlign: 'left', marginBottom: '10px' }} ref={panelEdit}>
                              <Filter
                                    t={t}
                                    i18n={'bill'}
                                    cleanFilter={searchFilter}
                                    searchFilter={searchFilter}
                                    filter={filter}
                                    titlebutton={t('trazabilidad.titlebutton')}
                                    estructure={createEstructureFilter}
                                    hiddenButton={true}
                                    expanded={true}
                              />
                        </Grid>

                        {totalPending && (
                              <Grid item sm={6} xs={12} style={{ textAlign: 'left', marginBottom: '3em', display: 'flex', flexDirection: 'row', color: 'red' }}>
                                    <b style={{ paddingRight: '20px' }}>TOAL PENDIENTE: </b>
                                    <span> {totalPending.toFixed(2)} €</span>
                              </Grid>
                        )}

                        {ids && ids.length > 0 && (
                              <Grid item sm={3} xs={12} style={{ textAlign: 'right', marginBottom: '3em', display: 'flex', flexDirection: 'column', color: 'red', alignItems: 'end' }}>
                                    <Button
                                          variant="contained"
                                          color="primary"
                                          onClick={() => {
                                                setPayAll(true);
                                          }}
                                          style={{ display: 'inline-block', width: '200px' }}
                                    >
                                          {' '}
                                          Marcar pagadas{' '}
                                    </Button>
                                    <p>Se marcaran como pagadas las seleccionadas</p>
                              </Grid>
                        )}

                        {totalPending && totalPending > 0 && (
                              <Grid item sm={ids && ids.length > 0 ? 3 : 6} xs={12} style={{ textAlign: 'right', marginBottom: '3em', display: 'flex', flexDirection: 'column', color: 'red', alignItems: 'end' }}>
                                    <Button variant="contained" color="primary" onClick={exportToExcel} style={{ display: 'inline-block', width: '200px' }}>
                                          {' '}
                                          Exportar a excel{' '}
                                    </Button>
                                    <p>Se exportaran segun el filtro indicado</p>
                              </Grid>
                        )}

                        <Grid item sm={12} xs={12} style={{ textAlign: 'left', display: 'flex', flexDirection: 'row' }}>
                              <span style={{ display: 'block', background: 'purple', width: '20px', height: '20px', margin: '0 1em' }}></span>
                              <b>CONTADO</b>
                              <span style={{ display: 'block', background: 'orange', width: '20px', height: '20px', margin: '0 1em' }}></span>
                              <b>CONTADO MEN.</b>
                              <span style={{ display: 'block', background: 'blue', width: '20px', height: '20px', margin: '0 1em' }}></span>
                              <b>TRANSFERENCIA</b>
                              <span style={{ display: 'block', background: 'green', width: '20px', height: '20px', margin: '0 1em' }}></span>
                              <b>GIRO MENSUAL</b>
                              <span style={{ display: 'block', background: 'lightseagreen', width: '20px', height: '20px', margin: '0 1em' }}></span>
                              <b>TPV</b>
                              <span style={{ display: 'block', background: 'fuchsia', width: '20px', height: '20px', margin: '0 1em' }}></span>
                              <b>GIRO SEMANAL</b>
                        </Grid>

                        <Grid item sm={12} xs={12} style={{ textAlign: 'left', marginBottom: '3em' }}>
                              <PanelRounded>
                                    <h3>Facturas pendientes</h3>
                                    <Grid container>
                                          <Grid item sm={12} xs={12} style={{ textAlign: 'left', marginBottom: '10px', marginTop: '20px' }}>
                                                <CustomPaginationActionsTable
                                                      t={t}
                                                      i18n={'bill'}
                                                      labels={labels}
                                                      rows={data}
                                                      fields={fields}
                                                      fieldId={'id'}
                                                      hiddenpagination={true}
                                                      deleteaction={false}
                                                      selectHandler={null}
                                                      editable={false}
                                                      defaultValues={{}}
                                                      paintCell={paintCell}
                                                      paintvalue={{}}
                                                      selectable={true}
                                                      handlerSelected={handlerSelected}
                                                      moreActions={moreActions}
                                                      conditionColor={conditionColor}
                                                      order={true}
                                                      clickHeader={clickHeader}
                                                      columOrder={columOrder}
                                                      columOrderDirection={columOrderDirection}
                                                      orderColumns={fieldsOrder}
                                                />
                                          </Grid>
                                    </Grid>
                              </PanelRounded>
                        </Grid>
                  </Grid>
                  <Modal open={openRect} onClose={handleCloseRect} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                        <Box sx={style}>
                              <div style={{ marginBottom: '30px' }}>¿Quieres marca esta factura como pagada?</div>
                              <div>
                                    <Button variant="outlined" color="primary" style={{ marginRight: '30px' }} onClick={handleCloseRect}>
                                          {' '}
                                          No
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={completePay}>
                                          {' '}
                                          Sí{' '}
                                    </Button>
                              </div>
                        </Box>
                  </Modal>
                  <Modal open={openParcial} onClose={handleCloseParcial} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                        <Box sx={style}>
                              <Grid container>
                                    <Grid sm={12} style={{ borderBottom: '1px solid black', marginBottom: '1em' }}>
                                          Albarán
                                    </Grid>
                                    <Grid sm={3} style={{ fontSize: '0.8em', color: '#777' }}>
                                          {billPar?.serie + ' - ' + billPar?.number}
                                    </Grid>
                                    <Grid sm={3} style={{ fontSize: '0.8em', color: '#777' }}>
                                          {billPar?.date ? moment(Date.parse(billPar?.date)).format('DD/MM/YYYY') : ''}
                                    </Grid>
                                    <Grid sm={3} style={{ fontSize: '0.8em', color: '#777' }}>
                                          {billPar?.nameClient}
                                    </Grid>
                              </Grid>
                              <Grid container style={{ marginBottom: '30px' }}>
                                    <Grid sm={6} style={{ padding: '10px' }}>
                                          <TextField
                                                variant="standard"
                                                sx={{ width: '100%' }}
                                                id={'paydate'}
                                                name={'paydate'}
                                                label={'Fecha de pago'}
                                                value={paydate}
                                                onChange={(evt) => setPayDate(evt.target.value)}
                                                type={'date'}
                                                InputLabelProps={{ shrink: true }}
                                                autoComplete="off"
                                          />
                                    </Grid>
                                    <Grid sm={6} style={{ padding: '10px' }}>
                                          <TextField
                                                variant="standard"
                                                sx={{ width: '100%' }}
                                                id={'paytotal'}
                                                name={'paytotal'}
                                                label={'Importe pagado'}
                                                value={paytotal}
                                                onChange={(evt) => setPaytotal(evt.target.value)}
                                                type={'number'}
                                                inputProps={{ step: '0.01', autoComplete: 'off' }}
                                                InputLabelProps={{ shrink: true }}
                                                autoComplete="off"
                                          />
                                    </Grid>
                              </Grid>
                              <div>
                                    <Button variant="outlined" color="primary" style={{ marginRight: '30px' }} onClick={handleCloseParcial}>
                                          {' '}
                                          Cancelar
                                    </Button>
                                    <Button variant="contained" color="primary" style={{ marginRight: '30px' }} onClick={handlePayParcial}>
                                          {' '}
                                          Pago Contado{' '}
                                    </Button>
                                    <Button variant="contained" color="primary" style={{ marginRight: '30px' }} onClick={handlePayParcialTpv}>
                                          {' '}
                                          Pago TPV{' '}
                                    </Button>
                                    <Button variant="contained" color="primary" style={{ marginRight: '30px' }} onClick={handlePayParcialTransferencia}>
                                          {' '}
                                          Pago Transferencia{' '}
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={handlePayParcialGiro}>
                                          {' '}
                                          Pago Giro bancario{' '}
                                    </Button>
                              </div>

                              {billPar?.payments && (
                                    <div>
                                          <Grid container>
                                                <Grid sm={12} style={{ fontWeight: 'bold', padding: '1em 0' }}>
                                                      Otros Cobros
                                                </Grid>
                                          </Grid>
                                          <Grid container style={{ borderBottom: '1px solid black', marginBottom: '1em' }}>
                                                <Grid sm={3}>Dia</Grid>
                                                <Grid sm={3}>Cantidad</Grid>
                                                <Grid sm={6}>Forma de pago</Grid>
                                          </Grid>
                                          {billPar?.payments.map((pay) => (
                                                <Grid container style={{ fontSize: '0.8em', color: '#777', marginBottom: '0.5em' }}>
                                                      <Grid sm={3}>{pay?.date ? moment(Date.parse(pay.date)).format('DD/MM/YYYY') : ''}</Grid>
                                                      <Grid sm={3}>{pay?.ammount}</Grid>
                                                      <Grid sm={5}>{pay?.paymentType?.name}</Grid>
                                                      <Grid>
                                                            <Button variant="contained" color="primary" style={{ backgroundColor: 'red', color: 'white' }} onClick={() => deleteIndividualPay(pay.id)}>
                                                                  {' '}
                                                                  <DeleteIcon />{' '}
                                                            </Button>
                                                      </Grid>
                                                </Grid>
                                          ))}
                                    </div>
                              )}
                        </Box>
                  </Modal>
                  <ToastContainer />
            </CrudEmpty>
      );
}

const PanelRounded = styled.div((props) => ({
      display: 'block',
      padding: '1em',
      position: 'relative',
      border: '1px solid #ccc',
      borderRadius: '3px',
      '& h3': {
            color: 'black',
            position: 'absolute',
            top: '-2em',
            left: '2em',
            background: 'white',
            padding: '0.2em 1em',
            display: 'inline-block',
      },
}));

const SpanAmmount = styled.span((props) => ({
      display: 'inline-block',
      fontWeight: 'bold',
      padding: '0.2em 1em',
}));
