import React, {useEffect,useState} from 'react';
import Layout from '../../../layout/Layout';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { clientService } from '../../../../services';
import CrudEmpty from "../../../crud/CrudEmpty"
import Filter from "../../../filter/Filter";
import styled from "styled-components";
import { red } from '@mui/material/colors';
import { InfoOutlined } from '@mui/icons-material';
import CustomPaginationActionsTable from "../../../table/Table"
import { Autocomplete, TextField } from '@mui/material';
import { textAlign } from '@mui/system';
const moment = require("moment");



export default function ClientReport() {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [open, setOpen] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [filter, setFilter] = React.useState({ejercicio: moment().year()});


  const search = (filter) => {
    clientService.report(filter.ejercicio, (data,error) => {
          if(data && data.data) {
              setData(data.data);
          }
      });
  }


  useEffect(() => {
        setFilter({ejercicio:moment().year()});
        search(filter)
  }, []);


  const searchFilter = (filter) => {
    console.log(filter);
    setFilter(filter);
    search(filter);
  }


  const paintCell = {
    totalSales: (value,object) => {
      if (value) {
        return <p style={{textAlign: "right"}}>{value.toFixed(2)}</p>
      } else {
        return <></>
      }
    },
    totalSalesPayLess: (value,object) => {
      if (value) {
        return <p style={{textAlign: "right"}}>{value.toFixed(2)}</p>
      } else {
        return <></>
      }
    },

  }


  const conditionColor = (row) => {
    
    if(row["totalSalesPayLess"] && row["totalSalesPayLess"] <= 1 ){
      return "black";
    } else {
      if(row["totalSalesPayLess"] && row["totalSalesPayLess"] > 1 ){
        return "red";
      } else {
        return "black";
      }
    }
  }

  return (
    <CrudEmpty i18n={"reportclient"}>
        <Grid container style={{background:"white", padding: "0.3em 1em 1em", borderRadius: "3px"}}>
            <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '3em', paddingTop: "3em"}}>
                <PanelRounded>
                        <h3>Ejercicio</h3>
                        <Grid container>
                                <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                                <Autocomplete
                                    id={"year"}
                                      options={[moment().year(), moment().year()-1,moment().year()-2,moment().year()-3,moment().year()-4,moment().year()-5,moment().year()-6,moment().year()-7,moment().year()-8]}
                                      value={filter.ejercicio}
                                      onChange={(event,value) => {filter.ejercicio = value; searchFilter(filter)}}
                                      disableClearable={true }
                                      renderInput={(params) => <TextField {...params} variant="standard" label={t("parteruta.field.year")}  inputProps={{ ...params.inputProps,  autoComplete: 'off' }} autoComplete="off"/>}
                                    />
                                </Grid>
                        </Grid>
                </PanelRounded>
            </Grid>

            <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '3em'}}>
                <PanelRounded>
                        <h3>Importe de albaranes de cada cliente</h3>
                        <Grid container>
                                <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', marginTop: '20px'}}>
                                        <CustomPaginationActionsTable t={t} i18n={"client"} rows={data} fields={["code","comercialName","totalSales","totalSalesPayLess"]} fieldId={"id"} hiddenpagination={true} deleteaction={false}  selectHandler={null} editable={false} defaultValues={{}} paintCell={paintCell} paintvalue={{}}  selectable={false} handlerSelected={null} conditionColor={conditionColor}/>
                                </Grid>
                        </Grid>
                </PanelRounded>
            </Grid>
        </Grid>




    </CrudEmpty>
  );
}

const PanelRounded = styled.div(props => ({
    display: 'block',
    padding: '1em',
    position: 'relative',
    border: '1px solid #ccc',
    borderRadius: '3px',
    "& h3":{
        color: "black",
        position: "absolute",
        top: "-2em",
        left: "2em",
        background: "white",
        padding: "0.2em 1em",
        display: "inline-block",

    }

  }));

const SpanAmmount = styled.span(props => ({
    display: 'inline-block',
    fontWeight: 'bold',
    padding: "0.2em 1em"

  }));
