import CrudTable from "../../crud/CrudTable"
import {supplierService as service, routeService, paymenttypesupplierService, activityService} from "../../../services"

const initFilter =  {
    code : null,
    nif: null,
    comercialName:null,
    nameContact:null,
    phone:null,
    route:null,
    paymentType:null,
    activity:null,
};

export default function Supplier() {

  const createEstructureFilter =  [
    {
      type:"input",
      name: "code",
      autoFocus:true,
      col: 4
     },
     {
       type:"input",
       name: "nif",
       col: 4
     },
     {
       type:"input",
       name: "comercialName",
       col: 4
     },
     {
      type:"input",
      name: "nameContact",
      col: 4
     },
     {
        type:"input",
        name: "phone",
        col: 4
     },
      {
        type:"autocomplete",
        name: "paymentType",
        service:paymenttypesupplierService.combo,
        col: 4
      },
  ];


  return (
    <CrudTable
          service={service}
          urledit={"/supplier/edit/"}
          urlnew={"/supplier/new/"}
          filter={initFilter}
          i18n={"supplier"}
          fieldstable={["picture","code","comercialName","nameContact","phone","email"]}
          estructureForm={createEstructureFilter} 
          order={true}
          expendedFilter={true}
          orderColumns={{"code":"code", "comercialName":"nameOrder", "nameContact":"nameContactOrder", "phone":"phone", "email":"email"}} />
  );
}
