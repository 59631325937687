import CrudTable from "../../../crud/CrudTable"
import {statedeliveryService as service} from "../../../../services"

const initFilter =  {
    name : null,
    code: null,
};


export default function Statedelivery() {

  const createEstructureFilter =  [
     {
       type:"input",
       name: "name",
       col: 4
      },
  ];


  return (
    <CrudTable
          service={service}
          urledit={"/statedelivery/edit/"}
          urlnew={"/statedelivery/new/"}
          filter={initFilter}
          i18n={"statedelivery"}
          fieldstable={["id","name"]}
          estructureForm={createEstructureFilter} />
  );
}
