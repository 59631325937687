import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTab from "../../crud/CrudTab"
import {deliverynoteService as service,clientService} from "../../../services"
import 'toasted-notes/src/styles.css';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { Button, Grid } from '@mui/material';
import Modal from '@mui/material/Modal';
import CheckIcon from '@mui/icons-material/Check';


const moment = require("moment");


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const initFilter =  {
  from:null,
  to: moment().format('YYYY-MM-DD')+'T00:00:00Z',
  isNotPay:true,
  paymenttype:1,
  route:-1,
  client: null,
  payLessFrom: null,
  payLessTo: null,
};


export default function TabDeliverynotePending(props) {

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const[{filter,rows,forcesearch,openModal,delivery,updateStateParent},setState] = useState({
    filter:{...initFilter},
    rows:[],
    forcesearch:false,
    openModal:false,
    delivery:{},
    updateStateParent: props.updateStateParent,
  })


  useEffect(() => {
    
    var filterAux = {...filter}
    filterAux["route"] = props.route.id;
    filterAux["to"] = props.dates.to;

  
    if(props.route.id > 0){

      var update = {
        filter:filterAux
      }
      setState(currentState => ({...currentState, ...update}))
    } else {
      var update2 = {
        filter:filterAux,
        rows:[]
      }
      setState(currentState => ({...currentState, ...update2}))
    }
  
  }, [props.route,props.dates]);

  useEffect(() => {
    return () => {
  
  
      
      setState({
        filter:{
          from:null,
          to: '2000-01-01T23:00:00Z',
          isNotPay:true,
          route:-1,
          client: null,
          payLessFrom: null,
          paymenttype:1,
          payLessTo: null,
        },
        rows:[],
        forcesearch:false,
        openModal:false,
        delivery:{},
        updateStateParent: null
      });
     }
   
  }, []);



  const paintCell = {
    base: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    discount: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    iva: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    total: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    equivalencia: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    tax: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    paytotal: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    payLess: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    }

  }

  const createEstructureFilter =  [
     {
       type:"autocomplete",
       name: "client",
       service: clientService.combo,
       searchcode:true,
       col: 3
      },
      {
       type:"input",
       name: "from",
       inputtype:"date",
       col: 3
      },
      {
       type:"input",
       name: "payLessFrom",
       inputtype:"number",
       step: 0.01,
       col: 3
      },
      {
       type:"input",
       name: "payLessTo",
       inputtype:"number",
       step: 0.01,
       col: 3
      },

      
  ];

  const handleEdit = (selected) => {
    var update = {
      delivery: selected,
      openModal:true
    } ;

    setState(currentState => ({...currentState, ...update}));
  }

  const handleOpenModal = () => {
    setState(currentState => ({...currentState, ["openModal"]:false}));
  }

  const handleSavePay = () => {
    setState(currentState => ({...currentState, ["openModal"]:false}));
    service.paycompleteRuta(delivery["id"], props.parteid ,(data,error) => {

      if(data && data.data){

        var update = {
          forcesearch: !forcesearch,
          delivery: {}
        }

        setState(currentState => ({...currentState, ...update}));
        updateStateParent(currentState => ({...currentState, ["parteRutaForm"]:data.data}));
      }
    });
  }


  return (
      <><CrudTab
        filter={filter}
        estructureForm={createEstructureFilter}
        service={service}
        editable={false}
        deleteaction={false}
        hiddenFilter={false}
        blockedinitsearch={true}
        paintCell={paintCell}
        rows={rows}
        hiddenpagination={true}
        i18n={"deliverynote"}
        moreActions={[{"handle":handleEdit, icon:<CheckIcon />}]}
        fieldstable={["serie","number","date","client.comercialName|client.email|client.phone","base","tax","equivalencia","iva","total","paytotal","payLess"]}
        labels={{"client.comercialName|client.email|client.phone":t('bill.field.client.name')}}
        defaultValues={{serie:"",number:"Borrador"}}
        rowsPerPage={400}
        forcesearch={forcesearch}
       />
       <Modal
           open={openModal}
           onClose={handleOpenModal}
           aria-labelledby="simple-modal-title"
           aria-describedby="simple-modal-description">
           <Box sx={style}>
             <div style={{marginBottom:'30px'}}>
                ¿Quieres marcar el albarán {delivery['serieAndNumber']} como pagado?
             </div>
             <div>
               <Button variant="outlined" color="primary" style={{marginRight:'30px'}} onClick={handleOpenModal}> Cancelar</Button>
               <Button variant="contained" color="primary" onClick={handleSavePay}> Guardar </Button>
             </div>
         </Box>
       </Modal>
       </>

  );

}
