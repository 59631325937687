import React, { useState, useEffect }  from 'react';
import CrudEmpty from "../../crud/CrudEmpty"
import { useTranslation } from 'react-i18next';
import {parterutaService, daydisableService} from "../../../services"
import { t } from "i18next";
import { Autocomplete, TextField,Grid } from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import ModalParteRuta from "./ModalParteRuta";


const moment = require("moment");




var calendarCurrent:any = null;

export default function Moth(props) {

  const t = props.t;
  const month = props.month;


  const createEmpty = () => {
    var ar:any[] = [];

    return ar;
 }


 const initNullable = () => {
  var init:any = null;

  return init;
}

var initialFilter = {
  route: null,
  from: initNullable(),
  to: initNullable()
}

  const [{route,days,year,partes,events,filter,dateSelected,id,openModal,startOfMonth,endOfMonth,endOfMonthAux},setState] = useState(
    {
      route:props.route,
      days: createEmpty(),
      year:props.year,
      partes: createEmpty(),
      events: createEmpty(),
      filter: {...initialFilter},
      dateSelected:initNullable(),
      id:initNullable(),
      openModal:false,
      startOfMonth: moment().set('month', month-1).startOf('month').format("YYYY-MM-DD"),
      endOfMonth:moment().set('month', month-1).endOf('month').format("YYYY-MM-DD"),
      endOfMonthAux: moment().set('month', month-1).endOf('month').add(1,'day').format("YYYY-MM-DD")
    }
  );
  const calendarRef  = React.createRef<FullCalendar>();



  console.log("Mes aux => " + endOfMonthAux);

  const reloadToClose = false;



  const search = () => {

    var update = {
      events:[],
      partes:[]
    }

    setState(currentState => ({...currentState, ...update}));

    let eventsAuxGeneral:any[] = [];
    parterutaService.pageFilter(0,50,filter, (data, error) => {
        if (data.data){
            setState(currentState => ({...currentState, ["partes"]:data.data}));

            if(data.data.length > 0){
                data.data.forEach(element => {
                  eventsAuxGeneral.push({
                            id: element.id,
                            title: '',
                            start: moment(element.date).format("YYYY-MM-DD")+"T00:00:00",
                            end: moment(element.date).format("YYYY-MM-DD")+"T23:59:59",
                            backgroundColor:element.accept ? 'green': element.loader?'orange':'yellow',
                            allDay:true
                    })
                });
               
            }
        }

        
        daydisableService.pageFilter(0,50,filter, (data, error) => {
          if (data.data){
              setState(currentState => ({...currentState, ["days"]:data.data}));
              if(data.data.length > 0){
                  data.data.forEach(element => {
                    eventsAuxGeneral.push({
                              id: -1,
                              title: '',
                              start: moment(element.date).format("YYYY-MM-DD")+"T00:00:00",
                              end: moment(element.date).format("YYYY-MM-DD")+"T23:59:59",
                              backgroundColor:'red',
                              allDay:true,
                              display: 'background'
                      })
                  });
              }
          }
          
          setState(currentState => ({...currentState, ["events"]:eventsAuxGeneral}));
      })
    })

 

  }

  useEffect(() => {
    filter.from = startOfMonth+'T00:00:00Z';
    filter.to =endOfMonth+'T23:59:59Z';
    filter.route = route && route.id?route.id : (route && route.key?route.key:null);
    setState(currentState => ({...currentState, ["filter"]:filter}));

    return () => {


      setState({
          route:-1,
          days: createEmpty(),
          year:-1,
          partes: createEmpty(),
          events: createEmpty(),
          filter: {...initialFilter},
          dateSelected:initNullable(),
          id:initNullable(),
          openModal:false,
          startOfMonth: moment().startOf('month').format("YYYY-MM-DD"),
          endOfMonth:moment().endOf('month').format("YYYY-MM-DD"),
          endOfMonthAux: moment().endOf('month').add(1,'day').format("YYYY-MM-DD")
     });
     }

  }, []);


  useEffect(() => {
    setState(currentState => ({...currentState, ["route"]:props.route}));
  }, [props.route]);

  useEffect(() => {
    let filterAux = {...filter};
    filterAux.route = route && route.id?route.id : (route && route.key?route.key:null);
    setState(currentState => ({...currentState, ["filter"]:filterAux}));
  }, [route]);

  useEffect(() => {
    if(filter.route){
        search();
    }
  }, [filter]);

  useEffect(() => {
    calendarRef?.current?.render();
    var filterAux = {...filter}
    filterAux.from = moment().set('year',props.year).set('month', month-1).startOf('month').format("YYYY-MM-DD")+'T00:00:00Z';
    filterAux.to =moment().set('year',props.year).set('month', month-1).endOf('month').format("YYYY-MM-DD")+'T23:59:59Z';
    filterAux.route = route && route.id?route.id : (route && route.key?route.key:null);
    
    var update = {
      filter: filterAux,
      startOfMonth:moment().set('year',props.year).set('month', month-1).startOf('month').format("YYYY-MM-DD"),
      endOfMonth:moment().set('year',props.year).set('month', month-1).endOf('month').format("YYYY-MM-DD"),
      endOfMonthAux: moment().set('year',props.year).set('month', month-1).endOf('month').add(1,'day').format("YYYY-MM-DD")
    }
    
    setState(currentState => ({...currentState, ...update}));

 }, [props.year]);

  
  const saveparte = (element,isCreated)=>{
    if(isCreated){
        var eventsAux:any[] = events;
        var e = {
            id: element.id,
            title: '',
            start: moment(element.date).format("YYYY-MM-DD")+"T00:00:00",
            end: moment(element.date).format("YYYY-MM-DD")+"T23:59:59",
            backgroundColor:element.accept ? 'green': element.loader?'orange':'yellow',
            allDay:true
        };
        eventsAux.push(e);
        
        var api = calendarCurrent?.getApi();
        api?.addEvent(e);

        var partesAux = partes;
        partesAux.push(element);

        var update = {
          partes: partesAux,
          eventsAux: eventsAux
        }
        setState(currentState => ({...currentState, ...update}));

    }
  }

  const dateIsValid = (date) => {
    var isValid = true;
    days.forEach(element => {
      if(days["date"] == date){
        isValid = false;
      }
      
    });

    return isValid;
  }

  const eventClose = (reload) => {

    var update = {
      dateSelected: "2000-01-01T00:00:00Z",
      id:null,
      openModal: !openModal
    }

    setState(currentState => ({...currentState, ...update}));
    debugger;

    if(reload) {
      search();
    }
  }

  return (
    <Grid item md={3} sm={4} xs={12} style={{padding: "1em"}}>  
        <FullCalendar
                ref={calendarRef}
                plugins={[ interactionPlugin, dayGridPlugin ]}
                initialView="dayGridMonth"
                locale="es"
                navLinks={false}
                headerToolbar={{start: 'title',   center: '', end: ''}}
                validRange={{start: startOfMonth, end: endOfMonthAux}}
                height={450}
                contentHeight={450}
                events={events}
                firstDay={1}
                eventClick={(info) => {
                      var update = {
                        dateSelected: info.event.start,
                        id:info.event.id,
                        openModal: !openModal
                      }

                      setState(currentState => ({...currentState, ...update}));
                    }
                }
                selectable={true}
                editable={true}
                dateClick={(info) => {
                    if(moment(info.date).isoWeekday() != 7 && dateIsValid(moment(info.date).format("YYYY-MM-DD")+"T00:00:00Z")) {
                      calendarCurrent = calendarRef.current;
                      var update = {
                        dateSelected: moment(info.date).format("YYYY-MM-DD")+"T00:00:00Z",
                        id:null,
                        openModal: !openModal
                      }

                      setState(currentState => ({...currentState, ...update}));
                    }
                }}
                
            />
            <ModalParteRuta id={id} route={route} openmodal={openModal} date={dateSelected} t={t} eventClose={(reload = false) => eventClose(reload)} /*save={saveparte}*//>
    </Grid>
  );


}
