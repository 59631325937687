
import Grid from '@mui/material/Grid';
import styled from "styled-components";
import {initElementStreet, estructureformStreet, getElementStreet, headerFecth} from './Data';

export const setValueSupplierData=  async (setValueField,objectform,value,valueform,setState,id,aux,supplier) => {
      
    var newobject = {...objectform};
    newobject =  setValueField(newobject,"supplier",supplier);

    var valueFormAux = {...valueform};
    valueFormAux["supplier.id"] = value;

    if(value && (value.key || value.key === 0)){

        newobject = setValueField(newobject,"nameClient",supplier.comercialName);
        newobject = setValueField(newobject,"emailClient",supplier.email);
        newobject = setValueField(newobject,"nif",supplier.nif);
        newobject = setValueField(newobject,"street",supplier.street);
        newobject = setValueField(newobject,"paymentType",supplier.paymentType);

    


        valueFormAux["street.street"]= supplier.street?.street;
        valueFormAux["street.postalcode"]= supplier.street?.postalcode;
        valueFormAux["street.country.id"]=  supplier?.street?.country?.id ? {key:supplier.street.country.id,label:supplier.street.country.name}: {key:null,label:null};
        valueFormAux["street.city.id"]= supplier?.street?.city?.id ? {key:supplier.street.city.id,label:supplier.street.city.name}: {key:null,label:null};
        valueFormAux["street.state.id"]= supplier?.street?.state?.id ? {key:supplier.street.state.id,label:supplier.street.state.name}: {key:null,label:null};
        valueFormAux["street.number"]= supplier.street?.number;
        valueFormAux["street.floor"]= supplier.street?.floor;
        valueFormAux["street.stairs"]= supplier.street?.stairs;
        valueFormAux["street.door"]= supplier.street?.door;
        valueFormAux["street.location"]= supplier.street?.door;

        valueFormAux["nameClient"]= supplier.comercialName ;
        valueFormAux["nif"]= supplier.nif? supplier.nif : "";
        valueFormAux["emailClient"]= supplier.email ? supplier.email: "";
        valueFormAux["paymentType.id"]=  supplier?.paymentType?.id ? {key:supplier.paymentType.id,label:supplier.paymentType.name}: {key:null,label:null};


    }

    var update = {
        objectform: newobject,
        valueform: valueFormAux
    }

    setState(currentState => ({...currentState, ...update}));

    return newobject;

  }


  export const updateRows = (setValueField,objectform,rows,setState, valueform) => {
   
    var totalLines = 0.0;

    var base = 0;
    var iva = 0;
    var recargo = 0;
    var total = 0;
    var tax = 0;

    var ivasLine:any[] = [];
    var recargosLine:any[] = [];
    var ivas = new Map<number, any>();
    var recargos = new Map<number, any>();

    for (let row of rows) {
         debugger;
        if(row["total"]) {
           base += +row["base"];
           iva += +row["iva"];
           recargo = +row["recargo"];
           total += +row["total"];
           tax += +row['amount'] * (+row['price']  * (+row['tax']/100));

           if(ivas.has(+row['percentIva'])){
            ivas.set(+[row['percentIva']],{percentIva:row['percentIva'], iva: (+row["iva"]) + ivas.get(+row['percentIva']).iva});
          } else {
            ivas.set(+[row['percentIva']], {percentIva:row['percentIva'], iva:(+row["iva"])});
          }

          if(recargos.has(+row['percentRecargo'])){
            recargos.set(+[row['percentRecargo']],{percent:row['percentRecargo'], amount: (+row["recargo"]) + recargos.get(+row['percentRecargo']).amount});
          } else {
            recargos.set(+[row['percentRecargo']], {percent:row['percentRecargo'], amount:(+row["recargo"])});
          }

        }
    }

    ivas.forEach((value, key) => {
      ivasLine.push(value);
    });

    recargos.forEach((value, key) => {
      recargosLine.push(value);
    });



    var disper = +objectform["discountPercent"];
    var dis = (base * (disper / 100.0)).toFixed(2);

    if(disper && disper > 0){

     ivasLine= [];
     recargosLine= [];
     ivas = new Map<number, any>();;
     recargos = new Map<number, any>();;

      base = 0;
      iva = 0;
      recargo = 0;
      total = 0;
      tax = 0;

       for (let row of rows) {
          if(row["total"]) {
            var priceLine = +row['price'] - (+row['price'] * disper/100);
            var taxLine = +row['amount'] * priceLine * (+row['tax'] / 100) ;
            var baseLine = (priceLine * +row['amount']) + taxLine;
            var ivaLine = baseLine * (+row['percentIva']/100);
            var recargoLine = baseLine * (+row['percentRecargo']/100);
            var totalLine = baseLine + ivaLine + recargoLine;

            
            base += baseLine;
            iva += ivaLine;
            recargo += recargoLine;
            total += totalLine;
            tax += taxLine;

            if(ivas.has(+row['percentIva'])){
              ivas.set(+[row['percentIva']],{percentIva:row['percentIva'], iva: ivaLine + ivas.get(+row['percentIva']).iva});
            } else {
              ivas.set(+[row['percentIva']], {percentIva:row['percentIva'], iva:ivaLine });
            }

            if(recargos.has(+row['percentRecargo'])){
              recargos.set(+[row['percentRecargo']],{percent:row['percentRecargo'], amount: recargoLine + recargos.get(+row['percentRecargo']).amount});
            } else {
              recargos.set(+[row['percentRecargo']], {percent:row['percentRecargo'], amount:recargoLine});
            }

          }
      }
      
      ivas.forEach((value, key) => {
        ivasLine.push(value);
      });


      recargos.forEach((value, key) => {
        recargosLine.push(value);
      });

    }

     let valueFormAux = {...valueform};
     valueFormAux['total'] = total.toFixed(2);
     valueFormAux['discount'] =  dis;
     valueFormAux['base'] =  base.toFixed(2);
     valueFormAux['iva'] =  iva.toFixed(2);
     valueFormAux['tax'] =  tax.toFixed(2);
     valueFormAux['equivalencia'] =  recargo.toFixed(2);

    var newobject  = {...objectform}
    newobject =  setValueField(newobject,"total",total);
    newobject =  setValueField(newobject,"discount",dis);
    newobject =  setValueField(newobject,"base",base);
    newobject =  setValueField(newobject,"iva",iva);
    newobject =  setValueField(newobject,"tax",tax);
    newobject =  setValueField(newobject,"equivalencia",recargo);
    newobject =  setValueField(newobject,"ivasline",ivasLine);
    newobject =  setValueField(newobject,"recargosline",recargosLine);

    var update = {
        objectform: newobject,
        valueform: valueFormAux
    } 

    setState(currentState => ({...currentState, ...update}));
    
    return newobject;
}


export const updateDiscountTotal = (setValueField,objectform,value,valueform,setState) => {
   
    var rows = objectform["lines"] 

    var totalLines = 0.0;

    var base = 0;
    var iva = 0;
    var recargo = 0;
    var total = 0;
    var tax = 0;


    var ivasLine:any[] = [];
    var recargosLine:any[] = [];
    var ivas = new Map<number, any>();
    var recargos = new Map<number, any>();

    for (let row of rows) {
        if(row["total"]) {
           base += +row["base"];
           iva += +row["iva"];
           recargo = +row["recargo"];
           total += +row["total"];
           tax += +row['amount'] * (+row['price']  * (+row['tax']/100));

          if(ivas.has(+row['percentIva'])){
            ivas.set(+[row['percentIva']],{percentIva:row['percentIva'], iva: (+row["iva"]) + ivas.get(+row['percentIva']).iva});
          } else {
            ivas.set(+[row['percentIva']], {percentIva:row['percentIva'], iva:(+row["iva"])});
          }

          if(recargos.has(+row['percentRecargo'])){
            recargos.set(+[row['percentRecargo']],{percent:row['percentRecargo'], amount: (+row["recargo"]) + recargos.get(+row['percentRecargo']).amount});
          } else {
            recargos.set(+[row['percentRecargo']], {percent:row['percentRecargo'], amount:(+row["recargo"])});
          }

        }
    }



    var disper = +value;
    var dis = (base * (disper / 100.0)).toFixed(2);
    if(disper && disper > 0){

      ivasLine= [];
      recargosLine= [];
      ivas = new Map<number, any>();
      recargos = new Map<number, any>();

      base = 0;
      iva = 0;
      recargo = 0;
      total = 0;
      tax = 0;

       for (let row of rows) {
          if(row["total"]) {
            var priceLine = +row['price'] - (+row['price'] * disper/100);
            var taxLine = +row['amount'] * priceLine * (+row['tax'] / 100) ;
            var baseLine = (priceLine * +row['amount']) + taxLine;
            var ivaLine = baseLine * (+row['percentIva']/100);
            var recargoLine = baseLine * (+row['percentRecargo']/100);
            var totalLine = baseLine + ivaLine + recargoLine;

            base += baseLine;
            iva += ivaLine;
            recargo += recargoLine;
            total += totalLine;
            tax += taxLine;



            if(ivas.has(+row['percentIva'])){
              ivas.set(+[row['percentIva']],{percentIva:row['percentIva'], iva: ivaLine + ivas.get(+row['percentIva']).iva});
            } else {
              ivas.set(+[row['percentIva']], {percentIva:row['percentIva'], iva:ivaLine });
            }

            if(recargos.has(+row['percentRecargo'])){
              recargos.set(+[row['percentRecargo']],{percent:row['percentRecargo'], amount: recargoLine + recargos.get(+row['percentRecargo']).amount});
            } else {
              recargos.set(+[row['percentRecargo']], {percent:row['percentRecargo'], amount:recargoLine});
            }

          }
      }
    }

    ivas.forEach((value, key) => {
      ivasLine.push(value);
    });


    recargos.forEach((value, key) => {
      recargosLine.push(value);
    });


    let valueFormAux = {...valueform};
    valueFormAux["discountPercent"]= value;
    valueFormAux["total"]= total.toFixed(2);
    valueFormAux["discount"]= dis;
    valueFormAux["base"]= base.toFixed(2);
    valueFormAux["iva"]= iva.toFixed(2);
    valueFormAux["tax"]= tax.toFixed(2);
    valueFormAux["equivalencia"]= recargo.toFixed(2);
     

    var newobject  = {...objectform}
    newobject =  setValueField(newobject,"discountPercent",value);
    newobject =  setValueField(newobject,"total",total.toFixed(2));
    newobject =  setValueField(newobject,"discount",dis);
    newobject =  setValueField(newobject,"base",base.toFixed(2));
    newobject =  setValueField(newobject,"iva",iva.toFixed(2));
    newobject =  setValueField(newobject,"tax",tax.toFixed(2));
    newobject =  setValueField(newobject,"equivalencia",recargo.toFixed(2));
    newobject["ivasline"] = ivasLine;
    newobject =  setValueField(newobject,"recargosline",recargosLine);


    var update = {
        objectform: newobject,
        valueform: valueFormAux
    } 

    setState(currentState => ({...currentState, ...update}));

    return newobject;
  }

  

  export const fieldIvas = (field,valueform,objectform,setState,setValueField) => {
    return <Grid container spacing={3}>
       <Grid item sm={6}>
        <h5>Desglose de ivas</h5>
        <PanelIvas>
          {objectform && objectform['ivasline'] && objectform['ivasline'].length > 0 && objectform['ivasline'][0].percentIva != 0 && (objectform['ivasline']).map((row) => (
              <PanelOneIva>
                  <PanelOneIvaLabel>{row.percentIva}%</PanelOneIvaLabel>
                  <PanelOneIvaSpan>{row.iva.toFixed(2)}</PanelOneIvaSpan>
              </PanelOneIva>
            ))}
        </PanelIvas>
      </Grid>

    </Grid>
  }

  const PanelIvas = styled.div `
  display: flex;
  
`;


export const setValueRowProduct = (setValueField,objectform,value,valueform,setState,id,aux,supplierGeneral) => {
  debugger;

  if(aux){

    
    var newobject = objectform;

    var product = aux;
    newobject =  setValueField(objectform,"amount",1.0);
    newobject =  setValueField(newobject,"name",product.name);
    newobject =  setValueField(newobject,"price",product.lastBuyPrice);
    var percentIva = supplierGeneral?.exentoIva ? 0:product.iva.percent;
    newobject =  setValueField(newobject,"percentIva",percentIva);
    newobject =  setValueField(newobject,"tax", 0);
    var percentEqui = 0;
    newobject =  setValueField(newobject,"percentRecargo",percentEqui);
    var base = product.lastBuyPrice;
    newobject =  setValueField(newobject,"base", base.toFixed(2));
    var iva = base * (percentIva/100);
    newobject =  setValueField(newobject,"iva",iva.toFixed(2));
    var recargo = base * (percentEqui/100.0);
    newobject =  setValueField(newobject,"recargo",recargo.toFixed(2));
    var total = base + iva + recargo;
    newobject =  setValueField(newobject,"total",total.toFixed(2));

    return newobject;
  } else {
    return objectform;
  }

}

export const setValueAmout = (setValueField,objectform,value,valueform,setState,id,aux) => {
  
  var newobject = {...objectform};
  newobject =  setValueField(newobject,"ammount", value);

  var valueFormAux = {...valueform};
  var rv = valueform[id];
  rv["ammount"] = value;



  if(value){

     

     
     var ammount = +value;
     var price = rv["price"] ? (isNaN(+rv["price"])? 0: +rv["price"]):0;
     var percentIva=rv["percentIva"] ? (isNaN(+rv["percentIva"])? 0: +rv["percentIva"]):0;
     var percentRecargo=rv["percentRecargo"] ? (isNaN(+rv["percentRecargo"])? 0: +rv["percentRecargo"]):0;
     var tax=rv["tax"] ? (isNaN(+rv["tax"])? 0: +rv["tax"]):0;

     var base = (price + tax) * ammount;
     var iva = base * (percentIva/100);
     var recargo = base * (percentRecargo/100.0);
     var total = base + iva + recargo;

     newobject =  setValueField(newobject,"base", base.toFixed(2));
     newobject =  setValueField(newobject,"iva",iva.toFixed(2));
     newobject =  setValueField(newobject,"recargo",recargo.toFixed(2));
     newobject =  setValueField(newobject,"total",total.toFixed(2));

     rv["base"] = base.toFixed(2);
     rv["iva"] = iva.toFixed(2);
     rv["recargo"] = recargo.toFixed(2);
     rv["total"] = total.toFixed(2);
     
   }  else {
      newobject =  setValueField(newobject,"base", 0);
      newobject =  setValueField(newobject,"iva",0);
      newobject =  setValueField(newobject,"recargo",);
      newobject =  setValueField(newobject,"total",);

      rv["base"] = "0.00";
      rv["iva"] = "0.00";
      rv["recargo"] = "0.00";
      rv["total"] = "0.00";
   }


  var update = {
    valueform: valueFormAux,
    objectform:newobject
  }
  setState(currentstate => ({...currentstate, ...update}))

   return newobject;
}

export const setValuePrice = (setValueField,objectform,value,valueform,setState,id,aux) => {
 
  var newobject = {...objectform};
  newobject =  setValueField(newobject,"price", value);

  var valueFormAux = {...valueform};
  var rv = valueform[id];
  rv["price"] = value;

  if(value){

   
     var ammount =  rv["amount"] ? (isNaN(+rv["amount"])? 0: +rv["amount"]):0;
     var price = +value;
     var percentIva=rv["percentIva"] ? (isNaN(+rv["percentIva"])? 0: +rv["percentIva"]):0; 
     var percentRecargo=rv["percentRecargo"] ? (isNaN(+rv["percentRecargo"])? 0: +rv["percentRecargo"]):0;
     var tax=rv["tax"] ? (isNaN(+rv["tax"])? 0: +rv["tax"]):0;

     var base = (price + tax) * ammount;
     var iva = base * (percentIva/100);
     var recargo = base * (percentRecargo/100.0);
     var total = base + iva + recargo;

     var newobject = objectform;
     newobject =  setValueField(newobject,"base", base.toFixed(2));
     newobject =  setValueField(newobject,"iva",iva.toFixed(2));
     newobject =  setValueField(newobject,"recargo",recargo.toFixed(2));
     newobject =  setValueField(newobject,"total",total.toFixed(2));

     rv["base"] = base.toFixed(2);
     rv["iva"] = iva.toFixed(2);
     rv["recargo"] = recargo.toFixed(2);
     rv["total"] = total.toFixed(2);

 }  else {
    newobject =  setValueField(newobject,"base", 0);
    newobject =  setValueField(newobject,"iva",0);
    newobject =  setValueField(newobject,"recargo",);
    newobject =  setValueField(newobject,"total",);

    rv["base"] = "0.00";
    rv["iva"] = "0.00";
    rv["recargo"] = "0.00";
    rv["total"] = "0.00";
}


var update = {
  valueform: valueFormAux,
  objectform:newobject
}
setState(currentstate => ({...currentstate, ...update}))

return newobject;
 
}

export const setValueResiduos = (setValueField,objectform,value,valueform,setState,id,aux) => {
 
var newobject = {...objectform};
newobject =  setValueField(newobject,"tax", value);

var valueFormAux = {...valueform};
var rv = valueform[id];
rv["tax"] = value;

 if(value){

   var ammount =  rv["amount"] ? (isNaN(+rv["amount"])? 0: +rv["amount"]):0;
   var price =  rv["price"] ? (isNaN(+rv["price"])? 0: +rv["price"]):0;
   var percentIva=rv["percentIva"] ? (isNaN(+rv["percentIva"])? 0: +rv["percentIva"]):0; 
   var percentRecargo=rv["percentRecargo"] ? (isNaN(+rv["percentRecargo"])? 0: +rv["percentRecargo"]):0;
   var tax=+value;

   var base = (price + tax) * ammount;
   var iva = base * (percentIva/100);
   var recargo = base * (percentRecargo/100.0);
   var total = base + iva + recargo;

   newobject =  setValueField(newobject,"base", base.toFixed(2));
   newobject =  setValueField(newobject,"iva",iva.toFixed(2));
   newobject =  setValueField(newobject,"recargo",recargo.toFixed(2));
   newobject =  setValueField(newobject,"total",total.toFixed(2));

   rv["base"] = base.toFixed(2);
   rv["iva"] = iva.toFixed(2);
   rv["recargo"] = recargo.toFixed(2);
   rv["total"] = total.toFixed(2);

} else {
  newobject =  setValueField(newobject,"base", 0);
  newobject =  setValueField(newobject,"iva",0);
  newobject =  setValueField(newobject,"recargo",);
  newobject =  setValueField(newobject,"total",);

  rv["base"] = "0.00";
  rv["iva"] = "0.00";
  rv["recargo"] = "0.00";
  rv["total"] = "0.00";
}


var update = {
valueform: valueFormAux,
objectform:newobject
}
setState(currentstate => ({...currentstate, ...update}))

return newobject;
}


export const setValueIva = (setValueField,objectform,value,valueform,setState,id,aux) => {

 
var newobject = {...objectform};
newobject =  setValueField(newobject,"percentIva", value);

var valueFormAux = {...valueform};
var rv = valueform[id];
rv["percentIva"] = value;

if(value){

   var ammount =  rv["amount"] ? (isNaN(+rv["amount"])? 0: +rv["amount"]):0;
   var price =  rv["price"] ? (isNaN(+rv["price"])? 0: +rv["price"]):0;
   var percentIva=+value;
   var percentRecargo=rv["percentRecargo"] ? (isNaN(+rv["percentRecargo"])? 0: +rv["percentRecargo"]):0;
  var tax=rv["tax"] ? (isNaN(+rv["tax"])? 0: +rv["tax"]):0;

   var base = (price + tax) * ammount;
   var iva = base * (percentIva/100);
   var recargo = base * (percentRecargo/100.0);
   var total = base + iva + recargo;

   newobject =  setValueField(newobject,"base", base.toFixed(2));
   newobject =  setValueField(newobject,"iva",iva.toFixed(2));
   newobject =  setValueField(newobject,"recargo",recargo.toFixed(2));
   newobject =  setValueField(newobject,"total",total.toFixed(2));

   rv["base"] = base.toFixed(2);
   rv["iva"] = iva.toFixed(2);
   rv["recargo"] = recargo.toFixed(2);
   rv["total"] = total.toFixed(2);

   
}  else {
  newobject =  setValueField(newobject,"base", 0);
  newobject =  setValueField(newobject,"iva",0);
  newobject =  setValueField(newobject,"recargo",);
  newobject =  setValueField(newobject,"total",);

  rv["base"] = "0.00";
  rv["iva"] = "0.00";
  rv["recargo"] = "0.00";
  rv["total"] = "0.00";
}


var update = {
valueform: valueFormAux,
objectform:newobject
}
setState(currentstate => ({...currentstate, ...update}))

return newobject;
}


export const setValueEquivalencia = (setValueField,objectform,value,valueform,setState,id,aux) => {


var newobject = {...objectform};
newobject =  setValueField(newobject,"percentRecargo", value);

var valueFormAux = {...valueform};
var rv = valueform[id];
rv["percentRecargo"] = value;

if(value){

 var ammount =  rv["amount"] ? (isNaN(+rv["amount"])? 0: +rv["amount"]):0;
 var price =  rv["price"] ? (isNaN(+rv["price"])? 0: +rv["price"]):0;
 var percentIva=rv["percentIva"] ? (isNaN(+rv["percentIva"])? 0: +rv["percentIva"]):0; 
 var percentRecargo=+value;
 var tax=rv["tax"] ? (isNaN(+rv["tax"])? 0: +rv["tax"]):0;

 var base = (price + tax) * ammount;
 var iva = base * (percentIva/100);
 var recargo = base * (percentRecargo/100.0);
 var total = base + iva + recargo;

 newobject =  setValueField(newobject,"base", base.toFixed(2));
 newobject =  setValueField(newobject,"iva",iva.toFixed(2));
 newobject =  setValueField(newobject,"recargo",recargo.toFixed(2));
 newobject =  setValueField(newobject,"total",total.toFixed(2));

 rv["base"] = base.toFixed(2);
 rv["iva"] = iva.toFixed(2);
 rv["recargo"] = recargo.toFixed(2);
 rv["total"] = total.toFixed(2);

} else {
  newobject =  setValueField(newobject,"base", 0);
  newobject =  setValueField(newobject,"iva",0);
  newobject =  setValueField(newobject,"recargo",);
  newobject =  setValueField(newobject,"total",);

  rv["base"] = "0.00";
  rv["iva"] = "0.00";
  rv["recargo"] = "0.00";
  rv["total"] = "0.00";
}


var update = {
valueform: valueFormAux,
objectform:newobject
}
setState(currentstate => ({...currentstate, ...update}))

return newobject;
}




const PanelOneIva = styled.div `
display: flex;
flex-direction: column;
text-align: center;
max-width: 146px;
width: 30%;
`;

const PanelOneIvaLabel = styled.label `
display: block;
background-color: rgba(100,172,71,0.7);
padding: 5px 0;
`;
const PanelOneIvaSpan = styled.span `
display: block;
background-color: rgba(100,172,71,0.5);
padding: 5px 0;
`;
