import React, { useState, useEffect, useRef, Fragment } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {uploadService} from "../../services";
import { Editor } from '@tinymce/tinymce-react';
import CircularProgress from '@mui/material/CircularProgress';
import FormTable from "./FormTable";
import styled from "styled-components";
import { flexbox } from '@mui/system';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const moment = require("moment");

let comboloads:any[] = [];

export default function Form(props) {

  const initopenautocomplete = (fields) => {
    var valuesfields = Array();
    for (let field of fields) {
      if(field.type == 'autocomplete' && !field.options) {
        var fn = field.name;
        valuesfields[fn] = false;
      }

    }
    return valuesfields;
  }

  const initsetValueAux = (fields) => {
    var valuesfields = Array();
    for (let field of fields) {
      if (field.setvalue) {
         valuesfields[field.name] = field.setvalue;
      }

    }
    return valuesfields;
  }

  const initrelationSearchAux = (fields) => {
    var valuesfields = Array();
    for (let field of fields) {
      if (field.relationSearch) {
         valuesfields[field.name] = field.relationSearch;
      }

    }
    return valuesfields;
  }

  const initvalueform = (fields) => {
    var valuesfields = Array();
    for (let field of fields) {
      valuesfields[field.name] = "";
    }
    return valuesfields;
  }

  const initChange = (fields) => {
    var valuesfields = Array();
    for (let field of fields) {
      valuesfields[field.name] = field.changecustom ? field.changecustom : null;
    }
    return valuesfields;
  }

  const initoptions = (fields) => {
    var valuesfields = Array();
    for (let field of fields) {
      if(field.type == 'autocomplete' && !field.options) {
        var fn = field.name;
        valuesfields[fn] = Array();
        //valuesfields[fn].push({key: -1, name:"Selecciona una opción"})
      } else if(field.type == 'autocomplete' && field.options) {
        var fn = field.name;
        valuesfields[fn] = field.options;
      } else if(field.type == 'location') {
        var fn = field.name;
        valuesfields[fn] = [];
      }

    }
    return valuesfields;
  }

  const initclear = (fields) => {
    var valuesfields = Array();
    for (let field of fields) {
      var fn = field.name;
      if(field.clear) {
        valuesfields[fn] = field.clear;
      } else  {
        valuesfields[fn] = [];
      }
    }
    return valuesfields;
  }

  const inithandlers = (fields) => {
    var valuesfields = Array();
    for (let field of fields) {
      if(field.type == 'autocomplete' && field.handler) {
        valuesfields[field.name] = field.handler;
      }
    }
    return valuesfields;
  }

  const initservicerelation = (fields) => {
    var valuesfields = Array();
    for (let field of fields) {
      if(field.type == 'autocomplete' && field.servicerelation) {
        valuesfields[field.name] = field.servicerelation;
      }
    }
    return valuesfields;
  }

  const initrelationfield = (fields) => {
    
    var valuesfields = Array();
    for (let field of fields) {
      if(field.type == 'autocomplete' && field.relationfield) {
        valuesfields[field.name] = field.relationfield;
      }
    }
    return valuesfields;
  }

  const [ { objectform, open, fields, labelsave,labelcancel, isHiddenSave, isHiddenCancel,cleanInOK,paintInitValue,onlyRead,forceupdatetable, notCleanOnCancel,openautocomplete, setValueAux,relationSearchAux,valueform,options,clear,handlers,servicerelation,relationfield,chnageCustom,inline  },  setState ] = useState({objectform: (props.objectform?props.objectform:{}),
          open:false, 
          fields:(props.fields?props.fields:[]),
          labelsave: (props.labelsave?props.labelsave:'Guardar'),
          labelcancel: (props.labelcancel?props.labelcancel:'Cancelar'),
          isHiddenSave: (props.isHiddenSave?props.isHiddenSave:false),
          isHiddenCancel: (props.isHiddenCancel?props.isHiddenCancel:false),
          cleanInOK:(props.cleanInOK?props.cleanInOK:false),
          paintInitValue: (props.paintInitValue?props.paintInitValue:[]),
          onlyRead:(props.onlyRead ?props.onlyRead:false),
          forceupdatetable: false,
          notCleanOnCancel: (props.notCleanOnCancel ? true: false),
          openautocomplete: initopenautocomplete(props.fields),
          setValueAux: initsetValueAux(props.fields),
          relationSearchAux: initrelationSearchAux(props.fields),
          valueform:initvalueform(props.fields),
          options: initoptions(props.fields),
          clear:initclear(props.fields),
          handlers:inithandlers(props.fields),
          servicerelation:initservicerelation(props.fields),
          relationfield:initrelationfield(props.fields),
          chnageCustom: initChange(props.fields),
          inline:props.inline ? props.inline : false,
        });
        
  const editorRef = useRef(null);
  const refImageArray = React.useRef<(HTMLInputElement | null)[]>([]);
  const t = props.t;
  const i18n = props.i18n;
  const loading = open;





  const handleFilter = function(){
    
    props.searchFilter(objectform);
    if(cleanInOK){
      handleClean()
    }
  }

  const handleClean = function(){
    let newobject = {...objectform}
    if(!notCleanOnCancel) {
      let valueformAux = {...valueform}
      for (let field of fields) {
        valueformAux[field.name] = "";
        newobject = setValueField(newobject,field.name,"");
      }

      var update = {objectform: newobject, valueform: valueformAux};
      setState(currentState => ({...currentState, ...update}));
    }

    props.cleanFilter(newobject);
  }

  const initValues = function(obj){

    let valueformAux = {...valueform};
  
    for (let field of props.fields) {

        if(field.initValue){
            const value =  field.initValue(obj);
            console.log(field.name);
            console.log(value);
            
            valueformAux[field.name] = value;
        } else if(field.type == 'autocomplete'){
          if(field.multiple){
            var result = Array();
            var array = getValueField(obj,field.name);
            for (let index = 0; index < array.length; index++) {
              const element = array[index];
              if(element.id && element.id > 0 && element.name){
                result.push({key:element.id,name:element.name});
              }
            }
            valueformAux[field.name] = result;
          } else {
            if(field.name.includes(".id")) {
              var fieldname = field.name.replace('.id','.name');
              let itemSelect = {key:getValueField(obj,field.name),name: getValueField(obj,fieldname)};
            valueformAux[field.name] = itemSelect;
            } else {
                var key = getValueField(obj,field.name);
                var name = getValueField(obj,field.name);
                if(field.options){
                  for (let index = 0; index < field.options.length; index++) {
                     const element = field.options[index];
                    if(element.key == key){
                      name = element.name;
                    }
                  }
                } else {
                  
                  if(options[field.name]){
                    for (let index = 0; index < options[field.name].length; index++) {
                      const element = options[field.name][index];
                     if(element.key == key){
                       name = element.name;
                     }
                   }
                  }
                }
                let itemSelect = {key:key,name: name};

                valueformAux[field.name] = itemSelect;
            }
          }
      } else if(field.type == 'image') {
        let valueField = getValueField(obj,field.name);
        if (valueField != null && valueField != ""){
          const url = process.env.REACT_APP_HOST+'/image' + (valueField.startsWith("/") ? "":"/");
          
          valueformAux[field.name] = url + valueField;
        }
      } else if(field.type == 'video') {
        let valueField = getValueField(obj,field.name);
        if (valueField != null && valueField != ""){
          const url = process.env.REACT_APP_HOST+'/image' + (valueField.startsWith("/") ? "":"/");
          valueformAux[field.name] = url + valueField;
        }
      } else if(field.type == 'file') {
        let valueField = getValueField(obj,field.name);
        if (valueField != null && valueField != ""){
          const url = process.env.REACT_APP_HOST+'/file' + (valueField.startsWith("/") ? "":"/");
          valueformAux[field.name] = url + valueField;
        }
      } else if(field.type == 'gallery') {
        let valueField = getValueField(obj,field.name);
          valueformAux[field.name] = valueField;
        
      } else if(field.type == 'title' || field.type == 'table') {

      } else if(field.type == 'location' || field.type == 'location') {
        if (getValueField(obj,field.street) && getValueField(obj,field.street) != ""){
          var location = {
            country:  getValueField(obj,field.country),
            display_name: getValueField(obj,field.street) +", " +getValueField(obj,field.number) +", " +getValueField(obj,field.city)+", " +getValueField(obj,field.postalcode)+", " +getValueField(obj,field.country),
            lat: getValueField(obj,field.latitude),
            location: getValueField(obj,field.city),
            lon: getValueField(obj,field.longitude),
            number: getValueField(obj,field.number),
            postalCode: getValueField(obj,field.postalcode),
            state: "",
            street: getValueField(obj,field.street),
          }
          valueformAux[field.name] = location;
      }

      } else {
        if (field.inputtype && field.inputtype == "date") {

             valueformAux[field.name] = (moment(Date.parse(getValueField(obj,field.name)))).format('YYYY-MM-DD');
        } else if (field.inputtype && field.inputtype == "datetime-local") {

            valueformAux[field.name] = (moment(Date.parse(getValueField(obj,field.name)))).format('YYYY-MM-DDTHH:mm');
        } else if (field.inputtype && field.inputtype == "number") {
          let value = getValueField(obj,field.name);
          if (value) {
            try{
              let vf = value.toFixed(2) ;
            valueformAux[field.name] = vf;
            } catch (e){
            valueformAux[field.name] = value;
            } 
          } else {
            valueformAux[field.name] = value;
          }
            
          
        } else{
            valueformAux[field.name] = getValueField(obj,field.name);
        }
      }

    }


    setState(currentState => ({...currentState, ["valueform"]:valueformAux}));

  }


  const getValueField = (object,field) => {
    if(object){
      if (field.includes(".")){
        const parts = field.split(".");
        var objfield = object[parts[0]];
        if(Array.isArray(objfield)){
          var result = Array();
          for (let entry of objfield) {
            result.push(getValueField(entry,field.substring(parts[0].length +1)));
          }
          return result;
        } else {
          return getValueField(objfield,field.substring(parts[0].length +1));
        }
      } else {
          return object[field];
      }
    } else {
      return '';
    }
  };

  const setValueField = (object,field,value) => {
    if(object){
      if (field.includes(".")){
        const parts = field.split(".");
        var objfield = object[parts[0]];
        object[parts[0]] = setValueField(objfield,field.substring(parts[0].length +1),value);
        return object;
      } else {
        object[field] = value;
        return object;
      }
    } else {
      return object;
    }
  };

  const handleChange = function(event){
      let name = event.target.name;
      var value = event.target.value;

      if (chnageCustom[name]){
        value = chnageCustom[name](value);
      }

      if(setValueAux[name]){
        setValueAux[name](setValueField,objectform,value,valueform,setState)
      } else {

        let valueformAux = {...valueform};
        valueformAux[name] = value;

        var field = null;
        for(let f of fields){
          if(f.name == name){
            field = f;
          }
        }
        var newobject = null;
        if(field && field['inputtype'] && field['inputtype'] == "date" && value && value != ""){
            var aux = (moment(Date.parse(value))).utc().format('YYYY-MM-DD')+"T00:00:00Z";
            newobject = setValueField(objectform,name,aux);
        } else if(field && field['inputtype'] && field['inputtype'] == "datetime-local" && value && value != ""){
            var aux = (moment(Date.parse(value))).utc().format("YYYY-MM-DDTHH:mm")+":00Z";
            newobject = setValueField(objectform,name,aux);
        } else {
            newobject = setValueField(objectform,name,value);
        }
        


        var update = {objectform: newobject, valueform: valueformAux,forceupdatetable:!forceupdatetable};
        setState(currentState => ({...currentState, ...update}));
      }
      

  }

  const onChangeEditor = function(content,name){

      let valueformAux = {...valueform};
      valueformAux[name] = content;
      var newobject = setValueField(objectform,name,content);

      var update = {objectform: newobject, valueform: valueformAux};
      setState(currentState => ({...currentState, ...update}));
  }


  const handleChangeCheckBox = function(event){
      let name = event.target.name;
      let value =  event.target.checked;

      let valueformAux = {...valueform};
      valueformAux[name] = value;
      var newobject = setValueField(objectform,name,value);

      var update = {objectform: newobject, valueform: valueformAux};
      setState(currentState => ({...currentState, ...update}));
  }

  const handleChangeAutocomplete = function(name,value){
      
      let valueformAux = {...valueform};
      valueformAux[name] = value;

      
      if(setValueAux[name]) {
        var newobject =  setValueAux[name](setValueField,objectform,value,valueform,setState)
        
        if (typeof newobject?.then === 'function') {
           newobject.then((data) => {

              if(handlers[name]){
                handlers[name](value);
              }
      
              if(relationfield[name] && servicerelation[name] && value){
                updateRelationCombo(relationfield[name],servicerelation[name],value,name)
              }
      
              clearOtherFieldsRel(name)
           });
        } else {
          
          setState(currentState => ({...currentState, ["objectform"]:newobject}));

          if(handlers[name]){
            handlers[name](value);
          }
  
          if(relationfield[name] && servicerelation[name] && value){
            updateRelationCombo(relationfield[name],servicerelation[name],value,name)
          }
  
          clearOtherFieldsRel(name)
        }

        


      } else if (value && (value.key || value.key === 0)){
        var newobject = setValueField(objectform,name,value.key);
        var update = {
          objectform: newobject,
          valueform: valueformAux,
        }
        setState(currentState => ({...currentState, ...update}));
        if(handlers[name]){
          handlers[name](value);
        }

        if(relationfield[name] && servicerelation[name]){
          updateRelationCombo(relationfield[name],servicerelation[name],value.key,name)
        }
        clearOtherFieldsRel(name)
      } else if(Array.isArray(value) && value[0]){

        const valuesSet = Array();
        for (let index = 0; index < value.length; index++) {
          const element = value[index];
          if(element.key) {
              valuesSet.push({id:element.key, name:element.name})
          } else {
              valuesSet.push({id:element.id, name:element.name})
          }
        }
        var newobject = setValueField(objectform,name,valuesSet);
        setState(currentState => ({...currentState, ["objectform"]:newobject}));
        clearOtherFieldsRel(name)
      } else {
        var newobject = setValueField(objectform,name,"");
        setState(currentState => ({...currentState, ["objectform"]:newobject}));
        if(handlers[name]){
          handlers[name](1234566879702);
        }
        clearOtherFieldsRel(name)
      }


  }


    const handleChangeAutocompleteLocation = function(name,value,field){


        let valueformAux = {...valueform};
        valueformAux[name] = value;
        let newobject = {...objectform};

        if (value && value.street) {
          valueformAux[name]=value;
          valueformAux[field.street]=value.stree;
          valueformAux[field.postalcode]=value.postalCode;
          valueformAux[field.country]=value.country;
          valueformAux[field.latitude]=value.lat;
          valueformAux[field.longitude]=value.lon;
          valueformAux[field.city]=value.location;
          valueformAux[field.number]=value.number;

          newobject = setValueField(newobject,field.street,value.street);
          newobject = setValueField(newobject,field.postalcode,value.postalCode);
          newobject = setValueField(newobject,field.country,value.country);
          newobject = setValueField(newobject,field.latitude,value.lat);
          newobject = setValueField(newobject,field.longitude,value.lon);
          newobject = setValueField(newobject,field.city,value.location);
          newobject = setValueField(newobject,field.number,value.number);

        } else {
          valueformAux[name]=value;
          valueformAux[field.street]="";
          valueformAux[field.postalcode]="";
          valueformAux[field.country]="";
          valueformAux[field.latitude]="";
          valueformAux[field.longitude]="";
          valueformAux[field.city]="";
          valueformAux[field.number]="";

          newobject = setValueField(newobject,field.street,"");
          newobject = setValueField(newobject,field.postalcode,"");
          newobject = setValueField(newobject,field.country,"");
          newobject = setValueField(newobject,field.latitude,null);
          newobject = setValueField(newobject,field.longitude,null);
          newobject = setValueField(newobject,field.city,"");
          newobject = setValueField(newobject,field.number,"");

        }


        var update = {objectform: newobject, valueform: valueformAux};
        setState(currentState => ({...currentState, ...update}));

    }

  const clearOtherFieldsRel = function(name){
    var clearFiels = clear[name];

    let valueformAux = {...valueform};
    let newobject = {...objectform};

    if (clearFiels && Array.isArray(clearFiels) && clearFiels.length){
      var newValues = {}
      clearFiels.map(n => {
          var isMultiple = false;
          fields.map(f => {
              if (f.name == n && f.multiple){
                isMultiple = true
              }
          });
          if (!isMultiple) {
              newValues[n] = "";
              newobject = setValueField(newobject,n,"");
          } else {
              newValues[n] = [];
               newobject = setValueField(newobject,n,[]);
          }
      });
      valueformAux = {...valueformAux, ...newValues}

      var update = {objectform: newobject, valueform: valueformAux};
      setState(currentState => ({...currentState, ...update}));
      
    }
  }

  const updateRelationCombo = function(field,service,key,name){

    if(relationSearchAux[name]) {
      relationSearchAux[name](setState,objectform)
    } else {
      service(key,(data, error) => {
        if(data && data.data){

          let optionsAux = {...options};
          optionsAux[field]= data.data;

          setState(currentState => ({...currentState, ["options"]:optionsAux}));
        }
      });
    }

  }

  const _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
    }
  }

  const renderTitle = function(field){
      return <Typography> <label style={{fontSize:'1.3em',fontWeight:'bold'}}>{t(i18n+".field."+field.name)}</label>  </Typography>
  }

  const renderInput = function(field){
      return <TextField variant="standard" sx={{width: "100%"}} id={field.name} name={field.name} label={t(i18n+".field."+field.name)} value={valueform[field.name]} onFocus={(evt) => {if(field.allselect) {evt.target.select();}}} onKeyDown={_handleKeyDown} onChange={handleChange} type={field.inputtype?field.inputtype:"text"}  inputProps={{step:field.step?field.step:"",  autoComplete: 'off', readOnly:field.readonly || onlyRead || (field.conditionalread && field.conditionalread(objectform))?true:false }}  InputLabelProps={{shrink: field.inputtype && (field.inputtype == 'date' || field.inputtype == 'datetime-local' || field.inputtype == 'number'||field.inputtype == 'color'|| field.inputtype == 'email')}} autoComplete="off" autoFocus={field.autoFocus?true:false}/>
  }

  const renderCheckBox = function(field){
      return  <FormControlLabel  control={ <Checkbox  checked={valueform[field.name]}   onChange={handleChangeCheckBox} name={field.name} color="primary" /> } label={t(i18n+".field."+field.name)} disabled={field.readonly || onlyRead|| (field.conditionalread && field.conditionalread(objectform))}/>
  }


  const getLabelOption = function (option){
      if(option && option.name){
        if (typeof option.name === 'string'){
          if(option.code) {
            return option.code + " - " + option.name;
          } else {
            return option.name
          }
        } else {
          return ""
        }
      } else if (option && option.label && typeof option.label === 'string'){
        return option.label
      } else {
        return "";
      }
  }

  const searchCode = (field,e) => {
    if(field.searchcode){
      if(e.nativeEvent.key == "Enter" || e.nativeEvent.key == "Tab"){
          e.preventDefault();
          debugger;
          var code =  e.target.value;
          var optionsfield = options[field.name];
          for (const key in optionsfield) {
            if (Object.prototype.hasOwnProperty.call(optionsfield, key)) {
              const element = optionsfield[key];
              if(element.code && element.code == code){
                handleChangeAutocomplete(field.name,element);
                if(field.nextFocus){
                  if(field.nextFocus == "lines") {
                    document.getElementById(field.nextFocus+"button")?.focus();
                  } else {
                    document.getElementById(field.nextFocus)?.focus();
                    (document.getElementById(field.nextFocus) as HTMLInputElement).select();
                  }
                  
                }
                break;
              }
            }
          }
      }
    }
  }

  const autovalueIsOptiuonEqualTo = (option, value) => {
    if (option && option.key && value && value.key ){
      return option.key === value.key; 
    } else  if (option && option.id && value && value.id ){
      return option.id === value.id; 
    } else {
      return false;
    }
  }
  

  const autocompleteAsync = (evt,field) => {
      var value = evt.target.value;
      if(value.length >= 2 && field.servicetosearch) {
          console.log("Buscaria con " + value);
          var id = field.servicetosearchfieldid ? getValueField(objectform,field.servicetosearchfieldid):null;
          field.servicetosearch(value, id, (data,error) => {
              if( data && data.data) {

                let optionsAux = {...options};
                optionsAux[field.name]= data.data;

                setState(currentState => ({...currentState, ["options"]:optionsAux}));
              }
          });

          
      }
  }

  const updateOpenAutocomplete = (name,value) => {
    let aux = {...openautocomplete};
    aux[name] = value;
    setState(currentState => ({...currentState, ["openautocomplete"]:aux}));
  }

  const renderAutocomplete = function(field){
    if (field.multiple){
      return <Autocomplete
            multiple
            freeSolo={field.freeSolo ? true: false}
            readOnly={field.readonly || onlyRead|| (field.conditionalread && field.conditionalread(objectform))}
            id={field.name}
            open={openautocomplete[field.name]}
            onOpen={ () => !field.readonly && !onlyRead && updateOpenAutocomplete(field.name,true)}
            onClose={ () => updateOpenAutocomplete(field.name,false) }
            isOptionEqualToValue={autovalueIsOptiuonEqualTo}
            options={options[field.name]}
            getOptionLabel={getLabelOption}
            value={objectform[field.name]}
            onChange={(event,value) => handleChangeAutocomplete(field.name,value)}
            disableClearable={ field.readonly || onlyRead?true:false }
            renderInput={(params) => (<TextField {...params} variant="standard" label={t(i18n+".field."+field.name)} onKeyDown={(evt) => searchCode(field,evt)} onChange={(evt) => autocompleteAsync(evt,field)} inputProps={{ ...params.inputProps, autoComplete: 'off' , readOnly:field.readonly || onlyRead || (field.conditionalread && field.conditionalread(objectform))? true:false, }} autoComplete="off" autoFocus={field.autoFocus?true:false} />)}
          />
    } else {
      return <Autocomplete
            id={field.name}
            freeSolo={field.freeSolo ? true: false}
            readOnly={field.readonly || onlyRead || (field.conditionalread && field.conditionalread(objectform))}
            options={options[field.name]}
            open={openautocomplete[field.name]}
            isOptionEqualToValue={autovalueIsOptiuonEqualTo}
            onOpen={ () => !field.readonly && !onlyRead && updateOpenAutocomplete(field.name,true)}
            onClose={ () => updateOpenAutocomplete(field.name,false) }
            getOptionLabel={getLabelOption}
            value={valueform[field.name]}
            onChange={(event,value) => handleChangeAutocomplete(field.name,value)}
            disableClearable={ field.readonly || onlyRead?true:false }
            renderInput={(params) => <TextField {...params} variant="standard" label={t(i18n+".field."+field.name)} onKeyDown={(evt) => searchCode(field,evt)}  onChange={(evt) => autocompleteAsync(evt,field)} inputProps={{ readOnly:(field.readonly || onlyRead || (field.conditionalread && field.conditionalread(objectform))?true:false), ...params.inputProps,  autoComplete: 'off' }} autoComplete="off" autoFocus={field.autoFocus?true:false}/>}
          />
    }

  }

  


  const getLabelOptionLocation = function (option){
      if(option && option.display_name){
        if (typeof option.display_name === 'string'){
          return option.display_name
        } else {
          return ""
        }
      } else if (option && option.street && typeof option.street === 'string'){
        return option.street
      } else {
        return "";
      }
  }


  const renderLocation = function(field){
      return <Autocomplete
            id={field.name}
            options={options[field.name]}
            getOptionLabel={getLabelOptionLocation}
            value={valueform[field.name]}
            open={open}
            onOpen={() => {
              if(!onlyRead){
                setState(currentState => ({...currentState, ["open"]:true}));
              }
            }}
            onClose={() => {
              setState(currentState => ({...currentState, ["false"]:true}));
            }}
            isOptionEqualToValue={(option, value) => (option && option.key && value && value.key && option.key == value.key) || (option && option.id && value && value.id && option.id == value.id)}
            onChange={(event,value) => handleChangeAutocompleteLocation(field.name,value,field)}
            filterOptions={(options, state) => options}
            loading={loading}
            renderInput={(params) => <TextField variant="standard" {...params}
                  onChange={ev => {
                     if (ev.target.value !== "" || ev.target.value !== null) {
                      /* if (ev.target.value.length > 2) {
                         locationService.search(ev.target.value, (data, error) => {
                             console.log("Quiere cargar options " + field.name);
                             console.log(data);
                             if(data){
                               setOptions(currentOptions => ({...currentOptions, [field.name]: data }));
                             }
                         });

                       }*/
                     }
             }}
            label={t(i18n+".field."+field.name)}  inputProps={{ ...params.inputProps, autoComplete: 'new-password'  , readOnly:field.readonly || onlyRead ?true:false, endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment> )}} />}
          />

  }


  const onChangeImage = function(event,field, path = '/image'){
    uploadService.upload(event.target.files[0],(data, error) => {
        if( data && data.data != null && data.data != "" ){
            

            let valueformAux = {...valueform};
            let newobject = {...objectform};

            newobject = setValueField(newobject,field,"/"+data.data);
            const url = process.env.REACT_APP_HOST+path;
            valueformAux[field] = url + "/"+data.data;


            var update = {objectform: newobject, valueform: valueformAux};
            setState(currentState => ({...currentState, ...update}));
        }
    })
  }

  const onChangeImageGallery = function(event,field){
    if(event.target.files.length > 0){
      for(var file of event.target.files){
          uploadService.upload(file,(data, error) => {
            if( data && data.data != null && data.data != "" ){

              let valueformAux = {...valueform};
              let newobject = {...objectform};

              const valuesSet = Array();
              var value = getValueField(objectform,field.name);
              for (let index = 0; index < value.length; index++) {
                const element = value[index];
                valuesSet.push(element)
              }
              const element = {};
              element[field.imagekey] = "/"+data.data;
              valuesSet.push(element);
               newobject = setValueField(newobject,field.name,valuesSet);
              valueformAux[field.name] = valuesSet;

              var update = {objectform: newobject, valueform: valueformAux};
              setState(currentState => ({...currentState, ...update}));
            }
        })
      }
    }
    
  }

  const onChangeImageDelete  = function(event,field){
      let valueformAux = {...valueform};
      let newobject = {...objectform};

      newobject = setValueField(newobject,field,null);
      valueformAux[field] = null;


      var update = {objectform: newobject, valueform: valueformAux};
      setState(currentState => ({...currentState, ...update}));
  }
  const onChangeImageDeleteGallery = function(event,field, valuedel){

    let valueformAux = {...valueform};
    let newobject = {...objectform};

    const valuesSet = Array();
    var value = getValueField(objectform,field.name);
    for (let index = 0; index < value.length; index++) {
      const element = value[index];
      if(element[field.imagekey] != valuedel){
        valuesSet.push(element)
      }
    }
    newobject = setValueField(newobject,field.name,valuesSet);
    valueformAux[field.name] = valuesSet;

    var update = {objectform: newobject, valueform: valueformAux};
    setState(currentState => ({...currentState, ...update}));
}

  const renderInputImage = function(field){

    const NormalButton = (props) => ( <button {...props} type='button' />);

      return <Grid container >
        <Grid item sm={12} xs={12}>
           <PanelImageWrapper>
              <PanelImage style={{backgroundImage:'url("'+(valueform[field.name]?valueform[field.name]:"/defaultplaceholder.png")+'")'}}></PanelImage>
              {!onlyRead &&  <ButtonImage type="button" onClick={(evt) => { evt.preventDefault();   refImageArray.current[field.name]?.click(); return false;}}> <label>{valueform[field.name]?t('general.changeimage'):t('general.uploadimage')}</label>  </ButtonImage>}
              <input ref={ref => {refImageArray.current[field.name] = ref}} type="file" hidden onChange={(event) => onChangeImage(event,field.name)}/>
           </PanelImageWrapper>
        </Grid>
        {valueform[field.name] && <Grid item sm={12} xs={12} style={{display:"flex",alignContent:"center", justifyContent:"center", marginTop:"2em"}}>
          {!onlyRead && <Button variant="contained" component={NormalButton} onClick={(event) => onChangeImageDelete(event,field.name)} style={{background:"pink",color:"white"}}> {t('general.deleteimage')} </Button> }
        </Grid>}
      </Grid>
  }

  const renderInputGallery = function(field){

    const NormalButton = (props) => ( <button {...props} type='button' />);

    return <Grid container >
      <Grid item md={2} sm={3} xs={6}>
         <PanelImageWrapper>
            <PanelImage style={{backgroundImage:'url("/defaultplaceholder.png")'}}></PanelImage>
            <ButtonImage type="button" onClick={(evt) => { evt.preventDefault();   refImageArray.current[field.name]?.click(); return false;}}> <label>{t('general.changeimage')}</label>  </ButtonImage>
            <input ref={ref => {refImageArray.current[field.name] = ref}} type="file" hidden onChange={(event) => onChangeImageGallery(event,field)} multiple/>
         </PanelImageWrapper>
      </Grid>
      {valueform[field.name] && valueform[field.name].map((image) => (
              <Grid item md={2} sm={3} xs={6}>
                <Grid container >
                  <Grid item sm={12} xs={12}>
                    <PanelImageWrapper style={{padding: "10px"}}>
                        <PanelImage style={{backgroundImage:'url("'+process.env.REACT_APP_HOST+'/image'+image[field.imagekey]+'")'}}></PanelImage>
                    </PanelImageWrapper>
                  </Grid>
                 <Grid item sm={12} xs={12} style={{display:"flex",alignContent:"center", justifyContent:"center", marginTop:"2em"}}>
                    <Button variant="contained" component={NormalButton} onClick={(event) => onChangeImageDeleteGallery(event,field,image[field.imagekey])} style={{background:"pink",color:"white"}}> {t('general.deleteimage')} </Button>
                </Grid>
                </Grid>
              </Grid>
      ))}
    </Grid>
}

  const renderInputVideo = function(field){

    const NormalButton = (props) => ( <button {...props} type='button' />);

    return <Grid container >
      <Grid item sm={12} xs={12}>
         <PanelImageWrapper>
            {!valueform[field.name] && <PanelImage style={{backgroundImage:'url("/videodefault.png")'}}> </PanelImage> }
            {valueform[field.name] && <video src={valueform[field.name]} controls style={{width:"100%"}}></video>}
            {!valueform[field.name] && !onlyRead &&<ButtonImage type="button" onClick={(evt) => { evt.preventDefault();  refImageArray.current[field.name]?.click(); return false;}}> <label>{valueform[field.name]?t('general.changevideo'):t('general.uploadvideo')}</label>  </ButtonImage>}
            <input ref={ref => {refImageArray.current[field.name] = ref}} type="file" hidden onChange={(event) => onChangeImage(event,field.name)}/>
         </PanelImageWrapper>
      </Grid>
      {valueform[field.name] && <Grid item sm={12} xs={12} style={{display:"flex",alignContent:"center", justifyContent:"center", marginTop:"2em"}}>
       {!onlyRead && <Button variant="contained" component={NormalButton} onClick={(event) => onChangeImageDelete(event,field.name)} style={{background:"pink",color:"white"}}> {t('general.deletevideo')} </Button>}
      </Grid>}
    </Grid>
}

const downloadFile = (url) => {

}

const renderInputFile = function(field){

  const NormalButton = (props) => ( <button {...props} type='button' />);

  

  return <Grid container >
    <Grid item sm={12} xs={12}>
          {valueform[field.name] && <><PictureAsPdfIcon style={{color: 'bisque', fontSize: '5em', marginRight: "0.1em"}} /><span>{objectform[field.name].substring(1)}</span></>}<br/>
          {!onlyRead && !valueform[field.name] &&<Button component={NormalButton} variant="outlined" onClick={(evt) => { evt.preventDefault();  refImageArray.current[field.name]?.click(); return false;}}> <label>{valueform[field.name]?t('general.changefile'):t('general.uploadfile')}</label>  </Button>}
          <input ref={ref => {refImageArray.current[field.name] = ref}} type="file" hidden onChange={(event) => onChangeImage(event,field.name,'/file')}/>
    </Grid>
    {valueform[field.name] && <Grid item sm={12} xs={12} style={{display:"flex",alignContent:"left", justifyContent:"left", marginTop:"2em"}}>
     {!onlyRead && <><Button variant="contained" component={NormalButton} onClick={(event) => onChangeImageDelete(event,field.name)} style={{background:"pink",color:"white"}}> {t('general.deletefile')} </Button>
     <Button variant="contained" component={NormalButton} onClick={(event) =>  { var win = window.open(valueform[field.name], '_blank'); if (win) {win.focus()}}} style={{background:"green",color:"white", marginLeft: "10px"}}> {t('general.filedownload')} </Button></>}
    </Grid>}
  </Grid>
}


  const renderTextarea = function(field){
    return <Grid container >
        <Grid item sm={12} xs={12} style={{textAlign:"left"}}>
            <label style={{fontSize:"0.8em",color:"rgba(0, 0, 0, 0.6)"}}>{t(i18n+".field."+field.name)}</label>
        </Grid>
        <Grid item sm={12} xs={12}>
          <TextareaAutosize id={field.name} name={field.name}  value={valueform[field.name]} onChange={handleChange}  minRows={4} autoComplete="nope" style={{width:"100%"}} readOnly={field.readonly || onlyRead || (field.conditionalread && field.conditionalread(objectform))?true:false}/>
        </Grid>
      </Grid>
  }

  const renderEditor = function(field){
    return <Grid container >
        <Grid item sm={12} xs={12} style={{textAlign:"left"}}>
            <label style={{fontSize:"0.8em",color:"rgba(0, 0, 0, 0.6)"}}>{t(i18n+".field."+field.name)}</label>
        </Grid>
        <Grid item sm={12} xs={12}>
        <Editor
           apiKey="vnodym0tt7bwz6fhfd2gdlhstcj2esz3atkwxpkk4k7tsjdm"
           value={valueform[field.name]}
           init={{
             height: 300,
             menubar: false,
             plugins: [
               'advlist autolink lists link image charmap print preview anchor',
               'searchreplace visualblocks code fullscreen',
               'insertdatetime media table paste code help wordcount'
             ],
             toolbar: 'undo redo | formatselect | ' +
             'bold italic backcolor | alignleft aligncenter ' +
             'alignright alignjustify | bullist numlist outdent indent | ' +
             'removeformat | help | image | media' ,
             images_upload_handler: getPromiseImage,
             content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
           }}
           onEditorChange={(content) => onChangeEditor(content,field.name)}
         />
        </Grid>
      </Grid>
  }

  async function getPromiseImage(	blobInfo,failure): Promise<string> {
    let imageFile = new FormData();
    imageFile.append("files[]", blobInfo.blob());
    var urlimg = "";
    await uploadService.uploadAsync(blobInfo.blob(),(data, error) => {
        if( data && data.data != null && data.data != "" ){
           urlimg = process.env.REACT_APP_HOST+'/image/'+data.data ;
        } 
        
    })

    return urlimg;

  }

  const updateTable = function(name,rows){
        let newobject = {...objectform}
        
        newobject = setValueField(newobject,name,rows);

        if(setValueAux[name]){
          
            newobject = setValueAux[name](setValueField,newobject,rows, setState, valueform)
        }


        setState(currentState => ({...currentState, ["objectform"]:newobject}));
  }


  const renderTable = function(field){
    return  <FormTable
         rows={ getValueField(objectform,field.name)}
         initrow={field.initrow}
         fields={field.fields}
         headers={field.headers}
         fieldId={field.fieldId}
         fieldName={field.name}
         labelbtn={field.labelbtn}
         conditionColor={field.conditionColor?field.conditionColor:null}
         onlyread={field.onlyread || onlyRead  || (field.conditionalread && field.conditionalread(objectform))?true:false}
         parent={objectform}
         updateRows={updateTable}
         removebuttonewline={field.removebuttonewline?true:false}
         forceupdatetable={forceupdatetable} />
  }

  const renderField = function(field){
      switch (field.type) {
        case 'input':
          return renderInput(field);
        case 'textarea':
          return renderTextarea(field);
        case 'autocomplete':
          return renderAutocomplete(field);
        case 'image':
          return renderInputImage(field);
          case 'video':
            return renderInputVideo(field);
        case 'checkbox':
            return renderCheckBox(field);
        case 'editor':
            return renderEditor(field);
        case 'title':
          return renderTitle(field);
        case 'location':
          return renderLocation(field);
        case 'table':
          return renderTable(field);
        case 'gallery':
          return renderInputGallery(field);
        case 'custom':
          return field.custom(field,valueform,objectform,setState,setValueField);
        case 'file':
          return renderInputFile(field);
        default:
          return renderInput(field);
      }
  }

  const loadCombos = function(options){
    
    for (let field of fields) {
      if(field.type == 'autocomplete' && field.service) {
         if(options[field.name].length == 0 && !comboloads.includes(field.name)){
            comboloads.push(field.name);
            field.service((data, error) => {
              if(data && data.data){
                
                var aux = {...options};
                aux[field.name]= data.data ;
                
                setState(currentState => ({...currentState, ["options"]:aux}));
              }
            });
            break;
         }
         
      } else if(field.type == 'autocomplete' && field.servicefunc) {
          field.servicefunc(field,setState);
      }
    }
  }

  useEffect(() => {
    comboloads = [];
    return () => {


     setState({
        objectform: {},
        open:false, 
        fields:[],
        labelsave: 'Guardar',
        labelcancel: 'Cancelar',
        isHiddenSave: false,
        isHiddenCancel: false,
        cleanInOK:false,
        paintInitValue: [],
        onlyRead:false,
        forceupdatetable: false,
        notCleanOnCancel: false,
        openautocomplete: [],
        setValueAux: [],
        relationSearchAux: [],
        valueform:[],
        options: [],
        clear:[],
        handlers:[],
        servicerelation:[],
        relationfield:[],
        chnageCustom:[],
        inline: false
      });
    }

  }, []);





  useEffect(() => {
    loadCombos(options);
  }, [options]);

  

  useEffect(() => {

    

    setState(currentState => ({...currentState, ["objectform"]:props.objectform}));
    initValues(props.objectform);
    for(var field of fields){
      if (field.type == 'autocomplete' && field.relationfield){
        var v = getValueField(props.objectform,field.name);
        if (v){
          updateRelationCombo(field.relationfield,field.servicerelation,v,field.name)
        }
      }
    }

  }, [props.objectform]);



  useEffect(() => {

    if(props.forcesave){
      handleFilter();
    }
  }, [props.forcesave]);


  useEffect(() => {

    setState(currentState => ({...currentState, ["fields"]:props.fields}));
    

  }, [props.fields]);

  const onSumbit = (evt) => {
    evt.preventDefault();
    handleFilter();
    return false;
  }

  const getObject = () => {
    return objectform;
  }

  useEffect(() => {

    setState(currentState => ({...currentState, ["onlyRead"]:props.onlyRead}));
   }, [props.onlyRead]);

   
   
  const renderAccordio = (field) => {
    return <Accordion sx={{borderRadius:"4px !important", border:"1px solid rgba(0, 0, 0, 0.42) !important",boxShadow:"none !important", "& .MuiAccordionSummary-root": {border:"none !important"}}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography> {t(i18n+".field."+field.name)} </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {renderField(field)}
        </AccordionDetails>
      </Accordion>
  } 
  return (
    <div>
      <form method='post' onSubmit={onSumbit} autoComplete="off">
          <Grid container  columnSpacing={3}>
            {fields.map((field) => (
              <Grid item md={field.colmd?field.colmd:(field.col?field.col:3)} sm={field.col?field.col:3} xs={12} sx={{marginTop: "10px"}}>
                {field.accordion ? renderAccordio(field):renderField(field)}
              </Grid>
          ))}
          { inline && (!isHiddenCancel || !isHiddenSave || props.haveMoreActions) &&
            <Grid item md={3} sm={3} xs={12} sx={{marginTop: "10px"}}>
                {props.haveMoreActions && props.moreActions(getObject, setValueField)}
                {!isHiddenCancel? <Button variant="outlined" color="primary" style={{marginLeft:'30px'}} onClick={handleClean}> {labelcancel} </Button> : ''}
                {!isHiddenSave && !onlyRead ? <Button variant="contained" color="primary" style={{marginLeft:'30px'}} type="submit"> {labelsave} </Button> : ''}
            </Grid>
          }
          </Grid>
          { !inline && (!isHiddenCancel || !isHiddenSave || props.haveMoreActions) && 
            <Grid container>
              <Grid item sm={12} style={{textAlign:'right', marginTop: '50px'}}>
                {props.haveMoreActions && props.moreActions(getObject, setValueField)}
                {!isHiddenCancel? <Button variant="outlined" color="primary" style={{marginLeft:'30px'}} onClick={handleClean}> {labelcancel} </Button> : ''}
                {!isHiddenSave && !onlyRead ? <Button variant="contained" color="primary" style={{marginLeft:'30px'}} type="submit"> {labelsave} </Button> : ''}

              </Grid>
          </Grid>}
      </form>
    </div>
  );
}



const ButtonImage = styled.button `
    position: absolute;
    width:100%;
    color:white;
    background:rgba(0,0,0,0.6);
    display: flex;
    justify-content:center;
    height: 100%;
    justify-content: center;
    text-align: center;
    top: 0;
    left: 0;
    text-align: center;
    align-items: center;
    border: none;
    font-weight: bold;
    cursor: pointer;
    visibility: visible;
    opacity:0;
    transition: opacity 0.3s linear;
    &:hover {
      visibility: visible;
      opacity:1;
    }
`;

const PanelImage = styled.div `
    width:100%;
    height:0;
    padding-bottom:100%;
    background-repeat:no-repeat;
    background-size:100% auto;
    background-position:center;
    position: relative;
    
`;
const PanelImageWrapper = styled.div `
    position: relative;
    
`;
