import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../../crud/CrudForm"
import {taxtypeService as service } from "../../../../services"
import { useTranslation } from 'react-i18next';


  const initElement =  {
    name : null,
};


export default function FormTaxType() {
  const { t, i18n } = useTranslation();


  const estructureform =  [
      {
        type:"input",
        name: "name",
        col: 4
       },
       {
         type:"input",
         name: "percent",
         inputtype: "number",
         col: 4
        },
   ];


  const recoveryElement = function(objectedit,element){


    objectedit['name']=element.name?element.name:"";
    objectedit['percent']=element.percent?element.percent:0.0;

    return objectedit;
  }



  const isValid = function(object){
      if(!object['name']){
        return t("taxtype.error.name");
      }
      if(!object['percent']){
        return t("taxtype.error.percent");
      }

      return null;
  }



  return (
    <CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        i18n={"taxtype"}
        urledit={"/taxtype/edit/"}
        urlCancel={"/taxtype"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />

  );
}
