import CrudTable from "../../../crud/CrudTable"
import {comercialService as service} from "../../../../services"

const initFilter =  {
  "code":null,
  "nif":null,
  "name":null,
  "lastname":null,
  "email":null,
  "phone":null,
};


export default function Country() {

  const createEstructureFilter =  [
    {
      type:"input",
      name: "code",
      col: 4,
     },
     {
       type:"input",
       name: "nif",
       col: 4,
      },
      {
        type:"input",
        name: "name",
        col: 4,
      },
      {
        type:"input",
        name: "lastname",
        col: 4,
      },
      {
        type:"input",
        name: "email",
        col: 4,
        inpputtype:"email"
      },
      {
        type:"input",
        name: "phone",
        col: 4,
      }
  ];


  return (
    <CrudTable
          service={service}
          urledit={"/comercial/edit/"}
          urlnew={"/comercial/new/"}
          filter={{...initFilter}}
          i18n={"comercial"}
          fieldstable={["code","nif","name","phone"]}
          order={true}
          orderColumns={{"code":"code", "nif":"nif", "phone":"phone", "name":"name"}}
          estructureForm={createEstructureFilter} />
  );
}

