import React, { useState, useEffect }  from 'react';
import CrudTable from "../../../crud/CrudTable"
import {remesaService as service, clientService} from "../../../../services"
import { useTranslation } from 'react-i18next';
import PrintIcon from '@mui/icons-material/Print'
import { Button } from "@mui/material";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';


const moment = require("moment");

const initFilter =  {
  number:null,
  serie : null,
  client:null,
  from:moment().format("YYYY-MM-DD")+"T00:00:00Z",
  to:null,
};



export default function Remesa() {
  const { t, i18n } = useTranslation();

 
  const createEstructureFilter =  [
    
    {
      type:"autocomplete",
      name: "serie",
      options:[{key:"",name:"TODAS"},{key:"S1",name:"S1"},{key:"S2",name:"S2"},{key:"R",name:"R"}],
      col: 3
     },
    {
      type:"input",
      name: "number",
      inputtype:"number",
      autoFocus:true,
      col: 3
     },
     {
       type:"autocomplete",
       name: "client",
       service: clientService.combo,
       col: 3
      },
       {
        type:"input",
        name: "from",
        inputtype:"date",
        col: 3
       },
      {
        type:"input",
        name: "to",
        inputtype:"date",
        col: 3
      }
  ];

  const paintCell = {
    bills: (value,object) => {
      if (value) {
        return <>{value.length}</>
      } else {
        return <></>
      }
    },
  }

  const handlePrint = (remesa) => {

    var date = moment(remesa.date);

    let nameFile = "remesa"+date.year()+(date.month()+1)+date.date()+".xls";
    service.excel(remesa.id, nameFile,(data, error) => {
    });
  }

  const editactionButton = function(row, handlerDobleClick){
      return <Button variant="contained" color="primary" style={{backgroundColor: "green", color:"white"}} onClick={() => handlerDobleClick(row["id"])}> <RemoveRedEyeIcon /> </Button>
  }



  return (
    <CrudTable
          service={service}
          urledit={"/remesa/view/"}
          urlnew={"/remesa/new/"}
          filter={initFilter}
          i18n={"remesa"}
          editable={true}
          fieldstable={["id","date","bills"]}
          labels={{"bills":t('remesa.field.billsnum')}}
          getEditButton={editactionButton}
          moreActions={[{"handle":handlePrint, icon:<PrintIcon />}]}
          paintCell={paintCell}
          expendedFilter={true}
          estructureForm={createEstructureFilter} />
  );
}
