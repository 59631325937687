import CrudTable from "../../../crud/CrudTable"
import {categoryService as service, ivatypeService, taxtypeService} from "../../../../services"
import { useTranslation } from 'react-i18next';

const initFilter =  {
    name : null,
    ivadefault:null,
    tasaDefault:null,
    category:null
};


export default function Category() {
  const { t, i18n } = useTranslation();

  const createEstructureFilter =  [
      {
        type:"input",
        name: "name",
        col: 4
        },
       {
         type:"autocomplete",
         name: "category",
         service:service.combo,
         col: 4
       },
       {
         type:"autocomplete",
         name: "ivadefault",
         service:ivatypeService.combo,
         col: 4
         },
         {
           type:"autocomplete",
           name: "tasaDefault",
           service:taxtypeService.combo,
           col: 4
         },
  ];


  return (
    <CrudTable
          service={service}
          urledit={"/categories/edit/"}
          urlnew={"/categories/new/"}
          filter={initFilter}
          i18n={"categories"}
          fieldstable={["imageprin","name","category.name","ivadefault.percent", "tasaDefault.percent","maxDiscount"]}
          estructureForm={createEstructureFilter} />
  );


}