import BaseService from './_base.service';

const URL = "/admin/billsupplier";

export const billsupplierService = {
    search,
    combo,
    comboState,
    page,
    pageFilter,
    one,
    create,
    edit,
    remove,
    removeLogic,
    deliverynote,
    deliverynotes,
    report,
    reportExcel,
    allFilterPdf,
    allFilterExcel,
    allFilterWord,
    reportpost,
    reportExcelpost
};

function search(offset,callback) {
    return BaseService.get(URL+"/all/", callback)
}

function combo(callback) {
    return BaseService.get(URL+"/combo/", callback, true)
}
function comboState(id,callback) {
    return BaseService.get(URL+"/combo/state/"+id, callback, true)
}
function page(page,size,callback) {
    return BaseService.get(URL+"/page/"+page+"/"+size, callback, true)
}

function pageFilter(page,size,filter,callback) {
    return BaseService.post(URL+"/page/"+page+"/"+size, filter, callback, true)
}

function one(id,callback) {
    return BaseService.get(URL + "/one/"+id, callback);
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post(URL, body, callback, true)
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put(URL+"/"+id, body, callback, true)
}

function remove(id,callback) {
    return BaseService.delete(URL+"/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete(URL+"/"+id+"/logic", callback);
}

function deliverynote(id,callback) {
    return BaseService.get(URL + "/deliverynote/"+id, callback);
}

function deliverynotes(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post(URL + "/deliverynotes/several", body, callback, true)
}


function report(year,callback) {
    return BaseService.get(URL + "/report/"+year, callback);
}


function reportExcel(year,name, callback) {
    return BaseService.getFile(URL + "/report/"+year+"/export",name, callback);
}

function allFilterPdf(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/pdf", filter,"facturasrecibidas.pdf", callback,)
}


function allFilterExcel(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/excel", filter,"facturasrecibidas.xlsx", callback,)
}


function allFilterWord(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/word", filter,"facturasrecibidas.doc", callback,)
}


function reportpost(filter,callback) {
    return BaseService.post(URL + "/report/",filter, callback);
}


function reportExcelpost(filter,name, callback) {
    return BaseService.postFile(URL + "/report/export",filter,name, callback);
}