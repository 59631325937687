import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'toasted-notes/src/styles.css';
import CrudForm from '../../../crud/CrudForm';
import Button from '@mui/material/Button';
import { budgetService as service, clientService, productService, statebudgetService, routeService, paymenttypeService, deliverynoteService, preformService } from '../../../../services';
import ModalClient from '../../../modal/ModalClient';
import { initElementStreet, estructureformStreet, getElementStreet, headerFecth } from '../../../utils/Data';
import { useTranslation } from 'react-i18next';
import { pad } from '../../../utils/Data';
import { Box } from '@mui/system';
import Modal from '@mui/material/Modal';
import { setValueRowProduct, setValueAmout, setValuePrice, setValueResiduos, setValueIva, setValueEquivalencia, updateRows, updateDiscountTotal, setValueClientData, fieldIvas } from '../../../utils/UtilsBill';
import { setValueRowProductWithoutTax, setValueAmoutWithoutTax, setValuePriceWithoutTax, setValueIvaWithoutTax, setValueEquivalenciaWithoutTax, updateRowsWithoutTax, updateDiscountTotalWithoutTax } from '../../../utils/UtilsBillWithoutTax';

const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'white',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
};

type FormBillmParams = {
      id: string;
};

export default function FormBudget() {
      const [reload, setReload] = useState(false);
      const [hiddenTax, setHiddenTax] = useState(true);
      const [client, setClient] = useState(false);
      const [openBill, setOpenBill] = React.useState(false);
      const [openPreform, setOpenPreform] = React.useState(false);
      const history = useHistory();
      let { id } = useParams<FormBillmParams>();
      const { t, i18n } = useTranslation();
      const [forcesave, setForcesave] = useState(false);

      let clientGeneral: any = { id: null, comercialName: null };
      let prices = new Map<number, any>();

      const initline = {
            id: -1,
            product: { id: null, name: '' },
            amount: 0.0,
            price: 0.0,
            tax: 0.0,
            percentIva: 0.0,
            iva: 0.0,
            percentRecargo: 0.0,
            recargo: 0.0,
            total: 0.0,
            base: 0.0,
            discount: 0.0,
            discountPercent: 0.0,
      };

      const initElement = {
            client: { id: null, name: '' },
            number: null,
            date: null,
            state: { id: 1, name: 'Borrador' },
            nameClient: '',
            emailClient: '',
            serie: '',
            nif: '',
            total: 0.0,
            base: 0.0,
            iva: 0.0,
            tax: 0.0,
            equivalencia: 0.0,
            discountPercent: 0.0,
            discount: 0.0,
            note: null,
            paymentType: { id: null, name: '' },
            route: { id: null, name: '' },
            recargoEqui: false,
            exentoIva: false,
            hiddenTax: true,
            lines: [],
            ivasline: [],
            recargosline: [],
            street: { ...initElementStreet },
      };

      const [objectform, setObjectform] = React.useState({ ...initElement });

      const setValueClient = async (setValueField, objectform, value, valueform, setSate, id, aux) => {
            if (value && (value.key || value.key === 0)) {
                  var newobject = objectform;

                  let url = process.env.REACT_APP_HOST + '/admin/client/one/' + value.key;
                  const responseblog = await fetch(url, { method: 'GET', headers: headerFecth() });
                  const jsoncomplete = await responseblog.json();

                  if (jsoncomplete && jsoncomplete.data && jsoncomplete.data) {
                        var client = jsoncomplete.data;
                        clientGeneral = client;
                        setValueClientData(setValueField, objectform, value, valueform, setSate, id, aux, client);

                        let urlPrices = process.env.REACT_APP_HOST + '/admin/clientprice/client/' + value.key;
                        const responseprices = await fetch(urlPrices, { method: 'GET', headers: headerFecth() });
                        const jsonprices = await responseprices.json();

                        prices.clear();
                        if (jsonprices.data && jsonprices.data.length > 0) {
                              for (let pri of jsonprices.data) {
                                    prices.set(pri.product.id, pri.lastPrice);
                              }
                        }
                  }

                  return newobject;
            } else {
                  return objectform;
            }
      };

      const setValueRow = (setValueField, objectform, value, valueform, setSate, id, aux) => {
            return setValueRowProduct(setValueField, objectform, value, valueform, setSate, id, aux, prices, clientGeneral);
      };

      const setValueRowWithoutTax = (setValueField, objectform, value, valueform, setSate, id, aux) => {
            return setValueRowProductWithoutTax(setValueField, objectform, value, valueform, setSate, id, aux, prices, clientGeneral);
      };

      const estructureformline = [
            {
                  type: 'input',
                  name: 'name',
                  colSpan: '10%',
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'product',
                  service: productService.all,
                  colSpan: '20%',
                  setvalue: setValueRow,
                  autoFocus: true,
                  searchcode: true,
                  nextFocus: 'amount',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'amount',
                  inputtype: 'number',
                  step: 0.01,
                  colSpan: '10%',
                  setvalue: setValueAmout,
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'price',
                  inputtype: 'number',
                  step: 0.01,
                  setvalue: setValuePrice,
                  tabeventnew: true,
                  colSpan: '10%',
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'lote',
                  colSpan: '10%',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'tax',
                  inputtype: 'number',
                  step: 0.1,
                  setvalue: setValueResiduos,
                  colSpan: '10%',
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'percentIva',
                  inputtype: 'number',
                  colSpan: '10%',
                  setvalue: setValueIva,
                  step: 0.1,
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'percentRecargo',
                  inputtype: 'number',
                  colSpan: '10%',
                  setvalue: setValueEquivalencia,
                  step: 0.1,
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'base',
                  inputtype: 'number',
                  step: 0.01,
                  readonly: true,
                  colSpan: '10%',
                  col: 3,
            },
      ];

      const estructureformlineWithoutTax = [
            {
                  type: 'input',
                  name: 'name',
                  colSpan: '10%',
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'product',
                  service: productService.all,
                  colSpan: '20%',
                  setvalue: setValueRowWithoutTax,
                  autoFocus: true,
                  searchcode: true,
                  nextFocus: 'amount',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'amount',
                  inputtype: 'number',
                  step: 0.01,
                  colSpan: '10%',
                  setvalue: setValueAmoutWithoutTax,
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'price',
                  inputtype: 'number',
                  step: 0.01,
                  setvalue: setValuePriceWithoutTax,
                  tabeventnew: true,
                  colSpan: '10%',
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'lote',
                  colSpan: '10%',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'percentIva',
                  inputtype: 'number',
                  colSpan: '10%',
                  setvalue: setValueIvaWithoutTax,
                  step: 0.1,
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'percentRecargo',
                  inputtype: 'number',
                  colSpan: '10%',
                  setvalue: setValueEquivalenciaWithoutTax,
                  step: 0.1,
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'base',
                  inputtype: 'number',
                  step: 0.01,
                  readonly: true,
                  colSpan: '10%',
                  col: 3,
            },
      ];

      const estructureform = [
            {
                  type: 'title',
                  name: 'titledata',
                  col: 12,
            },
            {
                  type: 'autocomplete',
                  name: 'state.id',
                  service: statebudgetService.combo,
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'serie',
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'number',
                  readonly: true,
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'date',
                  inputtype: 'date',
                  col: 2,
            },
            {
                  type: 'autocomplete',
                  name: 'client.id',
                  service: clientService.combo,
                  setvalue: setValueClient,
                  searchcode: true,
                  autoFocus: true,
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'nif',
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'nameClient',
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'emailClient',
                  col: 2,
            },
            {
                  type: 'autocomplete',
                  name: 'paymentType.id',
                  service: paymenttypeService.combo,
                  col: 2,
            },
            {
                  type: 'autocomplete',
                  name: 'route.id',
                  service: routeService.combo,
                  col: 2,
            },
            {
                  type: 'checkbox',
                  name: 'exentoIva',
                  readonly: true,
                  col: 2,
            },
            {
                  type: 'checkbox',
                  name: 'recargoEqui',
                  readonly: true,
                  col: 2,
            },
            ...estructureformStreet,
            {
                  type: 'editor',
                  name: 'note',
                  accordion: true,
                  col: 12,
            },
            {
                  type: 'title',
                  name: 'titlelineas',
                  col: 12,
            },
            {
                  type: 'table',
                  name: 'lines',
                  headers: [
                        { label: 'Nombre', colSpan: '10%' },
                        { label: 'Producto', colSpan: '20%' },
                        { label: 'Cantidad', colSpan: '10%' },
                        { label: 'Precio', colSpan: '10%' },
                        { label: 'Lote', colSpan: '10%' },
                        { label: 'Tas. Residuos', colSpan: '10%' },
                        { label: '% Iva', colSpan: '10%' },
                        { label: '% Equi.', colSpan: '10%' },
                        { label: 'B. Imponible', colSpan: '10%' },
                  ],
                  initrow: { ...initline },
                  fields: estructureformline,
                  fieldId: 'id',
                  setvalue: updateRows,
                  col: 12,
            },
            {
                  type: 'title',
                  name: 'titletotal',
                  col: 12,
            },
            {
                  type: 'input',
                  name: 'discountPercent',
                  inputtype: 'number',
                  step: 0.01,
                  setvalue: updateDiscountTotal,
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'discount',
                  inputtype: 'number',
                  step: 0.01,
                  readonly: true,
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'tax',
                  inputtype: 'number',
                  step: 0.01,
                  readonly: true,
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'base',
                  inputtype: 'number',
                  step: 0.01,
                  readonly: true,
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'iva',
                  inputtype: 'number',
                  step: 0.01,
                  readonly: true,
                  col: 1,
            },
            {
                  type: 'input',
                  name: 'equivalencia',
                  inputtype: 'number',
                  step: 0.01,
                  readonly: true,
                  col: 1,
            },
            {
                  type: 'input',
                  name: 'total',
                  inputtype: 'number',
                  step: 0.01,
                  readonly: true,
                  col: 2,
            },
            {
                  type: 'custom',
                  name: 'ivasline',
                  custom: fieldIvas,
                  col: 12,
            },
      ];

      const estructureformWithoutTax = [
            {
                  type: 'title',
                  name: 'titledata',
                  col: 12,
            },
            {
                  type: 'autocomplete',
                  name: 'state.id',
                  service: statebudgetService.combo,
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'serie',
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'number',
                  readonly: true,
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'date',
                  inputtype: 'date',
                  col: 2,
            },
            {
                  type: 'autocomplete',
                  name: 'client.id',
                  service: clientService.combo,
                  setvalue: setValueClient,
                  searchcode: true,
                  autoFocus: true,
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'nif',
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'nameClient',
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'emailClient',
                  col: 2,
            },
            {
                  type: 'autocomplete',
                  name: 'paymentType.id',
                  service: paymenttypeService.combo,
                  col: 2,
            },
            {
                  type: 'autocomplete',
                  name: 'route.id',
                  service: routeService.combo,
                  col: 2,
            },
            {
                  type: 'checkbox',
                  name: 'exentoIva',
                  readonly: true,
                  col: 2,
            },
            {
                  type: 'checkbox',
                  name: 'recargoEqui',
                  readonly: true,
                  col: 2,
            },
            ...estructureformStreet,
            {
                  type: 'editor',
                  name: 'note',
                  accordion: true,
                  col: 12,
            },
            {
                  type: 'title',
                  name: 'titlelineas',
                  col: 12,
            },
            {
                  type: 'table',
                  name: 'lines',
                  headers: [
                        { label: 'Nombre', colSpan: '10%' },
                        { label: 'Producto', colSpan: '20%' },
                        { label: 'Cantidad', colSpan: '10%' },
                        { label: 'Precio', colSpan: '15%' },
                        { label: 'Lote', colSpan: '15%' },
                        { label: '% Iva', colSpan: '10%' },
                        { label: '% Equi.', colSpan: '10%' },
                        { label: 'B. Imponible', colSpan: '10%' },
                  ],
                  initrow: { ...initline },
                  fields: estructureformline,
                  fieldId: 'id',
                  setvalue: updateRowsWithoutTax,
                  col: 12,
            },
            {
                  type: 'title',
                  name: 'titletotal',
                  col: 12,
            },
            {
                  type: 'input',
                  name: 'discountPercent',
                  inputtype: 'number',
                  step: 0.01,
                  setvalue: updateDiscountTotalWithoutTax,
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'discount',
                  inputtype: 'number',
                  step: 0.01,
                  readonly: true,
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'base',
                  inputtype: 'number',
                  step: 0.01,
                  readonly: true,
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'iva',
                  inputtype: 'number',
                  step: 0.01,
                  readonly: true,
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'equivalencia',
                  inputtype: 'number',
                  step: 0.01,
                  readonly: true,
                  col: 2,
            },
            {
                  type: 'input',
                  name: 'total',
                  inputtype: 'number',
                  step: 0.01,
                  readonly: true,
                  col: 2,
            },
            {
                  type: 'custom',
                  name: 'ivasline',
                  custom: fieldIvas,
                  col: 12,
            },
      ];

      const recoveryElement = function (objectedit, element) {
            clientGeneral = element.client;

            objectedit['client'] = { id: element.client && element.client.id ? element.client.id : null, name: element.client && element.client.comercialName ? element.client.comercialName : null };
            objectedit['number'] = element.number ? element.number : null;
            objectedit['state'] = { id: element.state && element.state.id ? element.state.id : null, name: element.state && element.state.name ? element.state.name : null };
            objectedit['nameClient'] = element.nameClient ? element.nameClient : null;
            objectedit['emailClient'] = element.emailClient ? element.emailClient : null;
            objectedit['serie'] = element.serie ? element.serie : null;
            objectedit['nif'] = element.nif ? element.nif : null;
            objectedit['total'] = element.total ? element.total.toFixed(2) : 0.0;
            objectedit['base'] = element.base ? element.base.toFixed(2) : 0.0;
            objectedit['discountPercent'] = element.discountPercent ? element.discountPercent : 0.0;
            objectedit['discount'] = element.discount ? element.discount.toFixed(2) : 0.0;
            objectedit['iva'] = element.iva ? element.iva.toFixed(2) : 0.0;
            objectedit['tax'] = element.tax ? element.tax.toFixed(2) : 0.0;
            objectedit['equivalencia'] = element.equivalencia ? element.equivalencia.toFixed(2) : 0.0;
            getElementStreet(element.street, objectedit['street']);
            objectedit['lines'] = element.lines ? element.lines : [];
            objectedit['ivasline'] = element.ivasline ? element.ivasline : [];
            objectedit['recargosline'] = element.lines ? element.recargosline : [];
            objectedit['note'] = element.note ? element.note : null;
            objectedit['date'] = element.date ? element.date : null;
            objectedit['hiddenTax'] = element.hiddenTax ? element.hiddenTax : false;

            setHiddenTax(objectedit['hiddenTax']);

            return objectedit;
      };

      const send = () => {
            service.send(id, (data, erro) => {
                  toast.success('Se ha enviado al cliente corrcetamente', { position: toast.POSITION.TOP_CENTER });
            });
      };

      const print = (serie, number) => {
            let fileName = 'Pedido' + (serie ? serie : '') + pad(+number, 5) + '.pdf';
            service.print(id, fileName, (data, erro) => {
                  toast.success('Se ha descargado el pedido', { position: toast.POSITION.TOP_CENTER });
            });
      };

      const generateBill = () => {
            deliverynoteService.budget(id, (data, error) => {
                  if (data && data.data && data.data.id) {
                        history.push('/deliverynote/edit/' + data.data.id);
                        window.location.reload();
                  } else {
                        setOpenBill(!openBill);
                        toast.error(error.mes, { position: toast.POSITION.TOP_CENTER });
                  }
            });
      };

      const generatePreform = () => {
            preformService.budget(id, (data, error) => {
                  if (data && data.data && data.data.id) {
                        history.push('/preform/edit/' + data.data.id);
                        window.location.reload();
                  } else {
                        setOpenPreform(!openPreform);
                        toast.error(error.mes, { position: toast.POSITION.TOP_CENTER });
                  }
            });
      };

      const generateAndSave = (getObject) => {
            getObject().state = { id: 2, name: '' };
            setForcesave(true);
      };

      const moreActions = (getObject) => {
            var isGenerate = id && getObject && getObject() && getObject().number && getObject().number != '' && getObject().state && getObject().state.id && getObject().state.id != 1;
            var haveEmail = id && getObject && getObject() && getObject().emailClient && getObject().emailClient != '';
            return (
                  <>
                        {isGenerate && haveEmail && (
                              <Button variant="outlined" color="primary" style={{ marginLeft: '30px' }} onClick={send}>
                                    {' '}
                                    Enviar al cliente
                              </Button>
                        )}
                        {isGenerate && (
                              <Button variant="outlined" color="primary" style={{ marginLeft: '30px' }} onClick={(evt) => print(getObject().serie, getObject().number)}>
                                    {' '}
                                    Descargar presupuesto{' '}
                              </Button>
                        )}
                        {isGenerate && (
                              <Button variant="outlined" color="primary" style={{ marginLeft: '30px' }} onClick={(evt) => setOpenBill(!openBill)}>
                                    {' '}
                                    Generar Albarán{' '}
                              </Button>
                        )}
                        {isGenerate && (
                              <Button variant="outlined" color="primary" style={{ marginLeft: '30px' }} onClick={(evt) => setOpenPreform(!openPreform)}>
                                    {' '}
                                    Generar Preforma{' '}
                              </Button>
                        )}
                        {!isGenerate && <span>No se puede enviar al cliente, ni imprimir hasta que se genere, para generarlo debes cambiar el estado a distinto de Borrador </span>}
                        {!isGenerate && (
                              <Button type="button" variant="contained" color="warning" style={{ marginLeft: '30px' }} onClick={(evt) => generateAndSave(getObject)}>
                                    {' '}
                                    Guardar y generar{' '}
                              </Button>
                        )}
                  </>
            );
      };

      const removeLines = function (object) {
            var other = true;
            var posRemove = -1;
            for (var i = 0; i < object['lines'].length; i++) {
                  var r = object['lines'][i];
                  if (!r['amount'] || r['amount'] == 0) {
                        posRemove = i;
                        break;
                  }
            }

            if (posRemove > -1) {
                  object['lines'].splice(posRemove, 1);
                  removeLines(object);
            }
      };

      const isValid = function (object) {
            removeLines(object);
            if (!object['state']) {
                  return t('budget.error.state');
            }
            if (!object['nameClient']) {
                  return t('budget.error.name');
            }
            if (!object['lines'] || object['lines'].length == 0) {
                  return t('budget.error.lines');
            }
            if (!object['nif']) {
                  return t('budget.error.nif');
            }

            return null;
      };

      const checkIsReadOnly = function (object) {
            return object && object.number && object.number > 0;
      };

      const haveOtherTop = function (idRow) {
            return !idRow || idRow <= 0;
      };

      const saveClient = (client) => {
            clientGeneral = client;

            var aux = [];
            aux['client'] = client;
            aux['client']['name'] = client.comercialName;
            aux['nameClient'] = client.comercialName;
            aux['emailClient'] = client.email;
            aux['nif'] = client.nif;
            aux['street'] = client.street;
            aux['paymentType'] = { id: client.paymentType.id, name: client.paymentType.name };
            aux['route'] = { id: client.route.id, name: client.route.name };
            aux['recargoEqui'] = client.recargoEqui;
            aux['exentoIva'] = client.exentoIva;
            if (client.dtoBill && client.dtoBill > 0) {
                  aux['discountPercent'] = client.dtoBill;
            }

            setObjectform((currentValueform) => ({ ...currentValueform, ...aux }));
      };

      useEffect(() => {
            clientGeneral = { id: null, comercialName: null };
      }, []);

      const handleCloseBill = () => {
            setOpenBill(!openBill);
      };

      const handleClosePreform = () => {
            setOpenPreform(!openPreform);
      };

      const notierror = () => {
            setForcesave(false);
      };

      return (
            <>
                  {hiddenTax ? (
                        <CrudForm
                              objectform={objectform}
                              estructureform={estructureformWithoutTax}
                              i18n={'budget'}
                              urledit={'/budget/edit/'}
                              urlCancel={'/budget'}
                              service={service}
                              recoveryElement={recoveryElement}
                              valid={isValid}
                              moreActions={moreActions}
                              haveotherTop={haveOtherTop}
                              reload={reload}
                              forcesave={forcesave}
                              notierror={notierror}
                              othersformTop={<ModalClient saveClient={saveClient} t={t} i18n={i18n} />}
                        />
                  ) : (
                        <CrudForm
                              objectform={objectform}
                              estructureform={estructureform}
                              i18n={'budget'}
                              urledit={'/budget/edit/'}
                              urlCancel={'/budget'}
                              service={service}
                              recoveryElement={recoveryElement}
                              valid={isValid}
                              moreActions={moreActions}
                              haveotherTop={haveOtherTop}
                              reload={reload}
                              forcesave={forcesave}
                              notierror={notierror}
                              othersformTop={<ModalClient saveClient={saveClient} t={t} i18n={i18n} />}
                        />
                  )}
                  <Modal open={openBill} onClose={handleCloseBill} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                        <Box sx={style}>
                              <div style={{ marginBottom: '30px' }}>¿Quieres hacer un albarán de este presupuesto?</div>
                              <div>
                                    <Button variant="outlined" color="primary" style={{ marginRight: '30px' }} onClick={handleCloseBill}>
                                          {' '}
                                          No
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={generateBill}>
                                          {' '}
                                          Sí{' '}
                                    </Button>
                              </div>
                        </Box>
                  </Modal>
                  <Modal open={openPreform} onClose={handleClosePreform} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                        <Box sx={style}>
                              <div style={{ marginBottom: '30px' }}>¿Quieres hacer una preforma de este presupuesto?</div>
                              <div>
                                    <Button variant="outlined" color="primary" style={{ marginRight: '30px' }} onClick={handleClosePreform}>
                                          {' '}
                                          No
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={generatePreform}>
                                          {' '}
                                          Sí{' '}
                                    </Button>
                              </div>
                        </Box>
                  </Modal>
                  <ToastContainer />
            </>
      );
}
