import React, { useState, useEffect }  from 'react';
import { useTranslation } from 'react-i18next';
import { t } from "i18next";
import { Autocomplete, TextField,Grid } from '@mui/material';
import { daydisableService as service } from '../../../../services';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const moment = require("moment");


var initialFilter = {
    from: null,
    to: null
}

var calendarCurrent:any = null;

export default function Moth(props) {

  const t = props.t;
  const month = props.month;
  const [year, setYear] = useState<any>(props.year); 
  const [days, setDays] = useState<any>([]); 
  const [events, setEvents] = useState<any>([]); 
  const [filter, setFiter] = useState<any>({...initialFilter}); 
  const [dateSelected, setDateSelected] = useState<any>(null);
  const [id,setId] = useState<any>(null);
  const [openModal, setOpenModal] = useState<any>(false); 
  const [openModalNo, setOpenModalNo] = useState<any>(false); 
  const [startOfMonth, setStartOfMonth] = useState<any>(moment().set('month', month-1).startOf('month').format("YYYY-MM-DD")); 
  const [endOfMonth, setEndOfMonth] = useState<any>(moment().set('month', month-1).endOf('month').format("YYYY-MM-DD")); 
  const [endOfMonthAux, setEndOfMonthAux] = useState<any>(moment().set('month', month-1).endOf('month').add(1,'day').format("YYYY-MM-DD")); 
  const calendarRef  = React.createRef<FullCalendar>();
  const [calendarResState, setCalendarResState] = useState<any>(null); 

  console.log("Mes aux => " + endOfMonthAux);

  const reloadToClose = false;



  const search = () => {
    setEvents([]);
    setDays([]);

    service.pageFilter(0,50,filter, (data, error) => {
      if (data.data){
          setDays(data.data);

          if(data.data.length > 0){
              var eventsAux:any[] = [];
              data.data.forEach(element => {
                  eventsAux.push({
                          id: element.id,
                          title: '',
                          start: moment(element.date).format("YYYY-MM-DD")+"T00:00:00",
                          end: moment(element.date).format("YYYY-MM-DD")+"T23:59:59",
                          backgroundColor:'orange',
                          allDay:true
                  })
              });
              setEvents(eventsAux);
          }
      }
  })
  }

  useEffect(() => {
    filter.from = startOfMonth+'T00:00:00Z';
    filter.to =endOfMonth+'T23:59:59Z';
    setFiter(filter);
  }, []);


  useEffect(() => {
      search();
  }, [filter]);

  useEffect(() => {
    setStartOfMonth(moment().set('year',props.year).set('month', month-1).startOf('month').format("YYYY-MM-DD"));
    setEndOfMonth(moment().set('year',props.year).set('month', month-1).endOf('month').format("YYYY-MM-DD"));
    setEndOfMonthAux(moment().set('year',props.year).set('month', month-1).endOf('month').add(1,'day').format("YYYY-MM-DD"))
    calendarRef?.current?.render();
    var filterAux = {...filter}
    filterAux.from = moment().set('year',props.year).set('month', month-1).startOf('month').format("YYYY-MM-DD")+'T00:00:00Z';
    filterAux.to =moment().set('year',props.year).set('month', month-1).endOf('month').format("YYYY-MM-DD")+'T23:59:59Z';
    
    setFiter(filterAux);
 }, [props.year]);

  
  const saveparte = (element,isCreated)=>{
    
  }



  const handleCloseYes = () => {
    setOpenModal(false);
  };
  const handleCloseNo = () => {
    setOpenModalNo(false);
  };


  const handlerSaveConfirm = function(){
    setOpenModal(false);
    let daydisable = {date:dateSelected}
    service.create(daydisable,(data,error) => {
      search();
    })
  }

  const handlerDelConfirm = function(){
    setOpenModalNo(false);
    let daydisable = {date:dateSelected}
    service.removeLogic(id,(data,error) => {
      search();
    })
  }

  return (
    <Grid item md={3} sm={4} xs={12} style={{padding: "1em"}}>  
        <FullCalendar
                ref={calendarRef}
                plugins={[ interactionPlugin, dayGridPlugin ]}
                initialView="dayGridMonth"
                locale="es"
                navLinks={false}
                headerToolbar={{start: 'title',   center: '', end: ''}}
                validRange={{start: startOfMonth, end: endOfMonthAux}}
                height={450}
                contentHeight={450}
                events={events}
                firstDay={1}
                eventClick={(info) => {
                  setDateSelected(info.event.start);
                  setId(info.event.id);
                  setOpenModalNo(!openModal)
                }}
                selectable={true}
                editable={true}
                dateClick={(info) => {
                  if(moment(info.date).isoWeekday() != 7) {
                    calendarCurrent = calendarRef.current;
                    setDateSelected(moment(info.date).format("YYYY-MM-DD")+"T00:00:00Z");
                    setOpenModal(!openModal)
                  }
                }}
            />
        <Modal
          open={openModal}
          onClose={handleCloseYes}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
            <Box sx={style}>
              <div style={{marginBottom:'30px'}}>
                  ¿Deseas marcar el día {moment(dateSelected).format("DD/MM/YYYY")} como festivo?
              </div>
              <div>
                <Button variant="outlined" color="primary" style={{marginRight:'30px'}} onClick={handleCloseYes}> No</Button>
                <Button variant="contained" color="primary" onClick={handlerSaveConfirm}> Sí </Button>
              </div>
          </Box>
        </Modal>
        <Modal
          open={openModalNo}
          onClose={handleCloseNo}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
            <Box sx={style}>
              <div style={{marginBottom:'30px'}}>
                  ¿Deseas borrar el día {moment(dateSelected).format("DD/MM/YYYY")} marcado festivo?
              </div>
              <div>
                <Button variant="outlined" color="primary" style={{marginRight:'30px'}} onClick={handleCloseNo}> No</Button>
                <Button variant="contained" color="primary" onClick={handlerDelConfirm}> Sí </Button>
              </div>
          </Box>
        </Modal>

    </Grid>
  );


}
