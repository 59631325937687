import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import CrudFormTabs from "../../../crud/CrudFormTabs"
import {productService as service} from "../../../../services"
import { useTranslation } from 'react-i18next';
import CrudEmpty from '../../../crud/CrudEmpty';
import { Card, Grid } from '@mui/material';
import Form from '../../../form/Form';
import Filter from '../../../filter/Filter';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const moment = require("moment");

const initElement =  {
    products:[]
    
};

const initline = {
  id: -1
}

type FormProductParms = {
  id: string;
};

const initFilter = {
  date:moment().format("YYYY-MM-DD")+"T00:00:00Z",
}
  

export default function FormPriceControl(props) {
  const { t, i18n } = useTranslation();
  const [{objectproduct,filter}, setState] = useState({
    objectproduct: {...initElement},
    filter:{...initFilter}
  });

  const estructurefilter=  [


    {
      type:"input",
      name: "date",
      inputtype:"date",
      col: 4
    }
  ]

  const setValuePrice = (setValueField,objectform,value,valueform,setState,id,aux,parent) => {
    debugger;
    var newobject = {...objectform};
    let diff = newobject["percentMinPvp"];
    newobject =  setValueField(newobject,"price",+value);
    newobject =  setValueField(newobject,"minprice",+value - diff);
  
    var price = +value;
    var lastBuyPrice = newobject["lastBuyPrice"];
    var percent = 0;
    if(lastBuyPrice && lastBuyPrice > 0){
      percent  = (price * 100 / lastBuyPrice) - 100;
      newobject =  setValueField(newobject,"percentPvp",percent);
    } else {
      percent  = 0;
      newobject =  setValueField(newobject,"percentPvp",percent);
    }
  
    var valueformAux = {...valueform};
    var rv = valueform[id];
    rv['price'] = value;
    rv['minprice'] = (value - diff).toFixed(2);
    rv['percentPvp'] = percent.toFixed(2);
  
  
    var update = {
      valueform: valueformAux,
      objectform:newobject
    }
    setState(currentstate => ({...currentstate, ...update}))
  
  
    return newobject;
  }

  const setValuePriceMin = (setValueField,objectform,value,valueform,setState,id,aux,parent) => {
    debugger;
    var newobject = {...objectform};
    let diff = newobject["price"] - +value;
    newobject =  setValueField(newobject,"percentMinPvp",diff);
  
  
    var valueformAux = {...valueform};
    var rv = valueform[id];
    rv['minprice'] = value;
    rv['percentMinPvp'] = diff.toFixed(2);
  
  
    var update = {
      valueform: valueformAux,
      objectform:newobject
    }
    setState(currentstate => ({...currentstate, ...update}))
  
  
    return newobject;
  }
  
  const setValueDiscount = (setValueField,objectform,value,valueform,setState,id,aux,parent) => {
    debugger;
    var newobject = {...objectform};
    let minprice = newobject["price"] - +value;
    newobject =  setValueField(newobject,"minprice",minprice);
  
  
    var valueformAux = {...valueform};
    var rv = valueform[id];
    rv['minprice'] = minprice.toFixed(2);
    rv['percentMinPvp'] = value;
  
  
    var update = {
      valueform: valueformAux,
      objectform:newobject
    }
    setState(currentstate => ({...currentstate, ...update}))
  
  
    return newobject;
  }

const estructureformline =  [


    {
      type:"input",
      name: "nameAndCode",
      colSpan:"40%",
      readonly:true,
      col: 4
    },
    {
      type:"input",
      name: "lastBuyPriceBefore",
      inputtype:"number",
      step:0.01,
      colSpan:"15%",
      readonly:true,
      col: 4
    },
   {
     type:"input",
     name: "lastBuyPrice",
     inputtype:"number",
     step:0.01,
     colSpan:"15%",
     readonly:true,
     col: 4
    },
    {
      type:"input",
      name: "priceBefore",
      inputtype:"number",
      step:0.01,
      colSpan:"15%",
      readonly:true,
      col: 3
     },
   {
     type:"input",
     name: "price",
     inputtype:"number",
     step:0.01,
     colSpan:"15%",
     setvalue:setValuePrice,
     col: 3
    },
];



  const estructureform =  [
  
          {
            type:"table",
            name: "products",
            headers:[{label:"Producto",colSpan:"40%"},{label:"Precio compra Anterior",colSpan:"15%"   },{label:"Precio Compra Actual",colSpan:"15%"   },{label:"Precio venta anterior",colSpan:"15%"   },{label:"Precio venta actual",colSpan:"15%"   },],
            initrow:{... initline},
            fields:estructureformline,
            fieldId:"id",
            removebuttonewline:true,
            col: 12
           },

   ];


   const saveForm = (object) => {

      service.controlpriceSave(object,(data,error)=>{
         if(data && data.data){
          toast.success(t("general.saveok"), {position: toast.POSITION.TOP_CENTER });
         } else {
          if (error){
            if(error.server){
              toast.error(error.mes, {
                position: toast.POSITION.TOP_CENTER });
            } else {
              toast.error(t(error.mes), {
                position: toast.POSITION.TOP_CENTER });
            }
         }
      }
    });

   }

   const search = () => {
      service.controlprice(moment(filter.date).format("YYYYMMDD"), (data,error) => {

        if(data && data.data){
           setState(currentState => ({...currentState, ["objectproduct"]:data.data}));
        } else {
          setState(currentState => ({...currentState, ["objectproduct"]:{ products:[] }}));
        }

      });
   }

   useEffect(() => {

    search();

  }, [filter]);

  useEffect(() => {
    

    return () => {
      debugger;
      setState(
        {
          objectproduct:{ products:[] },
          filter:  {
            date:moment().format("YYYY-MM-DD")+"T00:00:00Z",
          }
        }
      )
    }
}, []);

const cleanFilter = (filter) => {
  setState(currentState => ({...currentState, ["filter"]:{
    date:moment().format("YYYY-MM-DD")+"T00:00:00Z",
  }}));
}

const searchFilter = (filter) => {
  setState(currentState => ({...currentState, ["filter"]:filter}));
}


  return (
    <CrudEmpty i18n={"pricecontrol"}>
      <Grid container style={{background:"white", padding: "0.3em 1em 1em", borderRadius: "3px"}}>
         <Grid item sm={12} xs={12}>
         <Filter t={t} i18n={"pricecontrol"}  cleanFilter={cleanFilter} searchFilter={searchFilter} filter={filter} estructure={estructurefilter} labelsearch={"Buscar"}  hiddenButton={true} expanded={true} />
         </Grid>
      </Grid>
      <Grid container style={{background:"white", padding: "0.3em 1em 1em", borderRadius: "3px"}}>
            <Grid item sm={12} xs={12}>
                  <Card sx={{padding:"2em"}}>
                     <Form t={t} i18n={"pricecontrol"}  objectform={objectproduct} fields={estructureform}  searchFilter={saveForm} cleanFilter={(object) => {}} labelsave={"Guardar"} labelcancel={"Cancelar"} isHiddenCancel={true} haveMoreActions={false} />
                  </Card>
            </Grid>
      </Grid>
      <ToastContainer />
    </CrudEmpty>

  );
}



