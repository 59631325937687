import CrudTable from "../../../crud/CrudTable"
import {cityService as service,countryService,stateService} from "../../../../services"

const initFilter =  {
    name : null,
    code: null,
};


export default function City() {

  const createEstructureFilter =  [
     {
       type:"input",
       name: "name",
       col: 4
      },
      {
        type:"autocomplete",
        name: "idCountry",
        service:countryService.combo,
        relationfield:'idState',
        servicerelation:stateService.comboCountry,
        col: 4
       },
       {
         type:"autocomplete",
         name: "idState",
         options:[],
         col: 4
        },
      
  ];


  return (
    <CrudTable
          service={service}
          urledit={"/city/edit/"}
          urlnew={"/city/new/"}
          filter={initFilter}
          i18n={"city"}
          fieldstable={["name","state.name","country.name","latitude","longitude"]}
          estructureForm={createEstructureFilter} />
  );
}

