import BaseService from './_base.service';

const URL = "/admin/client";

export const clientService = {
    search,
    combo,
    comboAll,
    comboState,
    page,
    pageFilter,
    one,
    create,
    edit,
    remove,
    removeLogic,
    report,
    allFilterPdf,
    allFilterExcel,
    allFilterWord,
    reportMayor,
    reportMayorFilterPdf,
    reportMayorFilterExcel,
    reportMayorFilterWord
};

function search(offset,callback) {
    return BaseService.get(URL+"/all/", callback)
}

function combo(callback) {
    return BaseService.get(URL+"/combo/", callback, true)
}
function comboAll(callback) {
    return BaseService.get(URL+"/combo/all", callback, true)
}
function comboState(id,callback) {
    return BaseService.get(URL+"/combo/state/"+id, callback, true)
}
function page(page,size,callback) {
    return BaseService.get(URL+"/page/"+page+"/"+size, callback, true)
}

function pageFilter(page,size,filter,callback) {
    return BaseService.post(URL+"/page/"+page+"/"+size, filter, callback, true)
}

function one(id,callback) {
    return BaseService.get(URL + "/one/"+id, callback);
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post(URL, body, callback, true)
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put(URL+"/"+id, body, callback, true)
}

function remove(id,callback) {
    return BaseService.delete(URL+"/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete(URL+"/"+id+"/logic", callback);
}
function report(year,callback) {
    return BaseService.get(URL+"/report/"+year, callback);
}
function allFilterPdf(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/pdf", filter,"clientes.pdf", callback,)
}


function allFilterExcel(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/excel", filter,"clientes.xlsx", callback,)
}


function allFilterWord(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/word", filter,"clientes.doc", callback,)
}
function reportMayor(id,from,to,callback) {
    return BaseService.get(URL+"/mayor/"+id+"/"+from+"/"+to, callback);
}

function reportMayorFilterPdf(id,from,to,callback) {
    return BaseService.getFile(URL+"/mayor/"+id+"/"+from+"/"+to+"/pdf","mayor-"+id+".pdf", callback,)
}


function reportMayorFilterExcel(id,from,to,callback) {
    return BaseService.getFile(URL+"/mayor/"+id+"/"+from+"/"+to+"/excel","mayor-"+id+".xlsx", callback,)
}


function reportMayorFilterWord(id,from,to,callback) {
    return BaseService.getFile(URL+"/mayor/"+id+"/"+from+"/"+to+"/word","mayor-"+id+".doc", callback,)
}