import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudFormTabs from "../../crud/CrudFormTabs"
import {clientService as service, routeService, paymenttypeService, activityService, billtypeService } from "../../../services"
import Streets from '../users/Streets'
import { useTranslation } from 'react-i18next';
import {initElementStreet,initElementStreetSend, estructureformStreet, getElementStreet,estructureformStreetSend} from '../../utils/Data';
import TabDeliverynote from './TabDeliverynote';
import TabBill from './TabBill';
import TabBudget from './TabBudget';
import TabProductprice from './TabProductprice';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

const initElement = {
     code:"",
     nif:"",
     comercialName:"",
     email:"",
     phone: "",
     street: {...initElementStreet},
     streetSend: {...initElementStreetSend},
     dtoBill: 0.0,
     recargoEqui:false,
     exentoIva:false,
     specialPrice:false,
     paymentType:{id:null, name: null},
     activity:{id:null, name: null},
     billType:{id:null, name: null},
     route:{id:null, name: null},
     nameContact: "",
     note: "",
     bankOneName:null,
     bankOneAccount:null,
     bankOneDate:null,
     bankTwoName:null,
     bankTwoAccount:null,
     bankTwoDate:null,
     alias:null,
     active: true
  };


type FormClientParms = {
  id: string;
};
  

export default function FormClient() {
  let { id } = useParams<FormClientParms>();
  const [idRow, setIdrow] = useState(id?Number(id):0);
  const { t, i18n } = useTranslation();

  const copyStreetEvent = (valueform,objectform,setState,setValueField) => {

    var street = {...objectform['street']}
    street['id'] = null;
    var newobject =  setValueField(objectform,"streetSend",street);

    let valueFormAux = {...valueform};
    valueFormAux["streetSend.street"]= street.street;
    valueFormAux["streetSend.postalcode"]= street.postalcode;
    valueFormAux["streetSend.country.id"]=  street?.country?.id ? {key:street.country.id,label:street.country.name}: {key:null,label:null};
    valueFormAux["streetSend.city.id"]= street?.city?.id ? {key:street.city.id,label:street.city.name}: {key:null,label:null};
    valueFormAux["streetSend.state.id"]= street?.state?.id ? {key:street.state.id,label:street.state.name}: {key:null,label:null};
    valueFormAux["streetSend.number"]= street.number;
    valueFormAux["streetSend.floor"]= street.floor;
    valueFormAux["streetSend.stairs"]= street.stairs;
    valueFormAux["streetSend.door"]= street.door;
    valueFormAux["streetSend.location"]= street.door;


    var update = {
      objectform: newobject,
      valueform: valueFormAux,
    }

    setState(currentState => ({...currentState, ...update}));

  }

  const copyStreet = (field,valueform,objectform,setState,setValueField) => {

      return <Grid container spacing={3} style={{fontSize: "0.8em", color: "red"}}>
          <Grid item sm={12}>
             <Button type="button" variant="outlined" color="primary"  onClick={() => copyStreetEvent(valueform,objectform,setState,setValueField)}> Copiar dirección de facturación </Button>
          </Grid>
      </Grid>

  }



  const calculateLetterNif = (value) => {

    if(value != null && value.length == 8){
      var letras = ['T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B', 'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E', 'T'];
      var dni = +value;
      if(dni) {
        let pos = dni%23;
        value = value + letras[pos];
      }
    }

    return value;
  }
  const estructureform =  [
    {
      type:"input",
      name: "code",
      col: 4,
      readonly:idRow == 0
     },
     {
       type:"input",
       name: "nif",
       changecustom:calculateLetterNif,
       col: 4
     },
     {
       type:"input",
       name: "comercialName",
       col: 4
     },
     {
       type:"input",
       name: "alias",
       col: 2
     },
     {
      type:"input",
      name: "nameContact",
      col: 2
     },
     {
        type:"input",
        name: "phone",
        col: 4
     },
     {
        type:"input",
        name: "email",
        inputtype:"email",
        col: 4
     },
     {
        type:"input",
        name: "dtoBill",
        col: 4,
        inputtype:"number"
     },
     {
        type:"autocomplete",
        name: "route.id",
        service:routeService.combo,
        col: 4
      },
      {
        type:"autocomplete",
        name: "paymentType.id",
        service:paymenttypeService.combo,
        col: 4
      },
      {
        type:"autocomplete",
        name: "activity.id",
        service:activityService.combo,
        col: 2
      },
      {
        type:"autocomplete",
        name: "billType.id",
        service:billtypeService.combo,
        col: 2
      },
      {
         type:"input",
         name: "dayOfPay",
         inputtype:"number",
         step:1,
         col: 2
      },
      {
         type:"checkbox",
         name: "recargoEqui",
         col: 2
      },
      {
         type:"checkbox",
         name: "exentoIva",
         col: 2
      },
      {
         type:"checkbox",
         name: "specialPrice",
         col: 1
      },
      {
         type:"checkbox",
         name: "active",
         col: 1
      },
      {
         type:"title",
         name: "databank",
         col: 12
      },
      {
         type:"input",
         name: "bankOneName",
         col: 3
      },
      {
         type:"input",
         name: "bankOneBic",
         col: 2
      },
      {
         type:"input",
         name: "bankOneAccount",
         col: 5
      },
      {
         type:"input",
         name: "bankOneDate",
         inputtype:"date",
         col: 2
      },
      {
         type:"input",
         name: "bankTwoName",
         col: 3
      },
      {
         type:"input",
         name: "bankTwoBic",
         col: 2
      },
      {
         type:"input",
         name: "bankTwoAccount",
         col: 5
      },
      {
         type:"input",
         name: "bankTwoDate",
         inputtype:"date",
         col: 2
      },
      ...estructureformStreet,
      ...estructureformStreetSend,
      {
        type:"custom",
        name: "copystreet",
        custom:copyStreet,
        col: 12
      },
      {
        type:"title",
        name: "titlenotes",
        col: 12
      },
      {
        type:"editor",
        name: "note",
        col: 10
      },
      {
        type:"image",
        name: "picture",
        col: 2
      },

    ];



  const recoveryElement = function(objectedit,element){

    objectedit['code']=element.code?element.code:"";
    objectedit['nif']=element.nif?element.nif:"";
    objectedit['comercialName']=element.comercialName?element.comercialName:"";
    objectedit['alias']=element.alias?element.alias:"";
    objectedit['email']=element.email?element.email:"";
    objectedit['phone']=element.phone?element.phone:"";
    objectedit['dtoBill']=element.dtoBill?element.dtoBill:0.0;
    objectedit['recargoEqui']=element.recargoEqui?element.recargoEqui:false;
    objectedit['specialPrice']=element.specialPrice?element.specialPrice:false;
    objectedit['active']=element.active?element.active:false;
    objectedit['exentoIva']=element.exentoIva?element.exentoIva:false;
    objectedit['paymentType']=element.paymentType?element.paymentType:{id:null, name: null};
    objectedit['activity']=element.activity?element.activity:{id:null, name: null};
    objectedit['route']=element.route?element.route:{id:null, name: null};
    objectedit['nameContact']=element.nameContact?element.nameContact:"";
    objectedit['picture']=element.picture?element.picture:"";
    objectedit['note']=element.note?element.note:"";
    objectedit['bankOneName']=element.bankOneName?element.bankOneName:"";
    objectedit['bankOneAccount']=element.bankOneAccount?element.bankOneAccount:"";
    objectedit['bankTwoName']=element.bankTwoName?element.bankTwoName:"";
    objectedit['bankTwoAccount']=element.note?element.bankTwoAccount:"";
    objectedit['billType']=element.billType?element.billType:{id:null, name: null};
    objectedit['bankOneDate']=element.bankOneDate?element.bankOneDate:null;
    objectedit['bankTwoDate']=element.bankTwoDate?element.bankTwoDate:null;
    objectedit['active']=element.active?element.active:false;
    
    
    getElementStreet(element.street,objectedit['street']);
    getElementStreet(element.streetSend,objectedit['streetSend']);

    if(!objectedit['streetSend']){
      objectedit['streetSend'] = {...initElementStreetSend}
    }
   

    return objectedit;
  }




  const isValid = function(object){
  
    if(!object['comercialName']){
      return t("error.name");
    }
    if(!object['nif']){
      return t("error.nif");
    }

      return null;
  }

  const tabBudget = (id) => {
    return <TabBudget idclient={id}/>;
  }

  const tabPrice = (id) => {
    return <TabProductprice idclient={id}/>;
  }


  const tabDelivery= (id) => {
    return <TabDeliverynote idclient={id}/>;
  }

  const tabBill = (id) => {
    return <TabBill idclient={id}/>;
  }


  return (
    <CrudFormTabs
        objectform = {{...initElement}}
        estructureform = {estructureform}
        i18n={"client"}
        urledit={"/client/edit/"}
        urlCancel={"/client"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
        haveother = {true}
        search={true}
        searchCombo={true}
        fieldcode={"code"}
        labelCode={"Buscar cliente por código"}
        tabs={[{title:"client.tab.budgettitle", method: tabBudget},{title:"client.tab.deliverytitle", method:tabDelivery},{title:"client.tab.billtitle", method:tabBill},{title:"client.tab.productprice", method:tabPrice }]}
      />

  );
}
