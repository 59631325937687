import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'toasted-notes/src/styles.css';
import CrudForm from "../../../crud/CrudForm";
import Button from "@mui/material/Button"
import {billsupplierService as service, supplierService, productService, statebillsupplierService, routeService, paymenttypesupplierService} from "../../../../services"
import ModalSupplier from '../../../modal/ModalSupplier';
import {initElementStreet, estructureformStreet, getElementStreet, headerFecth} from '../../../utils/Data';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import styled from "styled-components";
import{pad} from '../../../utils/Data'; 
import {setValueSupplierData} from '../../../utils/UtilsSupplier'; 

const moment = require("moment");

type FormBillmParams = {
  id: string;
};


export default function FormBillsupplier() {
  const [reload,setReload] = useState(false);
  const [client,setClient] = useState(false);
  const history = useHistory();
  let { id } = useParams<FormBillmParams>();
  const { t, i18n } = useTranslation();
  const [forcesave,setForcesave] = useState(false);
  

  let supplierGeneral:any={id:null,comercialName:null};

  const initline = {
    id: -1,
    ammount:0.0,
    percentIva:0.0,
    iva:0.0,
  }
  
  
  const initElement = {
       supplier:{id:null,name:""},
       number: null,
       state:{id:1,name:"Recibida"},
       date:moment().format("YYYY-MM-DD")+"T00:00:00Z",
       dadateFinishte:null,
       nameClient:"",
       emailClient: "",
       serie:"",
       nif: "",
       total:0.0,
       base:0.0,
       iva:0.0,
       tax:0.0,
       equivalencia:0.0,
       discountPercent:0.0,
       discount:0.0,
       countToProduct:false,
       paymentType:{id:null,name:""},
       lines:[
  
       ],
       ivasline:[
  
       ],
       street: {...initElementStreet},
  
  
    };


  const [objectform, setObjectform] = React.useState({...initElement});

  const setValueSupplier=  async (setValueField,objectform,value,valueform,setState,id,aux) => {
      

    if(value && (value.key || value.key === 0)){
  
      var newobject = objectform;
  
      let url = process.env.REACT_APP_HOST+"/admin/supplier/one/"+value.key;
      const responseblog = await fetch(url, {  method: 'GET',headers: headerFecth()});
      const jsoncomplete = await responseblog.json();
  
      if(jsoncomplete && jsoncomplete.data && jsoncomplete.data){
        
         var supplier = jsoncomplete.data;
         supplierGeneral = supplier;
  
         newobject = setValueSupplierData(setValueField,objectform,value,valueform,setState,id,aux,supplier)
  
  
      }
  
      return newobject;
    } else {
      return objectform;
    }
  
  }


  const setValueAmout = (setValueField,objectform,value,valueform,setState,id,aux) => {
  
    var newobject = {...objectform};
    newobject =  setValueField(newobject,"ammount", value);
   
    var valueFormAux = {...valueform};
    var rv = valueform[id];
    rv["ammount"] = value;
    
    if(value){
  
       
  
  
       var ammount = +value;
       var percentIva=rv["percentIva"] ? (isNaN(+rv["percentIva"])? 0: +rv["percentIva"]):0;
  
       var iva = ammount * (percentIva/100);
  
       newobject =  setValueField(newobject,"iva",iva.toFixed(2));
  
       rv["iva"] = iva.toFixed(2);
       
     }  else {
        newobject =  setValueField(newobject,"iva",0);
        rv["iva"] = "0.00";
     }
  
  
    var update = {
      valueform: valueFormAux,
      objectform:newobject
    }
    setState(currentstate => ({...currentstate, ...update}))
  
     return newobject;
  }

  const setValuePercent = (setValueField,objectform,value,valueform,setState,id,aux) => {
  
    var newobject = {...objectform};
    newobject =  setValueField(newobject,"percentIva", value);
   
    var valueFormAux = {...valueform};
    var rv = valueform[id];
    rv["percentIva"] = value;
    
    if(value){
  
       
  
  
       var ammount = rv["ammount"] ? (isNaN(+rv["ammount"])? 0: +rv["ammount"]):0;;
       var percentIva= +value;
  
       var iva = ammount * (percentIva/100);
  
       newobject =  setValueField(newobject,"iva",iva.toFixed(2));
  
       rv["iva"] = iva.toFixed(2);
       
     }  else {
        newobject =  setValueField(newobject,"iva",0);
        rv["iva"] = "0.00";
     }
  
  
    var update = {
      valueform: valueFormAux,
      objectform:newobject
    }
    setState(currentstate => ({...currentstate, ...update}))
  
     return newobject;
  }

  const estructureformline =  [

         {
           type:"input",
           name: "ammount",
           inputtype:"number",
           step:0.01,
           colSpan:"33%",
           col: 4,
           setvalue:setValueAmout
          },
         {
           type:"input",
           name: "percentIva",
           inputtype:"number",
           step:0.01,
           colSpan:"33%",
           setvalue:setValuePercent,
           col: 3
          },
      
         {
           type:"input",
           name: "iva",
           inputtype:"number",
           colSpan:"33%",
           readonly:true,
           col: 3
          },
    ];


  const updateRows = (setValueField,objectform,rows,setState, valueform) => {
   
    var totalLines = 0.0;

    var base = 0;
    var iva = 0;
    var recargo = 0;
    var total = 0;
    var tax = 0;

    var recargosLine:any[] = [];

    for (let row of rows) {
         debugger;
           base += +row["ammount"];
           iva += +row["iva"];
           total += base + iva;
           tax += 0;
    }




     let valueFormAux = {...valueform};
     valueFormAux['total'] = total.toFixed(2);
     valueFormAux['base'] =  base.toFixed(2);
     valueFormAux['iva'] =  iva.toFixed(2);
     valueFormAux['tax'] =  tax.toFixed(2);
     valueFormAux['equivalencia'] =  recargo.toFixed(2);

    var newobject  = {...objectform}
    newobject =  setValueField(newobject,"total",total);
    newobject =  setValueField(newobject,"base",base);
    newobject =  setValueField(newobject,"iva",iva);

    var update = {
        objectform: newobject,
        valueform: valueFormAux
    } 

    setState(currentState => ({...currentState, ...update}));
    
    return newobject;
}

    

  const estructureform =  [
         {
           type:"title",
           name: "titledata",
           col: 12
          },
          {
            type:"autocomplete",
            name: "state.id",
            service:statebillsupplierService.combo,
            col: 2
           },
         {
           type:"input",
           name: "serie",
           col: 2
          },
         {
           type:"input",
           name: "number",
           col: 2
          },
          {
            type:"input",
            name: "date",
            inputtype:"date",
            col: 2
           },
           {
             type:"input",
             name: "dateFinish",
             inputtype:"date",
             col: 2
            },
          {
            type:"autocomplete",
            name: "supplier.id",
            service:supplierService.combo,
            setvalue: setValueSupplier,
            searchcode:true,
            autoFocus:true,
            col: 2
           },
         {
           type:"input",
           name: "nif",
           col: 2
          },
         {
           type:"input",
           name: "nameClient",
           col: 2
          },
         {
           type:"input",
           name: "emailClient",
           col: 2
          },
          {
            type:"autocomplete",
            name: "paymentType.id",
            service:paymenttypesupplierService.combo,
            col: 2
           },
           {
             type:"input",
             name: "pagare",
             col: 2
            },
          ...estructureformStreet,
          {
            type:"editor",
            name: "note",
            col: 12,
            accordion:true
           },
         {
           type:"title",
           name: "titlelineas",
           col: 12
          },
         {
          type:"table",
          name: "ivasline",
          headers:[{label:"Base imponible",colSpan:"33%"   },{label:"% IVA",colSpan:"33%"   },{label:"IVA",colSpan:"33%"   }],
          initrow:{... initline},
          fields:estructureformline,
          fieldId:"id",
          setvalue: updateRows,
          col: 12
         },
         {
           type:"title",
           name: "titletotal",
           col: 12
          },
         {
           type:"input",
           name: "base",
           inputtype:"number",
           step:0.01,
           readonly:true,
           col: 2
          },
         {
           type:"input",
           name: "iva",
           inputtype:"number",
           step:0.01,
           readonly:true,
           col: 1
          },
           {
             type:"input",
             name: "total",
             inputtype:"number",
             step:0.01,
             readonly:true,
             col: 2
            },
            {
              type:"file",
              name: "filepath",
              col: 4
             },
            
    ];


  const recoveryElement = function(objectedit,element){
    
    supplierGeneral = element.client;

    objectedit['supplier']={id:element.supplier && element.supplier.id?element.supplier.id:null, name: element.supplier && element.supplier.comercialName?element.supplier.comercialName:null};
    objectedit['number']=element.number?element.number:null;
    objectedit['state']={id:element.state && element.state.id?element.state.id:null, name: element.state && element.state.name?element.state.name:null};
    objectedit['paymentType']={id:element.paymentType && element.paymentType.id?element.paymentType.id:null, name: element.paymentType && element.paymentType.name?element.paymentType.name:null};
    objectedit['nameClient']=element.nameClient?element.nameClient:null;
    objectedit['emailClient']=element.emailClient?element.emailClient:null;
    objectedit['serie']=element.serie?element.serie:null;
    objectedit['nif']=element.nif?element.nif:null;
    objectedit['total']=element.total?element.total.toFixed(2):0.0;
    objectedit['base']=element.base?element.base.toFixed(2):0.0;
    objectedit['discountPercent']=element.discountPercent?element.discountPercent:0.0;
    objectedit['discount']=element.discount?element.discount.toFixed(2):0.0;
    objectedit['iva']=element.iva?element.iva.toFixed(2):0.0;
    objectedit['tax']=element.tax?element.tax.toFixed(2):0.0;
    objectedit['equivalencia']=element.equivalencia?element.equivalencia.toFixed(2):0.0;
    getElementStreet(element.street,objectedit['street']);
    objectedit['lines']=element.lines?element.lines:[];
    objectedit['ivasline']=element.ivasline?element.ivasline:[];
    objectedit['recargosline']=element.lines?element.recargosline:[];
    objectedit['date']=element.date?element.date:null;
    objectedit['dateFinish']=element.dateFinish?element.dateFinish:null;
    objectedit['pagare']=element.pagare?element.pagare:null;

    
    return objectedit;
  }


  const send = () =>{
    service.send(id,  (data,erro) =>{
      toast.success("Se ha enviado al cliente corrcetamente",  { position: toast.POSITION.TOP_CENTER })
    });
  }


  const generateAndSave = (getObject) => {
    getObject().countToProduct=true;
    setForcesave(true);
  }


  const moreActions = (getObject) => {
    var isGenerate = id && getObject && getObject() && getObject().number && getObject().number != '' && getObject().state && getObject().state.id && getObject().state.id != 1;
    var notFinish = getObject && getObject() && !getObject().finishCount;
    return <>
          { isGenerate && <Button variant="outlined" color="primary" style={{marginLeft:'30px'}}> Descargar Factura </Button>}
      </>
  }

  const removeLines = function(object){
    var other = true;
    var posRemove = -1;
    for (var i = 0; i < object['lines'].length; i++ ){
      var r = object['lines'][i];
      if(!r['amount'] || r['amount']== 0){
        posRemove = i;
        break;
      }
    }

    if(posRemove > -1){
      object['lines'].splice(posRemove, 1);
      removeLines(object);
    }
  } 


  const isValid = function(object){
      removeLines(object);
      if(!object['state']){
        return t("billsupplier.error.state");
      }
      if(!object['nameClient']){
        return t("billsupplier.error.name");
      }
      if(!object['ivasline'] || object['ivasline'].length == 0){
        return t("billsupplier.error.lines");
      }
      if(!object['date'] ){
        return t("billsupplier.error.date");
      }
      if(!object['number'] ){
        return t("billsupplier.error.number");
      }
      if(!object['nif'] ){
        return t("billsupplier.error.nif");
      }
      
      return null;
  }


  const haveOtherTop = function(idRow){
    return !idRow || idRow <= 0;
  }

  const saveSupplier = (supplier) =>{

    supplierGeneral = supplier;

    var aux = [];
    aux['supplier'] = supplier;
    aux['supplier']['name'] = supplier.comercialName;
    aux['nameClient'] = supplier.comercialName;
    aux['emailClient'] = supplier.email;
    aux['nif'] = supplier.nif;
    aux['street'] = supplier.street;
    if(supplier.paymentType && supplier.paymentType.id){
      aux['paymentType'] = {id:supplier.paymentType.id, name:supplier.paymentType.name};
    }
  

    setObjectform(currentValueform => ({...currentValueform, ...aux }));
  }

  useEffect(()=>{
    supplierGeneral={id:null,comercialName:null}
  },[]);


  const notierror = () => {
    setForcesave(false);
  }

  return (
    <><CrudForm
        objectform = {objectform}
        estructureform = {estructureform}
        i18n={"billsupplier"}
        urledit={"/billsupplier/edit/"}
        urlCancel={"/billsupplier"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
        moreActions={moreActions}
        haveotherTop={haveOtherTop}
        reload={reload}
        forcesave={forcesave}
        notierror={notierror}
        othersformTop={<ModalSupplier saveClient={saveSupplier} t={t} i18n={i18n}/>}
      /><ToastContainer /></>
      
  );
}

const PanelIvas = styled.div `
    display: flex;
    
`;


const PanelOneIva = styled.div `
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 146px;
  width: 30%;
`;

const PanelOneIvaLabel = styled.label `
  display: block;
  background-color: rgba(100,172,71,0.7);
  padding: 5px 0;
`;
const PanelOneIvaSpan = styled.span `
  display: block;
  background-color: rgba(100,172,71,0.5);
  padding: 5px 0;
`;

