import React, { useState, useEffect }  from 'react';
import { useParams,useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTable from "../../../crud/CrudTable"
import {remesaService as service} from "../../../../services"
import PrintIcon from '@mui/icons-material/Print'
import RefreshIcon from '@mui/icons-material/Refresh'
import { ToastContainer, toast } from 'react-toastify';
import 'toasted-notes/src/styles.css';
import { useTranslation } from 'react-i18next';
import{estructureformStreet, pad} from '../../../utils/Data'; 
import { Box } from '@mui/system';
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CustomPaginationActionsTable from "../../../table/Table"
import Filter from "../../../filter/Filter"
import Layout from '../../../layout/Layout';
import styled from "styled-components";

const moment = require("moment");

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const initFilter =  {
    month:moment().month(),
    year: moment().year()
};


type RemesaViewParams = {
  id: string;
};

export default function RemesaView() {

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [remesa,setRemesa] = useState({id:null,date:null,bills:[]});
  var { id } = useParams<RemesaViewParams>();

  const searchFilter = () => {
    console.log();
    setRemesa({id:null,date:null,bills:[]});
    service.one(id,(data, error) => {
      if(data && data.data){
        setRemesa(data.data)
      }
    });
    

  }

  const confirm = () => {
    var date = moment(remesa.date);

    let nameFile = "remesa"+date.year()+(date.month()+1)+date.date()+".xls";
    service.excel(remesa.id, nameFile,(data, error) => {
    });
    

  }


  const paintCell = {
    base: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    discount: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    iva: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    total: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    equivalencia: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    tax: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },

  }


  useEffect(() => {
    searchFilter();
  }, []);

  const getDay = () => {

    var date = moment(remesa.date);

    let nameFile = date.date()+"/"+(date.month()+1)+"/"+date.year();

    return nameFile;
  }


  return (
    <Layout t={t} title={t("remesa.titleformview")}>
      <DivPanel>
       <Grid container>
        <Grid item xs={12} style={{textAlign:'left', marginBottom: '10px',marginTop: '30px'}}>
            <h3>Remesa del día {getDay()}</h3>
        </Grid>
       </Grid>
       <DivTable>
          <CustomPaginationActionsTable t={t} i18n={"bill"} labels={{"client.comercialName|client.email|client.phone":t('bill.field.client.name')}} rows={remesa.bills} fields={["serie","number","date","client.comercialName|client.email|client.phone","base","tax","equivalencia","iva","total"]} fieldId={"id"} rowsPerPage={10} hiddenpagination={true} deleteaction={false} editable={false} defaultValues={{serie:"",number:"Borrador"}} paintCell={paintCell} paintvalue={{}} moreActions={[]}  selectable={false} handlerSelected={null} conditionDelete={null} columOrder={null} columOrderDirection={"ASC"} clickHeader={null} />
        </DivTable>
        <Grid item xs={12} style={{textAlign:'right', marginBottom: '10px',marginTop: '30px'}}>
            <Button  variant="outlined" color="secondary" style={{marginLeft:'30px'}} onClick={() => history.goBack()}> Volver </Button>
            {remesa.bills.length > 0 &&  <Button  variant="contained" color="secondary" style={{marginLeft:'30px'}} onClick={confirm}> Exportar remesa </Button> }
        </Grid>
      </DivPanel>
      <ToastContainer />
    </Layout>

  );
}

const DivPanel =  styled.div`
    padding: 2em;
    @media (max-width: 767px) {
      padding: 0.5em;
    }
`;

const DivTable =  styled.div`
`;