
import { PriceChange } from '@mui/icons-material';
import { headerFecth } from '../../../utils/Data';

export const setValueMin = (setValueField,objectform,value,valueform,setState) => {

    var newobject = {...objectform}
    newobject = setValueField(newobject,"minprice",value);

    var valueformAux = {...valueform}
    valueformAux["minprice"] = value;
    
    var price = newobject["price"];
     var percentMinPvp = price - (+value);
  
    debugger;
    if( price && percentMinPvp && percentMinPvp > 0){
      newobject = setValueField(newobject,"percentMinPvp",percentMinPvp);
      valueformAux["percentMinPvp"] = percentMinPvp.toFixed(2);
      //setValueform(currentValueform => ({...currentValueform, ['minprice']: minPrice.toFixed(2)}))
    } else {

      newobject = setValueField(newobject,"percentMinPvp",0);
      valueformAux["percentMinPvp"] = "0.00";
    }
  
    var update = {
      valueform:valueformAux,
      objectform: newobject
    }
  
    setState(currentState => ({...currentState, ...update}))
  
    return newobject;
  }




  export const setLastBuyPrice = (setValueField,objectform,value,valueform,setState) => {

    var newobject = {...objectform}
    newobject = setValueField(newobject,"lastBuyPrice",value);

    debugger;
    

    var valueformAux = {...valueform}
    valueformAux["lastBuyPrice"] = value;

    var last = value;
    var percentPvp = newobject["percentPvp"];

    var diff = newobject["percentMinPvp"] && +newobject["percentMinPvp"] > 0 ? +newobject["percentMinPvp"]:0;
    
    if( last && percentPvp && percentPvp > 0){
      debugger;
      var price = last * (1 + (percentPvp / 100));
      var minprice = price - diff; 
      newobject = setValueField(newobject,"price",price);
      valueformAux["price"] = price.toFixed(2);
      if(price > 0) {
        
        newobject = setValueField(newobject,"minprice",minprice);
        valueformAux["minprice"] = minprice.toFixed(2);

    } else {
        var minprice = 0;
        newobject = setValueField(newobject,"minprice",minprice);
        valueformAux["minprice"] = minprice.toFixed(2);
        var percentMinPvp = 0;
        newobject = setValueField(newobject,"percentMinPvp",percentMinPvp);
        valueformAux["percentMinPvp"] = percentMinPvp.toFixed(2);
    }
     
    }

    var update = {
    valueform:valueformAux,
    objectform: newobject
    }

    setState(currentState => ({...currentState, ...update}))

    return newobject;
}

export const setValuePercentPvp = (setValueField,objectform,value,valueform,setState) => {

    var newobject = {...objectform}
    newobject = setValueField(newobject,"percentPvp",value);

    var valueformAux = {...valueform}
    valueformAux["percentPvp"] = value;

    var last = newobject["lastBuyPrice"];
    var percentPvp = newobject["percentPvp"];
    
    debugger;

    var diff = newobject["percentMinPvp"] && +newobject["percentMinPvp"] > 0 ? +newobject["percentMinPvp"]:0;

    if( last && percentPvp && percentPvp > 0){
      var price = last * (1 + (percentPvp / 100));
      var minprice = price - diff; 
      newobject = setValueField(newobject,"price",price.toFixed(2));
      valueformAux["price"] = price.toFixed(2);
      newobject = setValueField(newobject,"minprice",minprice);
      valueformAux["minprice"] = minprice.toFixed(2);
    }

    var update = {
        valueform:valueformAux,
        objectform: newobject
    }

    setState(currentState => ({...currentState, ...update}))

    return newobject;
}


export const setValuePriceGenral = (setValueField,objectform,value,valueform,setState,id,aux,parent) => {
    var diff = 0;
    var price = +value;
    if(objectform['percentMinPvp'] && objectform['percentMinPvp'] > 0){
        diff = +objectform['percentMinPvp'];
    }

    debugger;
    var minprice = 0;
    if(value && +value > 0){
      minprice = +value - diff;
    }

    var lastBuyPrice = 0;
    if(objectform['lastBuyPrice'] && objectform['lastBuyPrice'] > 0){
      lastBuyPrice = +objectform['lastBuyPrice'];
    }

    var percentPvp = 0;
    if(lastBuyPrice > 0){
      percentPvp =  (price * 100 / lastBuyPrice) - 100;
    }


    var newobject = {...objectform}
    newobject = setValueField(newobject,"price",value);
    newobject = setValueField(newobject,"minprice",minprice);
    newobject = setValueField(newobject,"percentPvp",percentPvp);


    var valueformAux = {...valueform}
    valueformAux["price"] = value;
    valueformAux["minprice"] = minprice.toFixed(2);
    valueformAux["percentPvp"] = percentPvp.toFixed(2);
    

    if(newobject["subProduct"] && newobject["subProduct"].length > 0){
        for (var i = 0; i < newobject['subProduct'].length; i++ ){
            var r = newobject['subProduct'][i];
            if(r['product'] && r['product']['id'] && r['percent']){
                var percentSub = +r['percent'];
                var priceSub = price * (1 + (percentSub/100));
                r['product']['price'] = priceSub;
            }
        }

        newobject =  setValueField(newobject,"subProduct",newobject["subProduct"]);
    }

    var update = {
            valueform:valueformAux,
            objectform: newobject
    }

    setState(currentState => ({...currentState, ...update}))

    return newobject;
}



export const setCategory = async (setValueField,objectform,value,valueform,setState) => {

    var name = "subcategory.id";

    var newobject = {...objectform}
    newobject = setValueField(newobject,name,value?value.key:null);

    var valueformAux = {...valueform}
    valueformAux[name] = value;

    if(value && (value.key || value.key === 0)){
      
      let url = process.env.REACT_APP_HOST+"/admin/category/one/"+value.key;

  
    
      const responseblog = await fetch(url, {  method: 'GET',headers: headerFecth()});
      const jsoncomplete = await responseblog.json();

      if(jsoncomplete && jsoncomplete.data && jsoncomplete.data.ivadefault){

       // setValueform(currentValueform => ({...currentValueform, ['iva.id']: {key:jsoncomplete.data.tasaDefault.id, ['label']:jsoncomplete.data.ivadefault.name}}));
        valueformAux["iva.id"] = {key:jsoncomplete.data.tasaDefault.id, ['label']:jsoncomplete.data.ivadefault.name};

        newobject = setValueField(newobject,"iva.id",jsoncomplete.data.ivadefault.id);
   
      }



    }

    var update = {
        valueform:valueformAux,
        objectform: newobject
    }

    setState(currentState => ({...currentState, ...update}))

    return newobject;


}
