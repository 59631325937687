import BaseService from '../_base.service';

const URL = "/tpv/deliverynote";

export const deliverynotetpvService = {
    search,
    combo,
    comboState,
    page,
    pageFilter,
    one,
    create,
    edit,
    remove,
    removeLogic,
    print,
    send,
    budget,
    totalSalesForRoute,
    payLessForRoute,
    payLessForClient,
    paycomplete,
    paycompleteAll,
    payparcial,
    allFilter,
    printIncomplete,
    deletepay,
    printDirect,
    printIncompleteDirect,
    paycompleteRuta,
    paycompletecash,
    paycompletecashAll,
    allFilterPrint,
    allFilterPdf,
    allFilterExcel,
    allFilterWord,
    dashboard,
    reportpayment,
    reportpaymentexcel,
    getDeliveryNotePayments,
    getDeliveryNotePaymentsExcel,
    deleteIndividualPay
};

function search(offset,callback) {
    return BaseService.get(URL+"/all/", callback)
}

function combo(callback) {
    return BaseService.get(URL+"/combo/", callback, true)
}
function comboState(id,callback) {
    return BaseService.get(URL+"/combo/state/"+id, callback, true)
}
function page(page,size,callback) {
    return BaseService.get(URL+"/page/"+page+"/"+size, callback, true)
}

function pageFilter(page,size,filter,callback) {
    return BaseService.post(URL+"/page/"+page+"/"+size, filter, callback, true)
}
function allFilter(filter,callback) {
    return BaseService.post(URL+"/all", filter, callback, true)
}

function allFilterPrint(filter,callback) {
    return BaseService.postFile(URL+"/all/print", filter,"albaranespendientes.xls", callback)
}


function one(id,callback) {
    return BaseService.get(URL + "/one/"+id, callback);
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post(URL, body, callback, true)
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put(URL+"/"+id, body, callback, true)
}

function remove(id,callback) {
    return BaseService.delete(URL+"/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete(URL+"/"+id+"/logic", callback);
}
function print(id,name,callback) {
    return BaseService.getFile(URL + "/print/"+id,name, callback);
}
function printIncomplete(id,name,callback) {
    return BaseService.getFile(URL + "/print/"+id+"/incomplete",name, callback);
}
function printDirect(id,name,callback) {
    return BaseService.getFilePrint(URL + "/print/"+id,name, callback);
}
function printIncompleteDirect(id,name,callback) {
    return BaseService.getFilePrint(URL + "/print/"+id+"/incomplete",name, callback);
}

function budget(id,callback) {
    return BaseService.get(URL + "/budget/"+id, callback);
}
function totalSalesForRoute(from,to,callback){
    return BaseService.get(URL + "/route/totalsales/"+from+"/"+to, callback);
}

function payLessForRoute(from,to,callback){
    return BaseService.get(URL + "/route/payless/"+from+"/"+to, callback);
}


function payLessForClient(from,to,callback){
    return BaseService.get(URL + "/client/payless/"+from+"/"+to, callback);
}

function send(id,name,callback) {
    return BaseService.get(URL + "/send/"+id,name, callback);
}

function paycomplete (id,type, callback){
    return BaseService.get(URL + "/"+id+"/complete/"+type, callback);
}
function paycompleteAll (ids,type, callback){
    return BaseService.post(URL + "/complete/"+type,ids, callback);
}
function paycompletecash (id, callback){
    return BaseService.get(URL + "/"+id+"/complete/cash", callback);
}
function paycompletecashAll (ids, callback){
    return BaseService.post(URL + "/complete/cash", ids, callback);
}

function paycompleteRuta (id,parte, callback){
    return BaseService.get(URL + "/"+id+"/complete/byparte/"+parte, callback);
}


function payparcial(id,payment, callback){
    let body = JSON.stringify(payment)
    return BaseService.post(URL + "/"+id+"/payparcial",body, callback);
}

function deletepay (id, callback){
    return BaseService.get(URL + "/"+id+"/deletepay", callback);
}

function allFilterPdf(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/pdf", filter,"albaranes.pdf", callback,)
}


function allFilterExcel(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/excel", filter,"albaranes.xlsx", callback,)
}


function allFilterWord(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/word", filter,"albaranes.doc", callback,)
}



function dashboard (year,callback){
    return BaseService.get(URL + "/dashboard/"+year, callback);
}

function reportpayment(from,to, callback) {
    return BaseService.get(URL +"/reportpayment/"+from+"/"+to, callback);
} 
function reportpaymentexcel(from,to, callback) {
    return BaseService.getFile(URL +"/reportpayment/"+from+"/"+to+"/excel","pagosenefectivo.xls", callback);
} 


function getDeliveryNotePayments(from,to, callback) {
    return BaseService.get(URL +"/paymentsall/"+from+"/"+to, callback);
} 
function getDeliveryNotePaymentsExcel(from,to, callback) {
    return BaseService.getFile(URL +"/paymentsall/"+from+"/"+to+"/excel","pagosalbaran.xls", callback);
} 


function deleteIndividualPay (id, callback){
    return BaseService.delete(URL + "/deleteIndividualPay/"+id, callback);
}
