import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../../crud/CrudForm"
import {configurationService as service } from "../../../../services"

const initElement = {
  nif:"",
  email: "",
  name: "",
  phone: "",
  street: "",
  picture: "",
  textTicket: "",
  serie: "",
  serieBillRectificative:"",
  porcntajeVenta:"",
	serieAlmacen:"",
	maxEffective:400,
  image:"",
  bankAccount:"",
  addYearSerie: null
}


export default function FormConfig() {
  let id = 1
  const [idRow, setIdrow] = useState(id);

  const estructureform =  [
          {
           type:"input",
           name: "nif",
           col: 4
          },
          {
           type:"input",
           name: "email",
           col: 4,
          },
          {
           type:"input",
           name: "name",
           col: 4
          },
          {
           type:"input",
           name: "phone",
           col: 4
          },
          {
           type:"input",
           name: "bankAccount",
           col: 4
          },
          {
           type:"input",
           name: "street",
           col: 4
          },
          {
           type:"input",
           name: "textTicket",
           col: 4,
          },
          {
           type:"input",
           name: "serie",
           col: 4
          },
          {
           type:"input",
           name: "serieAlmacen",
           col: 4
          },
          {
           type:"input",
           name: "serieBill",
           col: 4
          },
          {
           type:"input",
           name: "serieBill2",
           col: 4
          },
          {
           type:"input",
           name: "serieBillRectificative",
           col: 4
          },
          {
           type:"checkbox",
           name: "addYearSerie",
           col: 2
          },
          {
           type:"input",
           name: "porcntajeVenta",
           inputtype:"number",
           col: 4
          },
          {
           type:"input",
           name: "maxEffective",
           inputtype:"number",
           col: 4
          },
          {
            type:"image",
            name: "picture",
            col: 2
          },
          {
            type:"image",
            name: "pictureCasaDaAvoa",
            col: 2
          },
          
          
    ];

  const recoveryElement = function(objectedit,element){


    objectedit['nif']=element.nif?element.nif:"";
    objectedit['email']=element.email?element.email:"";
    objectedit['name']=element.name?element.name:"";
    objectedit['street']=element.street?element.street:"";
    objectedit['picture']=element.picture?element.picture:"";
    objectedit['pictureCasaDaAvoa']=element.pictureCasaDaAvoa?element.pictureCasaDaAvoa:"";
    objectedit['textTicket']=element.textTicket?element.textTicket:"";
    objectedit['serieBill']=element.serieBill?element.serieBill:"";
    objectedit['serieBill2']=element.serieBill2?element.serieBill2:"";
    objectedit['serieBillRectificative']=element.serieBillRectificative?element.serieBillRectificative:"";
    objectedit['porcntajeVenta']=element.porcntajeVenta?element.porcntajeVenta:null;
    objectedit['serieAlmacen']=element.serieAlmacen?element.serieAlmacen:"";
    objectedit['maxEffective']=element.maxEffective?element.maxEffective:0;
    objectedit['bankAccount']=element.bankAccount?element.bankAccount:null;
    objectedit['addYearSerie']=element.addYearSerie?element.addYearSerie:null;
    
    return objectedit;
  }



  const isValid = function(object){

      return null;
  }



  return (
    <CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        i18n={"config"}
        urledit={"/shopdata"}
        urlCancel={"/shopdata"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
        haveother = {true}
        id={idRow}
      />

  );
}
