import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import './App.css';
import './language/i18n';
import { isNotLoginLogin, rolUser } from './utils/session';
import LoginRoute from './components/route/LoginRoute';
import Login from './components/pages/login/Login';
import Dashboard from './components/pages/dasboard/Dashboard';
import PrivateRoute from './components/route/PrivateRoute';
import Administrator from './components/pages/users/Administrator';
import Client from './components/pages/client/Client';
import FormClient from './components/pages/client/FormClient';
import FormAdministrator from './components/pages/users/FormAdministrator';
import FormMyAccount from './components/pages/users/FormMyAccount';
import FormConfig from './components/pages/configuration/config/FormConfig';
import Payment from './components/pages/configuration/payment/Payment';
import FormPayment from './components/pages/configuration/payment/FormPayment';
import Country from './components/pages/data/country/Country';
import FormCountry from './components/pages/data/country/FormCountry';
import State from './components/pages/data/state/State';
import FormState from './components/pages/data/state/FormState';
import City from './components/pages/data/city/City';
import FormCity from './components/pages/data/city/FormCity';
import Category from './components/pages/product/category/Category';
import FormCategory from './components/pages/product/category/FormCategory';
import Product from './components/pages/product/product/Product';
import FormProduct from './components/pages/product/product/FormProduct';
import Iva from './components/pages/configuration/iva/Iva';
import FormIva from './components/pages/configuration/iva/FormIva';
import Tax from './components/pages/configuration/tax/Tax';
import FormTaxtype from './components/pages/configuration/tax/FormTaxtype';
import Statedelivery from './components/pages/configuration/statedelivery/Statedelivery';
import FormStatedelivery from './components/pages/configuration/statedelivery/FormStatedelivery';
import Statebill from './components/pages/configuration/statebill/Statebill';
import FormStatebill from './components/pages/configuration/statebill/FormStatebill';
import Statebudget from './components/pages/configuration/statebudget/Statebudget';
import FormStatebudget from './components/pages/configuration/statebudget/FormStatebudget';
import Billtype from './components/pages/configuration/billtype/Billtype';
import Formbilltype from './components/pages/configuration/billtype/Formbilltype';
import Comercial from './components/pages/employee/comercial/Comercial';
import FormComercial from './components/pages/employee/comercial/FormComercial';
import RouteOrder from './components/pages/data/route/Route';
import FormRoute from './components/pages/data/route/FormRoute';
import Car from './components/pages/data/car/Car';
import FormCar from './components/pages/data/car/FormCar';
import Activity from './components/pages/data/activity/Activity';
import FormActivity from './components/pages/data/activity/FormActivity';
import Supplier from './components/pages/supplier/Supplier';
import FormSupplier from './components/pages/supplier/FormSupplier';
import Bill from './components/pages/sales/bill/Bill';
import FormBill from './components/pages/sales/bill/FormBill';
import Preform from './components/pages/sales/preform/Preform';
import FormPreform from './components/pages/sales/preform/FormPreform';
import Deliverynote from './components/pages/sales/deliverynote/Deliverynote';
import FormDeliverynote from './components/pages/sales/deliverynote/FormDeliverynote';
import Budget from './components/pages/sales/budget/Budget';
import FormBudget from './components/pages/sales/budget/FormBudget';
import Statedeliverysupplier from './components/pages/configuration/statedeliverysupplier/Statedeliverysupplier';
import FormStatedeliverysupplier from './components/pages/configuration/statedeliverysupplier/FormStatedeliverysupplier';
import Statebillsupplier from './components/pages/configuration/statebillsupplier/Statebillsupplier';
import FormStatebillsupplier from './components/pages/configuration/statebillsupplier/FormStatebillsupplier';
import Billsupplier from './components/pages/buys/bill/Billsupplier';
import FormBillsupplier from './components/pages/buys/bill/FormBillsupplier';
import Deliverynotesupplier from './components/pages/buys/deliverynote/Deliverynotesupplier';
import FormDeliverynotesupplier from './components/pages/buys/deliverynote/FormDeliverynotesupplier';
import Movements from './components/pages/product/movements/Movements';
import FormMovements from './components/pages/product/movements/FormMovements';
import ParteRuta from './components/pages/parteruta/ParteRuta';
import Statepreform from './components/pages/configuration/statepreform/Statepreform';
import FormStatepreform from './components/pages/configuration/statepreform/FormStatepreform';
import BillGenerate from './components/pages/sales/bill/BillGenerate';
import Remesa from './components/pages/sales/remesa/Remesa';
import RemesaGenerate from './components/pages/sales/remesa/RemesaGenerate';
import RemesaView from './components/pages/sales/remesa/RemesaView';
import Deliverynotepending from './components/pages/sales/deliverynote/Deliverynotepending';
import Trazabilidad from './components/pages/trazabilidad/Trazabilidad';
import ControlPayment from './components/pages/controlpayment/bill/ControlPayment';
import CashConfigure from './components/pages/cash/configure/CashConfigure';
import FormCashConfigure from './components/pages/cash/configure/FormCashConfigure';
import CashExit from './components/pages/cash/exit/CashExit';
import FormCashExist from './components/pages/cash/exit/FormCashExist';
import Diary from './components/pages/report/diarycash/Diary';
import ControlPaymentDelivery from './components/pages/controlpayment/deliverynote/ControlPaymentDelivery';
import PaymentSupplier from './components/pages/configuration/paymentsupplier/PaymentSupplier';
import FormPaymentSupplier from './components/pages/configuration/paymentsupplier/FormPaymentSupplier';
import DiaryBillSupplier from './components/pages/report/billsupplier/DiaryBillSupplier';
import ReportRemesa from './components/pages/report/remesa/Reportremesa';
import ProductReport from './components/pages/report/product/ProductReport';
import ClientReport from './components/pages/report/client/ClientReport';
import ExportContable from './components/pages/sales/bill/ExportContable';
import CalendarLaboral from './components/pages/configuration/calendar/CalendarLaboral';
import Hollyday from './components/pages/employee/hollyday/Hollyday';
import FormHollyday from './components/pages/employee/hollyday/FormHollyday';
import Model347 from './components/pages/contable/model347/Model347';
import Entryemployee from './components/pages/employee/entryeemployee/EntryEmployee';
import FormPriceControl from './components/pages/product/product/FormPrice';
import ProductReportAmmountSale from './components/pages/report/product/ProductReportAmmountSale';
import ProductReportAmmountBuy from './components/pages/report/product/ProductReportAmmountBuy';
import PaymentEffective from './components/pages/report/asesoria/PaymentEffective';
import PaymentsDelivery from './components/pages/report/delivery/PaymentsDelivery';
import TpvRoute from './components/route/TPVRoute';
import ProductTPV from './components/pages/tpv/product/ProductTPV';
import ClientTPV from './components/pages/tpv/client/ClientTPV';
import ViewTPVClient from './components/pages/tpv/client/ViewTPVClient';
import DeliverynoteTPV from './components/pages/tpv/delivery/DeliverynoteTPV';
import FormDeliverynoteTPV from './components/pages/tpv/delivery/FormDeliverynoteTPV';
import ClientMayor from './components/pages/report/client/ClientMayor';

export default function App() {
      const isLoggedNotIn = isNotLoginLogin();

      const isAdmin = rolUser() == 'ADMIN';

      return (
            <>
                  <Router>
                        <Switch>
                              <LoginRoute path="/login" component={Login} />
                              <PrivateRoute path="/administrator/new" component={FormAdministrator} />
                              <PrivateRoute path="/administrator/edit/:id" component={FormAdministrator} />
                              <PrivateRoute path="/administrator" component={Administrator} />
                              <PrivateRoute path="/dashboard" component={Dashboard} />
                              <PrivateRoute path="/client/new" component={FormClient} />
                              <PrivateRoute path="/client/edit/:id" component={FormClient} />
                              <PrivateRoute path="/client" component={Client} />
                              <PrivateRoute path="/myaccount/:id" component={FormMyAccount} />
                              <PrivateRoute path="/shopdata" component={FormConfig} />
                              <PrivateRoute path="/paymenttype/new" component={FormPayment} />
                              <PrivateRoute path="/paymenttype/edit/:id" component={FormPayment} />
                              <PrivateRoute path="/paymenttype" component={Payment} />
                              <PrivateRoute path="/paymenttypesupplier/new" component={FormPaymentSupplier} />
                              <PrivateRoute path="/paymenttypesupplier/edit/:id" component={FormPaymentSupplier} />
                              <PrivateRoute path="/paymenttypesupplier" component={PaymentSupplier} />
                              <PrivateRoute path="/country/new" component={FormCountry} />
                              <PrivateRoute path="/country/edit/:id" component={FormCountry} />
                              <PrivateRoute path="/country" component={Country} />
                              <PrivateRoute path="/state/new" component={FormState} />
                              <PrivateRoute path="/state/edit/:id" component={FormState} />
                              <PrivateRoute path="/state" component={State} />
                              <PrivateRoute path="/city/new" component={FormCity} />
                              <PrivateRoute path="/city/edit/:id" component={FormCity} />
                              <PrivateRoute path="/city" component={City} />
                              <PrivateRoute path="/categories/new" component={FormCategory} />
                              <PrivateRoute path="/categories/edit/:id" component={FormCategory} />
                              <PrivateRoute path="/categories" component={Category} />
                              <PrivateRoute path="/product/new" component={FormProduct} />
                              <PrivateRoute path="/product/edit/:id" component={FormProduct} />
                              <PrivateRoute path="/product" component={Product} />
                              <PrivateRoute path="/ivatype/new" component={FormIva} />
                              <PrivateRoute path="/ivatype/edit/:id" component={FormIva} />
                              <PrivateRoute path="/ivatype" component={Iva} />
                              <PrivateRoute path="/taxtype/new" component={FormTaxtype} />
                              <PrivateRoute path="/taxtype/edit/:id" component={FormTaxtype} />
                              <PrivateRoute path="/taxtype" component={Tax} />
                              <PrivateRoute path="/statedelivery/new" component={FormStatedelivery} />
                              <PrivateRoute path="/statedelivery/edit/:id" component={FormStatedelivery} />
                              <PrivateRoute path="/statedelivery" component={Statedelivery} />
                              <PrivateRoute path="/statebill/new" component={FormStatebill} />
                              <PrivateRoute path="/statebill/edit/:id" component={FormStatebill} />
                              <PrivateRoute path="/statebill" component={Statebill} />
                              <PrivateRoute path="/statebudget/new" component={FormStatebudget} />
                              <PrivateRoute path="/statebudget/edit/:id" component={FormStatebudget} />
                              <PrivateRoute path="/statebudget" component={Statebudget} />
                              <PrivateRoute path="/comercial/new" component={FormComercial} />
                              <PrivateRoute path="/comercial/edit/:id" component={FormComercial} />
                              <PrivateRoute path="/comercial" component={Comercial} />
                              <PrivateRoute path="/route/new" component={FormRoute} />
                              <PrivateRoute path="/route/edit/:id" component={FormRoute} />
                              <PrivateRoute path="/route" component={RouteOrder} />
                              <PrivateRoute path="/car/new" component={FormCar} />
                              <PrivateRoute path="/car/edit/:id" component={FormCar} />
                              <PrivateRoute path="/car" component={Car} />
                              <PrivateRoute path="/activity/new" component={FormActivity} />
                              <PrivateRoute path="/activity/edit/:id" component={FormActivity} />
                              <PrivateRoute path="/activity" component={Activity} />
                              <PrivateRoute path="/supplier/new" component={FormSupplier} />
                              <PrivateRoute path="/supplier/edit/:id" component={FormSupplier} />
                              <PrivateRoute path="/supplier" component={Supplier} />
                              <PrivateRoute path="/bill/new" component={FormBill} />
                              <PrivateRoute path="/bill/edit/:id" component={FormBill} />
                              <PrivateRoute path="/bill" component={Bill} />
                              <PrivateRoute path="/billgenerate" component={BillGenerate} />
                              <PrivateRoute path="/preform/new" component={FormPreform} />
                              <PrivateRoute path="/preform/edit/:id" component={FormPreform} />
                              <PrivateRoute path="/preform" component={Preform} />
                              <PrivateRoute path="/deliverynote/new" component={FormDeliverynote} />
                              <PrivateRoute path="/deliverynote/edit/:id" component={FormDeliverynote} />
                              <PrivateRoute path="/deliverynote" component={Deliverynote} />
                              <PrivateRoute path="/deliverynotepending" component={Deliverynotepending} />
                              <PrivateRoute path="/budget/new" component={FormBudget} />
                              <PrivateRoute path="/budget/edit/:id" component={FormBudget} />
                              <PrivateRoute path="/budget" component={Budget} />
                              <PrivateRoute path="/statedeliverysupplier/new" component={FormStatedeliverysupplier} />
                              <PrivateRoute path="/statedeliverysupplier/edit/:id" component={FormStatedeliverysupplier} />
                              <PrivateRoute path="/statedeliverysupplier" component={Statedeliverysupplier} />
                              <PrivateRoute path="/statebillsupplier/new" component={FormStatebillsupplier} />
                              <PrivateRoute path="/statebillsupplier/edit/:id" component={FormStatebillsupplier} />
                              <PrivateRoute path="/statebillsupplier" component={Statebillsupplier} />
                              <PrivateRoute path="/billsupplier/new" component={FormBillsupplier} />
                              <PrivateRoute path="/billsupplier/edit/:id" component={FormBillsupplier} />
                              <PrivateRoute path="/billsupplier" component={Billsupplier} />
                              <PrivateRoute path="/deliverynotesupplier/new" component={FormDeliverynotesupplier} />
                              <PrivateRoute path="/deliverynotesupplier/edit/:id" component={FormDeliverynotesupplier} />
                              <PrivateRoute path="/deliverynotesupplier" component={Deliverynotesupplier} />
                              <PrivateRoute path="/movements/new" component={FormMovements} />
                              <PrivateRoute path="/movements/edit/:id" component={FormMovements} />
                              <PrivateRoute path="/movements" component={Movements} />
                              <PrivateRoute path="/parteruta" component={ParteRuta} />
                              <PrivateRoute path="/billtype/new" component={Formbilltype} />
                              <PrivateRoute path="/billtype/edit/:id" component={Formbilltype} />
                              <PrivateRoute path="/billtype" component={Billtype} />
                              <PrivateRoute path="/statepreform/new" component={FormStatepreform} />
                              <PrivateRoute path="/statepreform/edit/:id" component={FormStatepreform} />
                              <PrivateRoute path="/statepreform" component={Statepreform} />
                              <PrivateRoute path="/remesa/new" component={RemesaGenerate} />
                              <PrivateRoute path="/remesa/view/:id" component={RemesaView} />
                              <PrivateRoute path="/remesa" component={Remesa} />
                              <PrivateRoute path="/trazabilidad" component={Trazabilidad} />
                              <PrivateRoute path="/controlpayment" component={ControlPayment} />
                              <PrivateRoute path="/controlpaymentdelivery" component={ControlPaymentDelivery} />
                              <PrivateRoute path="/cashconfigure/new" component={FormCashConfigure} />
                              <PrivateRoute path="/cashconfigure/edit/:id" component={FormCashConfigure} />
                              <PrivateRoute path="/cashconfigure" component={CashConfigure} />
                              <PrivateRoute path="/cashexit/new" component={FormCashExist} />
                              <PrivateRoute path="/cashexit/edit/:id" component={FormCashExist} />
                              <PrivateRoute path="/cashexit" component={CashExit} />
                              <PrivateRoute path="/cashdiary" component={Diary} />
                              <PrivateRoute path="/billsupplierdiary" component={DiaryBillSupplier} />
                              <PrivateRoute path="/reportremesa" component={ReportRemesa} />
                              <PrivateRoute path="/reportclient" component={ClientReport} />
                              <PrivateRoute path="/exportcontable" component={ExportContable} />
                              <PrivateRoute path="/calendarwork" component={CalendarLaboral} />
                              <PrivateRoute path="/hollyday/new" component={FormHollyday} />
                              <PrivateRoute path="/hollyday/edit/:id" component={FormHollyday} />
                              <PrivateRoute path="/hollyday" component={Hollyday} />
                              <PrivateRoute path="/model347" component={Model347} />
                              <PrivateRoute path="/entryemployee" component={Entryemployee} />
                              <PrivateRoute path="/pricecontrol" component={FormPriceControl} />
                              <PrivateRoute path="/reportproduct" component={ProductReport} />
                              <PrivateRoute path="/reportammountsale" component={ProductReportAmmountSale} />
                              <PrivateRoute path="/reportproductbuy" component={ProductReportAmmountBuy} />
                              <PrivateRoute path="/payeffective" component={PaymentEffective} />
                              <PrivateRoute path="/paydelivery" component={PaymentsDelivery} />
                              <PrivateRoute path="/mayorclient" component={ClientMayor} />

                              <TpvRoute path="/tpv/products" component={ProductTPV} />
                              <TpvRoute path="/tpv/clients/edit/:id" component={ViewTPVClient} />
                              <TpvRoute path="/tpv/clients" component={ClientTPV} />
                              <TpvRoute path="/tpv/deliverynote/new" component={FormDeliverynoteTPV} />
                              <TpvRoute path="/tpv/deliverynote/edit/:id" component={FormDeliverynoteTPV} />
                              <TpvRoute path="/tpv/deliverynote" component={DeliverynoteTPV} />
                              {isLoggedNotIn ? <Route path="/" component={Login} /> : isAdmin ? <PrivateRoute path="/" component={Dashboard} /> : <TpvRoute path="/" component={ProductTPV} />}
                        </Switch>
                  </Router>
            </>
      );
}
