import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButtonMui from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ListItem, Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';


const ListItemButton = styled(ListItemButtonMui)(({ theme }) => ({
  transition: "0.15s padding ease",
  ".MuiListItemText-root": {
    opacity:"1",
    paddingLeft: "15px",
    "span":{
      transition: "font 0.3s ease",
      opacity:"1"
    },
  },
  ".arrowmenu": {
    transition: "visibility 0s, opacity 0.3s linear",
    visibility:"hidden",
    opacity:"0"
  },
  "&:hover":{
    paddingLeft:"2em",
    ".MuiListItemText-root": {
      "span":{
        fontSize: "0.9em"
      },
    },
    ".arrowmenu": {
      visibility:"visible",
      opacity:"1"
    },
    
  }
}));

export default function MenuParent(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(props.open);
  const t = props.t;

  React.useEffect(() => {
    setOpen(props.open)
 }, [props.open]);

  return (
    <>
      <List>
      <div>
        <ListItem button onClick={() => {props.close(props.num)}}>
        <ListItemIcon>
          {props.icon}
        </ListItemIcon>
        <ListItemText primary={props.text} />
        {props.open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={props.open} timeout="auto" unmountOnExit sx={{background:"#fafafa"}}>
            {props.children}
        </Collapse>
      </div>
    </List> 
    <Divider />
   </>
  );
}