import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTab from "../../crud/CrudTab"
import {paymentInRouteService as service} from "../../../services"
import 'toasted-notes/src/styles.css';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { Button, Grid } from '@mui/material';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const initFilter =  {
  from : '2000-01-01T00:00:00Z',
  to: '2000-01-01T23:00:00Z',
  route:-1
};


export default function TabPaymentInRoute(props) {

  const history = useHistory();
  const { t, i18n } = useTranslation();


  const[{filter,rows,forcesearch,openModal,paymentselected,updateStateParent,openDelete },setState] = useState({
    filter:{...initFilter},
    rows:[],
    forcesearch:false,
    openModal:false,
    openDelete:false,
    paymentselected:{},
    updateStateParent: props.updateStateParent
  })


  useEffect(() => {
    debugger;
    var filterAux = {...filter}
    filterAux["route"] = props.route.id;
    filterAux["from"] = props.dates.from;
    filterAux["to"] = props.dates.to;
  
    if(props.route.id > 0){

      var update = {
        filter:filterAux,
        forcesearch:!forcesearch
      }
      setState(currentState => ({...currentState, ...update}))
    } else {
      var update2 = {
        filter:filterAux,
        rows:[]
      }
      setState(currentState => ({...currentState, ...update2}))
    }
  
  }, [props.route,props.dates]);


  const paintCell = {
    ammount: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    }

  }

  const conditionColor = (row) => {
    
    if(row["payLess"] && +row["payLess"] > 1){
      return "red";
    } else {
      return "black";
    }
  }
  
  useEffect(() => {
    return () => {
  
  
      
      setState({
        filter:{
          from : '2000-01-01T00:00:00Z',
          to: '2000-01-01T23:00:00Z',
          route:-1
        },
        rows:[],
        forcesearch:false,
        openModal:false,
        openDelete:false,
        paymentselected:{},
        updateStateParent: props.updateStateParent});
     }
   
  }, []);


  const handleOpenModal = () => {
    setState(currentState => ({...currentState, ["openModal"]:false}));
  }

  const handleOpenDelete = () => {
    setState(currentState => ({...currentState, ["openDelete"]:false}));
  }

  

  const handleSavePay = () => {
    setState(currentState => ({...currentState, ["openModal"]:false}));
    service.modifypay(paymentselected, (data,error) => {

      if(data && data.data){

        var update = {
          forcesearch: !forcesearch,
          paymentselected: {}
        }

        setState(currentState => ({...currentState, ...update}));
        updateStateParent(currentState => ({...currentState, ["parteRutaForm"]:data.data.parteRuta}));
      }
    });
  }

  const handleSaveDelete = () => {
    setState(currentState => ({...currentState, ["openDelete"]:false}));
    service.deletepay(paymentselected, (data,error) => {

      if(data && data.data){

        var update = {
          forcesearch: !forcesearch,
          paymentselected: {}
        }

        setState(currentState => ({...currentState, ...update}));
        updateStateParent(currentState => ({...currentState, ["parteRutaForm"]:data.data.parteRuta}));
      }
    });
  }

  const handleEdit = (selected) => {
    var update = {
      paymentselected: selected,
      openModal:true
    } ;

    setState(currentState => ({...currentState, ...update}));
  }



  const handleDelete = (selected) => {
    var update = {
      paymentselected: selected,
      openDelete:true
    } ;

    setState(currentState => ({...currentState, ...update}));
  }

  return (
      <>
      <p style={{color:"red"}}>En caso de querer modificarlo o borrarlo debe hacerse antes de aceptar la carga, despues deben modificarse en gestión de cobros</p>
      <CrudTab
        filter={filter}
        service={service}
        editable={false}
        deleteaction={false}
        hiddenFilter={true}
        blockedinitsearch={true}  
        rows={rows}
        hiddenpagination={true}
        paintCell={paintCell}
        moreActions={[{"handle":handleEdit, icon:<EditIcon />},{"handle":handleDelete, icon:<ClearIcon />}]}
        conditionColor={conditionColor}
        i18n={"paymentInRoute"}
        fieldstable={["delivery.serie","delivery.number","delivery.date","delivery.client.comercialName|delivery.client.email|delivery.client.phone","ammount"]}
        labels={{"delivery.client.comercialName|delivery.client.email|delivery.client.phone":t('bill.field.client.name'), "delivery.serie":t('deliverynote.field.serie'), "delivery.number":t('deliverynote.field.number'),"delivery.date":t('deliverynote.field.date'),"ammount":"Cantidad cobrada"}}
        defaultValues={{serie:"",number:"Borrador"}}
        rowsPerPage={400}
        forcesearch={forcesearch}
       />
       <Modal
           open={openModal}
           onClose={handleOpenModal}
           aria-labelledby="simple-modal-title"
           aria-describedby="simple-modal-description">
           <Box sx={style}>
             <div style={{marginBottom:'30px'}}>
                 Modificar el pago del albarán {paymentselected && paymentselected['delivery'] && paymentselected['delivery']['serieAndNumber'] ? paymentselected['delivery']['serieAndNumber'] : ""}
             </div>
             <div style={{marginBottom:'30px'}}>
               <TextField variant="standard" sx={{width: "100%"}} label={t("bill.field.payTotal")} value={paymentselected['ammount']} onFocus={(evt) => {evt.target.select()}} onChange={(evt) => {var s = {...paymentselected}; s["ammount"] = evt.target.value; setState(currenstate => ({...currenstate, ["paymentselected"]:s}))}} type={"number"}  inputProps={{step:0.01,  autoComplete: 'off'}}  InputLabelProps={{shrink:true}} autoComplete="off"/>
             </div>
             <div>
               <Button variant="outlined" color="primary" style={{marginRight:'30px'}} onClick={handleOpenModal}> Cancelar</Button>
               <Button variant="contained" color="primary" onClick={handleSavePay}> Guardar </Button>
             </div>
         </Box>
       </Modal>
       <Modal
           open={openDelete}
           onClose={handleOpenDelete}
           aria-labelledby="simple-modal-title"
           aria-describedby="simple-modal-description">
           <Box sx={style}>
             <div style={{marginBottom:'30px'}}>
                 ¿Deseas borrar el pago del albarán {paymentselected && paymentselected['delivery'] && paymentselected['delivery']['serieAndNumber'] ? paymentselected['delivery']['serieAndNumber'] : ""}?
             </div>
             <div>
               <Button variant="outlined" color="primary" style={{marginRight:'30px'}} onClick={handleOpenDelete}> NO</Button>
               <Button variant="contained" color="primary" onClick={handleSaveDelete}> SI </Button>
             </div>
         </Box>
       </Modal>
       </>

  );

}
