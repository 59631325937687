import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTab from "../../crud/CrudTab"
import {billService as service, clientService, routeService, paymenttypeService, statebillService} from "../../../services"
import PrintIcon from '@mui/icons-material/Print'
import RefreshIcon from '@mui/icons-material/Refresh'
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import{pad} from '../../utils/Data'; 
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import Modal from '@mui/material/Modal'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};



export default function TabBill(props) {


  const [filter,setFilter] = useState({
    number:null,
    serie : null,
    client: props.idclient,
    from:null,
    to:null,
    state:null,
    route:null,
    paymenttype:null
})

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [openRect, setOpenRect] = React.useState(false);
  const [billRect, setBillRect] = React.useState({});

  const createEstructureFilter =  [
    {
      type:"input",
      name: "serie",
      col: 3
     },
    {
      type:"input",
      name: "number",
      inputtype:"number",
      col: 3
     },
       {
        type:"input",
        name: "from",
        inputtype:"date",
        col: 3
       },
      {
        type:"input",
        name: "to",
        inputtype:"date",
        col: 3
      },
      {
        type:"autocomplete",
        name: "state",
        service: statebillService.combo,
        col: 3
        },
        {
          type:"autocomplete",
          name: "route",
          service: routeService.combo,
          col: 3
        },
        {
          type:"autocomplete",
          name: "paymenttype",
          service: paymenttypeService.combo,
          col: 3
          },
  ];

  const handlePrint = (bill) => {

    var isGenerate = bill && bill.number && bill.number != '' && bill.state && bill.state.id && bill.state.id != 1;
    if (isGenerate){
      let fileName = "Factura"+(bill.serie?bill.serie:"")+pad(+bill.number,5)+".pdf";
      service.print(bill.id,fileName,  (data,erro) =>{
        toast.success("Se ha descargado la factura", {  position: toast.POSITION.TOP_CENTER });  
      });
    } else {
      toast.error("Solo se puede imprimir factura ya generadas", {  position: toast.POSITION.TOP_CENTER });
    }
    
  }
  
  const paintCell = {
    base: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    discount: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    iva: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    total: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    equivalencia: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    tax: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },

  }

  const handleRecti = (bill) => {
    var isGenerate = bill && bill.number && bill.number != '' && bill.state && bill.state.id && bill.state.id != 1;
    if (isGenerate){
      setBillRect(bill);
      setOpenRect(true);
    } else {
      toast.error("Solo se puede hacer rectificativas de factura ya generadas", {  position: toast.POSITION.TOP_CENTER });
    }
  }

  const rectify = () =>{
    debugger;
    handleCloseRect();
    service.rectificative(billRect['id'], (data,error) => {
      debugger;
        if(data.data) {
            history.push("/bill/edit/"+data.data.id);
        }
    });
  }

  const handleCloseRect = () => {
    setOpenRect(!openRect);
  };

  useEffect(() => {
    setFilter({
      number:null,
      serie : null,
      client: props.idclient,
      from:null,
      to:null,
      state:null,
      route:null,
      paymenttype:null
  });
  },[props.idclient])

  return (
      <><CrudTab
        service={service}
        urledit={"/bill/edit/"}
        urlnew={"/bill/new/"}
        filter={filter}
        i18n={"bill"}
        fieldstable={["serie","number","date","base","tax","equivalencia","iva","total"]}
        defaultValues={{serie:"",number:"Borrador"}}
        moreActions={[{"handle":handlePrint, icon:<PrintIcon />},{"handle":handleRecti, icon:<RefreshIcon />}]}
        paintCell={paintCell}
        labels={{"client.comercialName|client.email|client.phone":t('bill.field.client.name')}}
        estructureForm={createEstructureFilter} />
        <Modal
            open={openRect}
            onClose={handleCloseRect}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
            <Box sx={style}>
              <div style={{marginBottom:'30px'}}>
                  ¿Quieres hacer una factura rectificativa?
              </div>
              <div>
                <Button variant="outlined" color="primary" style={{marginRight:'30px'}} onClick={handleCloseRect}> No</Button>
                <Button variant="contained" color="primary" onClick={rectify}> Sí </Button>
              </div>
          </Box>
        </Modal>
      </>

  );
}
