import BaseService from './_base.service';

const URL = "/admin/category";

export const categoryService = {
    search,
    combo,
    comboState,
    page,
    pageFilter,
    one,
    create,
    edit,
    remove,
    removeLogic,
    comboChildren,
    allFilterPdf,
    allFilterExcel,
    allFilterWord
};

function search(offset,callback) {
    return BaseService.get(URL+"/all/", callback)
}

function combo(callback) {
    return BaseService.get(URL+"/combo/onlyparent", callback, true)
}
function comboChildren(id,callback) {
    return BaseService.get(URL+"/combo/parent/"+id, callback, true)
}
function comboState(id,callback) {
    return BaseService.get(URL+"/combo/state/"+id, callback, true)
}
function page(page,size,callback) {
    return BaseService.get(URL+"/page/"+page+"/"+size, callback, true)
}

function pageFilter(page,size,filter,callback) {
    return BaseService.post(URL+"/page/"+page+"/"+size, filter, callback, true)
}

function one(id,callback) {
    return BaseService.get(URL + "/one/"+id, callback);
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post(URL, body, callback, true)
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put(URL+"/"+id, body, callback, true)
}

function remove(id,callback) {
    return BaseService.delete(URL+"/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete(URL+"/"+id+"/logic", callback);
}
function allFilterPdf(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/pdf", filter,"familias.pdf", callback,)
}


function allFilterExcel(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/excel", filter,"familias.xlsx", callback,)
}


function allFilterWord(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/word", filter,"familias.doc", callback,)
}