import CrudTable from "../../../crud/CrudTable"
import {routeService as service} from "../../../../services"

const initFilter =  {
    name : null,
    code: null,
};


export default function Route() {

  const createEstructureFilter =  [
    {
      type:"input",
      name: "code",
      col: 4
     },
     {
       type:"input",
       name: "name",
       col: 4
      },
  ];


  return (
    <CrudTable
          service={service}
          urledit={"/route/edit/"}
          urlnew={"/route/new/"}
          filter={initFilter}
          i18n={"route"}
          fieldstable={["code","name"]}
          order={true}
          orderColumns={{"code":"code", "name":"name"}}
          estructureForm={createEstructureFilter} />
  );
}
