import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../../crud/CrudForm"
import {activityService as service } from "../../../../services"
import { useTranslation } from 'react-i18next';


  const initElement =  {
    name : null,
};


export default function FormActivity() {
  const { t, i18n } = useTranslation();


  const estructureform =  [
      {
        type:"input",
        name: "name",
        col: 4
       },
   ];


  const recoveryElement = function(objectedit,element){


    objectedit['name']=element.name?element.name:"";

    return objectedit;
  }



  const isValid = function(object){
      if(!object['name']){
        return t("activity.error.name");
      }

      return null;
  }



  return (
    <CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        i18n={"activity"}
        urledit={"/activity/edit/"}
        urlCancel={"/activity"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />

  );
}
