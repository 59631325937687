import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTable from "../../../crud/CrudTable"
import {billService as service, billtypeService} from "../../../../services"
import PrintIcon from '@mui/icons-material/Print'
import RefreshIcon from '@mui/icons-material/Refresh'
import { ToastContainer, toast } from 'react-toastify';
import 'toasted-notes/src/styles.css';
import { useTranslation } from 'react-i18next';
import{estructureformStreet, pad} from '../../../utils/Data'; 
import { Box } from '@mui/system';
import Modal from '@mui/material/Modal'

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CustomPaginationActionsTable from "../../../table/Table"
import Filter from "../../../filter/Filter"
import Layout from '../../../layout/Layout';
import styled from "styled-components";

const moment = require("moment");

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const initFilter =  {
    month:moment().month(),
    year: moment().year(),
    billtype: 5
};


export default function BillGenerate() {

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows,setRows] = useState([]);
  const [rowsTotal,setRowsTotal] = useState(0);
  const [filterConfirm,setFilterConfirm] = useState({...initFilter});
  
  const month = [{key:0, name:"Enero"},{key:1, name:"Febrero"},{key:2, name:"Marzo"},{key:3, name:"Abril"},{key:4, name:"Mayo"},{key:5, name:"Junio"},{key:6, name:"Julio"},{key:7, name:"Agosto"},{key:8, name:"Septimbre"},{key:9, name:"Octubre"},{key:10, name:"Noviembre"},{key:11, name:"Diciembre"}];
  const year = [{key:moment().year(), name:moment().year()+""},{key:moment().year()-1, name:(moment().year()-1)+""}];

  const createEstructureFilter =  [
     {
       type:"autocomplete",
       name: "month",
       options:month,
       col: 3
      },
      {
        type:"autocomplete",
        name: "year",
        options:year,
        col: 3
       },
       {
         type:"autocomplete",
         name: "billtype",
         service: billtypeService.combo,
         col: 3
        },
      
  ];

  const cleanFilter = (filter) => {
    console.log(filter);
    setRows([]);

  }

  const searchFilter = (filter) => {
    console.log(filter);
    setFilterConfirm(filter);
    setRows([]);
    debugger;
    if(filter.month  != null && filter.year){
      service.generateFromDeliveryNotes(filter.month, filter.year,filter.billtype, (data, error) => {
        if(data && data.data){
          setRows(data.data)
        }
      });
    }
    

  }

  const confirm = () => {
    if(rows.length > 0){
      service.generateFromDeliveryNotesConfirm(filterConfirm.month, filterConfirm.year,filterConfirm.billtype, (data, error) => {
        setRows([])
      });
    }
    

  }


  const paintCell = {
    base: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    discount: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    iva: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    total: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    equivalencia: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    tax: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },

  }

  const conditionColor = (row) => {
    
    if(!row["paymentType"]["id"] || row["paymentType"]["id"] == 5){
        return "orange";
      } else if(!row["paymentType"]["id"] || row["paymentType"]["id"] == 7){
        return "blue";
      }  else if(!row["paymentType"]["id"] || row["paymentType"]["name"].indexOf("GIRO") >= 0){
        return "blue";
      } else {
        return "purple";
      }
    
  }

  return (
    <Layout t={t} title={t("billgenerator.title")}>
      <DivPanel>
        <Filter t={t} i18n={"billgenerator"} cleanFilter={cleanFilter} searchFilter={searchFilter} filter={initFilter} estructure={createEstructureFilter}  hiddenButton={true} forceExpand={true}/>
        <Grid container>

            <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '3em', display:"flex", flexDirection:"row"}}>
               <span style={{display:"block", background:"orange",width: "20px", height:"20px", margin:"0 1em"}}></span><b>TRANSFERENCIA</b><span style={{display:"block", background:"blue",width: "20px", height:"20px", margin:"0 1em"}}></span><b>GIRO BANCARIO</b><span style={{display:"block", background:"purple",width: "20px", height:"20px", margin:"0 1em"}}></span><b>CONTADO</b>
            </Grid>
        </Grid>
        <DivTable>
          <CustomPaginationActionsTable t={t} i18n={"bill"} labels={{"client.comercialName|client.email|client.phone":t('bill.field.client.name')}} rows={rows} fields={["serie","number","date","client.comercialName|client.email|client.phone","base","tax","equivalencia","iva","total"]} fieldId={"id"} rowsPerPage={10} hiddenpagination={true} deleteaction={false} editable={false} defaultValues={{serie:"",number:"Borrador"}} paintCell={paintCell} paintvalue={{}} moreActions={[]}  selectable={false} handlerSelected={null} conditionDelete={null} columOrder={null} columOrderDirection={"ASC"} clickHeader={null} conditionColor={conditionColor}/>
        </DivTable>
        {rows.length > 0 && <Grid item xs={12} style={{textAlign:'right', marginBottom: '10px',marginTop: '30px'}}>
            <Button  variant="contained" color="secondary" style={{marginLeft:'30px'}} onClick={confirm}> {t("general.generar")} </Button>
        </Grid>}
      </DivPanel>
    </Layout>

  );
}

const DivPanel =  styled.div`
    padding: 2em;
    @media (max-width: 767px) {
      padding: 0.5em;
    }
`;

const DivTable =  styled.div`
`;