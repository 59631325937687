import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CrudTab from '../../crud/CrudTab';
import { clientpriceDeliveryService as service, productService } from '../../../services';
import { useTranslation } from 'react-i18next';

export default function TabProductprice(props) {
      const { t, i18n } = useTranslation();
      const [filter, setFilter] = useState({ client: props.idclient, to: null, from: null });

      const createEstructureFilter = [
            {
                  type: 'autocomplete',
                  name: 'product',
                  col: 4,
                  service: productService.combo,
            },
            {
                  type: 'input',
                  name: 'from',
                  col: 4,
                  inputtype: 'date',
            },
            {
                  type: 'input',
                  name: 'to',
                  col: 4,
                  inputtype: 'date',
            },
      ];

      const paintCell = {
            lastPrice: (value, object) => {
                  if (value) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
            amount: (value, object) => {
                  if (value) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
      };

      useEffect(() => {
            setFilter({ client: props.idclient, to: null, from: null });
      }, [props.idclient]);

      return (
            <>
                  <CrudTab
                        filter={filter}
                        service={service}
                        editable={false}
                        hiddenFilter={false}
                        deleteaction={false}
                        i18n={'clientprice'}
                        fieldstable={['product.nameComplete', 'updateDate', 'lastPrice', 'amount']}
                        labels={{ 'product.nameComplete': t('clientprice.field.product.name') }}
                        estructureForm={createEstructureFilter}
                        paintCell={paintCell}
                        hiddenpagination={true}
                        rowsPerPage={1000}
                        blockedinitsearch={props.load !== 'undefined' ? !props.load : false}
                        forcesearch={props.load !== 'undefined' ? props.load : true}
                  />
            </>
      );
}
