import CrudTable from "../../../crud/CrudTable"
import {movementsService as service, productService, clientService, supplierService} from "../../../../services"
import { t } from "i18next";

const initFilter =  {
  from : null,
  to: null,
  lote:null,
  type:null,
  product:null,
  client:null,
  supplier:null,
};




export default function Client() {

  const createEstructureFilter =  [
    {
      type:"input",
      name: "from",
      inputtype:"date",
      col: 4
     },
     {
       type:"input",
       name: "to",
       inputtype:"date",
       col: 4
     },
     {
      type:"autocomplete",
      name: "product",
      service: productService.combo,
      col: 4
     },
     {
       type:"input",
       name: "lote",
       col: 4
     },
     {
      type:"autocomplete",
      name: "type",
      options:[{key:0,name:"Ambos"},{key:1,name:"Salida"},{key:2,name:"Entrada"}],
      col: 4
     },
     {
      type:"autocomplete",
      name: "supplier",
      service: supplierService.combo,
      col: 4
     }
  ];

  const paintCell = {
    type: (value,object) => {
      if (value == 1) {
        return <>Salida</>
      } else {
        return <>Entrada</>
      }
    },

  }



  return (
    <CrudTable
          service={service}
          urledit={"/movements/edit/"}
          urlnew={"/movements/new/"}
          filter={initFilter}
          i18n={"movements"}
          fieldstable={["date","type","product.nameComplete","ammount","lote","parteRuta.code","supplier.name","deliveryNoteEntry.serieAndNumber"]}
          labels={{"product.nameComplete":t('movements.field.product.name')}}
          paintCell={paintCell}
          estructureForm={createEstructureFilter} />
  );


}
