import CrudTable from "../../../crud/CrudTable"
import {cashConfigureService as service} from "../../../../services"

const initFilter =  {
    ejercicio : null,
};


export default function CashConfigure() {

  const createEstructureFilter =  [
     {
      type:"autocomplete",
      name: "ejercicio",
      service: service.combo,
      col: 4
     },
  ];


  return (
    <CrudTable
          service={service}
          urledit={"/cashconfigure/edit/"}
          urlnew={"/cashconfigure/new/"}
          filter={initFilter}
          i18n={"cashconfigure"}
          fieldstable={["ejercicio","ammount"]}
          deleteaction={false}
          estructureForm={createEstructureFilter} />
  );
}
