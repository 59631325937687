import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTab from "../../../crud/CrudTab"
import {clientpriceService as service} from "../../../../services"
import PrintIcon from '@mui/icons-material/Print'
import 'toasted-notes/src/styles.css';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import{pad} from '../../../utils/Data'; 
import { Box } from '@mui/system';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import PublishIcon from '@mui/icons-material/Publish';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function TabProductprice(props) {

  const [filter,setFilter] = useState({
    product:props.idproduct
  });

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [openBill, setOpenBill] = React.useState(false);
  const [idBill, setIdBill] = React.useState<any>(null);
  var deliverynotesSelected = [];

  const createEstructureFilter =  [ ];

  useEffect(() => {
    setFilter({
      product:props.idproduct
    });
  },[props.idproduct])

  return (
      <><CrudTab
        filter={filter}
        service={service}
        editable={false}
        hiddenFilter={true}
        deleteaction={false}
        i18n={"clientprice"}
        fieldstable={["client.code|client.comercialName","updateDate","lastPrice","amount"]}
        labels={{"client.code|client.comercialName":"Cliente"}}
       /></>

  );
}
