import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTab from "../../crud/CrudTab"
import {billsupplierService as service, paymenttypeService, statebillsupplierService,billService} from "../../../services"
import PrintIcon from '@mui/icons-material/Print'
import 'toasted-notes/src/styles.css';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import{pad} from '../../utils/Data'; 
import { Box } from '@mui/system';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import PublishIcon from '@mui/icons-material/Publish';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function TabBillsupplier(props) {


  const [filter,setFilter] = useState({
    number:null,
    serie : null,
    supplier:props.idSupplier,
    from:null,
    to:null,
    state:null,
    route:null,
    paymenttype:null,
    product:null,
    lote:null
})


  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [openBill, setOpenBill] = React.useState(false);
  const [idBill, setIdBill] = React.useState<any>(null);
  var deliverynotesSelected = [];

  const createEstructureFilter =  [
    {
      type:"input",
      name: "serie",
      col: 3
     },
    {
      type:"input",
      name: "number",
      inputtype:"number",
      col: 3
     },
       {
        type:"input",
        name: "from",
        inputtype:"date",
        col: 3
       },
      {
        type:"input",
        name: "to",
        inputtype:"date",
        col: 3
      },
      {
        type:"autocomplete",
        name: "state",
        service: statebillsupplierService.combo,
        col: 3
        },
        {
          type:"autocomplete",
          name: "paymenttype",
          service: paymenttypeService.combo,
          col: 3
          }
  ];

  
  const paintCell = {
    base: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    discount: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    iva: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    total: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    equivalencia: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    tax: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    

  }

  useEffect(() => {
    setFilter({
      number:null,
      serie : null,
      supplier:props.idSupplier,
      from:null,
      to:null,
      state:null,
      route:null,
      paymenttype:null,
      product:null,
      lote:null
  });
  },[props.idSupplier])

  return (
      <><CrudTab
        service={service}
        urledit={"/deliverynotesupplier/edit/"}
        urlnew={"/deliverynotesupplier/new/"}
        filter={filter}
        i18n={"deliverynotesupplier"}
        fieldstable={["serie","number","date","supplier.comercialName|supplier.email|supplier.phone","base","tax","equivalencia","iva","total"]}
        defaultValues={{serie:"",number:"Borrador"}}
        paintCell={paintCell}
        labels={{"supplier.comercialName|supplier.email|supplier.phone":t('deliverynotesupplier.field.supplier.name')}}
        estructureForm={createEstructureFilter}
        haveother={true}
        selectable={false} /><ToastContainer/>
        </>

  );
}
