import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import CrudForm from "../../crud/CrudForm"
import {userService as service} from "../../../services"
import { useTranslation } from 'react-i18next';

const initElement = {
     email:"",
     pass: "",
     name: "",
     lastname: "",
     isClient:false,
     rol: {code:"ADMIN",name:"ADMIN"},
     enable: true,
     picture:null
  };

  type FormAdministratorParms = {
    id: string;
  };
  
export default function FormAdministrator() {
  const [objectform, setObjectform] = React.useState({...initElement});
  let { id } = useParams<FormAdministratorParms>();
  const [idRow, setIdrow] = useState(id?Number(id):0);
  const { t, i18n } = useTranslation();


  const estructureform =  [
          {
           type:"input",
           name: "email",
           readonly:idRow > 0?true:false,
           col: 4
          },
          {
           type:"input",
           name: "pass",
           col: 4,
           inputtype: "password"
          },
          {
           type:"input",
           name: "name",
           col: 4
          },
          {
           type:"input",
           name: "lastname",
           col: 4
          },
          {
           type:"image",
           name: "picture",
           col: 3
          }
    ];

  const recoveryElement = function(objectedit,element){


    objectedit['email']=element.email?element.email:"";
    objectedit['name']=element.name?element.name:"";
    objectedit['lastname']=element.lastname?element.lastname:"";
    objectedit['rol']={code:element.rol && element.rol.code ?element.rol.code:null, name: element.rol && element.rol.name?element.rol.name:null};
    objectedit['laundry']={id:element.laundry && element.laundry.id ?element.laundry.id:null, name: element.laundry && element.laundry.name?element.laundry.name:null};
    objectedit['picture']=element.picture?element.picture:"";

    return objectedit;
  }




  const isValid = function(object){
    if(!object['name']){
      return t("administrator.error.name");
    }
    if(!object['email']){
      return t("administrator.error.email");
    }
    if(!object['rol'] || !object['rol']['code'] || !object['rol']['code']==null){
      return t("administrator.error.rol");
    }

    return null;
}

  return (
    <><CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        i18n={"administrator"}
        urledit={"/administrator/edit/"}
        urlCancel={"/administrator"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />
    </>

  );
}
