import CrudTable from "../../../crud/CrudTable"
import {statebillsupplierService as service} from "../../../../services"

const initFilter =  {
    name : null,
    code: null,
};


export default function Statebillsupplier() {

  const createEstructureFilter =  [
     {
       type:"input",
       name: "name",
       col: 4
      },
  ];


  return (
    <CrudTable
          service={service}
          urledit={"/statebillsupplier/edit/"}
          urlnew={"/statebillsupplier/new/"}
          filter={initFilter}
          i18n={"statebillsupplier"}
          fieldstable={["id","name"]}
          estructureForm={createEstructureFilter} />
  );
}
