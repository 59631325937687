import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudFormTabs from "../../../crud/CrudFormTabs"
import {productService as service, categoryService, ivatypeService, supplierService } from "../../../../services"
import { useTranslation } from 'react-i18next';
import { headerFecth } from '../../../utils/Data';
import TabProductprice from './TabProductprice';
import TabLote from './TabLote';
import TabLotebuy from './TabLotebuy';
import {setValueMin, setLastBuyPrice, setValuePercentPvp, setValuePriceGenral,setCategory} from './HeaderForm'


  const initElement =  {
    code : null,
    name : null,
    price: 0.0,
    percentPvp: 0.0,
    iva:{id:null,name:null},
    tax:{id:null,name:null},
    shortDescription:null,
    imageprin: null,
    category:{id:null,name:null},
    subcategory:{id:null,name:null},
    lastBuyPrice:0.0,
    supplier:{id:null,name:null},
    lastBuyDate:null,
    automaticUpdatePrice:false,
    lote:"",
    minprice:0.0,
    percentMinPvp:0.0,
    taxresiduos:0.0,
    active:true,
    visiblePDA:true,
    subProduct:[]
    
};

const initline = {
  id: -1,
  product:{id:null,name:"", price:0},
  percent:0.0
}

type FormProductParms = {
  id: string;
};
  

export default function FormProduct(props) {
  let { id } = useParams<FormProductParms>();
  const { t, i18n } = useTranslation();
  const [categories, setCategories] = useState([{id:-1,name:""}]);
  const [objectproduct, setObjectproduct] = useState({...initElement});



const setValueProduct= (setValueField,objectform,value,valueform,setState,id,aux,parent) => {

  var newobject = {...objectform};

  if(aux){

    debugger;

    var product = aux;
    newobject =  setValueField(newobject,"product",product);
  
  }

  return newobject;
}

const setValuePercent = (setValueField,objectform,value,valueform,setState,id,aux,parent) => {

  var newobject = {...objectform};
  let diff = newobject["product"]["price"] - newobject["product"]["minprice"];
  newobject =  setValueField(newobject,"product.percentPvp",value);


  var valueformAux = {...valueform};
  var rv = valueform[id];
  rv['product.percentPvp'] = value;

  var percent = +value + 100;
  var pricebuy = +newobject["lastBuyPrice"];
  var price = pricebuy * (percent/100);
  var minprice = price - diff;

  newobject =  setValueField(objectform,"product.price",price);
  newobject =  setValueField(objectform,"product.minprice",minprice);

  var rv = valueform[id];
  rv["product.price"] = price.toFixed(2);
  rv["product.minprice"] = minprice.toFixed(2);



  var update = {
    valueform: valueformAux,
    objectform:newobject
  }
  setState(currentstate => ({...currentstate, ...update}))


  return newobject;
}

const setValuePrice = (setValueField,objectform,value,valueform,setState,id,aux,parent) => {
  var newobject = {...objectform};
  let diff = newobject["product"]["price"] - newobject["product"]["minprice"];
  newobject =  setValueField(newobject,"product.price",+value);
  newobject =  setValueField(newobject,"product.minprice",+value - diff);

  var price = +value;
  var lastBuyPrice = newobject["lastBuyPrice"];
  var percent = 0;
  if(lastBuyPrice && lastBuyPrice > 0){
    percent  = (price * 100 / lastBuyPrice) - 100;
    newobject =  setValueField(newobject,"product.percentPvp",percent.toFixed(2));
  } 

  var valueformAux = {...valueform};
  var rv = valueform[id];
  rv['product.price'] = value;
  rv['product.minprice'] = value - diff;
  rv['product.percentPvp'] = percent.toFixed(2);


  var update = {
    valueform: valueformAux,
    objectform:newobject
  }
  setState(currentstate => ({...currentstate, ...update}))


  return newobject;
}


const setValueMerma = (setValueField,objectform,value,valueform,setState,id,aux,parent) => {
  var newobject = {...objectform};

  let diff = newobject["product"]["price"] - newobject["product"]["minprice"];

  debugger;
  newobject =  setValueField(newobject,"merma",+value);

  var percent = +value;
  var priceparent = +parent.lastBuyPrice;
  var price = priceparent * 1+(percent/100);

  newobject =  setValueField(objectform,"lastBuyPrice",price.toFixed(2));

  
  var valueformAux = {...valueform};
  var rv = valueform[id];
  rv['merma'] = value;
  rv["lastBuyPrice"] = price.toFixed(2);
  if(newobject["product"] && newobject["product"]["percentPvp"]) {
    var priceSales = price * (1+(newobject["product"]["percentPvp"] / 100));
    rv["product.price"] = priceSales.toFixed(2);
    rv["product.minprice"] = (priceSales - diff).toFixed(2);
    newobject =  setValueField(objectform,"product.price",priceSales);
    newobject =  setValueField(objectform,"product.minprice",priceSales - diff);

  }

 
  var update = {
    valueform: valueformAux,
    objectform:newobject
  }
  setState(currentstate => ({...currentstate, ...update}))




  return newobject;
}



const estructureformline =  [


   {
    type:"autocomplete",
    name: "product",
    service:service.all,
    colSpan:"40%",
    autoFocus:true,
    searchcode:true,
    setvalue: setValueProduct,
    col: 4
   },
   {
     type:"input",
     name: "merma",
     inputtype:"number",
     step:0.01,
     colSpan:"10%",
     setvalue:setValueMerma,
     col: 4
    },
    {
      type:"input",
      name: "lastBuyPrice",
      inputtype:"number",
      step:0.01,
      colSpan:"10%",
      readonly:true,
      col: 4
     },
   {
     type:"input",
     name: "product.percentPvp",
     inputtype:"number",
     step:0.01,
     colSpan:"15%",
     setvalue:setValuePercent,
     col: 4
    },
   {
     type:"input",
     name: "product.price",
     inputtype:"number",
     step:0.01,
     colSpan:"15%",
     setvalue:setValuePrice,
     col: 3
    },
    {
      type:"input",
      name: "product.minprice",
      inputtype:"number",
      step:0.01,
      tabeventnew:true,
      colSpan:"15%",
      col: 3
     }
];




  const estructureform =  [
      {
        type:"input",
        name: "code",
        inputtype:"number",
        step:1,
        col: 4,
      },
      {
        type:"input",
        name: "name",
        col: 4
       },
       {
          type:"autocomplete",
          name: "category.id",
          service:categoryService.combo,
          relationfield:'subcategory.id',
          servicerelation:categoryService.comboChildren,
          col: 2,
        },
        {
           type:"autocomplete",
           name: "subcategory.id",
           options:[],
           col: 2,
           setvalue: setCategory
         },
         {
            type:"autocomplete",
            name: "supplier.id",
            service:supplierService.combo,
            col: 4,
          },
          {
            type:"input",
            name: "lastBuyDate",
            col: 4,
            inputtype:"date",
           },
       {
         type:"input",
         name: "lastBuyPrice",
         col: 4,
         inputtype:"number",
         step:0.01,
         setvalue: setLastBuyPrice
        },
        {
          type:"input",
          name: "percentPvp",
          col: 4,
          inputtype:"number",
          step:0.01,
          setvalue: setValuePercentPvp
         },
         {
           type:"input",
           name: "price",
           col: 4,
           inputtype:"number",
           setvalue:setValuePriceGenral,
           step:0.01,
          },
           {
             type:"input",
             name: "percentMinPvp",
             col: 4,
             inputtype:"number",
             step:0.01,
             readonly:true
            },
            {
              type:"input",
              name: "minprice",
              col: 4,
              inputtype:"number",
              step:0.01,
              setvalue:setValueMin
             },
          {
            type:"input",
            name: "lote",
            col: 4
          },
          {
            type:"autocomplete",
            name: "iva.id",
            service:ivatypeService.combo,
            col: 4
          },
          {
            type:"checkbox",
            name: "active",
            col: 2
          },
          {
            type:"checkbox",
            name: "automaticUpdatePrice",
            col: 2
          },
          {
            type:"checkbox",
            name: "visiblePDA",
            col: 2
          },
          {
            type:"editor",
            name: "shortDescription",
            col: 10
          },
          {
            type:"image",
            name: "imageprin",
            col: 2
          },
          {
            type:"title",
            name: "titlesubproduct",
            col: 12
           },
          {
            type:"table",
            name: "subProduct",
            headers:[{label:"Producto",colSpan:"20%"},{label:"Merma",colSpan:"10%"   },{label:"Precio compra",colSpan:"10%"   },{label:"% PVP",colSpan:"10%"   },{label:"Precio",colSpan:"10%"   },{label:"Precio Min Venta",colSpan:"10%"   }],
            initrow:{... initline},
            fields:estructureformline,
            fieldId:"id",
            col: 12
           },

   ];



   
   
  

   

  const recoveryElement = function(objectedit,element){


    objectedit['code']=element.code?element.code:"";
    objectedit['name']=element.name?element.name:"";
    objectedit['lote']=element.lote?element.lote:"";
    objectedit['price']=element.price?element.price.toFixed(2):0.0;
    objectedit['percentPvp']=element.percentPvp?element.percentPvp.toFixed(2):0.0;
    objectedit['lastBuyPrice']=element.lastBuyPrice?element.lastBuyPrice.toFixed(2):0.0;
    objectedit['shortDescription']=element.shortDescription?element.shortDescription:"";
    objectedit['imageprin']=element.imageprin?element.imageprin:null;
    objectedit['category']=element.category?element.category:{id:null,name:""};
    objectedit['subcategory']=element.subcategory?element.subcategory:{id:null,name:""};
    objectedit['iva']=element.iva?element.iva:{id:null,name:""};
    objectedit['taxresiduos']=element.taxresiduos?element.taxresiduos:0.0;
    objectedit['minprice']=element.minprice?element.minprice:0.0;
    objectedit['percentMinPvp']=element.percentMinPvp?element.percentMinPvp:0.0;
    objectedit['automaticUpdatePrice']=element.automaticUpdatePrice?element.automaticUpdatePrice:false;
    objectedit['active']=element.active?element.active:false;
    objectedit['subProduct']=element.subProduct?element.subProduct:[];
    objectedit['supplier']=element.supplier?element.supplier:{id:null,name:""};
    objectedit['lastBuyDate']=element.lastBuyDate?element.lastBuyDate:null;
    objectedit['visiblePDA']=element.visiblePDA?element.visiblePDA:false;
    
    return objectedit;
  }

  const removeLines = function(object){
    var other = true;
    var posRemove = -1;
    for (var i = 0; i < object['subProduct'].length; i++ ){
      var r = object['subProduct'][i];
      if(!r['product'] || !r['product']['id']){
        posRemove = i;
        break;
      }
    }

    if(posRemove > -1){
      object['subProduct'].splice(posRemove, 1);
      removeLines(object);
    }
  } 

  const testPrice = function(object){
    for (var i = 0; i < object['subProduct'].length; i++ ){
      var r = object['subProduct'][i];
      if(r['product.price'] && !r['product']['price']){
        r['product']['price'] = r['product.price'];
      }
    }
  }


  const isValid = function(object){
      debugger;
      removeLines(object);
      testPrice(object);

      if(!object['name']){
        return t("product.error.name");
      }
      if(!object['price']){
        return t("product.error.price");
      }
      if(!object['category'] || !object['category']['id']){
        return t("product.error.category");
      }
      if(!object['iva'] || !object['iva']['id']){
        return t("product.error.iva");
      }

      return null;
  }


  const getPrice = (id) => {
    return  <TabProductprice idproduct={id}/>;
  }


  const getLote = (id) => {
    return   <TabLote idproduct={id}/>;
  }


  const getLoteBuy = (id) => {
    return <TabLotebuy idproduct={id}/>;
  }


  return (
    <CrudFormTabs
        objectform = {objectproduct}
        estructureform = {estructureform}
        i18n={"product"}
        urledit={"/product/edit/"}
        urlCancel={"/product"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
        search={true}
        searchCombo={true}
        fieldcode={"code"}
        labelCode={"Buscar articulo por código"}
        tabs={[{title:"product.tab.productprice", method:getPrice},{title:"product.tab.lote", method:getLote},{title:"product.tab.lotebuy", method:getLoteBuy }]}
      />

  );
}



