import CrudTable from "../../../crud/CrudTable"
import {billtypeService as service} from "../../../../services"

const initFilter =  {
    name : null,
    code: null,
};


export default function Billtype() {

  const createEstructureFilter =  [
     {
       type:"input",
       name: "name",
       col: 4
      },
  ];


  return (
    <CrudTable
          service={service}
          urledit={"/billtype/edit/"}
          urlnew={"/billtype/new/"}
          filter={initFilter}
          i18n={"billtype"}
          fieldstable={["code","name"]}
          estructureForm={createEstructureFilter} />
  );
}
