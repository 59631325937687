import React, { useEffect, useState } from 'react';
import Layout from '../../../layout/Layout';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { clientService } from '../../../../services';
import CrudEmpty from '../../../crud/CrudEmpty';
import Filter from '../../../filter/Filter';
import styled from 'styled-components';
import { red } from '@mui/material/colors';
import { InfoOutlined } from '@mui/icons-material';
import CustomPaginationActionsTable from '../../../table/Table';
import { Autocomplete, TextField, Button } from '@mui/material';
import { textAlign } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilePdf, faFileWord } from '@fortawesome/free-solid-svg-icons';
const moment = require('moment');

const initFilter = {
      from: '2024-01-01T00:00:00Z',
      to: moment().format('YYYY-MM-DD') + 'T23:59:59Z',
      idclient: null,
};

export default function ClientMayor() {
      const { t, i18n } = useTranslation();
      const [anchorEl, setAnchorEl] = React.useState(null);

      const [open, setOpen] = React.useState(true);
      const [data, setData] = React.useState([]);
      const [filter, setFilter] = React.useState({ ...initFilter });
      const [exportAction, setExportAction] = React.useState(0);
      const [exportActionPdf, setExportActionPdf] = React.useState(0);
      const [exportActionWord, setExportActionWord] = React.useState(0);

      const createEstructureFilter = [
            {
                  type: 'autocomplete',
                  name: 'idclient',
                  service: clientService.combo,
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'from',
                  inputtype: 'date',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'to',
                  inputtype: 'date',
                  col: 4,
            },
      ];

      const search = (filter) => {
            setFilter(filter);
            clientService.reportMayor(filter.idclient, moment(filter.from).format('YYYYMMDD'), moment(filter.to).format('YYYYMMDD'), (data, error) => {
                  if (data && data.data) {
                        var d = data.data;
                        var lines: any[] = [];
                        lines.push({
                              fecha: null,
                              concepto: 'Saldos anteriores',
                              documento: null,
                              importe_debe: d.saldo,
                              importe_haber: 0,
                              saldo: d.saldo,
                        });

                        lines = lines.concat(d['lines'] != null ? d['lines'] : []);
                        lines = lines.concat([
                              {
                                    fecha: null,
                                    concepto: 'Total cuenta',
                                    documento: null,
                                    importe_debe: d.totalDebe,
                                    importe_haber: d.totalHaber,
                                    saldo: d.totalSaldo,
                              },
                        ]);

                        d['lines'] = lines;

                        setData(data.data);
                  }
            });
      };

      const exportToExcel = () => {
            setExportAction((currentState) => currentState + 1);
      };

      const exportToPdf = () => {
            setExportActionPdf((currentState) => currentState + 1);
      };

      const exportToWord = () => {
            setExportActionWord((currentState) => currentState + 1);
      };

      useEffect(() => {
            setFilter({
                  from: '2024-01-01T00:00:00Z',
                  to: moment().format('YYYY-MM-DD') + 'T23:59:59Z',
                  idclient: null,
            });
      }, []);

      useEffect(() => {
            if (exportAction > 0) {
                  setExportAction(0);
                  clientService.reportMayorFilterExcel(filter.idclient, moment(filter.from).format('YYYYMMDD'), moment(filter.to).format('YYYYMMDD'), (data, error) => {});
            }
      }, [exportAction, filter]);

      useEffect(() => {
            if (exportActionPdf > 0) {
                  setExportActionPdf(0);
                  clientService.reportMayorFilterPdf(filter.idclient, moment(filter.from).format('YYYYMMDD'), moment(filter.to).format('YYYYMMDD'), (data, error) => {});
            }
      }, [exportActionPdf, filter]);

      useEffect(() => {
            if (exportActionWord > 0) {
                  setExportActionWord(0);
                  clientService.reportMayorFilterWord(filter.idclient, moment(filter.from).format('YYYYMMDD'), moment(filter.to).format('YYYYMMDD'), (data, error) => {});
            }
      }, [exportActionWord, filter]);

      const paintCell = {
            importe_debe: (value, object) => {
                  if (value || value == 0) {
                        return <p style={{ textAlign: 'right' }}>{value.toFixed(2)}</p>;
                  } else {
                        return <></>;
                  }
            },
            importe_haber: (value, object) => {
                  if (value || value == 0) {
                        return <p style={{ textAlign: 'right' }}>{value.toFixed(2)}</p>;
                  } else {
                        return <></>;
                  }
            },
            saldo: (value, object) => {
                  if (value || value == 0) {
                        return <p style={{ textAlign: 'right' }}>{value.toFixed(2)}</p>;
                  } else {
                        return <></>;
                  }
            },
            fecha: (value, object) => {
                  if (value) {
                        return <p style={{ textAlign: 'left' }}>{moment(value).format('DD/MM/YYYY')}</p>;
                  } else {
                        return <></>;
                  }
            },
      };

      const conditionColor = (row) => {
            if (row['fecha']) {
                  return 'black';
            } else {
                  return 'red';
            }
      };

      return (
            <CrudEmpty i18n={'reportclient'}>
                  <Grid container style={{ background: 'white', padding: '0.3em 1em 1em', borderRadius: '3px' }}>
                        <Grid item sm={12} xs={12} style={{ textAlign: 'left', marginBottom: '3em', paddingTop: '3em' }}>
                              <Filter t={t} i18n={'mayor'} cleanFilter={() => {}} searchFilter={search} filter={filter} estructure={createEstructureFilter} hiddenButton={true} expanded={true} isHiddenCancel={true} />
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'right', marginBottom: '10px' }}>
                              <Button variant="text" color="secondary" style={{ marginLeft: '5px', fontSize: '30px' }} onClick={exportToExcel}>
                                    {' '}
                                    <FontAwesomeIcon icon={faFileExcel} />{' '}
                              </Button>
                              <Button variant="text" color="secondary" style={{ marginLeft: '5px', fontSize: '30px', color: 'red' }} onClick={exportToPdf}>
                                    {' '}
                                    <FontAwesomeIcon icon={faFilePdf} />{' '}
                              </Button>
                              <Button variant="text" color="secondary" style={{ marginLeft: '5px', fontSize: '30px', color: 'blue' }} onClick={exportToWord}>
                                    {' '}
                                    <FontAwesomeIcon icon={faFileWord} />{' '}
                              </Button>
                        </Grid>
                        <Grid item sm={12} xs={12} style={{ textAlign: 'left', marginBottom: '3em' }}>
                              <PanelRounded>
                                    <h3>{t('mayor.title')}</h3>
                                    <Grid container>
                                          <Grid item sm={12} xs={12} style={{ textAlign: 'left', marginBottom: '10px', marginTop: '20px' }}>
                                                <CustomPaginationActionsTable
                                                      t={t}
                                                      i18n={'mayor'}
                                                      rows={data['lines']}
                                                      fields={['fecha', 'concepto', 'documento', 'importe_debe', 'importe_haber', 'saldo']}
                                                      fieldId={'id'}
                                                      hiddenpagination={true}
                                                      deleteaction={false}
                                                      selectHandler={null}
                                                      editable={false}
                                                      defaultValues={{}}
                                                      paintCell={paintCell}
                                                      paintvalue={{}}
                                                      selectable={false}
                                                      handlerSelected={null}
                                                      conditionColor={conditionColor}
                                                />
                                          </Grid>
                                    </Grid>
                              </PanelRounded>
                        </Grid>
                  </Grid>
            </CrudEmpty>
      );
}

const PanelRounded = styled.div((props) => ({
      display: 'block',
      padding: '1em',
      position: 'relative',
      border: '1px solid #ccc',
      borderRadius: '3px',
      '& h3': {
            color: 'black',
            position: 'absolute',
            top: '-2em',
            left: '2em',
            background: 'white',
            padding: '0.2em 1em',
            display: 'inline-block',
      },
}));

const SpanAmmount = styled.span((props) => ({
      display: 'inline-block',
      fontWeight: 'bold',
      padding: '0.2em 1em',
}));
