import CrudTable from "../../../crud/CrudTable"
import {cashService as service} from "../../../../services"

const initFilter =  {
    from: null,
    to: null,
    concept: null,
    type : 1,
};


export default function CashExit() {

  const createEstructureFilter =  [
     {
      type:"input",
      name: "from",
      inputtype:"date",
      col: 4
     },
     {
      type:"input",
      name: "to",
      inputtype:"date",
      col: 4
     },
     {
      type:"input",
      name: "concept",
      col: 4
     },
     {
      type:"autocomplete",
      name: "type",
      options:[{key:0,name:"Ambos"},{key:1,name:"Salida"},{key:2,name:"Entrada"}],
      col: 4
     },
  ];


  return (
    <CrudTable
          service={service}
          urledit={"/cashexit/edit/"}
          urlnew={"/cashexit/new/"}
          filter={initFilter}
          i18n={"cashexit"}
          fieldstable={["date","concept","ammount","exit"]}
          deleteaction={false}
          estructureForm={createEstructureFilter} />
  );
}
