import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTab from "../../crud/CrudTab"
import 'toasted-notes/src/styles.css';
import { useTranslation } from 'react-i18next';
import {paymentInRouteBillService as service} from "../../../services"

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const initFilter =  {
  from : '2000-01-01T00:00:00Z',
  to: '2000-01-01T23:00:00Z',
  route:-1
};


export default function TabPaymentInRouteBill(props) {

  const history = useHistory();
  const { t, i18n } = useTranslation();

  const[{filter,rows,forcesearch},setState] = useState({
    filter:{...initFilter},
    rows:[],
    forcesearch:false
  })




  useEffect(() => {
    
    var filterAux = {...filter}
    filterAux["route"] = props.route.id;
    filterAux["from"] = props.dates.from;
    filterAux["to"] = props.dates.to;
  
    if(props.route.id > 0){

      var update = {
        filter:filterAux,
        forcesearch:!forcesearch
      }
      setState(currentState => ({...currentState, ...update}))
    } else {
      var update2 = {
        filter:filterAux,
        rows:[]
      }
      setState(currentState => ({...currentState, ...update2}))
    }
  
  }, [props.route,props.dates]);

  useEffect(() => {
    return () => {
  
  
      
      setState({
        filter:{
          from : '2000-01-01T00:00:00Z',
          to: '2000-01-01T23:00:00Z',
          route:-1
        },
        rows:[],
        forcesearch:false});
     }
   
  }, []);

  const paintCell = {
    ammount: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    }

  }

  const conditionColor = (row) => {
    
    if(row["payLess"] && +row["payLess"] > 1){
      return "red";
    } else {
      return "black";
    }
  }

  return (
      <><CrudTab
        filter={filter}
        service={service}
        editable={false}
        deleteaction={false}
        hiddenFilter={true}
        blockedinitsearch={true}  
        rows={rows}
        hiddenpagination={true}
        paintCell={paintCell}
        conditionColor={conditionColor}
        i18n={"paymentInRouteBill"}
        fieldstable={["bill.serie","bill.number","bill.date","bill.client.comercialName|bill.client.email|bill.client.phone","ammount"]}
        labels={{"bill.client.comercialName|bill.client.email|bill.client.phone":t('bill.field.client.name'), "bill.serie":t('bill.field.serie'), "bill.number":t('bill.field.number'),"bill.date":t('bill.field.date'),"ammount":"Cantidad cobrada"}}
        defaultValues={{serie:"",number:"Borrador"}}
        rowsPerPage={400}
       /></>

  );

}
