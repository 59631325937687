import BaseService from '../_base.service';

const URL = "/tpv/route";

export const routetpvService = {
    search,
    combo,
    comboState,
    page,
    pageFilter,
    one
};

function search(offset,callback) {
    return BaseService.get(URL+"/all/", callback)
}

function combo(callback) {
    return BaseService.get(URL+"/combo/", callback, true)
}
function comboState(id,callback) {
    return BaseService.get(URL+"/combo/state/"+id, callback, true)
}
function page(page,size,callback) {
    return BaseService.get(URL+"/page/"+page+"/"+size, callback, true)
}

function pageFilter(page,size,filter,callback) {
    return BaseService.post(URL+"/page/"+page+"/"+size, filter, callback, true)
}

function one(id,callback) {
    return BaseService.get(URL + "/one/"+id, callback);
}
