import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTable from "../../../crud/CrudTable"
import {deliverynoteService as service, clientService, routeService, paymenttypeService, statedeliveryService,billService,productService} from "../../../../services"
import PrintIcon from '@mui/icons-material/Print'
import 'toasted-notes/src/styles.css';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import{pad} from '../../../utils/Data'; 
import { Box } from '@mui/system';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import PublishIcon from '@mui/icons-material/Publish';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

type Filter = {
    number:any,
    serie : any,
    client:any,
    from:any,
    to:any,
    state:any,
    route:any,
    paymenttype:any,
    product:any,
    lote:any,
    isNotPay:any
}

const initFilter:Filter =  {
    number:null,
    serie : null,
    client:null,
    from:null,
    to:null,
    state:null,
    route:null,
    paymenttype:null,
    product:null,
    lote:null,
    isNotPay:true
};


export default function Deliverynotepending() {

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [openBill, setOpenBill] = React.useState(false);
  const [idBill, setIdBill] = React.useState<any>(null);
  var deliverynotesSelected = [];

  const queryParams = new URLSearchParams(window.location.search)
  const product = queryParams.get("product");
  const lote = queryParams.get("lote");
  initFilter['product'] = product?product:null;
  initFilter['lote'] = lote?lote:null;

  const createEstructureFilter =  [
    {
      type:"input",
      name: "serie",
      col: 3
     },
    {
      type:"input",
      name: "number",
      inputtype:"number",
      col: 3
     },
     {
       type:"autocomplete",
       name: "client",
       service: clientService.combo,
       col: 3
      },
       {
        type:"input",
        name: "from",
        inputtype:"date",
        col: 3
       },
      {
        type:"input",
        name: "to",
        inputtype:"date",
        col: 3
      },
      {
        type:"autocomplete",
        name: "state",
        service: statedeliveryService.combo,
        col: 3
        },
        {
          type:"autocomplete",
          name: "route",
          service: routeService.combo,
          col: 3
        },
        {
          type:"autocomplete",
          name: "paymenttype",
          service: paymenttypeService.combo,
          col: 3
          },
          {
            type:"autocomplete",
            name: "product",
            service: productService.combo,
            col: 3
            },
          {
            type:"input",
            name: "lote",
            col: 3
           },
  ];

  const handlePrint = (bill) => {

    var isGenerate = bill && bill.number && bill.number != '' && bill.state && bill.state.id && bill.state.id != 1;
    if (isGenerate){
      let fileName = "Albarán"+(bill.serie?bill.serie:"")+pad(+bill.number,5)+".pdf";
      service.print(bill.id,fileName,  (data,erro) =>{
        toast.success("Se ha descargado la albarán", {  position: toast.POSITION.TOP_CENTER });  
      });
    } else {
      toast.error("Solo se puede imprimir albaranes ya generados", {  position: toast.POSITION.TOP_CENTER });
    }
    
  }

  const handelBill = (bill) => {
    setIdBill(bill.id);
    setOpenBill(!openBill);    
  }
  
  
  const paintCell = {
    base: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    discount: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    iva: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    total: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    equivalencia: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    tax: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    payTotal: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    payLess: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    

  }

  const generateBill = () =>{
    billService.deliverynote(idBill, (data,error) =>{

      if (data && data.data && data.data.id){
        history.push("/bill/edit/"+data.data.id);
        window.location.reload();
      } else {
        setOpenBill(!openBill);
        toast.error(error.mes, { position: toast.POSITION.TOP_CENTER })
      }
    });
  }



  const handleCloseBill = () => {
    setOpenBill(!openBill);
  };


  const handlerSelected = (ids) => {
    deliverynotesSelected = ids;
  }

  useEffect(()=>{
    deliverynotesSelected = [];
  },[]);

  const clickCreateBill = (evt) => {
    debugger;
    if(!deliverynotesSelected || deliverynotesSelected.length == 0){
      toast.error("Debe seleccionar de que albaranes quiere crear la factura",{ position: toast.POSITION.TOP_CENTER })
    } else {
        billService.deliverynotes(deliverynotesSelected,(data,error)=>{
          if (data && data.data && data.data.id){
            history.push("/bill/edit/"+data.data.id);
            window.location.reload();
          } else {
            toast.error(error.mes,{ position: toast.POSITION.TOP_CENTER })
          }
        });
    }
  }



  const conditionIsGenerate = (bill) => {
    var isGenerate = bill && bill.number && bill.number != '' && bill.state && bill.state.id && bill.state.id != 1;
    return isGenerate
  }

  const conditionDelete = (bill) => {
    var isGenerate = bill && bill.number && bill.number != '' && bill.state && bill.state.id && bill.state.id != 1;
    return !isGenerate
  }

  const conditionColor = (row) => {
    
    if(row["isPay"]){
      return "black";
    } else {
      if(row["number"]){
        if(!row["paymentType"]["id"] || row["paymentType"]["id"] == 1){
          return "red";
        } else {
          return "orange";
        }
      } else {
        return "black";
      }
    }
  }
  
  return (
      <><CrudTable
        service={service}
        title={"Albaranes pendientes de cobro"}
        urledit={"/deliverynote/edit/"}
        urlnew={"/deliverynote/new/"}
        filter={initFilter}
        i18n={"deliverynote"}
        fieldstable={["serie","number","date","client.comercialName|client.email|client.phone","base","equivalencia","iva","total","paytotal","payLess","state.name","paymentType.name","isPay"]}
        defaultValues={{serie:"",number:"Borrador"}}
        moreActions={[{"handle":handlePrint, icon:<PrintIcon />, condition: conditionIsGenerate},{"handle":handelBill, icon:<PublishIcon />, condition: conditionIsGenerate}]}
        conditionDelete={conditionDelete}
        paintCell={paintCell}
        labels={{"client.comercialName|client.email|client.phone":t('bill.field.client.name')}}
        estructureForm={createEstructureFilter}
        order={true}
        hiddenButton={true}
        conditionColor={conditionColor}
        orderColumns={{"serie":"serie", "number":"number", "date":"date"}}   /><ToastContainer/>
      <Modal
            open={openBill}
            onClose={handleCloseBill}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
            <Box sx={style}>
              <div style={{marginBottom:'30px'}}>
                  ¿Quieres hacer una factura de este albarán?
              </div>
              <div>
                <Button variant="outlined" color="primary" style={{marginRight:'30px'}} onClick={handleCloseBill}> No</Button>
                <Button variant="contained" color="primary" onClick={generateBill}> Sí </Button>
              </div>
          </Box>
        </Modal>
        </>

  );
}
