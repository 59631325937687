import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../../crud/CrudForm"
import {cashService as service } from "../../../../services"
import { useTranslation } from 'react-i18next';

const moment = require("moment");

  const initElement =  {
    concept : null,
    ammount: null,
    date: moment().format('YYYY-MM-DD')+'T00:00:00Z',
    exit: true,
    type : 1,
    totalInCash: 0,
};


export default function FormCashExist() {
  const { t, i18n } = useTranslation();


  const estructureform =  [
      {
        type:"input",
        name: "date",
        inputtype:"date",
        col: 4
      },
      {
        type:"input",
        name: "concept",
        col: 4
       },
       {
         type:"input",
         name: "ammount",
         inputtype:"number",
         step:0.1,
         col: 4
        },
        {
         type:"autocomplete",
         name: "type",
         options:[{key:1,name:"Salida"},{key:2,name:"Entrada"}],
         col: 4
        },
   ];

  const recoveryElement = function(objectedit,element){

    objectedit['id']=element.id?element.id:null;
    objectedit['concept']=element.concept?element.concept:null;
    objectedit['ammount']=element.ammount?element.ammount:null;
    objectedit['date']=element.date?element.date:null;
    objectedit['exit']=element.exit?element.exit:null;
    objectedit['totalInCash']=element.totalInCash?element.totalInCash:null;
    objectedit['type']=element.type?element.type:null;

    return objectedit;
  }



  const isValid = function(object){
      if(!object['concept']){
        return t("cashexit.error.concept");
      }
      if(!object['ammount']){
        return t("cashexit.error.ammount");
      }
      if(!object['date']){
        return t("cashexit.error.date");
      }
      if(!object['type']){
        return t("cashexit.error.type");
      }

      return null;
  }



  return (
    <CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        i18n={"cashexit"}
        urledit={"/cashexit/edit/"}
        urlCancel={"/cashexit"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />

  );
}
