import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import CrudTable from '../../../crud/CrudTable';
import { billService as service, clientService, routeService, paymenttypeService, statebillService, billsupplierService } from '../../../../services';
import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ToastContainer, toast } from 'react-toastify';
import 'toasted-notes/src/styles.css';
import { useTranslation } from 'react-i18next';
import { pad } from '../../../utils/Data';
import { Box } from '@mui/system';
import { Button, Grid } from '@mui/material';
import Modal from '@mui/material/Modal';
import DownloadIcon from '@mui/icons-material/Download';
import MailIcon from '@mui/icons-material/Mail';

const moment = require('moment');

const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'white',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
};

const initFilter = {
      number: null,
      serie: null,
      client: null,
      from: moment().format('YYYY') + '-01-01T00:00:00Z',
      to: null,
      state: null,
      route: null,
      paymenttype: null,
      paymenttypeClient: null,
};

export default function Bill() {
      const history = useHistory();
      const { t, i18n } = useTranslation();
      const [{ openRect, billRect, filter, evetclick, ids, typePrint }, setState] = useState({
            openRect: false,
            billRect: {},
            filter: { ...initFilter },
            evetclick: 0,
            ids: [],
            typePrint: -1,
      });

      const createEstructureFilter = [
            {
                  type: 'autocomplete',
                  name: 'route',
                  service: routeService.combo,
                  col: 3,
            },
            {
                  type: 'autocomplete',
                  name: 'serie',
                  options: [
                        { key: '', name: 'TODAS' },
                        { key: 'S1', name: 'S1' },
                        { key: 'S2', name: 'S2' },
                        { key: 'R', name: 'R' },
                  ],
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'number',
                  inputtype: 'number',
                  autoFocus: true,
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'cif',
                  col: 3,
            },
            {
                  type: 'autocomplete',
                  name: 'client',
                  service: clientService.combo,
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'from',
                  inputtype: 'date',
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'to',
                  inputtype: 'date',
                  col: 3,
            },
            {
                  type: 'autocomplete',
                  name: 'state',
                  service: statebillService.combo,
                  col: 3,
            },
            {
                  type: 'autocomplete',
                  name: 'paymenttype',
                  service: paymenttypeService.combo,
                  col: 3,
            },
            {
                  type: 'autocomplete',
                  name: 'paymenttypeClient',
                  service: paymenttypeService.combo,
                  col: 3,
            },
      ];

      const handlePrint = (bill) => {
            var isGenerate = bill && bill.number && bill.number != '' && bill.state && bill.state.id && bill.state.id != 1;
            if (isGenerate) {
                  let fileName = 'Factura' + (bill.serie ? bill.serie : '') + pad(+bill.number, 5) + '.pdf';
                  service.print(bill.id, fileName, (data, erro) => {
                        toast.success('Se ha descargado la factura', { position: toast.POSITION.TOP_CENTER });
                  });
            } else {
                  toast.error('Solo se puede imprimir factura ya generadas', { position: toast.POSITION.TOP_CENTER });
            }
      };

      const handlePrintDirect = (bill) => {
            var isGenerate = bill && bill.number && bill.number != '' && bill.state && bill.state.id && bill.state.id != 1;
            if (isGenerate) {
                  let fileName = 'Factura' + (bill.serie ? bill.serie : '') + pad(+bill.number, 5) + '.pdf';
                  service.printDirect(bill.id, fileName, (data, erro) => {
                        toast.success('Se ha descargado la factura', { position: toast.POSITION.TOP_CENTER });
                  });
            } else {
                  toast.error('Solo se puede imprimir factura ya generadas', { position: toast.POSITION.TOP_CENTER });
            }
      };

      const handleSend = (bill) => {
            service.send(bill.id, (data, erro) => {
                  toast.success('Se ha enviado al cliente corrcetamente', { position: toast.POSITION.TOP_CENTER });
            });
      };

      const paintCell = {
            base: (value, object) => {
                  if (value) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
            discount: (value, object) => {
                  if (value) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
            iva: (value, object) => {
                  if (value) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
            total: (value, object) => {
                  if (value) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
            equivalencia: (value, object) => {
                  if (value) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
            tax: (value, object) => {
                  if (value) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
      };

      const handleRecti = (bill) => {
            var isGenerate = bill && bill.number && bill.number != '' && bill.state && bill.state.id && bill.state.id != 1;
            if (isGenerate) {
                  var update = {
                        billRect: bill,
                        openRect: true,
                  };
                  setState((currentState) => ({ ...currentState, ...update }));
            } else {
                  toast.error('Solo se puede hacer rectificativas de factura ya generadas', { position: toast.POSITION.TOP_CENTER });
            }
      };

      const rectify = () => {
            handleCloseRect();
            service.rectificative(billRect['id'], (data, error) => {
                  if (data.data) {
                        history.push('/bill/edit/' + data.data.id);
                  }
            });
      };

      const handleCloseRect = () => {
            var update = {
                  openRect: true,
            };
            setState((currentState) => ({ ...currentState, ...update }));
      };

      const conditionIsGenerate = (bill) => {
            var isGenerate = bill && bill.number && bill.number != '' && bill.state && bill.state.id && bill.state.id != 1;
            return isGenerate;
      };

      const conditionIsGenerateAndSend = (bill) => {
            var isGenerate = bill && bill.number && bill.number != '' && bill.state && bill.state.id && bill.state.id != 1 && bill['client'] && bill['client']['email'];
            return isGenerate;
      };

      const conditionDelete = (bill) => {
            var isGenerate = bill && bill.number && bill.number != '' && bill.state && bill.state.id && bill.state.id != 1;
            return !isGenerate;
      };

      const conditionColor = (row) => {
            if (row['number']) {
                  if (!row['paymentType'] || !row['paymentType']['id'] || row['paymentType']['id'] == 1) {
                        //CONTADO
                        return 'purple';
                  } else if (!row['paymentType']['id'] || row['paymentType']['id'] == 3) {
                        //CONTADO MENSUAL
                        return 'orange';
                  } else if (!row['paymentType']['id'] || row['paymentType']['id'] == 7) {
                        //TRANSFERENCIA BANCARIA
                        return 'blue';
                  } else if (!row['paymentType']['id'] || row['paymentType']['id'] == 5) {
                        //GIRO REMESA BANCARIA MENSUAL
                        return 'green';
                  } else if (!row['paymentType']['id'] || row['paymentType']['id'] == 8) {
                        //TPV
                        return 'lightseagreen';
                  } else if (!row['paymentType']['id'] || row['paymentType']['id'] == 1001) {
                        //GIRO REMESA BANCARIA SEMANAL
                        return 'fuchsia';
                  } else {
                        return 'purple';
                  }
            } else {
                  return 'black';
            }
      };

      const handlerSelected = (ids) => {
            setState((currentState) => ({ ...currentState, ['ids']: ids }));
      };
      /*useEffect(()=>{
    billSelected = [];
  },[]);*/

      useEffect(() => {
            if (evetclick > 0) {
                  setState((currentState) => ({ ...currentState, ...{ evetclick: 0 } }));
                  if (typePrint == 3) {
                        if (!ids || ids.length == 0) {
                              toast.error('Debe seleccionar de que albaranes quiere crear la factura', { position: toast.POSITION.TOP_CENTER });
                        } else {
                              let fileName = 'Facturas.pdf';
                              service.printSeverals(ids, fileName, (data, erro) => {
                                    toast.success('Se han descargado las facturas', { position: toast.POSITION.TOP_CENTER });
                              });
                        }
                  } else if (typePrint == 4) {
                        if (!ids || ids.length == 0) {
                              toast.error('Debe seleccionar de que albaranes quiere crear la factura', { position: toast.POSITION.TOP_CENTER });
                        } else {
                              let fileName = 'Facturas.pdf';
                              service.printDirectSeverals(ids, fileName, (data, erro) => {
                                    toast.success('Se han descargado las facturas', { position: toast.POSITION.TOP_CENTER });
                              });
                        }
                  }
            }
      }, [ids, evetclick, typePrint]);

      const clickDownloadBills = (evt) => {
            setState((currentState) => ({ ...currentState, ...{ evetclick: currentState['evetclick'] + 1, typePrint: 3 } }));
      };

      const clickPrintBills = (evt) => {
            setState((currentState) => ({ ...currentState, ...{ evetclick: currentState['evetclick'] + 1, typePrint: 4 } }));
      };

      useEffect(() => {
            if (evetclick > 0) {
                  setState((currentState) => ({ ...currentState, ...{ evetclick: 0 } }));
                  if (typePrint == 1) {
                        let fileName = 'Facturas.pdf';
                        console.log(filter);
                        service.printSeveralsFilter(filter, fileName, (data, erro) => {
                              toast.success('Se han descargado las facturas', { position: toast.POSITION.TOP_CENTER });
                        });
                  } else if (typePrint == 2) {
                        let fileName = 'Facturas.pdf';
                        service.printDirectSeveralsFilter(filter, fileName, (data, erro) => {
                              toast.success('Se han descargado las facturas', { position: toast.POSITION.TOP_CENTER });
                        });
                  } else if (typePrint == 5) {
                        let fileName = 'ListadoIva.xlsx';
                        service.allFilterIvaExcel(filter, fileName, (data, erro) => {
                              toast.success('Se han descargado el listado de iva', { position: toast.POSITION.TOP_CENTER });
                        });
                  }
            }
      }, [filter, evetclick, typePrint]);

      const clickDownloadBillsFilter = (evt) => {
            setState((currentState) => ({ ...currentState, ...{ evetclick: currentState['evetclick'] + 1, typePrint: 1 } }));
      };

      const clickPrintBillsFilter = (evt) => {
            setState((currentState) => ({ ...currentState, ...{ evetclick: currentState['evetclick'] + 1, typePrint: 2 } }));
      };
      const clickPrintListIva = (evt) => {
            setState((currentState) => ({ ...currentState, ...{ evetclick: currentState['evetclick'] + 1, typePrint: 5 } }));
      };

      const othersPanel = () => {
            return (
                  <Grid container>
                        <Grid item sm={6} xs={12} style={{ textAlign: 'left', display: 'flex', flexDirection: 'row' }}>
                              <span style={{ display: 'block', background: 'purple', width: '20px', height: '20px', margin: '0 1em' }}></span>
                              <b>CONTADO</b>
                              <span style={{ display: 'block', background: 'orange', width: '20px', height: '20px', margin: '0 1em' }}></span>
                              <b>CONTADO MEN.</b>
                              <span style={{ display: 'block', background: 'blue', width: '20px', height: '20px', margin: '0 1em' }}></span>
                              <b>TRANSFERENCIA</b>
                              <span style={{ display: 'block', background: 'green', width: '20px', height: '20px', margin: '0 1em' }}></span>
                              <b>GIRO MENSUAL</b>
                              <span style={{ display: 'block', background: 'lightseagreen', width: '20px', height: '20px', margin: '0 1em' }}></span>
                              <b>TPV</b>
                              <span style={{ display: 'block', background: 'fuchsia', width: '20px', height: '20px', margin: '0 1em' }}></span>
                              <b>GIRO SEMANAL</b>
                        </Grid>
                        <Grid item sm={6} xs={12} style={{ textAlign: 'right' }}>
                              <Button variant="contained" color="primary" style={{ marginLeft: '10px', fontSize: '0.8em' }} onClick={clickDownloadBills}>
                                    {' '}
                                    Descargar facturas seleccionadas
                              </Button>
                              <Button variant="contained" color="primary" style={{ marginLeft: '10px', fontSize: '0.8em' }} onClick={clickPrintBills}>
                                    {' '}
                                    Imprimir facturas seleccionadas
                              </Button>
                              <Button variant="contained" color="primary" style={{ marginLeft: '10px', fontSize: '0.8em' }} onClick={clickDownloadBillsFilter}>
                                    {' '}
                                    Descargar todas
                              </Button>
                              <Button variant="contained" color="primary" style={{ marginLeft: '10px', fontSize: '0.8em' }} onClick={clickPrintBillsFilter}>
                                    {' '}
                                    Imprimir todas
                              </Button>
                              <Button variant="contained" color="primary" style={{ marginLeft: '10px', fontSize: '0.8em' }} onClick={clickPrintListIva}>
                                    {' '}
                                    Listado de ivas
                              </Button>
                        </Grid>
                        <Grid item sm={6} xs={12} style={{ textAlign: 'right', marginBottom: '10px' }}></Grid>
                        <Grid item sm={6} xs={12} style={{ textAlign: 'right', marginBottom: '10px' }}>
                              <p style={{ textAlign: 'right' }}>
                                    <small>Con todas no referimos aquellas que cumplan el filtro (con un maximo de 300)</small>
                              </p>
                        </Grid>
                  </Grid>
            );
      };

      const setFilter = (filter) => {
            var update = {
                  filter: filter,
            };
            setState((currentState) => ({ ...currentState, ...update }));
      };

      useEffect(() => {
            return () => {
                  setState({
                        openRect: false,
                        billRect: {},
                        filter: { ...initFilter },
                        evetclick: 0,
                        ids: [],
                        typePrint: -1,
                  });
            };
      }, []);

      return (
            <>
                  <CrudTable
                        service={service}
                        urledit={'/bill/edit/'}
                        urlnew={'/bill/new/'}
                        filter={initFilter}
                        i18n={'bill'}
                        setFilter={setFilter}
                        fieldstable={['serie', 'number', 'date', 'client.comercialName|client.email|client.phone', 'total', 'paymentType.name', 'isPay']}
                        exportfields={['number', 'date', 'client.code', 'client.comercialName', 'base', 'iva', 'equivalencia', 'total']}
                        defaultValues={{ serie: '', number: 'Borrador' }}
                        moreActions={[
                              { handle: handleSend, icon: <MailIcon />, condition: conditionIsGenerateAndSend },
                              { handle: handlePrintDirect, icon: <PrintIcon />, condition: conditionIsGenerate },
                              { handle: handlePrint, icon: <DownloadIcon />, condition: conditionIsGenerate },
                              { handle: handleRecti, icon: <RefreshIcon />, condition: conditionIsGenerate },
                        ]}
                        conditionDelete={conditionDelete}
                        paintCell={paintCell}
                        labels={{ 'client.comercialName|client.email|client.phone': t('bill.field.client.name') }}
                        estructureForm={createEstructureFilter}
                        order={true}
                        conditionColor={conditionColor}
                        selectable={true}
                        handlerSelected={handlerSelected}
                        expendedFilter={true}
                        orderColumns={{ serie: 'serie', number: 'number', date: 'date' }}
                        haveother={true}
                        othersPanel={othersPanel()}
                  />
                  <ToastContainer />
                  <Modal open={openRect} onClose={handleCloseRect} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                        <Box sx={style}>
                              <div style={{ marginBottom: '30px' }}>¿Quieres hacer una factura rectificativa?</div>
                              <div>
                                    <Button variant="outlined" color="primary" style={{ marginRight: '30px' }} onClick={handleCloseRect}>
                                          {' '}
                                          No
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={rectify}>
                                          {' '}
                                          Sí{' '}
                                    </Button>
                              </div>
                        </Box>
                  </Modal>
            </>
      );
}
