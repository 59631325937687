import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../../crud/CrudForm"
import {countryService as service } from "../../../../services"
import { useTranslation } from 'react-i18next';


  const initElement =  {
    name : null,
    iso3: null,
    numeric_code:null,
    iso2:null,
    phonecode:null,
    capital:null,
    currency : null,
    currency_name: null,
    currency_symbol:null,
    tld:null,
    nativeName:null,
    region:null,
    subregion : null,
    timezones: null,
    translations:null,
    latitude:0.0,
    longitude:0.0,
    emoji:null,
    emojiU:null,
    flag:null,
    wikiDataId:null,
};


export default function FormCountry() {
  const { t, i18n } = useTranslation();


  const estructureform =  [
      {
        type:"input",
        name: "name",
        col: 4
       },
      {
        type:"input",
        name: "iso3",
        col: 4
       },
       {
         type:"input",
         name: "numeric_code",
         col: 4
       },
       {
         type:"input",
         name: "iso2",
         col: 4
       },
       {
         type:"input",
         name: "phonecode",
         col: 4
       },
       {
         type:"input",
         name: "capital",
         col: 4
       },
       {
         type:"input",
         name: "currency",
         col: 4
       },
       {
         type:"input",
         name: "currency_name",
         col: 4
       },
       {
         type:"input",
         name: "currency_symbol",
         col: 4
       },
       {
         type:"input",
         name: "tld",
         col: 4
       },
       {
         type:"input",
         name: "nativeName",
         col: 4
       },
       {
         type:"input",
         name: "region",
         col: 4
       },
       {
         type:"input",
         name: "subregion",
         col: 4
       },
       {
         type:"input",
         name: "timezones",
         col: 4
       },
       {
         type:"input",
         name: "translations",
         col: 4
       },
       {
         type:"input",
         name: "latitude",
         col: 4
       },
       {
         type:"input",
         name: "longitude",
         col: 4
       },
       {
         type:"input",
         name: "emoji",
         col: 4
       },
       {
         type:"input",
         name: "emojiU",
         col: 4
       },
       {
         type:"input",
         name: "flag",
         col: 4
       },
       {
         type:"input",
         name: "wikiDataId",
         col: 4
       },
   ];



  const recoveryElement = function(objectedit,element){


    objectedit['name']=element.name?element.name:"";
    objectedit['iso3']=element.iso3?element.iso3:"";
    objectedit['numeric_code']=element.numeric_code?element.numeric_code:"";
    objectedit['iso2']=element.iso2?element.iso2:"";
    objectedit['phonecode']=element.phonecode?element.phonecode:"";
    objectedit['capital']=element.capital?element.capital:"";
    objectedit['currency']=element.currency?element.currency:"";
    objectedit['currency_name']=element.currency_name?element.currency_name:"";
    objectedit['currency_symbol']=element.currency_symbol?element.currency_symbol:"";
    objectedit['tld']=element.tld?element.tld:"";
    objectedit['nativeName']=element.nativeName?element.nativeName:"";
    objectedit['region']=element.region?element.region:"";
    objectedit['subregion']=element.subregion?element.subregion:"";
    objectedit['timezones']=element.timezones?element.timezones:"";
    objectedit['translations']=element.translations?element.translations:"";
    objectedit['latitude']=element.latitude?element.latitude:"";
    objectedit['longitude']=element.longitude?element.longitude:"";
    objectedit['emoji']=element.emoji?element.emoji:"";
    objectedit['emojiU']=element.emojiU?element.emojiU:"";
    objectedit['flag']=element.flag?element.flag:"";
    objectedit['wikiDataId']=element.wikiDataId?element.wikiDataId:"";

    return objectedit;
  }




  const isValid = function(object){
      if(!object['name']){
        return t("country.error.name");
      }
      if(!object['nativeName']){
        return t("country.error.nativeName");
      }

      return null;
  }



  return (
    <CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        i18n={"country"}
        urledit={"/country/edit/"}
        urlCancel={"/country"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />

  );
}
