import CrudTable from '../../crud/CrudTable';
import { clientService as service, routeService, paymenttypeService, activityService } from '../../../services';

const initFilter = {
      code: null,
      nif: null,
      comercialName: null,
      nameContact: null,
      phone: null,
      route: null,
      paymentType: null,
      activity: null,
      active: 'true',
};

export default function Client() {
      const createEstructureFilter = [
            {
                  type: 'autocomplete',
                  name: 'route',
                  service: routeService.combo,
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'code',
                  autoFocus: true,
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'nif',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'comercialName',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'nameContact',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'phone',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'state',
                  col: 4,
            },
            {
                  type: 'input',
                  name: 'city',
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'paymentType',
                  service: paymenttypeService.combo,
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'activity',
                  service: activityService.combo,
                  col: 4,
            },
            {
                  type: 'autocomplete',
                  name: 'active',
                  options: [
                        { key: 'true', name: 'SI' },
                        { key: 'false', name: 'NO' },
                  ],
                  col: 4,
            },
      ];

      return (
            <CrudTable
                  service={service}
                  urledit={'/client/edit/'}
                  urlnew={'/client/new/'}
                  filter={initFilter}
                  i18n={'client'}
                  fieldstable={['code', 'comercialName', 'nameContact', 'phone', 'email', 'route.name']}
                  estructureForm={createEstructureFilter}
                  order={true}
                  expendedFilter={true}
                  orderColumns={{ code: 'code', comercialName: 'nameOrder', nameContact: 'nameContactOrder', phone: 'phone', email: 'email', 'route.name': 'routeName' }}
            />
      );
}
