

import {countryService, cityService, stateService } from "../../services"

export const initElementStreet = {
    "street": null,
    "number": null,
    "door": null,
    "floor": null,
    "stairs": null,
    "cityLabel": null,
    "poblation": null,
    "stateLabel": null,
    "countryLabel": "España",
    "postalcode": null,

}

export const initElementStreetSend = {
  "street": null,
  "number": null,
  "door": null,
  "floor": null,
  "stairs": null,
  "city": null,
  "poblation": null,
  "state": null,
  "country": "España",
  "postalcode": null,

}


export const estructureformStreet =  [
    {
        type:"title",
        name: "street.title",
        col: 12,
    },
    {
      type:"input",
      name: "street.street",
      col: 3,
     },
     {
       type:"input",
       name: "street.number",
       col: 2,
     },
     {
       type:"input",
       name: "street.floor",
       col: 1,
     },
     {
       type:"input",
       name: "street.door",
       col: 2,
     },
     {
       type:"input",
       name: "street.stairs",
       col: 1,
     },
     {
       type:"input",
       name: "street.postalcode",
       col: 3,
     },
     {
        type:"input",
        name: "street.countryLabel",
        col: 3
       },
       {
        type:"input",
        name: "street.stateLabel",
        col: 3
       },
       {
        type:"input",
        name: "street.cityLabel",
        col: 3
       }
    ];


export const estructureformStreetSend =  [
  {
      type:"title",
      name: "streetSend.title",
      col: 12,
  },
  {
    type:"input",
    name: "streetSend.street",
    col: 3,
   },
   {
     type:"input",
     name: "streetSend.number",
     col: 2,
   },
   {
     type:"input",
     name: "streetSend.floor",
     col: 1,
   },
   {
     type:"input",
     name: "streetSend.door",
     col: 2,
   },
   {
     type:"input",
     name: "streetSend.stairs",
     col: 1,
   },
   {
     type:"input",
     name: "streetSend.postalcode",
     col: 3,
   },
   {
      type:"input",
      name: "streetSend.countryLabel",
      col: 3
     },
     {
      type:"input",
      name: "streetSend.stateLabel",
      col: 3
     },
     {
      type:"input",
      name: "streetSend.cityLabel",
      col: 3
     },
  ];

    export const getElementStreet = (street, original) => {
        if(street && street.id){
            original["street"]=street.street?street.street:null;
            original["number"]=street.number?street.number:null;
            original["door"]=street.door?street.door:null;
            original["floor"]=street.floor?street.floor:null;
            original["stairs"]=street.stairs?street.stairs:null;
            original["cityLabel"]=street.cityLabel?street.cityLabel:null;
            original["poblation"]=street.poblation?street.poblation:null;
            original["stateLabel"]=street.stateLabel?street.stateLabel:  null;
            original["countryLabel"]=street.countryLabel?street.countryLabel:  null;
            original["postalcode"]=street.postalcode?street.postalcode:null;
        }

    
    }

    

    export const headerFecth = ():HeadersInit => {

      let token = localStorage.getItem('token');
      
      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set('Accept', 'application/json');
      requestHeaders.set('Content-Type', 'application/json');
      requestHeaders.set('Authorization', 'Bearer '+token);
      requestHeaders.set('Access-Control-Allow-Credentials', 'true');
      requestHeaders.set('Access-Control-Expose-Headers', 'X-refreshtoken, X-Authorization, X-Access-Token, X-Uid, X-Requested-With');
      requestHeaders.set('Access-Control-Allow-Headers', 'X-Requested-With, Origin, X-Requested-With, Content-Type, Accept, Authorization, refreshtoken, X-refreshtoken, X-Authorization, X-Access-Token, X-Uid');
    
      return requestHeaders;
    }


    export const pad = (num:number, size:number): string => {
      let s = num+"";
      while (s.length < size) s = "0" + s;
      return s;
  }