import CrudTable from "../../../crud/CrudTable"
import {ivatypeService as service} from "../../../../services"

const initFilter =  {
    name : null,
    code: null,
};


export default function Iva() {

  const createEstructureFilter =  [
     {
       type:"input",
       name: "name",
       col: 4
      },
  ];


  return (
    <CrudTable
          service={service}
          urledit={"/ivatype/edit/"}
          urlnew={"/ivatype/new/"}
          filter={initFilter}
          i18n={"ivatype"}
          fieldstable={["name","percent","recagoPercent"]}
          estructureForm={createEstructureFilter} />
  );
}
