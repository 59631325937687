import React, { useState, useEffect }  from 'react';
import CrudEmpty from "../../../crud/CrudEmpty"
import { useTranslation } from 'react-i18next';
import {routeService} from "../../../../services"
import { t } from "i18next";
import { Autocomplete, TextField,Grid } from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import Moth from './Month';
const moment = require("moment");

export default function CalendarLaboral(props) {

  const { t } = useTranslation();
  const [days, setDays] = useState<any>([]);
  const [events, setEvents] = useState<any>([]); 
  const calendarRef  = React.createRef<FullCalendar>();


  const getLabelOption = function (option){
    if(option && option.name){
      if (typeof option.name === 'string'){
        return option.name
      } else {
        return ""
      }
    } else if (option && option.label && typeof option.label === 'string'){
      return option.label
    } else {
      return "";
    }
 } 

 
 useEffect(() => {
  }, []);



  return (
    <CrudEmpty i18n={"calendarwork"}>
        <Grid container style={{background:"white", padding: "0.3em 1em 1em", borderRadius: "3px", marginTop:"2em"}}>
            <Moth month={1} t={t}  year={moment().format("YYYY")}/>
            <Moth month={2} t={t}  year={moment().format("YYYY")} />
            <Moth month={3} t={t}  year={moment().format("YYYY")} />
            <Moth month={4} t={t}  year={moment().format("YYYY")} />
            <Moth month={5} t={t}  year={moment().format("YYYY")} />
            <Moth month={6} t={t}  year={moment().format("YYYY")} />
            <Moth month={7} t={t}  year={moment().format("YYYY")} />
            <Moth month={8} t={t}  year={moment().format("YYYY")} />
            <Moth month={9} t={t}  year={moment().format("YYYY")} />
            <Moth month={10} t={t}  year={moment().format("YYYY")} />
            <Moth month={11} t={t}  year={moment().format("YYYY")} />
            <Moth month={12} t={t}  year={moment().format("YYYY")} />
        </Grid>
    </CrudEmpty>
  );


}
