import CrudTable from "../../../crud/CrudTable"
import {carService as service} from "../../../../services"

const initFilter =  {
    name : null,
    code: null,
    matricula: null,
    brand: null,
    model: null,
};


export default function Route() {

  const createEstructureFilter =  [
    {
      type:"input",
      name: "code",
      col: 4
     },
     {
       type:"input",
       name: "matricula",
        col: 4
      },
      {
        type:"input",
        name: "brand",
        col: 4
      },
      {
        type:"input",
        name: "model",
        col: 4
      },
      {
        type:"input",
        name: "name",
        col: 4
      },
  ];



  return (
    <CrudTable
          service={service}
          urledit={"/car/edit/"}
          urlnew={"/car/new/"}
          filter={initFilter}
          i18n={"car"}
          fieldstable={["code","matricula","brand","model","name"]}
          order={true}
          orderColumns={{"code":"code", "matricula":"matricula", "brand":"brand", "model":"model", "name":"name"}}
          estructureForm={createEstructureFilter} />
  );
}
