import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import InventoryIcon from '@mui/icons-material/Inventory';
import CategoryIcon from '@mui/icons-material/Category';
import MenuElement from './MenuItem';
import MenuParent from './MenuParent';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import TuneIcon from '@mui/icons-material/Tune';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import DiscountIcon from '@mui/icons-material/Discount';
import AppsIcon from '@mui/icons-material/Apps';
import PublicIcon from '@mui/icons-material/Public';
import GiteIcon from '@mui/icons-material/Gite';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import BuildIcon from '@mui/icons-material/Build';
import ShareIcon from '@mui/icons-material/Share';
import ContactsIcon from '@mui/icons-material/Contacts';
import MapIcon from '@mui/icons-material/Map';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import EuroIcon from '@mui/icons-material/Euro';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SecurityUpdateIcon from '@mui/icons-material/SecurityUpdate';
import LiquorIcon from '@mui/icons-material/Liquor';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ArticleIcon from '@mui/icons-material/Article';
import CasinoIcon from '@mui/icons-material/Casino';
import DoorbellIcon from '@mui/icons-material/Doorbell';
import TollIcon from '@mui/icons-material/Toll';
import { rolUser } from '../../utils/session';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
      width: drawerWidth,
      transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
      transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: `calc(${theme.spacing(7)} + 1px)`,
      [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(8)} + 1px)`,
      },
});

const closedMixinXs = (theme: Theme): CSSObject => ({
      transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: `0px`,
});

const DrawerHeader = styled('div')(({ theme }) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
            '& .MuiListItemText-root': {
                  display: 'none',
            },
            '& .MuiListItem-root': {
                  paddingLeft: '8px !important',
            },
            '& .MuiListItemIcon-root': {
                  minWidth: '26px',
            },
      }),
      [theme.breakpoints.down('sm')]: {
            ...(!open && {
                  ...closedMixinXs(theme),
                  '& .MuiDrawer-paper': closedMixinXs(theme),
                  '& .MuiListItemText-root': {
                        display: 'none',
                  },
            }),
      },
}));

export default function Menu(props) {
      const theme = useTheme();
      const [open, setOpen] = React.useState(props.open);
      const [openProduct, setOpenProduct] = React.useState(false);
      const [openSales, setOpenSales] = React.useState(false);
      const [openBuys, setOpenBuys] = React.useState(false);
      const [openConfig, setOpenConfig] = React.useState(false);
      const [openData, setOpenData] = React.useState(false);
      const [openCash, setOpenCash] = React.useState(false);
      const [openPay, setOpenPay] = React.useState(false);
      const [openReport, setOpenReport] = React.useState(false);
      const [openContable, setOpenContable] = React.useState(false);
      const [openEmpl, setOpenEmpl] = React.useState(false);
      const isAdmin = rolUser() === 'ADMIN';

      const t = props.t;

      const closeAll = (id) => {
            if (id != 1) {
                  setOpenProduct(false);
            } else {
                  setOpenProduct(!openProduct);
            }
            if (id != 2) {
                  setOpenConfig(false);
            } else {
                  setOpenConfig(!openConfig);
            }
            if (id != 3) {
                  setOpenData(false);
            } else {
                  setOpenData(!openData);
            }
            if (id != 4) {
                  setOpenSales(false);
            } else {
                  setOpenSales(!openSales);
            }
            if (id != 5) {
                  setOpenBuys(false);
            } else {
                  setOpenBuys(!openBuys);
            }
            if (id != 6) {
                  setOpenCash(false);
            } else {
                  setOpenCash(!openCash);
            }
            if (id != 7) {
                  setOpenPay(false);
            } else {
                  setOpenPay(!openPay);
            }

            if (id != 8) {
                  setOpenReport(false);
            } else {
                  setOpenReport(!openReport);
            }

            if (id != 9) {
                  setOpenContable(false);
            } else {
                  setOpenContable(!openContable);
            }

            if (id != 10) {
                  setOpenEmpl(false);
            } else {
                  setOpenEmpl(!openEmpl);
            }
      };

      React.useEffect(() => {
            setOpen(props.open);
      }, [props.open]);

      return (
            <Drawer variant="permanent" open={open}>
                  <DrawerHeader>
                        <IconButton onClick={props.handleDrawerClose}>{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
                  </DrawerHeader>
                  <Divider />
                  {isAdmin && (
                        <>
                              <MenuElement url={'/dashboard'} icon={<DashboardIcon />} text={t('menu.dashboard')} />
                              <MenuElement url={'/parteruta'} icon={<CalendarMonthIcon />} text={t('menu.parteruta')} />
                              <MenuElement url={'/client'} icon={<PersonIcon />} text={t('menu.clients')} />
                              <MenuElement url={'/supplier'} icon={<LocalShippingIcon />} text={t('menu.supplier')} />
                              <MenuParent icon={<AttachMoneyIcon />} text={t('menu.sales')} open={openSales} num={4} close={closeAll}>
                                    <MenuElement url={'/bill'} icon={<InsertDriveFileIcon />} text={t('menu.bill')} inside={true} />
                                    <MenuElement url={'/billgenerate'} icon={<InsertDriveFileIcon />} text={t('menu.billgenerate')} inside={true} />
                                    <MenuElement url={'/preform'} icon={<RequestQuoteIcon />} text={t('menu.preform')} inside={true} />
                                    <MenuElement url={'/deliverynote'} icon={<ReceiptLongIcon />} text={t('menu.deliverynotes')} inside={true} />
                                    <MenuElement url={'/deliverynotepending'} icon={<ReceiptLongIcon />} text={t('menu.deliverynotespending')} inside={true} />
                                    <MenuElement url={'/budget'} icon={<ReceiptIcon />} text={t('menu.budget')} inside={true} />
                                    <MenuElement url={'/remesa'} icon={<DocumentScannerIcon />} text={t('menu.remesa')} inside={true} />
                                    <MenuElement url={'/remesa/new'} icon={<FileOpenIcon />} text={t('menu.remesagenerate')} inside={true} />
                                    <MenuElement url={'/exportcontable'} icon={<SecurityUpdateIcon />} text={t('menu.exportcontable')} inside={true} hiddenDivider={true} />
                              </MenuParent>
                              <MenuParent icon={<CurrencyExchangeIcon />} text={t('menu.controlpayment')} open={openPay} num={7} close={closeAll}>
                                    <MenuElement url={'/controlpayment'} icon={<InsertDriveFileIcon />} text={t('menu.bill')} inside={true} />
                                    <MenuElement url={'/controlpaymentdelivery'} icon={<ReceiptLongIcon />} text={t('menu.deliverynotes')} inside={true} hiddenDivider={true} />
                              </MenuParent>

                              <MenuParent icon={<ShoppingCartIcon />} text={t('menu.buys')} open={openBuys} num={5} close={closeAll}>
                                    <MenuElement url={'/billsupplier'} icon={<InsertDriveFileIcon />} text={t('menu.bill')} inside={true} />
                                    <MenuElement url={'/deliverynotesupplier'} icon={<ReceiptLongIcon />} text={t('menu.deliverynotes')} inside={true} hiddenDivider={true} />
                              </MenuParent>
                              <MenuParent icon={<InventoryIcon />} text={t('menu.product')} open={openProduct} num={1} close={closeAll}>
                                    <MenuElement url={'/product'} icon={<InventoryIcon />} text={t('menu.product')} inside={true} />
                                    <MenuElement url={'/categories'} icon={<CategoryIcon />} text={t('menu.categories')} inside={true} />
                                    <MenuElement url={'/pricecontrol'} icon={<TollIcon />} text={t('menu.pricecontrol')} inside={true} />
                                    <MenuElement url={'/movements'} icon={<SyncAltIcon />} text={t('menu.movements')} inside={true} hiddenDivider={true} />
                              </MenuParent>
                              <MenuParent icon={<PointOfSaleIcon />} text={t('menu.cashcontrol')} open={openCash} num={6} close={closeAll}>
                                    <MenuElement url={'/cashexit'} icon={<ShoppingCartCheckoutIcon />} text={t('menu.cashexit')} inside={true} />
                                    <MenuElement url={'/cashconfigure'} icon={<PointOfSaleIcon />} text={t('menu.cashconfigure')} inside={true} hiddenDivider={true} />
                              </MenuParent>
                              <MenuElement url={'/trazabilidad'} icon={<AirlineStopsIcon />} text={t('menu.trazabilidad')} />
                              <MenuParent icon={<BuildIcon />} text={t('menu.configuration')} open={openConfig} num={2} close={closeAll}>
                                    <MenuElement url={'/shopdata'} icon={<BuildIcon />} text={t('menu.shopdata')} inside={true} />
                                    <MenuElement url={'/calendarwork'} icon={<CalendarTodayIcon />} text={t('menu.calendarwork')} inside={true} />
                                    <MenuElement url={'/paymenttype'} icon={<CreditCardIcon />} text={t('menu.paymenttype')} inside={true} />
                                    <MenuElement url={'/paymenttypesupplier'} icon={<AccountBalanceWalletIcon />} text={t('menu.paymenttypesupplier')} inside={true} />
                                    <MenuElement url={'/billtype'} icon={<AccountBalanceIcon />} text={t('menu.billtype')} inside={true} />
                                    <MenuElement url={'/ivatype'} icon={<PriceChangeIcon />} text={t('menu.ivatype')} inside={true} />
                                    <MenuElement url={'/statebudget'} icon={<ReceiptIcon />} text={t('menu.statebudget')} inside={true} />
                                    <MenuElement url={'/statedelivery'} icon={<ReceiptLongIcon />} text={t('menu.statedelivery')} inside={true} />
                                    <MenuElement url={'/statebill'} icon={<InsertDriveFileIcon />} text={t('menu.statebill')} inside={true} />
                                    <MenuElement url={'/statepreform'} icon={<RequestQuoteIcon />} text={t('menu.statepreform')} inside={true} />
                                    <MenuElement url={'/statedeliverysupplier'} icon={<ReceiptLongIcon />} text={t('menu.statedeliverysupplier')} inside={true} />
                                    <MenuElement url={'/statebillsupplier'} icon={<InsertDriveFileIcon />} text={t('menu.statebillsupplier')} inside={true} hiddenDivider={true} />
                              </MenuParent>
                              <MenuParent icon={<AppsIcon />} text={t('menu.data')} open={openData} num={3} close={closeAll}>
                                    <MenuElement url={'/route'} icon={<MapIcon />} text={t('menu.route')} inside={true} />
                                    <MenuElement url={'/car'} icon={<LocalShippingIcon />} text={t('menu.car')} inside={true} />
                                    <MenuElement url={'/activity'} icon={<RestaurantIcon />} text={t('menu.activity')} inside={true} hiddenDivider={true} />
                              </MenuParent>
                              <MenuParent icon={<AssessmentIcon />} text={t('menu.reports')} open={openReport} num={8} close={closeAll}>
                                    <MenuElement url={'/cashdiary'} icon={<CurrencyExchangeIcon />} text={t('menu.cashdiary')} inside={true} />
                                    <MenuElement url={'/billsupplierdiary'} icon={<InsertDriveFileIcon />} text={t('menu.billsupplierdiary')} inside={true} />
                                    <MenuElement url={'/reportremesa'} icon={<DocumentScannerIcon />} text={t('menu.reportremesa')} inside={true} />
                                    <MenuElement url={'/reportclient'} icon={<PersonIcon />} text={t('menu.reportclient')} inside={true} />
                                    <MenuElement url={'/mayorclient'} icon={<PersonIcon />} text={t('menu.mayorclient')} inside={true} />
                                    <MenuElement url={'/reportproduct'} icon={<InventoryIcon />} text={t('menu.reportproduct')} inside={true} />
                                    <MenuElement url={'/reportammountsale'} icon={<InventoryIcon />} text={t('menu.reportproductbeteween')} inside={true} />
                                    <MenuElement url={'/reportproductbuy'} icon={<InventoryIcon />} text={t('menu.reportproductbuy')} inside={true} />
                                    <MenuElement url={'/paydelivery'} icon={<ReceiptLongIcon />} text={'Cobros realizados'} inside={true} hiddenDivider={true} />
                              </MenuParent>
                              <MenuParent icon={<ContactsIcon />} text={t('menu.comercial')} open={openEmpl} num={10} close={closeAll}>
                                    <MenuElement url={'/comercial'} icon={<ContactsIcon />} text={t('menu.comercial')} inside={true} />
                                    <MenuElement url={'/hollyday'} icon={<LiquorIcon />} text={t('menu.hollyday')} />
                                    <MenuElement url={'/entryemployee'} icon={<DoorbellIcon />} text={t('menu.entryemployee')} />
                              </MenuParent>
                              <MenuParent icon={<CasinoIcon />} text={t('menu.contable')} open={openContable} num={9} close={closeAll}>
                                    <MenuElement url={'/model347'} icon={<ArticleIcon />} text={t('menu.model437')} inside={true} />
                              </MenuParent>

                              <MenuElement url={'/administrator'} icon={<SupervisedUserCircleIcon />} text={t('menu.users')} />
                        </>
                  )}

                  {!isAdmin && (
                        <>
                              <MenuElement url={'/tpv/clients'} icon={<PersonIcon />} text={t('menu.clients')} inside={false} />
                              <MenuElement url={'/tpv/products'} icon={<InventoryIcon />} text={t('menu.product')} inside={false} />
                              <MenuElement url={'/tpv/deliverynote'} icon={<ReceiptLongIcon />} text={t('menu.deliverynotes')} inside={false} />
                        </>
                  )}
            </Drawer>
      );
}
