import React, { useState, useEffect }  from 'react';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import {parterutaService as service, comercialService, routeService, carService, deliverynoterutaService} from "../../../services"
import Form from "../../form/Form"
import 'toasted-notes/src/styles.css';
import { Box } from '@mui/system';
import styled from "styled-components";
import TabMovementsForm from './TabMovementsForm';
import TabDeliverynotePending from './TabDeliverynotePending';
import TabDeliverynoteRuta from './TabDeliverynoteRuta';
import TabPaymentInRoute from './TabPaymentInRoute';
import TabPaymentInRouteBill from './TabPaymentInRouteBill';
import { Button } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const moment = require("moment");

const style = {
  position: 'absolute' as 'absolute',
  top: '20px',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  width: 2000,
  maxWidth:"90%",
  maxHeight:"80%",
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  overflowY:"auto",
  p: 4,
};




const initParteRuta = {
    id:null,
    date:null,
    route:{id:null, name: null},
    comercial:{id:null, name: null},
    car:{id:null, name: null},
    effective:0.0,
    effectiveCount:0.0,
    effectiveDiff:0.0,
    total:0.0,
    deliveryNotes:[],
    movements:[],
    loader: false,
    accept: false,
    deliveryInRoute: [],
    deliveryPending: [],
    paymentInRoute: [],
    paymentInRouteBill: []

 };


export default function ModalParteRuta(props) {
 const t = props.t;
 const [{parteRutaForm,openParte,route,id,dates,editable,value,reloadToClose},setState] = useState ({
    parteRutaForm:{...initParteRuta},
    openParte:props.openmodal,
    route:props.route,
    id:props.id,
    dates:{from:"",to:""},
    editable: props.editable != null?props.editable:true,
    value:'1',
    reloadToClose: false,
 })



const setValueCaja = (setValueField,objectform,value,valueform,setState,id,aux) => {
  
  
  if(value){
    var name = "effectiveCount";

    var newobject = {...objectform}
    newobject = setValueField(newobject,name,value);

    var valueformAux = {...valueform}
    valueformAux[name] = value;


    var effective = valueformAux["effective"];
    var effectiveDiff = effective - value;
   
    newobject =  setValueField(newobject,"effectiveDiff", effectiveDiff.toFixed(2));

    valueformAux["effectiveDiff"] = effectiveDiff.toFixed(2);


    var update = {
      objectform: newobject,
      valueform: valueformAux,
    }

    setState(currentState => ({...currentState, ...update}));

    return newobject;
  } 
}

 
 
 const estructureformClient =  [
  {
    type:"input",
    name: "date",
    inputtype:"date",
    col: 3,
    readonly:true
   },
   {
    type:"autocomplete",
    name: "route.id",
    service:routeService.combo,
    col: 3
   },
   {
      type:"autocomplete",
      name: "comercial.id",
      service:comercialService.combo,
      col: 3
   },
   {
      type:"autocomplete",
      name: "car.id",
      service:carService.combo,
      col: 3
   },
   {
     type:"input",
     name: "effective",
     inputtype:"number",
     readonly:true,
     col: 3
   },
   {
     type:"input",
     name: "effectiveCount",
     inputtype:"number",
     setvalue: setValueCaja,
     col: 3
   },
   {
     type:"input",
     name: "effectiveDiff",
     inputtype:"number",
     readonly:true,
     col: 3
   },
   {
     type:"input",
     name: "total",
     inputtype:"number",
     readonly:true,
     col: 3
   },
   {
     type:"input",
     name: "totalPending",
     inputtype:"number",
     readonly:true,
     col: 3
   },
   {
     type:"checkbox",
     name: "loader",
     readonly:true,
     col: 3
   },
   {
     type:"checkbox",
     name: "accept",
     readonly:true,
     col: 3
   },

   

  ];

const handleCloseClient = () => {
    cleanObjectForm();
    if(props.eventClose){
      
      props.eventClose(reloadToClose);
    }
  }

const sesaveFormParte = (object) => {
    service.create(object, (data, error) => {
        if(data){
            if (props.save){
              props.save(data.data, id == null);
            }

            let update = {
              openParte: false,
              reloadToClose: true,
            }
            setState(currentState => ({...currentState, ...update}))
        }
    });
}

const getOnte = () => {
  
  service.one(id, (data, error) => {
      if(data && data.data && data.data.id){
        
        if(!data.data.comercial){
          data.data.comercial = {id:null,name:null};
        }
        if(!data.data.car){
          data.data.car = {id:null,car:null};
        }
        //setParteRutaForm(currentValueform => ({...currentValueform, ...data.data }))
        var parteRutaAux = {...parteRutaForm, ...data.data}
        setState(currentState => ({...currentState, ["parteRutaForm"]:parteRutaAux}))
      }
  });
}

const cleanObjectForm = () => {
    var parteAux = {
      id:null,
      date:props.date,
      route: route,
      comercial:{id:null, name: null},
      car:{id:null, name: null},
      effective:0.0,
      effectiveCount:0.0,
      effectiveDiff:0.0,
      total:0.0,
      deliveryNotes:[],
      movements:[],
      loader: false,
      accept: false,
      deliveryInRoute: [],
      deliveryPending: [],
      paymentInRoute: [],
      paymentInRouteBill: []
  };
  setState(currentState => ({...currentState, ["parteRutaForm"]:parteAux}))
  //setParteRutaForm(parteAux);
}


const handleChange = (event: React.SyntheticEvent, newValue: string) => {
  //setValue(newValue);
  setState(currentState => ({...currentState, ["value"]:newValue}))
};


useEffect(() => {
  
  
  if(id !=null){
    getOnte()
  } else {
    cleanObjectForm()
  }
}, [id]);


useEffect(() => {
  return () => {


    
    setState({
      parteRutaForm:{...initParteRuta},
      openParte:false,
      route:{id:null,name:""},
      id:-1,
      dates:{from:"",to:""},
      editable: true,
      value:'1',
      reloadToClose: false,
   });
   }
 
}, []);

useEffect(() => {
  
  //setOpenParte(props.openmodal);
  if(!props.openmodal){
    var update = {
      openParte:props.openmodal,
      id:-1
    }
    setState(currentState => ({...currentState, ...update}))
    //setId(-1);
  } else {

    var updateTwo = {
      openParte:props.openmodal
    }
    setState(currentState => ({...currentState, ...updateTwo}))
  
  }
}, [props.openmodal]);

useEffect(() => {
  
  //setOpenParte(props.openmodal);
  if(!openParte && openParte != props.openmodal){
     handleCloseClient();
  }
}, [openParte]);





useEffect(() => {
  
  setState(currentState => ({...currentState, ["id"]:props.id}))
 // setId(props.id);
}, [props.id]);



useEffect(() => {
  var parteAux = {...parteRutaForm}
  parteAux['route'] = {id: props.route?.key?props.route.key:null, name: props.route?.name?props.route.name:null};
  var update = {
    parteRutaForm: parteAux,
    route:{id: props.route?.key?props.route.key:null, name: props.route?.name?props.route.name:null}
  }

  setState(currentState => ({...currentState, ...update}))
}, [props.route]);

useEffect(() => {
  var parteAux = {...parteRutaForm}
  parteAux['date'] = props.date;
  var datesAux = {from: moment(props.date).format('YYYY-MM-DD')+'T00:00:00Z',to:moment(props.date).format('YYYY-MM-DD')+'T23:59:59Z'};


  var update = {
    parteRutaForm: parteAux,
    dates:datesAux
  }
  setState(currentState => ({...currentState, ...update}))

}, [props.date]);

const clickDelete = (evt) => {
  deliverynoterutaService.deleteParte(parteRutaForm.id, (data,error) => {

    if(data.data){
       setState(currentState => ({...currentState, ["reloadToClose"]:true}));
      toast.success("Se ha borrado correctamente", {position: toast.POSITION.TOP_CENTER });
      getOnte();
    } else {
      toast.error("Ha ocurrido un error vuelva a intentarlo mas tarde", {position: toast.POSITION.TOP_CENTER });
    }
      
  });
}

const clickImp = (evt) => {
  deliverynoterutaService.importParte(parteRutaForm.id, (data,error) => {

    if(data.data){
       setState(currentState => ({...currentState, ["reloadToClose"]:true}));
       toast.success("Se ha importado correctamente", {position: toast.POSITION.TOP_CENTER });
      getOnte();
    } else {
      toast.error("Ha ocurrido un error vuelva a intentarlo mas tarde", {position: toast.POSITION.TOP_CENTER });
    }
      
  });
}

const deleteParteRuta = (id) => {
     
    service.removeLogic(id, (data, error) => {
      

      let update = {
        openParte: false,
        reloadToClose: true,
      }
      setState(currentState => ({...currentState, ...update}))  
    }); 
}

const moreActions = (getObject) => {
  var isAllow = id && getObject && getObject() && !getObject().accept && !getObject().loader;

  return <>
        { isAllow && <Button type="button" variant="outlined" color="primary" style={{marginLeft:'30px'}} onClick={(evt) => deleteParteRuta(getObject().id)}> Borrar parte </Button>}
        { !isAllow && getObject().loader && <span> Para borrar el parte primero es necesario borrar la importacion </span>}
    </>
}

const changeValue = (value) => {
  setState(currentState => ({...currentState, ["value"]:value}))
}

return (
    <Modal
          open={openParte}
          onClose={handleCloseClient}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <Box sx={style}>
            <div>
              <h2>Parte de ruta</h2>
            </div>
            <div>
                <Grid container>
                  <Grid sm={12}>
                    <Form t={props.t} i18n={'parteruta'}  objectform={parteRutaForm} fields={estructureformClient}  searchFilter={sesaveFormParte} cleanFilter={handleCloseClient} labelsave={props.t('general.save')} labelcancel={"Cerrar"}  moreActions={moreActions} haveMoreActions={true} />
                  </Grid>

                  {parteRutaForm && parteRutaForm.id && <Grid sm={12} style={{marginTop: "2em"}}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>  
                      <TabList aria-label="lab API tabs example">
                          <Tab value="1" selected={'1'==value?'selected':'notselected'} onClick={() =>  changeValue("1")}>{t('parteruta.tabs.pda')}</Tab>
                          <Tab value="2" selected={'2'==value?'selected':'notselected'} onClick={() =>  changeValue("2")}>Cobros pendientes recibidos</Tab>
                          <Tab value="2" selected={'3'==value?'selected':'notselected'} onClick={() =>  changeValue("3")}>Cobros pendientes facturas</Tab>
                          <Tab value="3" selected={'4'==value?'selected':'notselected'} onClick={() =>  changeValue("4")}>{t('parteruta.tabs.pending')}</Tab>
                          <Tab value="4" selected={'5'==value?'selected':'notselected'} onClick={() =>  changeValue("5")}>{t('parteruta.tabs.movements')}</Tab>
                      </TabList>
                    </Box> 
                    <TabPanel value={'1'} display={'1'==value?'block':'none'}><TabDeliverynoteRuta  updateStateParent={setState} dates={dates} route={route} /><div style={{marginTop:"2em"}}>{parteRutaForm.loader && !parteRutaForm.accept && <Button variant="contained" color="primary" style={{marginLeft:'30px'}} type="button" onClick={clickImp}> {t('general.import')} </Button>}{parteRutaForm.loader && !parteRutaForm.accept && <Button variant="outlined" color="primary" style={{marginLeft:'30px'}} type="button" onClick={clickDelete}> {t('general.removeimport')} </Button>}</div></TabPanel>
                    <TabPanel value={'2'} display={'2'==value?'block':'none'}><TabPaymentInRoute  updateStateParent={setState} dates={dates} route={route} /></TabPanel>
                    <TabPanel value={'3'} display={'3'==value?'block':'none'}><TabPaymentInRouteBill  updateStateParent={setState} dates={dates} route={route} /></TabPanel>
                    <TabPanel value={'4'} display={'4'==value?'block':'none'}><TabDeliverynotePending  updateStateParent={setState} dates={dates} route={route} parteid={parteRutaForm.id}/></TabPanel>
                    <TabPanel value={'5'} display={'5'==value?'block':'none'}><TabMovementsForm  updateStateParent={setState} objectform={parteRutaForm} dates={dates} route={route} /></TabPanel>
                    
                  </Grid> }
                </Grid>
                
            </div>
            <ToastContainer />
        </Box>
        </Modal>
  );
}


const Tab = styled.button(props => ({
  background: 'transparent',
  fontSize: '1em',
  border: 0,
  fontWeight: props.selected == 'selected'?'bold':'normal',
  borderBottom: props.selected == 'selected'?'3px solid #64ac5b':'0px',
  padding: '0px 2em'
}));

const TabList = styled.div `
    position: relative;
    display:flex;
`;

const TabPanel = styled.div(props => ({
  display: props.display,
  padding: '1em',
  position: 'relative'
}));
   


