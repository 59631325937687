import CrudTable from "../../../crud/CrudTable"
import {clienttpvService as service, routetpvService} from "../../../../services/tpv"

const initFilter =  {
    code : null,
    nif: null,
    comercialName:null,
    nameContact:null,
    phone:null,
    route:13,
    paymentType:null,
    activity:null,
    active:"true",
};

export default function ClientTPV() {

  const createEstructureFilter =  [

    {
      type:"autocomplete",
      name: "route",
      service:routetpvService.combo,
      col: 4
    },
    {
      type:"input",
      name: "code",
      autoFocus:true,
      col: 4
     },
     {
       type:"input",
       name: "nif",
       col: 4
     },
     {
       type:"input",
       name: "comercialName",
       col: 4
     },
     {
      type:"input",
      name: "nameContact",
      col: 4
     },
     {
        type:"input",
        name: "phone",
        col: 4
     },
  ];


  return (
    <CrudTable
          service={service}
          urledit={"/tpv/clients/edit/"}
          urlnew={"/tpv/clients/new/"}
          filter={initFilter}
          i18n={"client"}
          fieldstable={["code","comercialName","nameContact","phone","email","route.name"]}
          estructureForm={createEstructureFilter}
          order={true}
          expendedFilter={true}
          hiddenButton={true}
          notexport={true}
          deleteaction={false}
          editable={true}
          orderColumns={{"code":"code", "comercialName":"nameOrder", "nameContact":"nameContactOrder", "phone":"phone", "email":"email", "route.name":"routeName"}} />
  );
}
