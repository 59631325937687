import React, {  useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Form from "../form/Form"
import styled from "styled-components";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

export default function Filter(props) {
  const t = props.t;
  const [{filter,estructure,hiddenButton,labelsearch,labelclean,labeltitle,cleanInOK,onlyRead,expanded,notopeninit,forceExpand,isHiddenCancel},setState] = useState({
    filter: props.filter?props.filter:{},
    estructure: props.estructure?props.estructure:[],
    hiddenButton: props.hiddenButton?props.hiddenButton:false,
    labelsearch: props.labelsearch?props.labelsearch:t("general.search"),
    labelclean:props.labelclean?props.labelclean:t("general.clean"),
    labeltitle:props.labeltitle?props.labeltitle:t("general.search"),
    cleanInOK:props.cleanInOK?props.cleanInOK:false,
    onlyRead:props.onlyRead?props.onlyRead:false,
    expanded: props.expanded?props.expanded:false,
    notopeninit: props.notopeninit?props.notopeninit:false,
    forceExpand: props.forceExpand?props.forceExpand:false,
    isHiddenCancel: props.isHiddenCancel ? props.isHiddenCancel : (props.onlyRead?props.onlyRead:false)
  });

  const iconfilter = props.iconfilter ? props.iconfilter  : <FilterAltIcon sx={{marginBottom:"-7px"}} />;


  useEffect(() => {
    if(props.filter["id"] && props.filter["id"] > 0 && !notopeninit){
      var update = {
        filter: props.filter,
        expanded: true
      }
      setState(currentState => ({...currentState,...update}))
    } else {
      setState(currentState => ({...currentState,["filter"]:props.filter}))
    }
  }, [props.filter]);

  useEffect(() => {
    if(forceExpand){
      setState(currentState => ({...currentState,["expanded"]:true}))
    }
  }, [props.forceExpand])

  useEffect(() => {
    
    return () => {
      debugger;
      setState(
        {
          filter: {},
          estructure: [],
          hiddenButton: false,
          labelsearch: t("general.search"),
          labelclean:t("general.clean"),
          labeltitle:t("general.search"),
          cleanInOK:false,
          onlyRead:false,
          expanded: false,
          notopeninit: false,
          forceExpand: false,
          isHiddenCancel:false
        }
      )
    }
}, []);

 
  return (
      <DivPanelFilter>
        <DivPanelButton>
          {!hiddenButton?<Button component={Link} to={props.urlnew?props.urlnew:"#"} variant="contained" color="secondary" style={{marginLeft:'30px'}}> {props.titlebutton?props.titlebutton:'Crear nuevo'} </Button>:''}
        </DivPanelButton>
      
        <Accordion expanded={expanded} onChange={() => setState(currentState => ({...currentState, ["expanded"]:!expanded}) )} sx={{borderRadius:"4px !important", border:"none !important", "& .MuiAccordionSummary-root": {border:"none !important"}}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography> {iconfilter} <label style={{fontSize:'1em',fontWeight:'bold'}}>{labeltitle}</label>  </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Form t={t} i18n={props.i18n + ".filter"} objectform={filter} fields={estructure}  searchFilter={props.searchFilter} cleanFilter={props.cleanFilter} labelsave={labelsearch} labelcancel={labelclean} cleanInOK={cleanInOK} isHiddenSave={onlyRead} isHiddenCancel={isHiddenCancel} notCleanOnCancel={false} inline={props.inline}/>
          </AccordionDetails>
        </Accordion>
      </DivPanelFilter>
  );

}


const DivPanelFilter =  styled.div`
  margin-bottom: 3em;
`;

const DivPanelButton =  styled.div`
  text-align: right;
  margin-bottom: 10px;
`;
