import CrudTable from "../../../crud/CrudTable"
import {entryemployeeService as service,comercialService} from "../../../../services"

const initFilter =  {
    from : null,
    to: null,
    commercial: null
};


export default function Entryemployee() {

  const createEstructureFilter =  [
    {
      type:"input",
      name: "from",
      inputtype:"date",
      col: 4
     },
     {
       type:"input",
       name: "to",
       inputtype:"date",
        col: 4
      },
      {
        type:"autocomplete",
        name: "commercial",
        service:comercialService.combo,
        col: 4
      }
  ];



  return (
    <CrudTable
          service={service}
          urledit={"/entryemployee/edit/"}
          urlnew={"/entryemployee/new/"}
          filter={initFilter}
          i18n={"entryemployee"}
          hiddenButton={true}
          fieldstable={["comercial.name","date","hour","exit","imagepath"]}
          estructureForm={createEstructureFilter} />
  );
}
