import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../../crud/CrudForm"
import {comercialService as service } from "../../../../services"
import { useTranslation } from 'react-i18next';
import {initElementStreet, estructureformStreet, getElementStreet} from '../../../utils/Data';


  const initElement =  {
    "code":null,
    "nif":null,
    "name":null,
    "lastname":null,
    "email":null,
    "phone":null,
    "pass":null,
    "street": initElementStreet,
};


export default function FormCountry() {
  const { t, i18n } = useTranslation();


  const estructureform =  [
      {
        type:"input",
        name: "code",
        col: 4,
        readonly: true
       },
       {
         type:"input",
         name: "nif",
         col: 4,
        },
        {
          type:"input",
          name: "name",
          col: 4,
        },
        {
          type:"input",
          name: "lastname",
          col: 4,
        },
        {
          type:"input",
          name: "email",
          col: 4,
          inpputtype:"email"
        },
        {
          type:"input",
          name: "phone",
          col: 4,
        },
        {
          type:"input",
          name: "pass",
          col: 4,
          inputtype:"password"
        },
        {
          type:"input",
          name: "color",
          inputtype:"color",
          col: 2,
        },
        ...estructureformStreet
   ];



  const recoveryElement = function(objectedit,element){


    objectedit['code']=element.code?element.code:null;
    objectedit['nif']=element.nif?element.nif:null;
    objectedit['name']=element.name?element.name:null;
    objectedit['lastname']=element.lastname?element.lastname:null;
    objectedit['email']=element.email?element.email:null;
    objectedit['phone']=element.phone?element.phone:null;
    objectedit['pass']=element.pass?element.pass:null;
    getElementStreet(element.street,objectedit['street']);

    return objectedit;
  }




  const isValid = function(object){
      if(!object['name']){
        return t("country.error.name");
      }

      return null;
  }



  return (
    <CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        i18n={"comercial"}
        urledit={"/comercial/edit/"}
        urlCancel={"/comercial"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />

  );
}
