import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { isNotLoginLogin, rolUser } from '../../utils/session';




const moment = require("moment");

const PrivateRoute = ({ component: Component, ...rest }) => {

  interface TokenInfo {
    exp: number;
  }


  const isLoggedNotIn = isNotLoginLogin();
  const isAdmin = rolUser() == 'ADMIN';

  return (
    <Route
      {...rest}
      render={props =>
        !isLoggedNotIn && isAdmin? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  )
}

export default PrivateRoute