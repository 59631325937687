import React, {useEffect,useState} from 'react';
import Layout from '../../../layout/Layout';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { productService, categoryService } from '../../../../services';
import CrudEmpty from "../../../crud/CrudEmpty"
import Filter from "../../../filter/Filter";
import styled from "styled-components";
import { red } from '@mui/material/colors';
import { InfoOutlined } from '@mui/icons-material';
import CustomPaginationActionsTable from "../../../table/Table"
import { Autocomplete, TextField } from '@mui/material';
import { textAlign } from '@mui/system';
const moment = require("moment");


const initFilter =  {
  code : null,
  name : null,
  fromPrice: null,
  toPrice: null,
  category: null,
  active:"true",
  fromDel:  moment(Date()).format('YYYY')+"-01-01T00:00:00Z",
  toDel:  moment(Date()).format('YYYY-MM-DD')+"T00:00:00Z",
};



export default function ProductReport() {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [open, setOpen] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [filter, setFilter] = React.useState({...initFilter});
  const [exportAction, setExportAction] = React.useState(0);



  const createEstructureFilter =  [    
    {
      type:"input",
      name: "fromDel",
      inputtype: "date",
      col: 4
     },  
     {
       type:"input",
       name: "toDel",
       inputtype: "date",
       col: 4
      },
    {
      type:"input",
      name: "code",
      col: 4
     },
    {
      type:"input",
      name: "name",
      col: 4
     },
     {
       type:"input",
       name: "fromPrice",
       inputtype:"numer",
       step:0.01,
       col: 4
     },
     {
       type:"input",
       name: "toPrice",
       inputtype:"numer",
       step:0.01,
       col: 4
     },
     {
       type:"autocomplete",
       name: "category",
       service:categoryService.combo,
       relationfield:'subcategory',
       servicerelation:categoryService.comboChildren,
       col: 4,
     },
     {
        type:"autocomplete",
        name: "subcategory",
        options:[],
        col: 4
      },
      {
        type:"autocomplete",
        name: "active",
        options:[{key:"true",name:"SI"},{key:"false",name:"NO"}],
        col: 4
      },
 ];

  const search = (filter) => {
      productService.benefice(filter, (data,error) => {
          if(data && data.data) {
              setData(data.data);
          } else {
            setData([]);
          
          }
      });
  }


  useEffect(() => {
        setFilter({...initFilter});
        search(filter)
  }, []);


  const searchFilter = (filter) => {
    console.log(filter);
    setFilter(filter);
    search(filter);
  }


  const paintCell = {
    totalSales: (value,object) => {
      if (value) {
        return <p style={{textAlign: "right"}}>{value.toLocaleString()} €</p>
      } else {
        return <></>
      }
    },
    totalAmmountBuy: (value,object) => {
      if (value) {
        return <p style={{textAlign: "right"}}>{value.toLocaleString()} Kg</p>
      } else {
        return <></>
      }
    },
    totalAmmountSales: (value,object) => {
      if (value) {
        return <p style={{textAlign: "right"}}>{value.toLocaleString()} Kg</p>
      } else {
        return <></>
      }
    },
    totalBuy: (value,object) => {
      if (value) {
        return <p style={{textAlign: "right"}}>{value.toLocaleString()} €</p>
      } else {
        return <></>
      }
    },
    priveAvgBuy: (value,object) => {
      if (value) {
        return <p style={{textAlign: "right"}}>{value.toLocaleString()} €</p>
      } else {
        return <></>
      }
    },
    priveAvgSales: (value,object) => {
      if (value) {
        return <p style={{textAlign: "right"}}>{value.toLocaleString()} €</p>
      } else {
        return <></>
      }
    },
    benefice: (value,object) => {
      if (value) {
        return <p style={{textAlign: "right"}}>{value.toLocaleString()} €</p>
      } else {
        return <></>
      }
    },
    percentBenefice: (value,object) => {
      if (value) {
        return <p style={{textAlign: "right"}}>{value.toLocaleString()} %</p>
      } else {
        return <></>
      }
    },

  }

  const exportToExcel = () => {
    setExportAction(currentState => (currentState + 1));
  }

  useEffect(() => {
    if(exportAction > 0){
       setExportAction(0);
         productService.beneficeExcel(filter, (data,error) => {
        
         });
    }
}, [exportAction,filter,data]);



const conditionColor = (row) => {
    
  if(row["benefice"]){
      if(row["benefice"] < 0){
          return "red";
      } else {
        return "black";
      }
    } else {
      return "black";
    }
    
  }

  return (
    <CrudEmpty i18n={"productreportbene"}>
        <Grid container style={{background:"white", padding: "0.3em 1em 1em", borderRadius: "3px"}}>
            <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '3em', paddingTop: "3em"}}>
                 <Filter t={t} i18n={"product"} cleanFilter={()=>{}} searchFilter={searchFilter} filter={filter} estructure={createEstructureFilter}  hiddenButton={true} expanded={true} isHiddenCancel={true}/>
           
            </Grid>
            <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '3em'}}>
                  <Button variant="contained" color="primary" onClick={exportToExcel} style={{display:"inline-block", width:"200px"}}> Exportar a excel </Button><p>Se exportaran segun el filtro indicado</p>
            </Grid>

            <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '3em'}}>
                <PanelRounded>
                        <h3>Beneficio por producto</h3>
                        <Grid container>
                                <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px', marginTop: '20px'}}>
                                        <CustomPaginationActionsTable
                                           t={t} i18n={"product"} 
                                           rows={data} 
                                           fields={["code|name","category.name|subcategory.name","totalAmmountBuy","totalAmmountSales","priveAvgBuy","priveAvgSales","totalBuy","totalSales","benefice","percentBenefice"]} 
                                           labels={{"code|name":"Producto", "category.name|subcategory.name": "Categoria","totalAmmountBuy": "Cantidad Comprada", "totalAmmountSales" :" Cantidad vendida","priveAvgBuy": "Precio medio compra","priveAvgSales": "Precio medio venta","totalBuy":"Total compra","totalSales":"Total venta","benefice":"Beneficio","percentBenefice":"% Beneficio"}}
                                           fieldId={"id"} hiddenpagination={true} deleteaction={false}  selectHandler={null} editable={false} defaultValues={{}} paintCell={paintCell} paintvalue={{}}  selectable={false} handlerSelected={null} 
                                           conditionColor={conditionColor}/>
                                </Grid>
                        </Grid>
                </PanelRounded>
            </Grid>
        </Grid>




    </CrudEmpty>
  );
}

const PanelRounded = styled.div(props => ({
    display: 'block',
    padding: '1em',
    position: 'relative',
    border: '1px solid #ccc',
    borderRadius: '3px',
    "& h3":{
        color: "black",
        position: "absolute",
        top: "-2em",
        left: "2em",
        background: "white",
        padding: "0.2em 1em",
        display: "inline-block",

    }

  }));

const SpanAmmount = styled.span(props => ({
    display: 'inline-block',
    fontWeight: 'bold',
    padding: "0.2em 1em"

  }));
