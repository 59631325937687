import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import CustomPaginationActionsTable from "../table/Table"
import Filter from "../filter/Filter"
import { useTranslation } from 'react-i18next';
import styled from "styled-components";



export default function CrudTab(props) {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [{page,rowsPerPage,filter,rows,rowsTotal},setState] = useState({
    page:0,
    rowsPerPage:props.rowsPerPage?props.rowsPerPage : 10,
    filter:props.filter?props.filter:{},
    rows:props.rows ? props.rows: [],
    rowsTotal: 0
  })

  const fieldstable = props.fieldstable?props.fieldstable:[];
  const service =  props.service?props.service:null;
  const estructureForm =  props.estructureForm?props.estructureForm:null;
  const urledit =  props.urledit?props.urledit:"";
  const urlnew =  props.urlnew?props.urlnew:"";
  const fieldId =  props.fieldId?props.fieldId:"id";
  const hiddenFilter = props.hiddenFilter?props.hiddenFilter:false;
  const editable = props.editable != null?props.editable:true;




  const changePageTable = (page,size) => {
    search(page,size);
  };

  const cleanFilter = (filter) => {
    console.log(filter);
    var update = {
      page:0,
      rowsPerPage: rowsPerPage,
      filter:filter,
    }
    setState(currentState => ({...currentState, ...update}))
   // search(0,rowsPerPage);

  }

  const searchFilter = (filterSearch) => {
    var filterAux = {...filter, ...filterSearch};
    var update = {
      page:0,
      rowsPerPage: rowsPerPage,
      filter:filterAux,
    }
    setState(currentState => ({...currentState, ...update}))
    //search(0,rowsPerPage,filter);
  }


  const search = (page,size,filterAux = null) => {
    if(service){
      let filterS = filterAux?filterAux:filter;
      service.pageFilter(page,size,filterS, (data, error) => {
          if(data){
            console.log(data);

            var update = {
              rows: data.data,
              rowsTotal: data.total
            }
            setState(currentState => ({...currentState, ...update}))
          } else {
            var update2 = {
              rows: [],
              rowsTotal: 0
            }
            setState(currentState => ({...currentState, ...update2}))
          }
      });
    }
  }

  useEffect(() => {
    setState(currentState => ({...currentState, ["filter"]:props.filter}))
  }, [props.filter]);

  useEffect(() => {
    
    var update2 = {
      rows: [],
      rowsTotal: 0
    }
    setState(currentState => ({...currentState, ...update2}))
    search(page,rowsPerPage,filter);
  }, [filter,page, rowsPerPage]);

  useEffect(() => {
    if(!props.blockedinitsearch){
      search(0,props.rowsPerPage?props.rowsPerPage : 10);
    }

    return () => {
      setState({
        page:0,
        rowsPerPage:10,
        filter:{},
        rows:[],
        rowsTotal: 0
      })
    }
  }, []);

  useEffect(() => {
    searchFilter(props.filter);
  }, [props.forcesearch]);


  const deleteHandler = (id) => {
    service.removeLogic(id, (data, error) => {
        if(data){
          console.log(data);
            search(page,rowsPerPage);
        }
    });
  }

  const eventSelect = (id) => {
     if (editable){
       if (props.gotoEdit) {
          rows.forEach(element => {
              if(element[fieldId] == id){
                  props.gotoEdit(element);
              }
          });
       } else {
          history.push(urledit+id);
       }
     }
  }

  useEffect(() => {
    setState(currentState => ({...currentState, ["rows"]:(props.rows ? props.rows : [])}))
  }, [props.rows]);
  

  return (
      <div>
        {!hiddenFilter && 
          <Filter t={t} i18n={props.i18n} cleanFilter={cleanFilter} searchFilter={searchFilter} filter={filter} titlebutton={t(props.i18n+".titlebutton")} estructure={estructureForm}  urlnew={urlnew} hiddenButton={true}/>
        }

        <div>
          <CustomPaginationActionsTable t={t} i18n={props.i18n} labels={props.labels?props.labels:[]} rows={rows} fields={fieldstable} fieldId={fieldId} rowsPerPage={props.rowsPerPage?props.rowsPerPage:10} rowsTotal={rowsTotal} changePage={changePageTable} page={page} deleteaction={props.deleteaction != null?props.deleteaction:true} deleteactionHandler={deleteHandler} selectHandler={eventSelect} getDeleteButton={props.getDeleteButton?props.getDeleteButton:null} editable={editable} defaultValues={props.defaultValues?props.defaultValues:{}} paintCell={props.paintCell ? props.paintCell : {}} paintvalue={props.paintvalue ? props.paintvalue : {}} moreActions={props.moreActions ? props.moreActions : []}  selectable={props.selectable?props.selectable:false} handlerSelected={props.handlerSelected?props.handlerSelected:null} hiddenpagination={props.hiddenpagination?props.hiddenpagination:false} conditionColor={props.conditionColor?props.conditionColor:null}/>
        </div>
      </div>
  );

}

const DivPanel =  styled.div`
`;

const DivTable =  styled.div`
`;
