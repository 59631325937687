import BaseService from './_base.service';

const URL = "/admin/trazabilidad";

export const trazabilidadService = {
    get,
    getWihoutProduct,
    allFilterPdf,
    allFilterExcel,
    allFilterWord
};



function get(ejercicio,product,lote,callback) {
    return BaseService.get(URL + "/"+ejercicio+"/"+product+"/"+lote, callback);
}


function getWihoutProduct(ejercicio,lote,callback) {
    return BaseService.get(URL + "/"+ejercicio+"/"+lote, callback);
}
function allFilterPdf(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/pdf", filter,"trazabilidad.pdf", callback,)
}


function allFilterExcel(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/excel", filter,"trazabilidad.xlsx", callback,)
}


function allFilterWord(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/word", filter,"trazabilidad.doc", callback,)
}