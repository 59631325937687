import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import CrudFormTab from "../../crud/CrudFormTab"
import {parterutaService as service, productService, deliverynoterutaService} from "../../../services"
import 'toasted-notes/src/styles.css';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EyeIcon from '@mui/icons-material/RemoveRedEye';
import { Box } from '@mui/system';
import { Button, Grid } from '@mui/material';
import Modal from '@mui/material/Modal';


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  maxWidth: "80%",
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const initFilter =  {
  from : '2000-01-01T00:00:00Z',
  to: '2000-01-01T23:00:00Z',
  type:1,
  route:-1
};


export default function TabMovementsForm(props) {

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [{objectform, element,openModal,deliveries},setState] = useState({
    objectform: props.objectform?props.objectform:{},
    element:{},
    openModal:false,
    deliveries:[]
  });

  const initElementLine = {
    date:objectform.date,
    product:{id:null, name: null},
    lote:"",
    ammount: null,
    ammountReal: null,
    ammountDiff: null,
    type: 1
 };


 const setValueProduct= (setValueField,objectform,value,valueform,setState,id,aux) => {
  var newobject = objectform;     
  newobject =  setValueField(newobject,"ammount",null);
  newobject =  setValueField(newobject,"ammountReal",0.0);
  newobject =  setValueField(newobject,"ammountDiff",0.0);
  newobject =  setValueField(newobject,"lote",aux.lote);

  return newobject;
}

const setValueAmout = (setValueField,objectform,value,valueform,setState,id,aux) => {
    
  debugger;
  
  var newobject = {...objectform};
  newobject =  setValueField(newobject,"ammount", +value);

  var valueFormAux = {...valueform};
  var rv = valueform[id];
  rv["ammount"] = +value;


  if(value){

     
     var ammount = +value;
     var real = rv["ammountReal"] ?  rv["ammountReal"]: 0;
     var diff = ammount - real;
     
     var newobject = objectform;
     newobject =  setValueField(newobject,"ammountDiff", diff.toFixed(2));

     rv["ammountDiff"] = diff;

     return newobject;
   } else {
    var real = rv["ammountReal"] ?  rv["ammountReal"]: 0;
    rv["ammountDiff"] = 0 - real;
   }

    var update = {
      valueform: valueFormAux,
      objectform:newobject
    }
    setState(currentstate => ({...currentstate, ...update}))

   return newobject;
}


 const estructureformline =  [
   {
    type:"autocomplete",
    name: "product",
    service:productService.all,
    colSpan:"40%",
    autoFocus:true,
    searchcode:true,
    setvalue: setValueProduct,
    nextFocus: "ammount",
    col: 4
   },
   {
     type:"input",
     name: "ammount",
     inputtype:"number",
     step:0.01,
     colSpan:"10%",
     tabeventnew:true,
     setvalue: setValueAmout,
     col: 4
    },
   {
     type:"input",
     name: "ammountReal",
     inputtype:"number",
     step:0.01,
     colSpan:"10%",
     readonly:true,
     col: 3
    },
    {
      type:"input",
      name: "ammountDiff",
      inputtype:"number",
      step:0.1,
      colSpan:"10%",
      readonly:true,
      col: 3
     },
     {type:"button",
      icon: <EyeIcon />,
      press: (element) => {
        searchDelivery(element);
      }
    }
];

const searchDelivery = (element) => {


   var filterAux = {...initFilter};
   filterAux["from"] = props.dates.from;
   filterAux["to"] = props.dates.to;
   filterAux["route"] = props.route.id;
   filterAux["product"] = element["product"]["id"];
   filterAux["lote"] = element["lote"];

   deliverynoterutaService.pageFilter(0,100, filterAux, (data,error) => {

    var update = {
      deliveries: data.data,
      openModal: true,
      element: element,
    }
    setState(currentState => ({...currentState, ...update }));
    
    if(data && data.data){
      console.log(data.data);
    }
   });
}


const conditionColor = (row) => {
  
  if(row["ammountDiff"] && row["ammountDiff"] > -1 && row["ammountDiff"] < 1){
    return "black";
  } else if(!row["ammountDiff"]){
    return "black";
  } else{
      return "red";
  }
}
 const estructureForm = [
  {
    type:"table",
    name: "movements",
    headers:[{label:"Producto",colSpan:"50%"   },{label:"Cantidad",colSpan:"15%"   },{label:"Cantidad en alb.",colSpan:"15%" },{label:"Diferencia",colSpan:"20%"}],
    initrow:{... initElementLine},
    fields:estructureformline,
    tabeventnew:true,
    fieldId:"id",
    labelbtn:"Nuevo movimiento",
    conditionColor:conditionColor,
    col: 12
   },
  ];



  useEffect(() => {
    setState(currentState => ({...currentState, ["objectform"]: props.objectform }));
  }, [props.objectform]);

  const saveMethod = (object) => {
    service.movements(object.id, object.movements, (data,error) => {
      
        if(data.data){
          setState(currentState => ({...currentState, ["objectform"]: data.data }));
          toast.success(t("general.saveok"), {position: toast.POSITION.TOP_CENTER });
        }
    });
  }

  const removeLines = function(object){
    var other = true;
    var posRemove = -1;
    for (var i = 0; i < object['movements'].length; i++ ){
      var r = object['movements'][i];
      if(!r['product'] || !r['product']['id']){
        posRemove = i;
        break;
      }
    }

    if(posRemove > -1){
      object['movements'].splice(posRemove, 1);
      removeLines(object);
    }
  } 


  const isValid = function(object){
    removeLines(object);
    
    return null;
 }

 const recoveryElement = function(objectedit,element){
    objectedit['movements']=element.movements?element.movements:[];
    return objectedit;
 }

 const handleOpenModal = () => {
  setState(currentState => ({...currentState, ["openModal"]:false}));
}

  const getName = (d) => {
      debugger;
      if(d["lines"]){
        var result = <></>;
        d["lines"].forEach(line => {
            if(line["product"]["id"] == element["product"]["id"] ){
              result =  <>{element["product"]["name"]}</>
            }
        });
        return result;
      } else {
        return <></>
      }
  }
  const getAmount = (d) => {
    debugger;
    if(d["lines"]){
      var result = <></>;
      d["lines"].forEach(line => {
          if(line["product"]["id"] == element["product"]["id"] ){
            result =  <>{line["amount"]}</>
          }
      });
      return result;
    } else {
      return <></>
    }
}
  return (
    <><CrudFormTab
      objectform = {objectform}
      estructureform = {estructureForm}
      i18n={"movements"}
      service = {service}
      recoveryElement = {recoveryElement}
      valid = {isValid}
      saveMethod={saveMethod}
      isHiddenCancel={true}
    /><ToastContainer/>
    <Modal
           open={openModal}
           onClose={handleOpenModal}
           aria-labelledby="simple-modal-title"
           aria-describedby="simple-modal-description">
           <Box sx={style}>
             <div style={{marginBottom:'30px'}}>
                <Grid container style={{borderBottom:"1px solid black", fontWeight:"bold", marginBottom:"0.5em"}}><Grid item sm={2}>Serie</Grid><Grid item sm={1}>Nº</Grid><Grid item sm={4}>Cliente</Grid><Grid item sm={3}>Producto</Grid> <Grid item sm={2}>Cantidad</Grid> </Grid>
                {(deliveries).map((d) => {
                  return <Grid container style={{fontSize: "0.9em", marginBottom:"0.5em"}}> <Grid item sm={2} >{d["serie"]}</Grid><Grid item sm={1}>{d["number"]}</Grid><Grid item sm={4}>{d['client']['comercialName']}</Grid><Grid item sm={3}>{getName(d)}</Grid><Grid item sm={2}>{getAmount(d)}</Grid></Grid>
                })}
             </div>
             <div>
               <Button variant="outlined" color="primary" style={{marginRight:'30px'}} onClick={handleOpenModal}> Cerrar</Button>
             </div>
         </Box>
       </Modal>
    </>

  );

}
