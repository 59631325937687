import CrudTable from "../../../crud/CrudTable"
import {stateService as service,countryService} from "../../../../services"

const initFilter =  {
    name : null,
    code: null,
};


export default function State() {

  const createEstructureFilter =  [
     {
       type:"input",
       name: "name",
       col: 4
      },
      {
        type:"autocomplete",
        name: "idCountry",
        service:countryService.combo,
        col: 4
       },
      
  ];


  return (
    <CrudTable
          service={service}
          urledit={"/state/edit/"}
          urlnew={"/state/new/"}
          filter={initFilter}
          i18n={"state"}
          fieldstable={["name","country.name","latitude","longitude"]}
          estructureForm={createEstructureFilter} />
  );
}

