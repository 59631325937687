import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import CrudTable from "../../../crud/CrudTable"
import {billsupplierService as service, supplierService, paymenttypeService, statebillsupplierService} from "../../../../services"
import PrintIcon from '@mui/icons-material/Print'
import RefreshIcon from '@mui/icons-material/Refresh'
import { ToastContainer, toast } from 'react-toastify';
import 'toasted-notes/src/styles.css';
import { useTranslation } from 'react-i18next';
import{pad} from '../../../utils/Data'; 
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import Modal from '@mui/material/Modal'


const moment = require("moment");

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const initFilter =  {
    number:null,
    serie : null,
    supplier:null,
    from:moment().format("YYYY")+"-01-01T00:00:00Z",
    to:null,
    state:null,
    route:null,
    paymenttype:null
};


export default function Bill() {

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [openRect, setOpenRect] = React.useState(false);
  const [billRect, setBillRect] = React.useState({});

  const createEstructureFilter =  [
    {
      type:"input",
      name: "serie",
      col: 3
     },
    {
      type:"input",
      name: "number",
      inputtype:"number",
      col: 3
     },
     {
       type:"autocomplete",
       name: "supplier",
       service: supplierService.combo,
       col: 3
      },
       {
        type:"input",
        name: "from",
        inputtype:"date",
        col: 3
       },
      {
        type:"input",
        name: "to",
        inputtype:"date",
        col: 3
      },
      {
        type:"autocomplete",
        name: "state",
        service: statebillsupplierService.combo,
        col: 3
        },
        {
          type:"autocomplete",
          name: "paymenttype",
          service: paymenttypeService.combo,
          col: 3
          },
  ];


  const paintCell = {
    base: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    discount: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    iva: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },
    total: (value,object) => {
      if (value) {
        return <>{value.toFixed(2)}</>
      } else {
        return <></>
      }
    },

  }

  const handleCloseRect = () => {
    setOpenRect(!openRect);
  };

  return (
      <><CrudTable
        service={service}
        urledit={"/billsupplier/edit/"}
        urlnew={"/billsupplier/new/"}
        filter={initFilter}
        i18n={"billsupplier"}
        fieldstable={["serie","number","date","supplier.comercialName|supplier.email|supplier.phone","base","iva","total"]}
        exportfields={["number","date","supplier.code","supplier.comercialName","base","iva","equivalencia","total"]}
        paintCell={paintCell}
        labels={{"supplier.comercialName|supplier.email|supplier.phone":t('billsupplier.field.supplier.name')}}
        estructureForm={createEstructureFilter}
        order={true}
        expendedFilter={true}
        orderColumns={{"serie":"serie", "number":"number", "date":"date"}}  /><ToastContainer />
      </>

  );
}
