import CrudTable from "../../crud/CrudTable"
import {userService as service} from "../../../services"

const initFilter =  {
    name : null,
    email: null,
    phone:null,
    isClient:false
};

export default function Administrator() {

  const createEstructureFilter =  [
     {
       type:"input",
       name: "name",
       col: 4
      },
     {
       type:"input",
       name: "email",
       col: 4
      },
       {
         type:"input",
         name: "phone",
         col: 4
        },
  ];




  return (
    <CrudTable
          service={service}
          urledit={"/administrator/edit/"}
          urlnew={"/administrator/new/"}
          filter={initFilter}
          i18n={"administrator"}
          fieldstable={["picture","email","name","lastname","rol.name"]}
          estructureForm={createEstructureFilter} />
  );
}
