import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import CrudTable from '../../../crud/CrudTable';
import { deliverynoteService as service, clientService, routeService, paymenttypeService, statedeliveryService, billService, productService } from '../../../../services';
import PrintIcon from '@mui/icons-material/Print';
import 'toasted-notes/src/styles.css';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import { pad } from '../../../utils/Data';
import { Box } from '@mui/system';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import PublishIcon from '@mui/icons-material/Publish';
import PrintDisabledIcon from '@mui/icons-material/PrintDisabled';
import DownloadIcon from '@mui/icons-material/Download';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';

const moment = require('moment');

const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'white',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
};

type Filter = {
      number: any;
      serie: any;
      client: any;
      from: any;
      to: any;
      state: any;
      route: any;
      paymenttype: any;
      product: any;
      lote: any;
};

const initFilter: Filter = {
      number: null,
      serie: null,
      client: null,
      from: moment().format('YYYY') + '-01-01T00:00:00Z',
      to: null,
      state: null,
      route: null,
      paymenttype: null,
      product: null,
      lote: null,
};

export default function Deliverynote() {
      const history = useHistory();
      const { t, i18n } = useTranslation();
      const idBillInit: any = null;
      const [{ openBill, idBill, ids, eventClick }, setState] = useState({
            openBill: false,
            idBill: idBillInit,
            ids: [],
            eventClick: 0,
      });

      const queryParams = new URLSearchParams(window.location.search);
      const product = queryParams.get('product');
      const lote = queryParams.get('lote');
      initFilter['product'] = product ? product : null;
      initFilter['lote'] = lote ? lote : null;

      const createEstructureFilter = [
            {
                  type: 'autocomplete',
                  name: 'route',
                  service: routeService.combo,
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'serie',
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'number',
                  inputtype: 'number',
                  autoFocus: true,
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'cif',
                  col: 3,
            },
            {
                  type: 'autocomplete',
                  name: 'client',
                  service: clientService.combo,
                  searchcode: true,
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'from',
                  inputtype: 'date',
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'to',
                  inputtype: 'date',
                  col: 3,
            },
            {
                  type: 'autocomplete',
                  name: 'state',
                  service: statedeliveryService.combo,
                  col: 3,
            },
            {
                  type: 'autocomplete',
                  name: 'paymenttype',
                  service: paymenttypeService.combo,
                  col: 3,
            },
            {
                  type: 'autocomplete',
                  name: 'product',
                  service: productService.all,
                  searchcode: true,
                  col: 3,
            },
            {
                  type: 'input',
                  name: 'lote',
                  col: 3,
            },
      ];

      const handlePrint = (bill) => {
            var isGenerate = bill && bill.number && bill.number != '' && bill.state && bill.state.id && bill.state.id != 1;
            if (isGenerate) {
                  let fileName = 'Albarán' + (bill.serie ? bill.serie : '') + pad(+bill.number, 5) + '.pdf';
                  service.print(bill.id, fileName, (data, erro) => {
                        toast.success('Se ha descargado la albarán', { position: toast.POSITION.TOP_CENTER });
                  });
            } else {
                  toast.error('Solo se puede imprimir albaranes ya generados', { position: toast.POSITION.TOP_CENTER });
            }
      };

      const handlePrintIncomplete = (bill) => {
            var isGenerate = bill && bill.number && bill.number != '' && bill.state && bill.state.id && bill.state.id != 1;
            if (isGenerate) {
                  let fileName = 'Albarán' + (bill.serie ? bill.serie : '') + pad(+bill.number, 5) + '.pdf';
                  service.printIncomplete(bill.id, fileName, (data, erro) => {
                        toast.success('Se ha descargado la albarán', { position: toast.POSITION.TOP_CENTER });
                  });
            } else {
                  toast.error('Solo se puede imprimir albaranes ya generados', { position: toast.POSITION.TOP_CENTER });
            }
      };

      const handlePrintDirect = (bill) => {
            var isGenerate = bill && bill.number && bill.number != '' && bill.state && bill.state.id && bill.state.id != 1;
            if (isGenerate) {
                  let fileName = 'Albarán' + (bill.serie ? bill.serie : '') + pad(+bill.number, 5) + '.pdf';
                  service.printDirect(bill.id, fileName, (data, erro) => {
                        toast.success('Se ha descargado la albarán', { position: toast.POSITION.TOP_CENTER });
                  });
            } else {
                  toast.error('Solo se puede imprimir albaranes ya generados', { position: toast.POSITION.TOP_CENTER });
            }
      };

      const handlePrintIncompleteDirect = (bill) => {
            var isGenerate = bill && bill.number && bill.number != '' && bill.state && bill.state.id && bill.state.id != 1;
            if (isGenerate) {
                  let fileName = 'Albarán' + (bill.serie ? bill.serie : '') + pad(+bill.number, 5) + '.pdf';
                  service.printIncompleteDirect(bill.id, fileName, (data, erro) => {
                        toast.success('Se ha descargado la albarán', { position: toast.POSITION.TOP_CENTER });
                  });
            } else {
                  toast.error('Solo se puede imprimir albaranes ya generados', { position: toast.POSITION.TOP_CENTER });
            }
      };

      const handelBill = (bill) => {
            var update = { idBill: bill.id, openBill: !openBill };
            setState((currentState) => ({ ...currentState, ...update }));
      };

      const paintCell = {
            base: (value, object) => {
                  if (value) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
            discount: (value, object) => {
                  if (value) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
            iva: (value, object) => {
                  if (value) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
            total: (value, object) => {
                  if (value) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
            equivalencia: (value, object) => {
                  if (value) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
            tax: (value, object) => {
                  if (value) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
            paytotal: (value, object) => {
                  if (value) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
            payLess: (value, object) => {
                  if (value) {
                        return <>{value.toFixed(2)}</>;
                  } else {
                        return <></>;
                  }
            },
      };

      const generateBill = () => {
            billService.deliverynote(idBill, (data, error) => {
                  if (data && data.data && data.data.id) {
                        history.push('/bill/edit/' + data.data.id);
                        window.location.reload();
                  } else {
                        var update = { idBill: null, openBill: !openBill };
                        setState((currentState) => ({ ...currentState, ...update }));
                        toast.error(error.mes, { position: toast.POSITION.TOP_CENTER });
                  }
            });
      };

      const handleCloseBill = () => {
            var update = { idBill: null, openBill: !openBill };
            setState((currentState) => ({ ...currentState, ...update }));
      };

      const handlerSelected = (ids) => {
            var update = { ids: ids };
            setState((currentState) => ({ ...currentState, ...update }));
      };

      useEffect(() => {
            return () => {
                  setState({
                        openBill: false,
                        idBill: idBillInit,
                        ids: [],
                        eventClick: 0,
                  });
            };
      }, []);

      useEffect(() => {
            if (eventClick > 0) {
                  setState((currentState) => ({ ...currentState, ['eventClick']: 0 }));
                  if (!ids || ids.length == 0) {
                        toast.error('Debe seleccionar de que albaranes quiere crear la factura', { position: toast.POSITION.TOP_CENTER });
                  } else {
                        billService.deliverynotes(ids, (data, error) => {
                              if (data && data.data && data.data.id) {
                                    history.push('/bill/edit/' + data.data.id);
                                    window.location.reload();
                              } else {
                                    toast.error(error.mes, { position: toast.POSITION.TOP_CENTER });
                              }
                        });
                  }
            }
      }, [ids, eventClick]);

      const clickCreateBill = (evt) => {
            setState((currentState) => ({ ...currentState, ['eventClick']: 1 }));
      };

      const othersPanel = () => {
            return (
                  <Grid container>
                        <Grid item sm={9} xs={12} style={{ textAlign: 'left', display: 'flex', flexDirection: 'row' }}>
                              <span style={{ display: 'block', background: 'purple', width: '20px', height: '20px', margin: '0 1em' }}></span>
                              <b>CONTADO</b>
                              <span style={{ display: 'block', background: 'orange', width: '20px', height: '20px', margin: '0 1em' }}></span>
                              <b>CONTADO MEN.</b>
                              <span style={{ display: 'block', background: 'blue', width: '20px', height: '20px', margin: '0 1em' }}></span>
                              <b>TRANSFERENCIA</b>
                              <span style={{ display: 'block', background: 'green', width: '20px', height: '20px', margin: '0 1em' }}></span>
                              <b>GIRO MENSUAL</b>
                              <span style={{ display: 'block', background: 'lightseagreen', width: '20px', height: '20px', margin: '0 1em' }}></span>
                              <b>TPV</b>
                              <span style={{ display: 'block', background: 'fuchsia', width: '20px', height: '20px', margin: '0 1em' }}></span>
                              <b>GIRO SEMANAL</b>
                        </Grid>
                        <Grid item sm={3} xs={12} style={{ textAlign: 'right', marginBottom: '10px' }}>
                              <Button variant="contained" color="primary" style={{ marginLeft: '30px' }} onClick={clickCreateBill}>
                                    {' '}
                                    Crear factura{' '}
                              </Button>
                        </Grid>
                  </Grid>
            );
      };

      const conditionIsGenerate = (bill) => {
            var isGenerate = bill && bill.number && bill.number != '' && bill.state && bill.state.id && bill.state.id != 1;
            return isGenerate;
      };

      const conditionDelete = (bill) => {
            var isGenerate = bill && bill.number && bill.number != '' && bill.state && bill.state.id && bill.state.id != 1;
            return !isGenerate;
      };

      const conditionColor = (row) => {
            if (row['number']) {
                  if (!row['paymentType']['id'] || row['paymentType']['id'] == 1) {
                        //CONTADO
                        return 'purple';
                  } else if (!row['paymentType']['id'] || row['paymentType']['id'] == 3) {
                        //CONTADO MENSUAL
                        return 'orange';
                  } else if (!row['paymentType']['id'] || row['paymentType']['id'] == 7) {
                        //TRANSFERENCIA BANCARIA
                        return 'blue';
                  } else if (!row['paymentType']['id'] || row['paymentType']['id'] == 5) {
                        //GIRO REMESA BANCARIA MENSUAL
                        return 'green';
                  } else if (!row['paymentType']['id'] || row['paymentType']['id'] == 8) {
                        //TPV
                        return 'lightseagreen';
                  } else if (!row['paymentType']['id'] || row['paymentType']['id'] == 1001) {
                        //GIRO REMESA BANCARIA SEMANAL
                        return 'fuchsia';
                  } else {
                        return 'purple';
                  }
            } else {
                  return 'black';
            }
      };

      return (
            <>
                  <CrudTable
                        service={service}
                        urledit={'/deliverynote/edit/'}
                        urlnew={'/deliverynote/new/'}
                        filter={initFilter}
                        i18n={'deliverynote'}
                        fieldstable={['serie', 'number', 'date', 'client.comercialName|client.email|client.phone', 'total', 'paytotal', 'payLess', 'state.name', 'paymentType.name', 'isPay']}
                        exportfields={['number', 'date', 'client.code', 'client.comercialName', 'base', 'iva', 'equivalencia', 'total']}
                        defaultValues={{ serie: '', number: 'Borrador' }}
                        moreActions={[
                              { handle: handlePrintDirect, icon: <PrintIcon />, condition: conditionIsGenerate },
                              { handle: handlePrint, icon: <DownloadIcon />, condition: conditionIsGenerate },
                              { handle: handelBill, icon: <PublishIcon />, condition: conditionIsGenerate },
                        ]}
                        paintCell={paintCell}
                        labels={{ 'client.comercialName|client.email|client.phone': t('bill.field.client.name') }}
                        estructureForm={createEstructureFilter}
                        haveother={true}
                        othersPanel={othersPanel()}
                        selectable={true}
                        handlerSelected={handlerSelected}
                        order={true}
                        conditionColor={conditionColor}
                        expendedFilter={true}
                        orderColumns={{ serie: 'serie', number: 'number', date: 'date' }}
                  />
                  <ToastContainer />
                  <Modal open={openBill} onClose={handleCloseBill} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                        <Box sx={style}>
                              <div style={{ marginBottom: '30px' }}>¿Quieres hacer una factura de este albarán?</div>
                              <div>
                                    <Button variant="outlined" color="primary" style={{ marginRight: '30px' }} onClick={handleCloseBill}>
                                          {' '}
                                          No
                                    </Button>
                                    <Button variant="contained" color="primary" onClick={generateBill}>
                                          {' '}
                                          Sí{' '}
                                    </Button>
                              </div>
                        </Box>
                  </Modal>
            </>
      );
}
