import React, { useState, useEffect }  from 'react';
import CrudEmpty from "../../crud/CrudEmpty"
import { useTranslation } from 'react-i18next';
import {routeService} from "../../../services"
import { t } from "i18next";
import { Autocomplete, TextField,Grid } from '@mui/material';
import Moth from './Month';

const moment = require("moment");

export default function ParteRuta(props) {

  const { t } = useTranslation();
  const [routes, setRoutes] = useState<any>([]);
  const [routeSelected, setRouteSelected] = useState<any>(null);
  const [yearSelected, setYearSelected] = useState<any>(moment().year());


  const getLabelOption = function (option){
    if(option && option.name){
      if (typeof option.name === 'string'){
        return option.name
      } else {
        return ""
      }
    } else if (option && option.label && typeof option.label === 'string'){
      return option.label
    } else {
      return "";
    }
 } 

 const searchRoute = () => {
    routeService.combo((data,error) => {
        if(data.data){
          setRoutes(data.data);
          setRouteSelected(data.data[0]);
        }
    });
 }

 useEffect(() => {
    searchRoute()
  }, []);



  return (
    <CrudEmpty i18n={"parteruta"}>
        <Grid container style={{background:"white", padding: "0.3em 1em 1em", borderRadius: "3px"}}>
           <Grid item md={2} sm={3} xs={12}>  
              <Autocomplete
                   id={"route"}
                    options={routes}
                    getOptionLabel={getLabelOption}
                    value={routeSelected}
                    onChange={(event,value) => setRouteSelected(value)}
                    disableClearable={true }
                    renderInput={(params) => <TextField {...params} variant="standard" label={t("parteruta.field.route.id")}  inputProps={{ ...params.inputProps,  autoComplete: 'off' }} autoComplete="off"/>}
                  />
           </Grid> 
           <Grid item md={1} sm={1} xs={12}>  </Grid>
           <Grid item md={2} sm={3} xs={12}>  
              <Autocomplete
                   id={"year"}
                    options={[moment().year(), moment().year()-1,moment().year()-2,moment().year()-3,moment().year()-4,moment().year()-5,moment().year()-6,moment().year()-7,moment().year()-8]}
                    value={yearSelected}
                    onChange={(event,value) => setYearSelected(value)}
                    disableClearable={true }
                    renderInput={(params) => <TextField {...params} variant="standard" label={t("parteruta.field.year")}  inputProps={{ ...params.inputProps,  autoComplete: 'off' }} autoComplete="off"/>}
                  />
           </Grid>
        </Grid>
        <Grid container style={{background:"white", padding: "0.3em 1em 1em", borderRadius: "3px", marginTop:"2em"}}>
            <Moth month={1} t={t} route={routeSelected} year={yearSelected}/>
            <Moth month={2} t={t} route={routeSelected} year={yearSelected} />
            <Moth month={3} t={t} route={routeSelected} year={yearSelected} />
            <Moth month={4} t={t} route={routeSelected} year={yearSelected} />
            <Moth month={5} t={t} route={routeSelected} year={yearSelected} />
            <Moth month={6} t={t} route={routeSelected} year={yearSelected} />
            <Moth month={7} t={t} route={routeSelected} year={yearSelected} />
            <Moth month={8} t={t} route={routeSelected} year={yearSelected} />
            <Moth month={9} t={t} route={routeSelected} year={yearSelected} />
            <Moth month={10} t={t} route={routeSelected} year={yearSelected} />
            <Moth month={11} t={t} route={routeSelected} year={yearSelected} />
            <Moth month={12} t={t} route={routeSelected} year={yearSelected} />
        </Grid>
    </CrudEmpty>
  );


}
