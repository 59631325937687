import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../../crud/CrudForm"
import {carService as service } from "../../../../services"
import { useTranslation } from 'react-i18next';


  const initElement =  {
    name : null,
    code: null,
    matricula: null,
    brand: null,
    model: null,
    note: null
};


export default function FormStatebill() {
  const { t, i18n } = useTranslation();


  const estructureform =  [
    {
      type:"input",
      name: "code",
      col: 4
     },
     {
       type:"input",
       name: "matricula",
        col: 4
      },
      {
        type:"input",
        name: "brand",
        col: 4
      },
      {
        type:"input",
        name: "model",
        col: 4
      },
      {
        type:"input",
        name: "name",
        col: 4
      },
      {
        type:"editor",
        name: "note",
        col: 12
      },
   ];


  const recoveryElement = function(objectedit,element){


    objectedit['code']=element.code?element.code:"";
    objectedit['name']=element.name?element.name:"";
    objectedit['matricula']=element.matricula?element.matricula:"";
    objectedit['brand']=element.brand?element.brand:"";
    objectedit['model']=element.model?element.model:"";
    objectedit['note']=element.note?element.note:"";

    return objectedit;
  }



  const isValid = function(object){
      if(!object['code']){
        return t("car.error.code");
      }
      if(!object['matricula']){
        return t("car.error.matricula");
      }
      if(!object['brand']){
        return t("car.error.brand");
      }
      if(!object['model']){
        return t("car.error.model");
      }

      return null;
  }



  return (
    <CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        i18n={"car"}
        urledit={"/car/edit/"}
        urlCancel={"/car"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />

  );
}
