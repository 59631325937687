import React, {useEffect,useState} from 'react';
import Layout from '../../../layout/Layout';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';
import { billService } from '../../../../services';
import CrudEmpty from "../../../crud/CrudEmpty"
import Filter from "../../../filter/Filter";
import styled from "styled-components";
import { Autocomplete, TextField } from '@mui/material';
import 'moment/locale/es';
const moment = require("moment");



export default function ReportRemesa() {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [open, setOpen] = React.useState(true);
  const [data, setData] = React.useState<any>([]);
  const [filter, setFilter] = React.useState({ejercicio: moment().year()});


  const search = (filter) => {
      billService.reportremesa(filter.ejercicio, (data,error) => {
          if(data &&data.data) {
              setData(data.data);
          }
      });
  }


  useEffect(() => {
        setFilter({ejercicio:moment().year()});
        search({ejercicio:moment().year()});
  }, []);


  const searchFilter = (filter) => {
    console.log(filter);
    setFilter(filter);
    search(filter);
  }



  return (
    <CrudEmpty i18n={"reportremesa"}>
        <Grid container style={{background:"white", padding: "2em 1em 1em", borderRadius: "3px"}}>

            <Grid item sm={12} xs={12} style={{textAlign:'left', marginBottom: '10px'}}>
              <PanelRounded>
                        <h3>Ejercicio</h3>
                        <Grid container>
                                <Grid item sm={6} xs={6} style={{textAlign:'left', marginBottom: '10px'}}>
                                <Autocomplete
                                    id={"year"}
                                      options={[moment().year(), moment().year()-1,moment().year()-2,moment().year()-3,moment().year()-4,moment().year()-5,moment().year()-6,moment().year()-7,moment().year()-8]}
                                      value={filter.ejercicio}
                                      onChange={(event,value) => {filter.ejercicio = value; searchFilter(filter)}}
                                      disableClearable={true }
                                      renderInput={(params) => <TextField {...params} variant="standard" label={t("parteruta.field.year")}  inputProps={{ ...params.inputProps,  autoComplete: 'off' }} autoComplete="off"/>}
                                    />
                                </Grid>
                        </Grid>
                </PanelRounded>
            </Grid>

            <Grid item sm={12} xs={12} style={{textAlign:'center', background:"#64ac5b", color: "black", fontWeight: "bold", padding: "1em 0em"}}>
                  <Grid container>
                          <Grid item sm={7} xs={7}>
                            <Grid container>
                                <Grid item sm={1} xs={1}>
                                        Código.Cl
                                </Grid>
                                <Grid item sm={3} xs={2}>
                                        Cliente
                                </Grid>
                                <Grid item sm={2} xs={2}>
                                        Fecha de Fact.
                                </Grid>
                                <Grid item sm={2} xs={2}>
                                        Nº Factura
                                </Grid>
                                <Grid item sm={2} xs={2}>
                                        Importe
                                </Grid>
                                <Grid item sm={2} xs={2}>
                                        Fecha de vencimiento
                                </Grid>
                            </Grid>
                          </Grid>
                          <Grid item sm={1} xs={1}>
                                Fecha de pago
                          </Grid>
                          <Grid item sm={2} xs={2}>
                                   Remesa
                          </Grid>
                          <Grid item sm={2} xs={2}>
                                   Total
                          </Grid>
                          
                  </Grid>
            </Grid>
            {data && data.map((values,i) => (
              <Grid item sm={12} xs={12} style={{border: "1px solid #ccc"}}>
                  <Grid container style={{background: moment(values).day() == 0 ? "lightcoral":"white"}}>
                          
                       <Grid item sm={7} xs={7}>
                            {values.bills && values.bills.length > 0 && values.bills.map((v,i) => (
                                <Grid container style={{borderTop: (i > 0?"1px ":"0px ") +"solid #ccc"}}>
                                <Grid container>
                                        <Grid item sm={1} xs={1} style={{paddingLeft: "1em"}}>
                                                {v.client.code}
                                        </Grid>
                                        <Grid item sm={3} xs={2}>
                                                {v.client.comercialName}
                                        </Grid>
                                        <Grid item sm={2} xs={2} style={{textAlign: "center"}}>
                                                {v.date ? moment(v.date).format("DD-MM-YYYY"):""}
                                        </Grid>
                                        <Grid item sm={2} xs={2} style={{textAlign: "center"}}>
                                                {v.serieAndNumber}
                                        </Grid>
                                        <Grid item sm={2} xs={2} style={{textAlign: "right"}}>
                                                 {v.total.toFixed(2)}
                                        </Grid>
                                        <Grid item sm={2} xs={2} style={{textAlign: "center"}}>
                                                {v.finishPay ? moment(v.finishPay).format("DD-MM-YYYY"):""}
                                        </Grid>
                                  </Grid>
                                </Grid>
                            ))}

                            
                          </Grid>
                          <Grid item sm={1} xs={1} style={{textAlign: "center", borderLeft:"1px solid #ccc", display: "flex", justifyContent: "center",alignItems:"center"}}>
                                {values.date ? moment(values.date).format("DD-MM-YYYY"):""}
                          </Grid>
                          <Grid item sm={2} xs={2} style={{display: "flex", justifyContent: "center",alignItems:"center"}}>
                                   REMESA
                          </Grid>
                          <Grid item sm={2} xs={2} style={{display: "flex", justifyContent: "end",alignItems:"center", paddingRight: "1em"}}>
                                {values.total.toFixed(2)}
                          </Grid>
                          
                  </Grid>
            </Grid>
            ))} 

        </Grid>







    </CrudEmpty>
  );
}

const PanelRounded = styled.div(props => ({
    display: 'block',
    padding: '1em',
    position: 'relative',
    border: '1px solid #ccc',
    borderRadius: '3px',
    "& h3":{
        color: "black",
        position: "absolute",
        top: "-2em",
        left: "2em",
        background: "white",
        padding: "0.2em 1em",
        display: "inline-block",

    }

  }));

const SpanAmmount = styled.span(props => ({
    display: 'inline-block',
    fontWeight: 'bold',
    padding: "0.2em 1em"

  }));
