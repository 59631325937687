import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from "react-router-dom";
import toast from 'toasted-notes'
import 'toasted-notes/src/styles.css';
import CrudForm from "../../../crud/CrudForm"
import {stateService as service,countryService } from "../../../../services"
import { useTranslation } from 'react-i18next';


  const initElement =  {
    name : null,
    country: {id:null,name:""},
    country_code:null,
    fips_code:null,
    iso2:null,
    type:null,
    latitude:0.0,
    longitude:0.0,
    flag:null,
    wikiDataId:null,
};


export default function FormState() {
  const { t, i18n } = useTranslation();


  const setValueCountry = (setValueField,objectform,value) => {
    if(value && value){
       var newobject =  setValueField(objectform,"country.id",value.key);
       newobject =  setValueField(objectform,"country_code",value.other);
       return newobject;
    } else {
       return objectform;
    }
 }

  const estructureform =  [
      {
        type:"input",
        name: "name",
        col: 4
       },
      {
        type:"autocomplete",
        name: "country.id",
        service:countryService.combo,
        setvalue: setValueCountry,
        col: 4
       },
       {
         type:"input",
         name: "fips_code",
         col: 4
       },
       {
         type:"input",
         name: "iso2",
         col: 4
       },
       {
         type:"input",
         name: "type",
         col: 4
       },
       {
         type:"input",
         name: "latitude",
         col: 4
       },
       {
         type:"input",
         name: "longitude",
         col: 4
       },
       {
         type:"input",
         name: "flag",
         col: 4
       },
       {
         type:"input",
         name: "wikiDataId",
         col: 4
       },
   ];

  const recoveryElement = function(objectedit,element){


    objectedit['name']=element.name?element.name:"";
    objectedit['country']={id:(element.country && element.country.id? element.country.id:null),name:(element.name && element.country.name? element.country.name:null)};
    objectedit['country_code']=element.country_code?element.country_code:"";
    objectedit['iso2']=element.iso2?element.iso2:"";
    objectedit['type']=element.type?element.type:"";
    objectedit['latitude']=element.latitude?element.latitude:"";
    objectedit['longitude']=element.longitude?element.longitude:"";
    objectedit['flag']=element.flag?element.flag:"";
    objectedit['wikiDataId']=element.wikiDataId?element.wikiDataId:"";

    return objectedit;
  }




  const isValid = function(object){
      if(!object['name']){
        return t("state.error.name");
      }
      if(!object['country'] || !object['country']['id']){
        return t("state.error.country");
      }

      return null;
  }



  return (
    <CrudForm
        objectform = {{...initElement}}
        estructureform = {estructureform}
        i18n={"state"}
        urledit={"/state/edit/"}
        urlCancel={"/state"}
        service = {service}
        recoveryElement = {recoveryElement}
        valid = {isValid}
      />

  );
}
