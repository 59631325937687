import CrudTable from "../../../crud/CrudTable"
import {producttpvService as service, categorytpvService} from "../../../../services/tpv"
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'

const initFilter =  {
    code : null,
    name : null,
    fromPrice: null,
    toPrice: null,
    category: null,
    active:"true",

};


export default function ProductTPV() {
  const { t, i18n } = useTranslation();

  const createEstructureFilter =  [    
     {
       type:"input",
       name: "code",
       autoFocus:true,
       col: 4
      },
     {
       type:"input",
       name: "name",
       col: 4
      },
      {
        type:"autocomplete",
        name: "category",
        service:categorytpvService.combo,
        relationfield:'subcategory',
        servicerelation:categorytpvService.comboChildren,
        col: 4,
      },
      {
         type:"autocomplete",
         name: "subcategory",
         options:[],
         col: 4
       }
  ];

  const pritnprice = () => {
    service.printPrice("Listadeprecios.pdf", (data,error) => {

    });
  }

  const othersPanel = () => {
    return <Grid container> <Grid item sm={12} xs={12} style={{textAlign:'right', marginBottom: '10px'}}>
              <Button variant="contained" color="primary" style={{marginLeft:'30px'}} onClick={pritnprice}> Imprimir lista de precios </Button>
          </Grid>
          </Grid>
  }


  return (
    <CrudTable
          service={service}
          urledit={"/tpv/product/edit/"}
          urlnew={"/tpv/product/new/"}
          filter={initFilter}
          i18n={"product"}
          expendedFilter={true}
          hiddenButton={true}
          notexport={true}
          fieldstable={["imageprin","code","name","price","category.name"]}
          order={true}
          orderColumns={{"code":"code", "name":"name",  "price":"price"}}
          estructureForm={createEstructureFilter}
          deleteaction={false}
          editable={false}
          haveother={true}
          othersPanel={othersPanel()} />
  );


}